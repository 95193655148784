module.exports = {
  login:"Se connecter",
  or:"ou",
  member_login:"Connexion membre",
  signin_with:"Se connecter avec :",
  signin_fb:"Se connecter avec Facebook",
  signin_google:"Se connecter avec Google",
  email_address: "Adresse e-mail",
  password: "Mot de passe",
  confirm_password: 'Ressaisissez le mot de passe',
  been_logged_out_title: "Vous avez été déconnecté",
  been_logged_out_desc: "Votre compte a été accédé depuis un autre endroit",
  forgot_password:"Mot de passe oublié",
  create_free_account:"Créez votre compte gratuit",
  create_an_account:"Créer un compte",
  create_account:"Créer un compte",
  or_go_back_to:"Ou retourner à",
  name:"Nom",
  check_to_reset:"Veuillez vérifier votre boîte de réception pour réinitialiser votre mot de passe.",
  check_to_verify_header:"Vérifiez votre e-mail",
  check_to_verify:'Votre compte a été créé ! Si vous ne recevez pas le message de confirmation, veuillez vérifier votre <span style="font-weight:bold">Dossier Spam]</span>.',
  send_to_verify_header:"E-mail de vérification envoyé",
  send_to_verify:'Veuillez vérifier votre e-mail pour le lien de vérification afin d\'activer votre compte. Si vous ne recevez pas le message de confirmation, veuillez vérifier votre <span style="font-weight:bold">Dossier Spam</span>.',
  term_of_service_description1: "En vous connectant à Taption, vous acceptez nos ",
  term_of_service:"conditions d'utilisation",
  term_of_service_description2: " et vous avez lu et compris la ",
  privacy_policy:"politique de confidentialité",
  resend_verification:"Renvoyer l'e-mail de vérification",
  invite_desc:"Merci à {0}<br>Inscrivez-vous et obtenez 15 minutes gratuitement",
  login_desc:"Inscrivez-vous et obtenez 15 minutes gratuitement",
  referral_note:"Aucune carte de crédit requise.",
  redirecting:"Redirection en cours..."
}