module.exports = { 
    error_title: '404',
    error_desc: 'A página que está a procurar não existe 😟',
    share_network_error: 'Não tem permissão ou existe um problema de rede.',
    permission_error: 'Não tem permissão para executar esta ação, por favor, contacte o administrador da conta.',
    share_email_exist: 'O email já existe.',
    user_not_found: 'Não foi possível encontrar o utilizador.',
    email_invalid: 'Email inválido',
    email_not_registered: 'Email não registado no Taption',
    email_duplicate: 'Já adicionou este email à sua conta',
    email_slots_required: 'Por favor, adquira mais assentos para adicionar novos membros.',
    youtube_url_invalid: 'URL do YouTube inválido',
    youtube_url_required: 'É necessário o URL do YouTube',
    directlink_url_invalid: 'Não suportamos o URL fornecido neste momento, por favor, envie-nos um email para help@taption.com para solicitar suporte',
    directlink_url_required: 'URL obrigatório',
    required: 'Obrigatório',
    size_error: 'O tamanho do seu ficheiro não pode ser superior a {0}GB.',
    size_error_mb: 'O tamanho do seu ficheiro não pode ser superior a {0}MB.',
    verify_email: 'Por favor, verifique o seu email para ativar a sua conta. Se não receber a mensagem de confirmação, por favor, verifique a sua pasta de [Spam].',
    email_must_be_valid: 'O email deve ser válido',
    password_not_match: 'As palavras-passe não coincidem',
    invalid_youtube: 'Não foi possível recuperar o vídeo, por favor tente outro vídeo ou tente novamente mais tarde.',
    unsupported_youtube: 'Este vídeo está restrito, por favor tente outro vídeo.',
    unsupported_direct_video: 'Este vídeo está restrito<br>(Link de vídeo ao vivo não suportado)',
    must_be_more_than: 'Deve ter mais de {0} caracteres',
    must_be_less_than: 'Deve ter menos de {0} caracteres',
    must_be_more_than_words: 'Deve ter mais de {0} palavras',
    trial_limit_error: 'A versão de teste tem um limite de 15 minutos. Por favor, adquira minutos ou subscreva para remover este limite.',
    language_required: 'O idioma é obrigatório',
    title_required: 'O título é obrigatório',
    title_length: 'O título deve ter no máximo 80 caracteres',
    purpose_required: 'O objetivo é obrigatório',
    speakers_number_required: 'O número de locutores é obrigatório',
    category_required: 'A categoria é obrigatória',
    method_required: 'Por favor, selecione uma forma de transcrever o seu media',
    max_length: 'Deve ter menos de {0} caracteres',
    max_char_required: 'Por favor, insira um valor entre {0} e 1000. (Valor sugerido: {1})',
    insufficient_fund_title: 'Minutos insuficientes',
    insufficient_ai_desc: 'Créditos insuficientes, adquira mais créditos de IA para continuar.',
    exceed_trial_limit_title: 'O vídeo excede o limite de teste',
    file_too_large_title: 'O tamanho do vídeo é demasiado grande',
    not_supported_type_title: 'Tipo de vídeo não suportado',
    insufficient_fund_tip: 'Adquira mais minutos e tente novamente.',
    exceed_trial_limit_tip: 'A versão de teste oferece 1 vídeo gratuito com menos de 15 minutos. Adquira minutos ou atualize para Premium para remover este limite',
    file_too_large_tip: 'O tamanho do ficheiro não pode exceder 2GB',
    not_supported_type_tip: 'Formato de vídeo suportado: mov, avi, wmv, flv, mpeg, ogg, mp4',
    media_no_audio: 'O ficheiro media fornecido não tem áudio',
    network_upload_fail_title: 'A sua rede é instável, por favor tente novamente ou use o Google Drive',
    network_upload_fail_tip: 'Pode selecionar o seu ficheiro media do Google Drive ao carregar.',
    transcribe_prepare_fail_title: 'O servidor está ocupado, por favor tente mais tarde',
    transcribe_upload_fail_title: 'O servidor está ocupado, por favor tente mais tarde',
    transcribe_merge_fail_title: 'O servidor está ocupado, por favor tente mais tarde',
    transcribe_progress_fail_title: 'O servidor está ocupado, por favor tente mais tarde',
    transcribe_result_fail_title: 'O servidor está ocupado, por favor tente mais tarde',
    transcribe_error_fail_title: 'O servidor está ocupado, por favor tente mais tarde',
    directlink_url_error_title: 'O link fornecido não é válido',
    directlink_url_error_tip: 'Por favor, certifique-se de que as permissões para o link estão disponíveis para o público em geral',
    exceed_trial_usage_title: 'Excedeu o limite da versão de teste',
    exceed_trial_usage_tip: 'Adquira minutos ou subscreva para continuar a utilizar o serviço',
    need_payment_title: 'Subscrição suspensa',
    need_payment_tip: 'Por favor, resolva o problema de pagamento para continuar a utilizar o serviço',
    exceed_usage_title: 'Excedeu o limite de utilização',
    exceed_usage_tip: 'Por favor, contacte o nosso suporte.',
    file_too_long_title: 'O vídeo é demasiado longo',
    file_too_long_tip: 'Por favor, mantenha o seu vídeo dentro do limite de 2 horas',
    download_youtube_video_title: 'Não foi possível transferir este vídeo, por favor tente outro vídeo ou tente novamente mais tarde.',
    download_youtube_video_tip: 'Por favor, tente carregar novamente. Se o problema persistir, tente carregar outro vídeo ou contacte o nosso suporte.',
    video_corrupted_title: 'Não foi possível ler este ficheiro, por favor, certifique-se de que o ficheiro é válido',
    video_corrupted_tip: 'Certifique-se de que o ficheiro é válido ou contacte-nos através do email help@taption.com',
    system_maintanance_title: 'Aviso',
    system_maintanance: 'Manutenção do sistema. Por favor, tente novamente mais tarde ao atualizar a página.',
    error_add_title: 'Erro',
    error_add_desc: 'Não foi possível adicionar a frase corrigida ao dicionário.',
    error_warning: 'Aviso',
    warning_no_search_found: 'Não foi possível encontrar "{0}"',
    time_message: 'Existem <strong>{0}</strong> instâncias de sobreposição de carimbos de tempo de legendas, o que pode fazer com que softwares de terceiros os leiam incorretamente após a exportação.',
    time_message1: 'Existem <strong>{0}</strong> instâncias de carimbos de tempo de legendas que são muito curtas e necessitam de atenção.',
    time_message2: '(Posições de carimbos de tempo em laranja podem ser ajustadas utilizando o <strong>Editor de Timeline</strong> no canto inferior esquerdo)',
    overlap: 'Os carimbos de tempo sobrepõem-se aos vizinhos',
    short: 'A duração desta legenda é inferior a 0,1 segundos',
    error_merge_one: 'Tem uma exportação de vídeo em curso, por favor aguarde a sua conclusão e tente novamente.',
    retry_upload: 'Repetir carregamento',
    label_required: 'Por favor, selecione pelo menos uma etiqueta',
    label_name_required: 'O nome da etiqueta é obrigatório',
    label_name_duplicate: 'O nome da etiqueta já existe',
    srt_file_required: 'O ficheiro SRT é obrigatório',
    srt_file_invalid: 'Ficheiro SRT inválido ou vazio',
    txt_file_required: 'O ficheiro TXT é obrigatório',
    txt_file_invalid: 'Ficheiro TXT inválido ou vazio',
    media_file: 'Ficheiro media obrigatório',
    media_srt_mismatch: 'O ficheiro media selecionado é mais curto que o ficheiro SRT selecionado',
    subscription_pay_failed_title: 'O pagamento da sua subscrição falhou',
    subscription_pay_failed_desc: 'Por favor, faça uma das seguintes opções:',
    subscription_pay_failed_resolve1: 'Atualize o seu método de pagamento com um novo cartão de crédito em Conta',
    subscription_pay_failed_resolve2: 'Certifique-se de que o seu cartão tem fundos suficientes ou contacte o emissor do cartão de crédito e, em seguida, notifique-nos através do suporte@taption.com',
    subscription_pay_failed_resolve3: 'Cancele a sua subscrição atual',
    maximum_videos_title: 'A conta gratuita pode armazenar um máximo de {0} vídeos',
    maximum_videos_title1: 'A conta Básica pode armazenar um máximo de {0} vídeos',
    maximum_videos_resolve1: 'Elimine vídeos existentes para manter o total abaixo de {0}',
    maximum_videos_resolve2: 'Adquira ou atualize para um dos nossos <a href="https://app.taption.com/upgrade">planos</a> para aumentar ou remover os limites',
    manual_warning: 'Selecionar <strong>Manual</strong> dará uma transcrição vazia que exigirá que introduza o seu próprio texto. <br>Se o objetivo é obter uma transcrição automatizada, por favor selecione <strong>Transcrição Automatizada</strong>.',
    txt_mismatch_error: 'O idioma selecionado não coincide com o ficheiro de texto fornecido.<br>Por favor, certifique-se de que os idiomas coincidem para obter o melhor resultado.',
    txt_invalid_error: 'Não foi possível detetar a codificação do ficheiro fornecido.<br>Por favor, certifique-se de que o ficheiro está guardado com a codificação correta.<br><span style="font-size:12px">(<strong>Exemplo</strong>: Abra o seu ficheiro de texto, <strong>Guardar como</strong>→<strong>Codificação</strong> selecione <strong>UTF-8</strong>)</span>',
    language_mismatch_error: 'O idioma do vídeo carregado pode não coincidir com o idioma que selecionou. Isto pode reduzir a precisão da transcrição e levar a dificuldades na tradução para outros idiomas.<br><br>Se deseja traduzir o vídeo para outro idioma, por favor certifique-se de que seleciona o idioma correto do vídeo durante o processo de upload. Depois de concluído, use o botão <v-btn style="padding:3px 20px;margin:0px 5px;text-transform: capitalize;letter-spacing:0px;" class="translate-btn"><i style="font-size:14px;" aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-translate"></i><span class="text">Traduzir</span></v-btn> localizado no canto superior direito desta página para prosseguir com a tradução.',
    "auth/invalid-email": 'O seu email é inválido.',
    "auth/wrong-password": 'A sua palavra-passe está incorreta ou esta conta não existe.',
    "auth/email-already-in-use": 'O endereço de email já está a ser utilizado por outra conta.',
    "auth/user-not-found": 'A sua palavra-passe está incorreta ou esta conta não existe.',
    confirm_password_desc: 'Por favor, insira novamente sua senha',
    confirm_password_not_match: 'A senha não coincide',
}