module.exports = {
    login:'로그인',
    or:'또는',
    member_login:'멤버로그인',
    signin_with:'회원가입방법:',
    signin_fb:'페이스북으로 회원가입',
    signin_google:'구글로 회원가입',
    email_address: '이메일 주소',
    password: '비밀번호',
    confirm_password: '비밀번호를 다시 입력',
    been_logged_out_title: '로그아웃되었습니다.',
    // been_logged_out_desc: '다시 로그인해주세요',
    been_logged_out_desc: '다른 위치에서 계정에 액세스했습니다.',
    forgot_password:'비밀번호를 잊으셨나요?',
    create_free_account:'무료 회원가입',
    create_an_account:'회원가입',
    create_account:'회원가입',
    or_go_back_to:'또는 로 돌아가기', // insert destination before '로' without space according to the Korean grammar. If there's no destination, remove '로'.
    name:'성함',
    check_to_reset:'메일로 비밀번호 변경 안내가 발송되었습니다. 메일 수신함을 확인해주세요',
    check_to_verify_header:'이메일을 인증해주세요',
    check_to_verify:'회원가입이 완료되었습니다!확인 메일을 받지 못하셨다면, <span style="font-weight:bold">스팸메일</span>을 확인해주세요.',
    send_to_verify_header:'인증메일이 발송 완료되었습니다.',
    send_to_verify:'계정 활성화를 위한 인증메일이 발송되었습니다. 메일 수신함을 확인해주세요. 확인 메일을 받지 못하셨다면, <span style="font-weight:bold">스팸메일</span>을 확인해주세요.',
    term_of_service_description1: '탭션에 회원가입을 함으로서,',
    term_of_service:'이용규약에 동의합니다.',
    term_of_service_description2: '또한 읽고 이해했습니다.', // Like Japanese, row 27 should be come before row 26 in terms of grammar in Korea.
    privacy_policy:'개인정보 정책',
    resend_verification:'인증메일 재발송',
    //invite_desc:'Thanks to {0}<br>구독하시면 추가로 {1}분 더 무료이용 가능합니다. 무료 시험판에서는 15분 이내의 무료 받아쓰기가 제공됩니다.',
    invite_desc:'{0}를 통해<br>지금 회원가입하시고 무료 받아쓰기 이용시간 15분을 받아가세요',
    login_desc:'지금 회원가입하시고 무료이용시간 15분을 받아가세요',
    referral_note:'결제정보를 제공할 필요가 없습니다',
    redirecting:'리디렉션중...'
  }
  