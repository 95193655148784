module.exports = {
  upload: "Télécharger",
  upload_language: "Sélectionner la langue parlée",
  upload_method: "Sélectionner la méthode de transcription",
  delete_title: "Supprimer la transcription ?",
  delete_description: "Cette action ne peut pas être annulée.",
  delete_ok: "Supprimer",
  delete_cancel: "Annuler",
  remember_selection: "Se souvenir de moi",
  send_email: "M'envoyer un e-mail une fois terminé.",
  card_share: "Partager",
  card_delete: "Supprimer",
  upload_media: "Télécharger un média",
  upload_error_title: "Erreur",
  upload_error: "Vous n'avez pas assez de minutes.",
  transcribing_description: "Nous transcrivons votre fichier. <br>Le temps de traitement prend environ<br>la durée du fichier.<br> Nous vous enverrons un e-mail une fois prêt.",
  transcribing: "En cours de traitement",
  preparing: "Préparation",
  update_title: "Mettre à jour le titre",
  transcript_title: "Titre",
  add_more: "Ajouter",

  upload_limit_exceeded_title: "Durée maximale dépassée",
  upload_limit_exceeded: "Veuillez limiter la durée de votre vidéo à moins de 5 heures.",

  initializing_description: "Nous traitons votre fichier. <br>Cela prendra quelques minutes, veuillez patienter.",
  initializing: "En attente",
  translating_description: "Nous traduisons votre fichier. <br>Cela prendra quelques minutes, veuillez patienter.",
  translating: "Traduction en cours",
  error_description: "Veuillez nous contacter pour plus d'informations",
  error_info: "Code d'erreur : {0}",
  error: "Une erreur s'est produite",
  error_minutes: "{0} <br> Vos minutes ont été restituées.<br> Actualisez votre navigateur pour voir le nombre de minutes mis à jour.",

  please_wait: "Veuillez patienter...",
  loading: "Chargement...",
  upgrade_to_share: "Veuillez {0} pour inviter des collègues à partager et modifier vos transcriptions.",
  share_link_with: "Partager avec une autorisation en lecture seule",
  share_with_editable_permission: "Partager avec une autorisation de modification",
  share_with: "ex : exemple1@gmail.com,exemple2@gmail.com",
  share_with_currently: "Actuellement partagé avec",
  share_empty: "Veuillez aller dans <a href=\"\\management\" style=\"color:#2FC59F;\">Gestion</a> pour ajouter des utilisateurs partageables.",
  uploading: "Téléchargement en cours",
  shared: "Partagé",
  thumbnail: "Miniature",
  upload_purpose: "Sélectionner votre format de sortie",
  upload_number: "Nombre d'intervenants",
  upload_category: "Choisissez la meilleure catégorie pour votre vidéo",
  upload_clipped: "Plage horaire",
  clipped_adjustments_desc: "Cliquez sur la poignée du curseur et appuyez sur les touches fléchées gauche/droite pour des ajustements plus précis.",
  clipped_description: "Nous n'extrairons et ne transcrirons que dans la plage horaire sélectionnée du fichier média.<br>Prend en charge les médias de type Youtube, mp3 et mp4.",
  clipped_warning: "Cela augmentera le temps de traitement",
  clipped_length_warning: "Cette fonctionnalité est disponible pour les médias de plus d'une minute",
  purpose_description: "Cela nous permet de formater la transcription pour vous aider dans votre édition.",

  speaker_unsure: "Séparation automatique",

  purpose_speakers: "Décomposer automatiquement votre transcription en <strong>étiquetant chaque intervenant</strong>",
  purpose_transcript_desc: "(ex : Ajouter des sous-titres à une vidéo, sans ponctuation)",
  purpose_captions: "Décomposer automatiquement votre transcription pour les <strong>sous-titres</strong>",
  purpose_caption_desc: "(ex : Transcriptions pour vos réunions, avec ponctuation)",
  purpose_article: "Transcription avec ponctuation <strong>sans méthode de formatage spécifique</strong>",
  purpose_translate_desc: "(ex : Transcriptions pour produire des articles, avec ponctuation)",
  purpose_original: "Décomposer votre transcription de manière identique à votre <strong>fichier d'entrée</strong>",

  speakers_time: "Intervenants",
  captions_time: "Sous-titres",
  article_time: "Fichier d'entrée",
  original_time: "Fichier d'entrée",
  none_time: "",
  
  captions_time_desc: "Décomposer automatiquement votre transcription dans le but de sous-titrer.",
  speakers_time_desc: "Décomposer automatiquement votre transcription en étiquetant chaque intervenant.",
  article_time_desc: "La transcription aura le même format que le fichier texte d'entrée que vous avez fourni.",
  original_time_desc: "La transcription aura le même format que le fichier texte d'entrée que vous avez fourni.",
  none_time_desc: "",

  purpose_other: "Je n'ai pas encore décidé",
  create: "Créer",
  home: "Accueil",
  management_corp: "Gestion",
  management: "Gestion",
  account: "Compte",
  upgrade: "Mettre à niveau",
  faq: "FAQ",
  logout: "Déconnexion",
  return: "Retour à l'édition",
  drag_drop: "Glissez-déposez votre fichier vidéo/audio ici",
  select_from: "Sélectionner depuis votre appareil",
  or_select_from: "Ou sélectionner depuis",
  common_languages: "Langues courantes",
  additional_languages: "Langues supplémentaires",

  friendly_notice: "Conseils pour améliorer la précision",
  friendly_notice_desc0: "1. La langue sélectionnée doit correspondre à celle de la vidéo, la traduction peut être effectuée après la transcription",
  friendly_notice_desc: "2. L'audio est clair et comporte peu de musique de fond ou de bruits",
  friendly_notice_desc2: "3. Évitez de télécharger des vidéos liées à la musique ou au chant",
  friendly_notice_desc3: "4. Modifier la piste sonore de quelque manière que ce soit peut empêcher la transcription",
  friendly_notice_desc4: "5. Il est préférable de télécharger des fichiers *.mp4 ou *.mp3",

  google_drive_upgrade_title: "Sélectionner depuis Google Drive",
  google_drive_upgrade_desc: "Vous pouvez éviter l'attente du téléchargement et accélérer votre processus de transcription en vous abonnant.",
  trial_check_desc: "J'accepte d'utiliser la transcription uniquement à des fins personnelles et non commerciales. (Achetez des minutes ou un abonnement premium pour obtenir une licence commerciale)",

  browser_unsupported_title: "Navigateur non pris en charge",
  browser_unsupported_desc: "Veuillez utiliser Chrome sur un ordinateur pour une meilleure expérience d'édition.",
  browser_unsupported_mobile_desc: "Veuillez utiliser Chrome pour une meilleure expérience d'édition.",
  browser_translated_injected_title: "Veuillez désactiver la traduction de la page web",
  browser_translated_injected_desc: "Ne pas désactiver la traduction de la page web peut entraîner des comportements imprévisibles. (ex : impossibilité de sauvegarder les modifications et déconnexions)",
  browser_unsupported_step1: "1. Cliquez sur \"...\" ou \"⋮\" dans le coin inférieur droit ou supérieur droit",
  browser_unsupported_step2: "2. Sélectionnez Ouvrir dans le navigateur",

  individual_account: "Mon compte",
  corp_account_selection_desc: "Lorsqu'un utilisateur d'abonnement groupé vous ajoute à son compte, il apparaîtra dans la sélection déroulante",
  unsave_change_title: "Vous avez des modifications non enregistrées",
  unsave_change_desc: "Êtes-vous sûr de vouloir quitter ?",
  label_name: "Nom de l'étiquette",
  new_label: "Nouvelle étiquette",
  select_label: "Sélectionner l'accès à l'étiquette",
  label_dialog_header: "Gestion des étiquettes",
  label_dialog_desc: "Modifier et réorganiser vos étiquettes",
  label_empty: "Aucune étiquette",
  label_all: "Tous les fichiers",
  label_select_header: "Étiquette",
  label_limit_error: "La limite d'étiquettes a été atteinte. Passez à l'abonnement Premium ou à l'abonnement groupé pour débloquer la limite",
  segment_sample: "Exemple de sortie",
  zoom_no_data: "Il n'y a pas d'enregistrements au cours des 2 dernières années...",
  zoom_desc: "N'affichera que les enregistrements des 2 dernières années.",
  zoom_loading: "Chargement des enregistrements...",
  import_srt: "Importer SRT",
  srt_header: "Méthode de transcription",
  srt_select: "Sélectionner le fichier SRT",
  srt_desc: "En attente du fichier SRT...",
  import_txt: "Importer TXT",
  txt_select: "Sélectionner le fichier texte",
  txt_desc: "En attente du fichier TXT...",
  direct_link: "<span class=\"directlink-text-en\">Lien direct</span>",

  multi_upload: "Les abonnés peuvent transcrire plusieurs fichiers à la fois.",
  category: "Catégorie",
  max_char_header: "Nombre maximum de caractères",
  max_char_desc: "Le nombre maximum de caractères pour les sous-titres à afficher à la fois.<br> La transcription sera divisée en sections selon ce seuil.<br>Valeur recommandée : 95<br> Valeur recommandée pour le chinois/japonais/coréen : 20",
  language_tooltip: "Veuillez sélectionner la langue parlée pour cette vidéo. <br> Pour traduire dans d'autres langues, <br>allez dans la plateforme d'édition et traduisez depuis le coin supérieur droit après la transcription.<br><br> Si votre sélection pour <span style=\"color:#2FC59F\">Méthode de transcription</span> ci-dessous est <span style=\"color:#2FC59F\">Importer un fichier SRT</span>, <br> assurez-vous de sélectionner la langue identique à celle du fichier SRT fourni.",
  import_txt_tooltip: "Nous ajouterons des horodatages au fichier texte fourni. <br><strong>{0}</strong> : {1}",

  home_notice_title: "Économisez plus de minutes",
  home_notice_desc: "Notre nouveau système de déduction de minutes omettra désormais les secondes supplémentaires ! <div class=\"desc\">Exemple : Le téléchargement d'un média de 15 minutes et 59 secondes déduira 15 minutes. (Le téléchargement d'un média de moins d'une minute sera gratuit 🙂)</div>",
  no_name: "Sans titre",
  upload_language_reminder_title: "Assurez-vous que la langue sélectionnée correspond à la langue parlée dans la vidéo",
  upload_language_reminder_desc: "Exemple : Si la vidéo est en anglais, veuillez sélectionner \"Anglais\" comme langue. <br> Si vous souhaitez traduire les sous-titres ou les transcriptions de la vidéo, convertissez-les d'abord en texte, puis entrez dans la page d'édition. <br> Une fois dans la page d'édition, utilisez l'option \"Traduire\" dans le coin supérieur droit pour traduire la vidéo."
}