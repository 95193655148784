module.exports = {
    account_settings: '帐户设置',
    name: '名称',
    company: '公司',
    company_description:'发票上的公司名称会以此名称显示',
    institution_name:'公司名称/帐号名称',
    email: '电子邮件',
    card: ' 信用卡',
    create_password:'设置新密码',
    password: '密码',
    status: '帐户等级',
    new_password: '新密码',
    confirm_password: '确认新密码',
    update: '更改',
    update_name: '更改名称',
    update_corp_name: '更改公司名称',
    update_taxid_name: '更改税务识别码',
    update_email: '更改电子邮件',
    update_password: '更改密码',
  
    upgrade: '升级',
    unsubscribe: '取消订阅',
  
    update_name_description: '',
    update_email_description: '请提供您的新电子邮件',
    update_email_password_confirm: '为了安全，请输入您的密码',
    update_password_description: '',
    thank_you_interest:'欢迎与我们联络',
    reach_out:'电子邮件: {0}',
    update_language:'更改网站语言',
    reactivate:'继续订阅',
    
    credit_card_update:'您的信用卡已更新',
    password_update:'密码更新成功',
    click_here:'点击这',
    if_not_direct:'如果3秒内没有自动返回登入页面...',
    email_verified:'成功验证了信箱',
    email_restored:'成功更新信箱',

    email_unable_to_authenticate: '我们不支援此类型电子信箱',
    email_unable_to_authenticate_desc: '请用有效信箱注册来获得免费分钟',
    purchase_records: '订阅发票',
    purchase_date: '日期',
    purchase_amount: '金额',
    purchase_download: '下载',
    purchase_view: '查看',
    purchase_recent:'最近20笔⟶',
    purchase_unit:'美元',

    role:'用户角色',
    view:'阅读',
    upload:'上传并转成文字',
    edit:'编辑',
    translate:'翻译',
    export:'影片导出',
    analysis:'AI分析',

    coupon_title:'优惠码',
    coupon_redeem: '输入',
    admin: '管理员',
    producer: '全能',
    member: '标准',
    individual: '个人',
    editor: '编辑者',
    viewer: '监视员',
    disabled: '无权限',
    custom: '自订',

    check_description:'全部权限',
    info_description:'用户只对自己上传的影片有权限',
    times_description:'无权限',

    member_name: "名称",
  member_email: "电子信箱",
  member_last_login: "最近登入",
  member_minutes: "使用分钟数",
  user_role:"角色权限",
  add_new_role:'邀请用户加入',
  member_invite: '邀请',
  member_slot:'使用者人数',
  share_slot:'分享用户人数',
  member_slot_price_desc: '每增加一位使用者空位每{0}收费${1}美金.',
  member_slot_increase: '增加使用者上限',
  member_slot_increase_header: '扩充上限',
  member_slot_price_tooltip: '此费用是从今天到下个订阅日依比例计算出的总额',
  member_slot_agree:'我了解并允许:',
  member_slot_agree_desc1:'＊ {0}为本次订阅从即日起至下次扣款日的按比例计算金额',
  member_slot_agree_desc2:'＊ 从下个订阅周期开始，订阅费用将在原有基础上增加<span>{0}/{1}</span>',
  member_slot_agree_desc3:'＊ 金额明细：{0}',
  member_slot_purchase:'购买空间',
  member_invite_desc:'用户加入后不能替换成其他用户，但能透过给予{0}<br>来取消使用权',
  tax_id:'税务识别码',
  optional:'(选填)',
  required:'(必填)',
  added_users:'已加入用户',
  shareable_users:'可分享用戶',
  available_user_permissions:'用户权限表',
  corp_manage:'管理',
  edit_credit_card:'更改绑定信用卡',
  third_party:'已连接软体',
  disconnect:'取消串联',

  personal_information:'个人资讯',
  billing_information:'付款管理',
  product_information:'订阅相关',
  other_information:'其他',

  remaining_sub_minutes: '剩余订阅分钟',
  remaining_ai_credits: '剩余AI点数',
  remaining_forever_minutes: '剩余永久分钟',
  remaining_total_minutes: '剩余总分钟',
  add_more_minutes:'购买分钟',

  account_removal:'帐号移除',
  account_removal_desc:'所有与<span style="color:#00D1B2;font-weight:bold;">{0}</span>分享的影片权限将被取消，<br/>确定要移除帐号？',
  branding:'商标图示',
  branding_desc:'导出PDF时，可使用此客制化商标图示来取代默认的Taption图标',
  add_payment_method: '请先到<strong>「{0}」</strong> → <strong>「{1}」</strong> → <strong>「{1}」</strong> 添加付款方式',
  add_member_tips1:'请用逗号分隔多个电子邮件进行邀请',
 add_member_tips2:'每个输入的电子邮件必须是已注册的 Taption 用户',
  }