module.exports = { 
    error_title: '404',
    error_desc: 'La página que buscas no existe 😟',
    share_network_error: 'No tienes permiso o hay un problema de red.',
    permission_error: 'No tienes permiso para realizar esta acción, por favor contacta al administrador de la cuenta.',
    share_email_exist: 'El correo electrónico ya existe.',
    user_not_found: 'No se pudo encontrar al usuario.',
    email_invalid: 'Correo electrónico inválido',
    email_not_registered: 'No es un correo electrónico registrado en Taption',
    email_duplicate: 'Ya has añadido este correo electrónico a tu cuenta',
    email_slots_required: 'Por favor, compra más asientos para añadir nuevos miembros.',
    youtube_url_invalid: 'La URL de YouTube no es válida',
    youtube_url_required: 'Se requiere la URL de YouTube',
    directlink_url_invalid: 'No admitimos la URL proporcionada en este momento, por favor envíanos un correo a help@taption.com para solicitar soporte',
    directlink_url_required: 'Se requiere la URL',
    required: 'Obligatorio',
    size_error: 'El tamaño de tu archivo no puede ser mayor de {0}GB.',
    size_error_mb: 'El tamaño de tu archivo no puede ser mayor de {0}MB.',
    verify_email: 'Por favor, revisa tu correo electrónico para activar tu cuenta. Si no recibes el mensaje de confirmación, revisa tu [Carpeta de Spam].',
    email_must_be_valid: 'El correo electrónico debe ser válido',
    password_not_match: 'La contraseña no coincide',
    invalid_youtube: 'No se puede recuperar el video, por favor intenta con otro video o inténtalo más tarde.',
    unsupported_youtube: 'Este video está restringido, por favor intenta con otro video.',
    unsupported_direct_video: 'Este video está restringido<br>(No se admiten enlaces de video en vivo)',
    must_be_more_than: 'Debe tener más de {0} caracteres',
    must_be_less_than: 'Debe tener menos de {0} caracteres',
    must_be_more_than_words: 'Debe tener más de {0} palabras',
    trial_limit_error: 'La versión de prueba tiene un límite de 15 minutos. Por favor, compra minutos o suscríbete para eliminar este límite.',
    language_required: 'Se requiere el idioma',
    title_required: 'Se requiere el título',
    title_length: 'El título debe tener como máximo 80 caracteres',
    purpose_required: 'Se requiere el propósito',
    speakers_number_required: 'Se requiere el número de hablantes',
    category_required: 'Se requiere la categoría',
    method_required: 'Por favor, selecciona una forma de transcribir tu medio',
    max_length: 'Debe tener menos de {0} caracteres',
    max_char_required: 'Por favor, introduce un valor entre {0} y 1000. (Valor sugerido: {1})',
    insufficient_fund_title: 'No hay suficientes minutos',
    insufficient_ai_desc: 'No hay suficientes créditos, compra más créditos de IA para continuar.',
    exceed_trial_limit_title: 'La duración del video excede el límite de prueba',
    file_too_large_title: 'El tamaño del video es demasiado grande',
    not_supported_type_title: 'Tipo de video no compatible',
    insufficient_fund_tip: 'Compra más minutos e inténtalo de nuevo.',
    exceed_trial_limit_tip: 'La prueba te da 1 video gratis de menos de 15 minutos. Compra minutos o actualiza a Premium para eliminar este límite',
    file_too_large_tip: 'El tamaño del archivo no puede exceder los 2GB',
    not_supported_type_tip: 'Formatos de video compatibles: mov, avi, wmv, flv, mpeg, ogg, mp4',
    media_no_audio: 'El medio proporcionado no tiene audio',
    network_upload_fail_title: 'Tu red es inestable, por favor inténtalo de nuevo o usa Google Drive',
    network_upload_fail_tip: 'Puedes seleccionar tu archivo multimedia desde Google Drive al subir.',
    transcribe_prepare_fail_title: 'El servidor está ocupado, por favor inténtalo más tarde',
    transcribe_upload_fail_title: 'El servidor está ocupado, por favor inténtalo más tarde',
    transcribe_merge_fail_title: 'El servidor está ocupado, por favor inténtalo más tarde',
    transcribe_progress_fail_title: 'El servidor está ocupado, por favor inténtalo más tarde',
    transcribe_result_fail_title: 'El servidor está ocupado, por favor inténtalo más tarde',
    transcribe_error_fail_title: 'El servidor está ocupado, por favor inténtalo más tarde',
    directlink_url_error_title: 'El enlace proporcionado no es válido',
    directlink_url_error_tip: 'Por favor, asegúrate de que el permiso para el enlace esté disponible para el público en general',
    exceed_trial_usage_title: 'Se ha excedido el uso de prueba',
    exceed_trial_usage_tip: 'Compra minutos o suscríbete para continuar el servicio',
    need_payment_title: 'Suscripción pausada',
    need_payment_tip: 'Por favor, resuelve el problema de pago para continuar el servicio',
    exceed_usage_title: 'Uso excedido',
    exceed_usage_tip: 'Por favor, contacta a nuestro soporte.',
    file_too_long_title: 'El medio es demasiado largo',
    file_too_long_tip: 'Por favor, mantén la duración de tu medio dentro de las 2 horas',
    download_youtube_video_title: 'No se puede descargar este video, por favor intenta con otro video o inténtalo más tarde.',
    download_youtube_video_tip: 'Por favor, intenta subir de nuevo. Si el problema persiste, intenta subir un video diferente o contacta a nuestro soporte.',
    video_corrupted_title: 'No pudimos leer este archivo, por favor asegúrate de que el archivo sea válido',
    video_corrupted_tip: 'Asegúrate de que el archivo sea válido o contáctanos en help@taption.com',
    system_maintanance_title: 'Advertencia',
    system_maintanance: 'Mantenimiento del sistema. Por favor, inténtalo de nuevo más tarde actualizando la página.',
    error_add_title: 'Error',
    error_add_desc: 'No se pudo añadir la frase corregida al diccionario.',
    error_warning: 'Advertencia',
    warning_no_search_found: 'No se puede encontrar "{0}"',
    time_message: 'Hay <strong>{0}</strong> casos de marcas de tiempo de subtítulos superpuestas, lo que puede causar que el software de terceros las lea incorrectamente después de la exportación.',
    time_message1: 'Hay <strong>{0}</strong> casos de marcas de tiempo de subtítulos que son demasiado cortas y requieren atención.',
    time_message2: '(Las posiciones de las marcas de tiempo de color naranja se pueden ajustar usando el <strong>Editor de Línea de Tiempo</strong> en la esquina inferior izquierda)',
    overlap: 'Las marcas de tiempo se superponen con las vecinas',
    short: 'La duración de este subtítulo es menor a 0.1 segundos',
    error_merge_one: 'Tienes una exportación de video en progreso, por favor espera hasta que se complete e inténtalo de nuevo.',
    retry_upload: 'Reintentar',
    label_required: 'Por favor, selecciona al menos una etiqueta',
    label_name_required: 'Se requiere el nombre de la etiqueta',
    label_name_duplicate: 'El nombre de la etiqueta ya existe',
    srt_file_required: 'Se requiere el archivo SRT',
    srt_file_invalid: 'Archivo SRT inválido o vacío',
    txt_file_required: 'Se requiere el archivo TXT',
    txt_file_invalid: 'Archivo TXT inválido o vacío',
    media_file: 'Se requiere el archivo multimedia',
    media_srt_mismatch: 'El medio seleccionado es más corto que el archivo SRT seleccionado',
    subscription_pay_failed_title: 'El pago de tu suscripción falló',
    subscription_pay_failed_desc: 'Por favor, realiza una de las siguientes acciones:',
    subscription_pay_failed_resolve1: 'Actualiza tu método de pago con una nueva tarjeta de crédito en la sección de Cuenta',
    subscription_pay_failed_resolve2: 'Asegúrate de que haya fondos suficientes en tu tarjeta o contacta al emisor de la tarjeta de crédito y luego notifícanos en support@taption.com',
    subscription_pay_failed_resolve3: 'Cancela tu suscripción actual',
    maximum_videos_title: 'La cuenta gratuita puede almacenar un máximo de {0} videos',
    maximum_videos_title1: 'La cuenta Básica puede almacenar un máximo de {0} videos',
    maximum_videos_resolve1: 'Elimina videos existentes para mantener el número total de videos por debajo de {0}',
    maximum_videos_resolve2: 'Compra o actualiza a uno de nuestros <a href="https://app.taption.com/upgrade">planes</a> para extender o eliminar los límites',
    manual_warning: 'Seleccionar <strong>Manual</strong> te dará una transcripción vacía que requiere que ingreses tu propio texto. <br>Si obtener una transcripción automatizada es el propósito, por favor selecciona <strong>Transcripción automatizada</strong>.',
    txt_mismatch_error: 'El idioma seleccionado no coincide con el archivo de texto de entrada.<br>Por favor, asegúrate de que los idiomas coincidan para obtener el mejor resultado.',
    txt_invalid_error: 'No se pudo detectar la codificación del archivo de entrada.<br>Por favor, asegúrate de que el archivo esté guardado en su codificación correcta.<br><span style="font-size:12px">(<strong>Ejemplo</strong>: Abre tu archivo de texto, <strong>Guardar como</strong>→<strong>Codificación</strong> selecciona <strong>UTF-8</strong>)</span>',
    language_mismatch_error: 'El idioma del video subido puede no coincidir con el idioma que seleccionaste. Esto podría reducir la precisión de la transcripción y llevar a dificultades en la traducción a otros idiomas.<br><br>Si deseas traducir el video a otro idioma, asegúrate de seleccionar el idioma correcto del video durante el proceso de carga. Una vez completado, usa el botón <v-btn style="padding:3px 20px;margin:0px 5px;text-transform: capitalize;letter-spacing:0px;" class="translate-btn"><i style="font-size:14px;" aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-translate"></i><span class="text">Traducir</span></v-btn> ubicado en la esquina superior derecha de esta página para proceder con la traducción.',
    "auth/invalid-email": 'Tu correo electrónico no es válido.',
    "auth/wrong-password": 'Tu contraseña es incorrecta o esta cuenta no existe.',
    "auth/email-already-in-use": 'La dirección de correo electrónico ya está en uso por otra cuenta.',
    "auth/user-not-found": 'Tu contraseña es incorrecta o esta cuenta no existe.',
    confirm_password_desc: 'Por favor, vuelva a ingresar su contraseña',
    confirm_password_not_match: 'La contraseña no coincide',
}