module.exports = {
  account_settings: "Configuración de la cuenta",
  name: "Nombre",
  company: "Empresa",
  company_description: "El nombre de la empresa aparecerá en las facturas y pagos.",
  institution_name: "Información fiscal",
  email: "Correo electrónico",
  card: "Tarjeta",
  create_password: "Crear una nueva contraseña",
  password: "Contraseña",
  status: "Estado",
  new_password: "Nueva contraseña",
  confirm_password: "Confirmar contraseña",
  update: "Actualizar",
  update_name: "Actualizar nombre",
  update_corp_name: "Actualizar nombre de la empresa",
  update_taxid_name: "Actualizar identificación fiscal",
  update_email: "Actualizar correo electrónico",
  update_password: "Actualizar contraseña",

  upgrade: "Mejorar",
  unsubscribe: "Cancelar suscripción",

  update_name_description: "",
  update_email_description: "Por favor, introduce tu nueva dirección de correo electrónico",
  update_email_password_confirm: "Confirma tu contraseña",
  update_password_description: "",
  
  thank_you_interest: "Gracias por tu interés",
  reach_out: "Por favor, contáctanos en: {0}",
  update_language: "Preferencia de idioma",
  reactivate: "Reactivar",

  credit_card_update: "Tu tarjeta de crédito ha sido actualizada",
  password_update: "Tu contraseña ha sido actualizada",
  click_here: "Haz clic aquí",
  if_not_direct: "si no eres redirigido en 3 segundos...",
  email_verified: "Tu correo electrónico ha sido verificado.",
  email_restored: "Tu correo electrónico ha sido actualizado",

  email_unable_to_authenticate: "No admitimos el registro de correo electrónico anónimo",
  email_unable_to_authenticate_desc: "Por favor, regístrate con un correo electrónico válido para recibir minutos gratis.",
  purchase_records: "Facturas",
  purchase_date: "Fecha",
  purchase_amount: "Importe",
  purchase_download: "Descargar",
  purchase_view: "Ver todo",
  purchase_recent: "20 más recientes⟶",
  purchase_unit: "USD",

  coupon_title: "Código de promoción",
  coupon_redeem: "Canjear",

  role: "Rol",
  view: "Ver",
  upload: "Subir",
  edit: "Editar",
  translate: "Traducir",
  export: "Exportar",
  analysis: "Análisis de IA",

  admin: "Administrador",
  producer: "Productor",
  member: "Miembro",
  individual: "Individual",
  editor: "Editor",
  viewer: "Visualizador",
  disabled: "Desactivado",
  custom: "Personalizado",

  check_description: "Control total",
  info_description: "Acceso solo a las transcripciones subidas por este usuario",
  times_description: "Sin acceso",

  member_name: "Nombre",
  member_email: "Correo electrónico",
  member_last_login: "Último acceso",
  member_minutes: "Minutos utilizados",
  user_role: "Rol",
  add_new_role: "Añadir nuevos miembros",
  member_invite: "Invitar",
  member_slot: "Número de usuarios",
  share_slot: "Número de usuarios compartibles",
  member_slot_price_desc: "Los asientos adicionales cuestan ${1} por {0}.",
  member_slot_increase: "Aumentar asientos de usuario",
  member_slot_increase_header: "Añadir asientos",
  member_slot_price_tooltip: "El precio se prorratea hasta el próximo ciclo de facturación",
  member_slot_agree: "Entiendo y acepto lo siguiente:",
  member_slot_agree_desc1: "• {0} es el importe prorrateado para esta suscripción desde hoy hasta la próxima fecha de facturación",
  member_slot_agree_desc2: "• A partir del próximo ciclo de suscripción, la cuota de suscripción aumentará en <span>{0} por {1}</span> desde la tarifa actual",
  member_slot_agree_desc3: "• Desglose de costes: {0}",
  member_slot_purchase: "Confirmar pago",
  member_invite_desc: "No puedes reemplazar al usuario una vez añadido, asigna el permiso {0} <br> para revocar su acceso",
  tax_id: "Identificación fiscal",
  optional: "(Opcional)",
  required: "(Obligatorio)",
  added_users: "Miembros",
  shareable_users: "Miembros",
  available_user_permissions: "Permisos de usuario disponibles",
  corp_manage: "Gestión",
  edit_credit_card: "Editar tarjeta de crédito",

  third_party: "Aplicaciones instaladas",
  disconnect: "Desinstalar",

  personal_information: "Información personal",
  billing_information: "Detalles de facturación",
  product_information: "Suscripción",
  other_information: "Otros",

  remaining_sub_minutes: "Minutos de suscripción",
  remaining_ai_credits: "Créditos de IA",
  remaining_forever_minutes: "Minutos",
  remaining_total_minutes: "Total de minutos",
  add_more_minutes: "Añadir minutos",

  account_removal: "Confirmación de eliminación de cuenta",
  account_removal_desc: "<span style=\"color:#00D1B2;font-weight:bold;\">{0}</span> <br/> perderá todo acceso a las transcripciones compartidas.<br/> ¿Estás seguro de que quieres eliminar esta cuenta?",
  branding: "Logo",
  branding_desc: "Este logo puede reemplazar el logo predeterminado de Taption cuando exportes la transcripción como PDF.",
  add_payment_method: "Por favor, ve a <strong>\"{0}\"</strong> → <strong>\"{1}\"</strong> → <strong>\"{1}\"</strong> para añadir un método de pago",
  add_member_tips1: "Invita a varios destinatarios separando sus correos electrónicos con comas.",
  add_member_tips2: "Cada correo electrónico introducido debe ser de un usuario registrado en Taption.",
}