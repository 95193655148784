module.exports = {
  account_settings: 'Account Setting',
  name: 'Name',
  company: 'Company',
  company_description:'Company name will be shown on invoices and payments.',
  // institution_name:'Institution Name/Account Name',
  institution_name:'Tax information',
  email: 'Email',
  card: 'Card',
  create_password:'Create a new password',
  password: 'Password',
  status: 'Status',
  new_password: 'New password',
  confirm_password: 'Confirm password',
  update: 'Update',
  update_name: 'Update name',
  update_corp_name: 'Update company name',
  update_taxid_name: 'Update tax id',
  update_email: 'Update email',
  update_password: 'Update password',

  upgrade: 'Upgrade',
  unsubscribe: 'Unsubscribe',

  update_name_description: '',
  update_email_description: 'Please enter your new email address',
  update_email_password_confirm: 'Confirm your password',
  update_password_description: '',
  
  thank_you_interest:'Thank you for your interest',
  reach_out:'Please reach out to us at: {0}',
  update_language:'Language preference',
  reactivate:'Reactivate',

  credit_card_update:'Your credit card has been updated',
  password_update:'Your password has been updated',
  click_here:'Click here',
  if_not_direct:'if you are not redirect in 3 seconds...',
  email_verified:'Your email has been verified.',
  email_restored:'Your email has been updated',

  email_unable_to_authenticate: 'We do not support anonymous email registration',
  email_unable_to_authenticate_desc: 'Please register with a valid email to receive free minutes.',
  purchase_records: 'Invoices',
  purchase_date: 'Date',
  purchase_amount: 'Amount',
  purchase_download: 'Download',
  purchase_view: 'View All',
  purchase_recent:'20 most recent⟶',
  purchase_unit:'USD',

  coupon_title:'Promotion code',
  coupon_redeem: 'Redeem',

  role:'Role',
  view:'View',
  upload:'Upload',
  edit:'Edit',
  translate:'Translate',
  export:'Export',
  analysis:'AI Analysis',

  admin: 'Admin',
  producer: 'Producer',
  member: 'Member',
  individual: 'Individual',
  editor: 'Editor',
  viewer: 'Viewer',
  disabled: 'Disabled',
  custom:'Custom',

  check_description:'Full control',
  info_description:'Access to transcript that is uploaded by this user only',
  times_description:'No access',

  member_name: "Name",
  member_email: "Email",
  member_last_login: "Last login",
  member_minutes: "Minutes used",
  user_role:"Role",
  add_new_role:'Add new members',
  member_invite: 'Invite',
  member_slot:'Number of users',
  share_slot:'Number of shareable users',
  member_slot_price_desc: 'Additional seats are ${1} per {0}.',
  member_slot_increase: 'Increase user seats',
  member_slot_increase_header: 'Add seats',
  member_slot_price_tooltip: 'Price is prorated until the next billing cycle',
  member_slot_agree:'I understand and agree to the following:',
  member_slot_agree_desc1: '• {0} is the prorated amount for this subscription from today until the next billing date',
  member_slot_agree_desc2: '• Starting from the next subscription cycle, the subscription fee will increase by <span>{0} per {1}</span> from the current rate',
  member_slot_agree_desc3: '• Cost breakdown: {0}',
  member_slot_purchase:'Confirm payment',
  member_invite_desc:'You cannot replace the user once added, assign {0} <br> permission to revoke its access',
  tax_id:'Tax Id',
  optional:'(Optional)',
  required:'(Required)',
  added_users:'Members',
  shareable_users:'Members',
  available_user_permissions:'Available user permissions',
  corp_manage:'Management',
  edit_credit_card:'Edit credit card',

  third_party:'Installed Apps',
  disconnect:'Uninstall',

  personal_information:'Personal Information',
  billing_information:'Billing Detail',
  product_information:'Subscription',
  other_information:'other',

  remaining_sub_minutes: 'Subscription minutes',
  remaining_ai_credits: 'AI credits',
  remaining_forever_minutes: 'Minutes',
  remaining_total_minutes: 'Total minutes',
  add_more_minutes:'Add minutes',

  account_removal:'Account removal confirmation',
  account_removal_desc:'<span style="color:#00D1B2;font-weight:bold;">{0}</span> <br/> will lose all access to shared transcripts.<br/> Are you sure you want to remove this account?',
  branding:'Logo',
  branding_desc:'This logo can replace the default Taption logo when you export transcript as PDF.',
  add_payment_method: 'Please go to <strong>"{0}"</strong> → <strong>"{1}"</strong> → <strong>"{1}"</strong> to add a payment method',
  add_member_tips1:'Invite multiple recipients by separating their emails with commas.',
  add_member_tips2:'Each email entered must be a registered Taption user.',
}
