module.exports = {
    update: '更改',
    cancel: '取消',
    remove: '移除',
    session_title: '您即将被登出',
    session_description: '系统将在5分钟后自动登出',
    session_ok: '保持登入',
    session_no: '登出',
    free_min_title: '恭喜!',
    free_min_desc: '您邀请的{1}位朋友订阅了，因此我们赠送<span style="color:#2fc59f!important">{0}分钟</span>给您',
    email_placeholder: '请输入电子邮件...',
    a_few_seconds_ago:'几秒前',
    short_seconds:'秒',
    short_minutes:'分',
    minute:'分钟',
    minutes:'分钟',
    hour:'小时',
    hours:'小时',
    day:'天',
    days:'天',
    month:'月',
    months:'月',
    year:'年',
    years:'年',
    ago:'之前',
    a:'一',
    an:'一',
    title: '名称',
    welcome: '欢迎',
    youtube: 'Youtube',
    send:'送出',
    on:'开启',
    off:'关闭',
    new: '新',
    search: '搜寻',
    upgrade: '升級',

    feature_updating: '功能更新中，请稍后再试',

    copy: '复制',
    copied: '复制成功',
    trial:'试用',
    basic:'基本',
    premium:'订阅',
    corp:'大量订阅',

    speakers:'发声者',
    captions:'字幕',
    article:'逐字稿',
    char:'字',
    
    language:'语言',
    duration:'长度',
    type:'类型',
    date: '日期',
    
    transcriptType1:'字幕',
    transcriptType2:'发声者',
    transcriptType3:'逐字稿',
    accounts:'帐号选择',
    label:'标签',
    none: '无',
    me: '我',
    usd:'美金',  
    january:'1月',
    february:'2月',
    march:'3月',
    april:'4月',
    may:'5月',
    june:'6月',
    july:'7月',
    august:'8月',
    september:'9月',
    october:'10月',
    november:'11月',
    december:'12月',
    th:'号',
    pricing:'价格',
    privacy:'隐私权政策',
    terms:'服务条款',
    blog:'资讯专区',
    video:'影片专区',
    cookieSettings:'Cookie设定',
    back:'返回',
    credit:'点',
    ok: '确认'
  }