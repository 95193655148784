module.exports = {
    convert_to_text_title_meta:"Resumidor de Vídeos con IA en línea: Añade Temas, Resume Reuniones, Crea Capítulos de YouTube con un Clic",
    convert_to_text_desc_meta:"Nuestra herramienta de análisis con IA te permite resumir contenido de forma eficiente, identificar oradores clave, redactar guiones de narración e integrar capítulos y marcas de tiempo en YouTube. Explora nuestra gama de plantillas, incluyendo resumidores de vídeo, generadores de marcas de tiempo de YouTube y generadores de temas de vídeo con marcas de tiempo. ¡Empieza con una prueba gratuita hoy mismo!",
    convert_to_text_title:"Análisis con IA",
    convert_to_text_desc:"Resumidor de vídeos, Capítulos de YouTube, Resumen de reuniones",
    start_your_trial:"¡Comienza tu prueba gratuita!",
    hero_img:"./11_English.webp",

    three_step_title:"Tres pasos para la transcripción",
    step_one:"Haz una pregunta o da una instrucción",
    step_one_img:"./aianalysis_step1_en_US.webp",
    step_one_desc:"Puedes elegir si quieres que la herramienta de vídeo con IA resuma tu contenido, identifique oradores clave, sugiera guiones de narración y añada capítulos y marcas de tiempo en YouTube.",
    
    step_two:"Elige entre comandos predefinidos",
    step_two_img:"./aianalysis_step2_en_US.webp",
    step_two_desc:"Análisis con IA ofrece plantillas predefinidas como generador de resúmenes de vídeo, generador de marcas de tiempo de YouTube y generador de temas con marcas de tiempo.",

    step_three:"Resume en tu idioma preferido",
    step_three_img:"./aianalysis_step3_en_US.webp",
    step_three_desc:"Independientemente del idioma original del vídeo, puedes resumir o dar instrucciones en tu idioma preferido.",

    feature_tutorial_title:'Análisis y resumen de vídeos con Taption',
    feature_tutorial_desc:"La función \"Análisis con IA\" es una potente herramienta diseñada para el análisis en profundidad del contenido de vídeo. Los usuarios pueden subir vídeos para un resumen completo o usar plantillas predefinidas como resumen de vídeo, generador de temas con IA, marcas de tiempo de YouTube y capítulos de YouTube para análisis con un solo clic, eliminando la necesidad de comandos manuales. Esto mejora notablemente la comprensión e interacción con los puntos principales del vídeo.",
    feature_tutorial_poster_url:'./aianalysis_poster_en_US.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/PWOWwbHQCUM?autoplay=1',

    feature_why_image:'./ai_template_demo_en.webp',
    feature_extra_title:"Uso y ejemplos de Análisis con IA",
    feature_extra_desc:"Para aprovechar al máximo la función de Análisis con IA, la clave está en redactar indicaciones precisas. Aquí tienes algunas formas de articular tus solicitudes para obtener los insights más significativos:<ul><li><strong>Indicaciones para Resumen</strong>：\"Resume el vídeo en 300 palabras.\" Esta instrucción es ideal para resúmenes rápidos.</li><li><strong>Indicaciones para Tareas</strong>：\"Identifica las tareas de la reunión.\" Esta instrucción puede ayudar a señalar tareas y responsabilidades.</li><li><strong>Indicaciones para Comentarios de Coaching</strong>：\"Eres un coach útil. Proporciona un análisis de la transcripción y ofrece áreas de mejora con citas exactas. No incluyas introducción.\"</li><li><strong>Guión de Vídeo</strong>：Ayúdame a crear un guion para un vídeo explicativo de unos 5-10 minutos de esta película, enfocándote en la trama y las ideas que el autor desea transmitir.</li></ul>Al construir una indicación, comienza con un resumen general, luego proporciona detalles adicionales.",

    feature_what_title:"¿Cómo añadir marcas de tiempo y resumir tu YouTube?",
    feature_what_desc: "<ul>\n  <li>Inicia sesión en <a href=\"https://www.taption.com/login\">Taption</a></li>\n  <li>Haz clic en \"Subir\" para cargar tu vídeo MP4 o pega un enlace de YouTube para transcribir</li>\n  <li>Cuando esté completo, entra en la plataforma de edición y edita o traduce según sea necesario</li>\n  <li>Haz clic en el botón \"Análisis con IA\" en la parte inferior</li>\n  <li>Haz clic en \"Analizar\" para añadir capítulos de YouTube y generar un resumen del vídeo</li>\n</ul>"
}
