module.exports = { 
    update: '업데이트',
    cancel: '취소',
    remove: '삭제',
    session_title: '세션이 곧 만료됩니다',
    session_description: '5분 후에 로그아웃됩니다.',
    session_ok: '로그인을 유지합니다',
    session_no: '로그아웃합니다',
    free_min_title: '축하합니다!',
    free_min_desc: '친구 {1}명을 소개하여 <span style="color:#2fc59f!important">{0} 분</span>의 무료 이용시간이 적립되었습니다.',
    email_placeholder: '이메일 주소를 입력하세요...',
    a_few_seconds_ago: '몇 초 전',
    short_seconds: '초',
    short_minutes: '분',
    minute: '분',
    minutes: '분',
    hour: '시간',
    hours: '시간',
    day: '일',
    days: '일',
    month: '개월',
    months: '개월',
    year: '년',
    years: '년',
    ago: '전',
    a: '하나',
    an: '한',
    title: '제목',
    welcome: '환영합니다',
    youtube: 'Youtube',
    send: '송신',
    on: '켜기',
    off: '끄기',
    new: '신규',
    search: '검색',
    upgrade: '업그레이드',
    feature_updating: '기능 업데이트중, 나중에 다시 확인해주세요.',
    copy: '복사',
    copied: '복사됨',
    trial: '시험판',
    basic: '베이직 플랜',
    premium: '프리미엄 플랜',
    corp: '엔터프라이즈 플랜',
    speakers: '발화자',
    captions: '자막',
    article: '글',
    char: '자',
    language: '언어',
    duration: '재생시간',
    type: '형식',
    date: '날짜',
    transcriptType1: '자막',
    transcriptType2: '발화자',
    transcriptType3: '기사',
    accounts: '계정',
    label: '라벨',
    none: '없음',
    me: '유저',
    usd: '미국 달러',
    january: '1월',
    february: '2월',
    march: '3월',
    april: '4월',
    may: '5월',
    june: '6월',
    july: '7월',
    august: '8월',
    september: '9월',
    october: '10월',
    november: '11월',
    december: '12월',
    th: '번호',
    pricing: '가격',
    privacy: '개인정보',
    terms: '이용규약',
    blog: '블로그',
    video: '유튜브 채널',
    cookieSettings: '쿠키 설정',
    back: '뒤로',
    credit: '포인트',
    ok: '네',
}