import firebase from '../overrides/taption.firebase'
import {firebaseApp} from '../firebase-config'
import { getFirestore, doc, collection, getDoc, onSnapshot,getDocs } from "firebase/firestore";
import { getAuth,getIdToken } from 'firebase/auth';
import { httpsCallable } from "firebase/functions";

export default {

    getAuth () {
      return getAuth();
    },
    getAuthUser () {
      return getAuth().currentUser;
    },
    createIdToken () {
      return getIdToken(this.getAuthUser(),true)
    },
    getDocRef (collectionId, documentId, subCollectionId, subDocumentId) {
        const db = getFirestore(firebaseApp);
        let docRef = null;
    
        if(subCollectionId){
          docRef = doc(db, collectionId, documentId, subCollectionId, subDocumentId);
        }
        else{
          docRef = doc(db, collectionId, documentId);
        }
    
        return docRef;
      },
      getCollectionRef(collectionId, documentId, subCollectionId){// testedxxx
        const db = getFirestore(firebaseApp);
    
        if(subCollectionId){
          return collection(db, collectionId, documentId, subCollectionId)
        }
    
        return collection(db, collectionId);
      },
      // getFirestore(){
      //   return firebase.firestore()
      // },
    
      getDocsPromise (collectionId, documentId, subCollectionId) { 
        const me = this;
        return getDocs(me.getCollectionRef(collectionId, documentId, subCollectionId));
      },
    
      getDocPromise (collectionId, documentId, subCollectionId, subDocumentId) { 
          const docRef = this.getDocRef(collectionId, documentId, subCollectionId, subDocumentId);
          return getDoc(docRef);
        },
    
      getQueryDocsPromise(queryRef){
        return getDocs(queryRef);
      },
    
      getSnapshot(collectionId, documentId, subCollectionId, subDocumentId, callback){
        const me = this;
        let docRef = me.getDocRef(collectionId, documentId, subCollectionId, subDocumentId);
        return onSnapshot(docRef, callback);
      },
      getCloudFunctionPromise (cloudFunctionName, parameters, options) { //testedxxx
        const functions = firebase.getCloudFunction();
        let cloudFunction = null;
        if(options){
          cloudFunction = httpsCallable(functions,cloudFunctionName, options)
        }
        else{
          cloudFunction = httpsCallable(functions,cloudFunctionName)
        }
      //  var cloudFunction = httpsCallable(functions,cloudFunctionName,{ limitedUseAppCheckTokens: true })
    
        return cloudFunction(parameters)
      },
    
}
