module.exports = {
  account_settings: 'アカウントの設定',
  name: 'お名前',
  company: 'ご勤務先',
  company_description:'会社名は請求書や支払い時に表示されます。',
  institution_name:'所属先/アカウント名',
  email: 'メールアドレス',
  card: 'クレジットカード情報',
  create_password:'新しいパスワードを設定',
  password: 'パスワード',
  status: 'ステータス',
  new_password: '新しいパスワード',
  confirm_password: 'パスワードの確認',
  update: '更新',
  update_name: 'お名前の更新',
  update_corp_name: 'ご勤務先名の更新',
  update_taxid_name: '税番の更新',
  update_email: 'メールアドレスの更新',
  update_password: 'パスワードの更新',

  upgrade: 'アップグレード',
  unsubscribe: '購読を廃止',

  update_name_description: '', // need to check - blank
  update_email_description: '新しいメールアドレスを入力してください',
  update_email_password_confirm: 'パスワードを確認してください',
  update_password_description: '', // need to check - blank
  
  thank_you_interest:'お引き合い頂きありがとうございます。',
  reach_out:'お手数ですが、{0}までお問い合わせください。',
  update_language:'言語設定',
  reactivate:'アカウントの再有効化',

  credit_card_update:'クレジット カードが更新されました',
  password_update:'パスワードが更新されました。',
  click_here:'ここをクリック',
  if_not_direct:'3秒以内にリダイレクトしない場合は...',
  email_verified:'メールアドレスの認証を完了しました。',
  email_restored:'メールアドレスが更新されました。',

  email_unable_to_authenticate: '匿名のメール登録はお受入れしておりません。',
  email_unable_to_authenticate_desc: '無料トライアルをご希望の場合は、有効なメールアドレスでご登録ください。',
  purchase_records: '請求書',
  purchase_date: '日付',
  purchase_amount: '金額',
  purchase_download: 'ダウンロード',
  purchase_view: 'すべてを見る',
  purchase_recent:'最新の20件⟶',
  purchase_unit:'USD',

  coupon_title:'プロモーションコード',
  coupon_redeem: '利用する',

  role:'権限',
  view:'閲覧',
  upload:'アップロード',
  edit:'編集',
  translate:'翻訳',
  export:'抽出',
  analysis:'AI分析',

  admin: 'アドミン',
  producer: 'プロデューサー',
  member: 'メンバー',
  individual: '個人',
  editor: 'エディター',
  viewer: 'ビューアー',
  disabled: '権限なし',
  custom:'カスタマイズ',

  check_description:'フールコントロール',
  info_description:'このユーザーがアップロードした文字起こしのみアクセス可能',
  times_description:'アクセスなし',

  member_name: "お名前",
  member_email: "メールアドレス",
  member_last_login: "最近のログイン履歴",
  member_minutes: "利用累計（分）",
  user_role:"権限",
  add_new_role:'新規メンバーを登録',
  member_invite: '招待',
  member_slot:'ユーザー数',
  share_slot:'共有できるユーザー数',
  member_slot_price_desc: '追加スロットを利用するには、スロット当たり{0}${1}となります。',
  member_slot_increase: 'ユーザースロットを追加',
  member_slot_increase_header: 'スロットを追加',
  member_slot_price_tooltip: 'この料金は、今日から次のサブスクリプション日までの日割り合計です。',
  member_slot_agree:'理解して同意します:',
  member_slot_agree_desc1:'※ {0}は、本日から次回の支払日までの日割り計算された金額です',
  member_slot_agree_desc2:'※ 次の課金サイクルから、サブスクリプション料金が現在の金額に加えて<span>{0}/{1}</span>増額されます',
  member_slot_agree_desc3:'※ 料金内訳：{0}',
  member_slot_purchase:'お支払いの確認',
  member_invite_desc:'一度追加したユーザーは変更できません。アクセスを廃止するには、{0} <br> の権限を付与してください',
  tax_id:'税番',
  optional:'(選択項目)',
  required:'(必須項目)',
  added_users:'メンバー',
  shareable_users:'メンバー',
  available_user_permissions:'選択可能なユーザー権限',
  corp_manage:'管理',
  edit_credit_card:'クレジットカード情報の編集',

  third_party:'インストール済みのアプリ',
  disconnect:'アンインストール',

  personal_information:'個人情報',
  billing_information:'請求情報',
  product_information:'購読情報',
  other_information:'その他',

  remaining_sub_minutes: '残余サブスクリプション時間（分）',
  remaining_ai_credits: '残りの AI ポイント',
  remaining_forever_minutes: '残余永久時間（分）',
  remaining_total_minutes: '合計残余時間（分）',
  add_more_minutes:'利用可能時間を追加',

  account_removal:'アカウント削除の確認',
  account_removal_desc:'共有されたすべての文字起こしへの<span style="color:#00D1B2;font-weight:bold;">{0}</span> <br/>さんのアクセスを廃止します。<br/>アカウントを削除しますか？',
  branding:'商標アイコン',
  branding_desc: 'PDF をエクスポートするとき、このカスタマイズされたブランド アイコンを使用してデフォルトの Taption アイコンを置き換えることができます',
  add_payment_method: 'まず<strong>「{0}」</strong> → <strong>「{1}」</strong> → <strong>「{1}」</strong> に進み、支払い方法を追加してください',
  add_member_tips1:'招待するメールアドレスはコンマで区切ってください。', 
  add_member_tips2:'メールアドレスは登録済みのTaptionユーザーのみです。'
}
