import { getStorage } from "firebase/storage";
import { getFunctions} from "firebase/functions";
import {firebaseApp} from '../firebase-config'

const regionMapping = {
    storage: { AS: `gs://taption-${process.env.VUE_APP_MODE}-taiwan`},
    function: { AS: 'asia-east2'}
}

const firebaseOverrides = {};

firebaseOverrides.getStorage = () => {
    const location = window.localStorage.getItem('location');
    const storageUrl = regionMapping.storage[location];
    const defaultUrl = regionMapping.storage.AS;

    if(!location){
        return getStorage(firebaseApp, defaultUrl);
    }

    return storageUrl ? getStorage(firebaseApp, storageUrl) : getStorage(firebaseApp)
}

firebaseOverrides.getCloudFunction = () => {
    const location = window.localStorage.getItem('location');
    const region = regionMapping.function[location];
    const defaultRegion = regionMapping.function.AS;

    if(!location){
        return getFunctions(firebaseApp, defaultRegion);
    }

    return region ? getFunctions(firebaseApp,region) : getFunctions(firebaseApp);
}

export default firebaseOverrides;