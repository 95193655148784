module.exports = {
    convert_to_text_title_meta:"Converter Vídeo para Texto - Software de Transcrição por IA Online",
    convert_to_text_desc_meta:"Converta automaticamente vídeos MP4, vídeos do YouTube e áudios MP3 para texto online. Suporta mais de 40 idiomas, fornece legendas multilíngues e possui formatação automática e rotulagem de oradores. Inscreva-se para uma avaliação gratuita hoje!",
    convert_to_text_title:"Converter vídeo para texto",
    convert_to_text_desc:"Converta automaticamente o seu vídeo MP4 ou áudio MP3 para texto. Suporta legendas multilíngues, formatação automática e rotulagem de oradores.",
    start_your_trial:"Comece o seu teste grátis!",
    hero_img:"./01_English.webp",

    three_step_title:"Passos para transcrever",
    step_one:"Carregar ficheiro",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"Selecione de dispositivo, YouTube, Google Drive ou Zoom para converter para texto.",
    
    step_two:"Escolher método",
    step_two_img:"./select_split_en.webp",
    step_two_desc:"Escolha o seu método de transcrição entre: 1) Transcrição automática 2) Importar ficheiro de texto 3) Manual, depois escolha Segmentar cada seção para \"artigo\".",

    step_three:"Exportar",
    step_three_img:"./select_export_en.webp",
    step_three_desc:"Após editar na plataforma, exporte (srt, txt, pdf, mp4) clicando no botão de exportação no canto superior direito.",

    feature_tutorial_title:'Conversão de vídeo para texto sem esforço',
    feature_tutorial_desc:'O Taption oferece quatro métodos distintos para adicionar legendas ao seu vídeo: <span class="bullet-point">•</span>Transcrição Automática <span class="bullet-point">•</span>Importação de SRT <span class="bullet-point">•</span>Importação de TXT <span class="bullet-point">•</span>Entrada Manual.<br><br> A nossa plataforma de edição serve como uma ferramenta intuitiva para conversões de áudio para texto, fala para texto e vídeo para texto, simplificando as suas tarefas de transcrição. Para quem pretende alcançar públicos globais, também oferecemos suporte para legendas multilíngues. Não deixe de assistir ao nosso tutorial, onde explicamos estes passos de transcrição de forma simples, ajudando a entender como gerir eficientemente as suas necessidades de vídeo para texto.',
    feature_tutorial_poster_url:'./add_subtitles_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/KvyaH-QU9is?autoplay=1',

    feature_extra_title:"Os muitos benefícios da conversão de vídeo para texto",
    feature_extra_desc:"A conversão de vídeo para texto, que inclui processos de áudio para texto e fala para texto, vai além da função básica de gerar legendas. Esses serviços fornecem uma variedade abrangente de benefícios que podem melhorar significativamente a sua estratégia de conteúdo por meio da transcrição. Considere as seguintes vantagens principais de utilizar conversões de vídeo para texto, áudio para texto e fala para texto nas suas operações: <ul><li><strong>Mídia Pesquisável:</strong> Os seus ficheiros de mídia serão totalmente pesquisáveis, aumentando a descoberta do conteúdo. </li><li><strong>Revisão e Modificação Eficientes:</strong> Acelera os processos de revisão e edição sem sacrificar a qualidade. </li><li><strong>Tradução Fácil:</strong> Permite abordar vários idiomas de forma simples, expandindo assim o seu alcance global. </li><li><strong>Otimização SEO:</strong> Torna a sua mídia pesquisável, o que significa que está a melhorar o desempenho de SEO do seu site. </li><li><strong>Conformidade ADA:</strong> O seu conteúdo estará acessível a todos e cumprirá os padrões de conformidade ADA. </li></ul>",

    feature_why_image: "./translate_step1_en.webp",
    feature_what_title:'5 Passos para converter áudio para texto',
    feature_what_desc: "<ul>\n  <li>Registe uma nova conta ou faça login com o Gmail: Vá para a <a href=\"https://www.taption.com/login\">página de login do Taption</a>.</li>\n  <li>Clique no botão \"Carregar\" e selecione a fonte de mídia, como carregar do seu computador, YouTube, Google Drive, Zoom ou colar um link de mídia.</li>\n  <li>Selecione o \"Idioma\" e o \"Método de Segmentação\" necessários para a transcrição.</li>\n  <li>Após o upload, o ficheiro será automaticamente convertido para texto e poderá prosseguir para a plataforma de edição para uma edição adicional.</li>\n  <li>Na plataforma de edição, poderá traduzir, usar análise de IA e, finalmente, exportar o documento ou incorporar as legendas no ficheiro de vídeo.</li>\n</ul>"
}
