module.exports = {
  upload: 'Carregar',
  upload_language: 'Selecionar o idioma falado',
  upload_method: 'Selecionar o método de transcrição',
  delete_title: 'Eliminar transcrição?',
  delete_description: 'Esta ação não pode ser desfeita.',
  delete_ok: 'Eliminar',
  delete_cancel: 'Cancelar',
  remember_selection: 'Lembrar-me',
  send_email: 'Enviar-me um email quando estiver concluído.',
  card_share: 'Partilhar',
  card_delete: 'Eliminar',
  upload_media: 'Carregar media',
  upload_error_title: 'Erro',
  upload_error: 'Não tem minutos suficientes.',
  transcribing_description: 'Estamos a transcrever o seu ficheiro. <br>O tempo de processamento demora cerca de<br>o mesmo tempo que a duração do ficheiro.<br> Enviaremos um email quando estiver pronto.',
  transcribing: 'A processar',
  preparing: 'A preparar',
  update_title: 'Atualizar título',
  transcript_title: 'Título',
  add_more: 'Adicionar',

  upload_limit_exceeded_title: 'Excedeu o comprimento máximo',
  upload_limit_exceeded: 'Por favor, limite o comprimento do seu vídeo a menos de 5 horas.',

  initializing_description: 'Estamos a processar o seu ficheiro. <br>Isso levará alguns minutos, por favor aguarde.',
  initializing: 'Aguarde',
  translating_description: 'Estamos a traduzir o seu ficheiro. <br>Isso levará alguns minutos, por favor aguarde.',
  translating: 'A traduzir',
  error_description: 'Por favor, contacte-nos para mais informações',
  error_info: 'Código de erro: {0}',
  error: 'Algo correu mal',
  error_minutes: '{0} <br> Os seus minutos foram devolvidos.<br> Atualize o navegador para obter os minutos mais atualizados.',

  please_wait: 'Por favor, aguarde...',
  loading: 'A carregar...',
  upgrade_to_share: 'Por favor {0} para convidar colegas a partilhar e editar as suas transcrições.',
  share_link_with: 'Partilhar com permissão de leitura',
  share_with_editable_permission: 'Partilhar com permissão de edição',
  share_with: 'ex: sample1@gmail.com,sample2@gmail.com',
  share_with_currently: 'Atualmente partilhado com',
  share_empty: 'Por favor, vá a <a href="\\management" style="color:#2FC59F;">Gestão</a> para adicionar utilizadores partilháveis.',
  uploading: 'A carregar',
  shared: 'Partilhado',
  thumbnail: 'Miniatura',
  upload_purpose: 'Selecionar o formato de saída',
  upload_number: 'Número de locutores',
  upload_category: 'Escolha a melhor categoria para o seu vídeo',
  upload_clipped: 'Intervalo de tempo',
  clipped_adjustments_desc: 'Clique no manípulo do cursor e pressione as teclas de seta esquerda/direita para ajustes mais precisos.',
  clipped_description: 'Apenas extrairemos e transcreveremos dentro do intervalo de tempo selecionado do ficheiro media.<br>Suporta YouTube, mp3 e mp4.',
  clipped_warning: 'Isso aumentará o tempo de processamento',
  clipped_length_warning: 'Este recurso está disponível para ficheiros media com mais de 1 minuto',
  purpose_description: 'Isto permite-nos formatar a transcrição para o ajudar na edição.',

  speaker_unsure: 'Divisão automática',

  purpose_speakers: 'Divida automaticamente a sua transcrição <strong>rotulando cada locutor</strong>',
  purpose_transcript_desc: '(ex: Adicionando legendas ao vídeo, sem pontuação)',
  purpose_captions: 'Divida automaticamente a sua transcrição para <strong>legendas/subtítulos</strong>',
  purpose_caption_desc: '(ex: Transcrições para as suas reuniões, com pontuação)',
  purpose_article: 'Transcrição com pontuação <strong>sem método de formatação específico</strong>',
  purpose_translate_desc: '(ex: Transcrições para produzir artigos, com pontuação)',
  purpose_original: 'Divida a sua transcrição de forma idêntica ao <strong>ficheiro de entrada</strong>',

  speakers_time: 'Locutores',
  captions_time: 'Legendas/Subtítulos',
  article_time: 'Ficheiro de entrada',
  original_time: 'Ficheiro de entrada',
  none_time: '',

  captions_time_desc: 'Divida automaticamente a sua transcrição para o propósito de legendagem.',
  speakers_time_desc: 'Divida automaticamente a sua transcrição rotulando cada locutor.',
  article_time_desc: 'A transcrição terá um formato idêntico ao ficheiro de texto fornecido.',
  original_time_desc: 'A transcrição terá um formato idêntico ao ficheiro de texto fornecido.',
  none_time_desc: '',

  purpose_other: 'Ainda não decidi',
  create: 'Criar',
  home: 'Início',
  management_corp: 'Gestão',
  management: 'Gestão',
  account: 'Conta',
  upgrade: 'Atualizar',
  faq: 'FAQ',
  logout: 'Terminar sessão',
  return: 'Voltar à edição',
  drag_drop: 'Arraste e solte o seu ficheiro de vídeo/áudio aqui',
  select_from: 'Selecionar do seu dispositivo',
  or_select_from: 'Ou selecionar de',
  common_languages: 'Idiomas comuns',
  additional_languages: 'Idiomas adicionais',

  friendly_notice: 'Dicas para melhorar a precisão',
  friendly_notice_desc0: '1.O idioma selecionado deve coincidir com o vídeo em si, a tradução pode ser feita após a transcrição',
  friendly_notice_desc: '2.O áudio deve ser claro e ter pouca música ou ruído de fundo',
  friendly_notice_desc2: '3.Evite carregar vídeos relacionados com música ou canto',
  friendly_notice_desc3: '4.Editar a faixa de som de qualquer forma pode falhar a transcrição',
  friendly_notice_desc4: '5.Carregar arquivos *.mp4 ou *.mp3 é preferido',

  google_drive_upgrade_title: 'Selecionar do Google Drive',
  google_drive_upgrade_desc: 'Pode evitar o tempo de espera de upload e acelerar o processo de transcrição quando subscrever.',
  trial_check_desc: 'Concordo em utilizar a transcrição apenas para uso pessoal, não comercial. (Compre minutos ou uma subscrição premium para obter a licença comercial)',

  browser_unsupported_title: 'Navegador não suportado',
  browser_unsupported_desc: 'Por favor, utilize o Chrome num computador para obter a melhor experiência de edição.',
  browser_unsupported_mobile_desc: 'Por favor, utilize o Chrome para a melhor experiência de edição.',
  browser_translated_injected_title: 'Por favor, desative a tradução de página web',
  browser_translated_injected_desc: 'A falha ao desativar a tradução de página web pode resultar em comportamentos imprevisíveis. (ex: Não conseguir salvar edições e desconexões)',
  browser_unsupported_step1: '1. Clique em "..." ou "⋮" no canto inferior direito ou superior direito',
  browser_unsupported_step2: '2. Selecione Abrir no Navegador',

  individual_account: 'Minha conta',
  corp_account_selection_desc: 'Quando um utilizador de Subscrição em Massa o adicionar à conta, esta aparecerá no menu de seleção suspenso',
  unsave_change_title: 'Tem alterações não guardadas',
  unsave_change_desc: 'Tem a certeza de que deseja sair?',
  label_name: 'Nome da etiqueta',
  new_label: 'Nova etiqueta',
  select_label: 'Selecionar acesso à etiqueta',
  label_dialog_header: 'Gestão de Etiquetas',
  label_dialog_desc: 'Edite e reordene as suas etiquetas',
  label_empty: 'Nenhuma etiqueta',
  label_all: 'Todos os ficheiros',
  label_select_header: 'Etiqueta',
  label_limit_error: 'O limite de etiquetas foi atingido. Atualize para a Subscrição Premium ou Subscrição em Massa para desbloquear o limite',
  segment_sample: 'Exemplo de saída',
  zoom_no_data: 'Não existem gravações nos últimos 2 anos...',
  zoom_desc: 'Só mostrará gravações dos últimos 2 anos.',
  zoom_loading: 'A carregar gravações...',
  import_srt: 'Importar SRT',
  srt_header: 'Método de transcrição',
  srt_select: 'Selecionar ficheiro SRT',
  srt_desc: 'Aguardando ficheiro SRT...',
  import_txt: 'Importar TXT',
  txt_select: 'Selecionar ficheiro de texto',
  txt_desc: 'Aguardando ficheiro de texto...',
  direct_link: '<span class="directlink-text-en">Link Direto</span>',

  multi_upload: 'Os subscritores podem transcrever vários ficheiros ao mesmo tempo.',
  category: 'Categoria',
  max_char_header: 'Máximo de caracteres',
  max_char_desc: 'O número máximo de caracteres para que as legendas sejam exibidas de uma só vez.<br> A transcrição será dividida em secções de acordo com este limite.<br>Valor recomendado: 95<br> Valor recomendado para Chinês/Japonês/Coreano: 20',
  language_tooltip: 'Por favor, selecione o idioma falado para este vídeo. <br> Para traduzir para outros idiomas, <br>vá para a plataforma de edição e traduza a partir do canto superior direito após transcrever.<br><br> Se a sua seleção para <span style="color:#2FC59F">Método de transcrição</span> abaixo for <span style="color:#2FC59F">Importar ficheiro SRT</span>, <br> certifique-se de que seleciona o idioma idêntico ao ficheiro SRT fornecido.',
  import_txt_tooltip: 'Iremos adicionar carimbos de tempo ao ficheiro de texto fornecido. <br><strong>{0}</strong>: {1}',

  home_notice_title: 'Poupe mais minutos',
  home_notice_desc: 'O nosso novo sistema de dedução de minutos agora omite segundos adicionais! <div class="desc">Exemplo: Carregar um ficheiro media de 15 minutos e 59 segundos irá deduzir 15 minutos. (Carregar um ficheiro media com menos de 1 minuto será gratuito 🙂)</div>',
  no_name: 'Sem título',
  upload_language_reminder_title: 'Certifique-se de que o idioma selecionado corresponde ao idioma falado no vídeo',
  upload_language_reminder_desc: 'Exemplo: Se o vídeo estiver em inglês, por favor selecione "Inglês" como o idioma. <br> Se desejar traduzir legendas ou transcrições de vídeo, primeiro converta-as em texto e, em seguida, entre na página de edição. <br> Uma vez dentro da página de edição, use a opção "Traduzir" no canto superior direito para traduzir o vídeo.'
}
