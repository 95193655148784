module.exports = { 
    error_title: '404',
    error_desc: 'Halaman yang Anda cari tidak ada 😟',
    share_network_error: 'Anda tidak memiliki izin atau ini adalah masalah jaringan.',
    permission_error: 'Anda tidak memiliki izin untuk melakukan tindakan ini, silakan hubungi administrator akun.',
    share_email_exist: 'Email sudah ada.',
    user_not_found: 'Tidak dapat menemukan pengguna.',
    email_invalid: 'Email tidak valid',
    email_not_registered: 'Bukan email terdaftar Taption',
    email_duplicate: 'Anda sudah menambahkan email ini ke akun Anda',
    email_slots_required: 'Silakan beli lebih banyak kursi untuk menambahkan anggota baru.',
    youtube_url_invalid: 'URL Youtube tidak valid',
    youtube_url_required: 'URL Youtube diperlukan',
    directlink_url_invalid: 'Kami belum mendukung URL yang disediakan saat ini, silakan email kami di help@taption.com untuk meminta dukungan',
    directlink_url_required: 'URL diperlukan',
    required: 'Wajib diisi',
    size_error: 'Ukuran file Anda tidak boleh lebih besar dari {0}GB.',
    size_error_mb: 'Ukuran file Anda tidak boleh lebih besar dari {0}MB.',
    verify_email: 'Silakan periksa email Anda untuk mengaktifkan akun Anda. Jika Anda tidak menerima pesan konfirmasi, silakan periksa [Folder Spam] Anda.',
    email_must_be_valid: 'E-mail harus valid',
    password_not_match: 'Kata sandi tidak cocok',
    invalid_youtube: 'Tidak dapat mengambil video, silakan coba video lain atau coba lagi nanti.',
    unsupported_youtube: 'Video ini dibatasi, silakan coba video lain.',
    unsupported_direct_video: 'Video ini dibatasi<br>(Tautan video langsung tidak didukung)',
    must_be_more_than: 'Harus lebih dari {0} karakter',
    must_be_less_than: 'Harus kurang dari {0} karakter',
    must_be_more_than_words: 'Harus lebih dari {0} kata',
    trial_limit_error: 'Versi percobaan memiliki batas 15 menit. Silakan beli menit atau berlangganan untuk menghapus batas ini.',
    language_required: 'Bahasa diperlukan',
    title_required: 'Judul diperlukan',
    title_length: 'Judul harus maksimal 80 karakter',
    purpose_required: 'Tujuan diperlukan',
    speakers_number_required: 'Jumlah pembicara diperlukan',
    category_required: 'Kategori diperlukan',
    method_required: 'Silakan pilih cara untuk mentranskripsikan media Anda',
    max_length: 'Harus kurang dari {0} karakter',
    max_char_required: 'Silakan masukkan nilai antara {0} dan 1000. (Nilai yang disarankan:{1})',
    insufficient_fund_title: 'Menit tidak cukup',
    insufficient_ai_desc: 'Kredit tidak cukup, beli lebih banyak kredit AI untuk melanjutkan.',
    exceed_trial_limit_title: 'Panjang video melebihi batas percobaan',
    file_too_large_title: 'Ukuran video terlalu besar',
    not_supported_type_title: 'Jenis video tidak didukung',
    insufficient_fund_tip: 'Beli lebih banyak menit dan coba lagi.',
    exceed_trial_limit_tip: 'Percobaan memberi Anda 1 video gratis di bawah 15 menit. Beli menit atau tingkatkan ke Premium untuk menghapus batas ini',
    file_too_large_tip: 'Ukuran file tidak boleh melebihi 2GB',
    not_supported_type_tip: 'Format video yang didukung: mov, avi, wmv, flv, mpeg, ogg, mp4',
    media_no_audio: 'Media yang disediakan tidak memiliki audio',
    network_upload_fail_title: 'Jaringan Anda tidak stabil, silakan coba lagi atau gunakan Google Drive',
    network_upload_fail_tip: 'Anda dapat memilih file media Anda dari Google Drive saat mengunggah.',
    transcribe_prepare_fail_title: 'Server sedang sibuk, silakan coba lagi nanti',
    transcribe_upload_fail_title: 'Server sedang sibuk, silakan coba lagi nanti',
    transcribe_merge_fail_title: 'Server sedang sibuk, silakan coba lagi nanti',
    transcribe_progress_fail_title: 'Server sedang sibuk, silakan coba lagi nanti',
    transcribe_result_fail_title: 'Server sedang sibuk, silakan coba lagi nanti',
    transcribe_error_fail_title: 'Server sedang sibuk, silakan coba lagi nanti',
    directlink_url_error_title: 'Tautan yang disediakan tidak valid',
    directlink_url_error_tip: 'Pastikan izin untuk tautan tersedia untuk umum',
    exceed_trial_usage_title: 'Melebihi penggunaan percobaan',
    exceed_trial_usage_tip: 'Beli menit atau berlangganan untuk melanjutkan layanan',
    need_payment_title: 'Langganan dijeda',
    need_payment_tip: 'Silakan selesaikan masalah pembayaran untuk melanjutkan layanan',
    exceed_usage_title: 'Melebihi penggunaan',
    exceed_usage_tip: 'Silakan hubungi dukungan kami.',
    file_too_long_title: 'Media terlalu panjang',
    file_too_long_tip: 'Harap jaga panjang media Anda dalam 2 jam',
    download_youtube_video_title: 'Tidak dapat mengunduh video ini, silakan coba video lain atau coba lagi nanti.',
    download_youtube_video_tip: 'Silakan coba mengunggah lagi. Jika masalah berlanjut, coba unggah video yang berbeda atau hubungi dukungan kami.',
    video_corrupted_title: 'Kami tidak dapat membaca file ini, pastikan file tersebut valid',
    video_corrupted_tip: 'Pastikan file tersebut valid atau hubungi kami di help@taption.com',
    system_maintanance_title: 'Peringatan',
    system_maintanance: 'Pemeliharaan sistem. Silakan coba lagi nanti dengan merefresh halaman.',
    error_add_title: 'Kesalahan',
    error_add_desc: 'Tidak dapat menambahkan frasa yang dikoreksi ke dalam kamus.',
    error_warning: 'Peringatan',
    warning_no_search_found: 'Tidak dapat menemukan "{0}"',
    time_message: 'Ada <strong>{0}</strong> kasus tumpang tindih timestamp subtitle, yang mungkin menyebabkan perangkat lunak pihak ketiga membacanya secara tidak benar setelah diekspor.',
    time_message1: 'Ada <strong>{0}</strong> kasus timestamp subtitle yang terlalu pendek dan memerlukan perhatian.',
    time_message2: '(Posisi timestamp berwarna oranye dapat disesuaikan menggunakan <strong>Timeline Editor</strong> di pojok kiri bawah)',
    overlap: 'Timestamp tumpang tindih dengan tetangga',
    short: 'Durasi caption ini kurang dari 0,1 detik',
    error_merge_one: 'Anda memiliki satu video yang sedang dalam proses ekspor, harap tunggu hingga selesai dan coba lagi.',
    retry_upload: 'Coba lagi',
    label_required: 'Silakan pilih setidaknya satu label',
    label_name_required: 'Nama label diperlukan',
    label_name_duplicate: 'Nama label sudah ada',
    srt_file_required: 'File SRT diperlukan',
    srt_file_invalid: 'File SRT tidak valid atau kosong',
    txt_file_required: 'File TXT diperlukan',
    txt_file_invalid: 'File TXT tidak valid atau kosong',
    media_file: 'File media diperlukan',
    media_srt_mismatch: 'Media yang dipilih lebih pendek dari file SRT yang dipilih',
    subscription_pay_failed_title: 'Pembayaran langganan Anda gagal',
    subscription_pay_failed_desc: 'Silakan lakukan salah satu dari berikut ini:',
    subscription_pay_failed_resolve1: 'Perbarui metode pembayaran Anda dengan kartu kredit baru di Akun',
    subscription_pay_failed_resolve2: 'Pastikan ada cukup dana di kartu Anda atau hubungi penerbit kartu kredit kemudian beri tahu kami di support@taption.com',
    subscription_pay_failed_resolve3: 'Batalkan langganan Anda saat ini',
    maximum_videos_title: 'Akun gratis dapat menyimpan maksimal {0} video',
    maximum_videos_title1: 'Akun Basic dapat menyimpan maksimal {0} video',
    maximum_videos_resolve1: 'Hapus video yang ada untuk menjaga jumlah total video di bawah {0}',
    maximum_videos_resolve2: 'Beli atau tingkatkan ke salah satu <a href="https://app.taption.com/upgrade">paket</a> kami untuk memperpanjang atau menghapus batas',
    manual_warning: 'Memilih <strong>Manual</strong> akan memberi Anda transkrip kosong yang mengharuskan Anda memasukkan teks sendiri. <br>Jika mendapatkan transkrip otomatis adalah tujuannya, silakan pilih <strong>Transkripsi otomatis</strong>.',
    txt_mismatch_error: 'Bahasa yang dipilih tidak cocok dengan file teks input.<br>Pastikan bahasa cocok untuk hasil terbaik.',
    txt_invalid_error: 'Pengkodean file input tidak dapat dideteksi.<br>Pastikan file disimpan dalam pengkodean yang benar.<br><span style="font-size:12px">(<strong>Contoh</strong>: Buka file teks Anda, <strong>Simpan sebagai</strong>→<strong>Pengkodean</strong> pilih <strong>UTF-8</strong>)</span>',
    language_mismatch_error: 'Bahasa video yang diunggah mungkin tidak cocok dengan bahasa yang Anda pilih. Ini dapat mengurangi akurasi transkripsi dan menyebabkan kesulitan dalam menerjemahkan ke bahasa lain.<br><br>Jika Anda ingin menerjemahkan video ke bahasa lain, pastikan untuk memilih bahasa video yang benar selama proses unggah. Setelah selesai, gunakan tombol <v-btn style="padding:3px 20px;margin:0px 5px;text-transform: capitalize;letter-spacing:0px;" class="translate-btn"><i style="font-size:14px;" aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-translate"></i><span class="text">Terjemahkan</span></v-btn> yang terletak di pojok kanan atas halaman ini untuk melanjutkan terjemahan.',
    "auth/invalid-email": 'Email Anda tidak valid.',
    "auth/wrong-password": 'Kata sandi Anda salah atau akun ini tidak ada.',
    "auth/email-already-in-use": 'Alamat email sudah digunakan oleh akun lain.',
    "auth/user-not-found": 'Kata sandi Anda salah atau akun ini tidak ada.',
    confirm_password_desc: 'Silakan masukkan kembali kata sandi Anda',
    confirm_password_not_match: 'Kata sandi tidak cocok',
}