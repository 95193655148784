module.exports = { 
    video_quality_reminder: 'Mengapa kualitas video ini berkurang?',
    video_quality_tooltip: 'Pemilik transkrip ini perlu berlangganan untuk menampilkan dalam kualitas aslinya.',
    export: 'Ekspor',
    speaker: 'Pembicara',
    full_screen: 'Layar Penuh',
    full_screen_exit: 'Keluar dari layar penuh',
    play: 'Putar (TAB)',
    pause: 'Jeda (TAB)',
    captions_overlay_on: 'Teks Tertutup: <span style="color:#20F7C4">Aktif</span><br><span>(Geser untuk menyesuaikan posisi)</span>',
    captions_overlay_off: 'Teks: <span style="color:rgb(230, 118, 117)">Mati</span><br><span>(Geser untuk mengatur posisi)</span>',
}