module.exports = { 
    video_quality_reminder: 'なぜこのビデオの画質が低下しているのですか？',
    video_quality_tooltip: 'このトランスクリプトの所有者は、元の品質で表示するためにサブスクリプションを購入する必要があります。',
    export: 'エクスポート',
    speaker: 'スピーカー',
    full_screen: 'フルスクリーン',
    full_screen_exit: '全画面を終了',
    play: '再生 (TAB)',
    pause: '一時停止（TAB）',
    captions_overlay_on: '字幕：<span style="color:#20F7C4">オン</span><br><span>（位置を調整するにはスライド）</span>',
    captions_overlay_off: 'キャプション: <span style="color:rgb(230, 118, 117)">オフ</span><br><span>(スライドして位置を調整)</span>',
}