module.exports = { 
    error_title: '404',
    error_desc: '很抱歉，此页面不存在 😟',
    share_network_error: '网络发生问题或无权限，请稍后再试或者联络我们客服',
    permission_error: '你没有权限使用此功能，请联络帐号管理者',
    share_email_exist: '电子邮件已经存在',
    user_not_found: '找不到此用户',
    email_invalid: '电子邮件无效',
    email_not_registered: '此Email未在Taption注册',
    email_duplicate: '此Email已加入帐号',
    email_slots_required: '空间已满，请购买空间来加入更多帐号',
    youtube_url_invalid: '这不是一个有效的Youtube网址',
    youtube_url_required: '请输入Youtube网址',
    directlink_url_invalid: '我们目前不支援所提供的URL连结，请来信至help@taption.com询问支援',
    directlink_url_required: '请输入影音档的URL连结',
    required: '此处必须填写',
    size_error: '档案大小不能超过{0}GB.',
    size_error_mb: '档案大小不能超过{0}MB.',
    verify_email: '请到信箱点击我们寄给您的连结来开启帐号。如果没收到请到 [垃圾邮件] 确认。',
    email_must_be_valid: '信箱不正确',
    password_not_match: '密码不一致',
    invalid_youtube: '无法取得影片，请换一个连结或者稍后再试',
    unsupported_youtube: '不支援此影片, 请换一个连结',
    unsupported_direct_video: '无法读取影片连结<br>(不支援直播连结)',
    must_be_more_than: '必须多须{0}字元',
    must_be_less_than: '必须少须{0}字元',
    must_be_more_than_words: '必须多须{0}字',
    trial_limit_error: '试用版影音档必须遵守15分钟。请购买时数或订阅来取消此限制',
    language_required: '请选择语言',
    title_required: '请额定文本命名',
    title_length: '名称不能超过80个字',
    purpose_required: '请选择分段方式',
    speakers_number_required: '发声者总人数',
    category_required: '请选择影片类别',
    method_required: '请选择转译方式',
    max_length: '必须小于{0}个字符',
    max_char_required: '必须填入介於{0}和1000之间的数值(建议数值:{1})',
    insufficient_fund_title: '您的分钟数不足',
    insufficient_ai_desc: '您的AI点数不足, 请购买更多AI点数',
    exceed_trial_limit_title: '影片长度超过试用版限制',
    file_too_large_title: '影片档案太大',
    not_supported_type_title: '不支援此影片格式',
    insufficient_fund_tip: '请购买更多分钟后，再试一次',
    exceed_trial_limit_tip: '试用版可免费上传一支15分钟以内的影片. 购买分钟或订阅为高级用户来移除15分钟的限制',
    file_too_large_tip: '影片档案不能超过2GB',
    not_supported_type_tip: '支援影片格式: mov, avi, wmv, flv, mpeg, ogg, mp4',
    media_no_audio: '提供的影音档没有声音',
    network_upload_fail_title: '您的网络不稳定，稍后再试或是使用Google Drive.',
    network_upload_fail_tip: '上传时可从Google Drive选取影音档.',
    transcribe_prepare_fail_title: '伺服器忙线，请稍后再试',
    transcribe_upload_fail_title: '伺服器忙线，请稍后再试',
    transcribe_error_fail_title: '伺服器忙线，请稍后再试',
    transcribe_merge_fail_title: '伺服器忙线，请稍后再试',
    transcribe_progress_fail_title: '伺服器忙线，请稍后再试',
    transcribe_result_fail_title: '伺服器忙线，请稍后再试',
    directlink_url_error_title: '无法读取URL连结的档案',
    directlink_url_error_tip: '请确认此URL连结的权限为公开',
    exceed_trial_usage_title: '试用超额',
    exceed_trial_usage_tip: '请购买分钟数或订阅来继续使用服务',
    need_payment_title: '订阅暂停',
    need_payment_tip: '请确认目前绑定信用卡可支付或更换信用卡来恢复订阅',
    exceed_usage_title: '试用超额',
    exceed_usage_tip: '请与我们联络来继续使用服务',
    file_too_long_title: '影片过长',
    file_too_long_tip: '请上传2小时以內的影片',
    download_youtube_video_title: '此Youtube连结失败，请换连结或稍候再试一次',
    download_youtube_video_tip: '如果Youtube连结失败持续发生，请换一个影片连结或是与我们联络',
    video_corrupted_title: '我们无法读取此档案，请确认档案可正常播放',
    video_corrupted_tip: '如有问题请寄信到help@taption.com',
    error_add_title: '错误',
    error_add_desc: '无法将此更改加入辞典',
    error_warning: '注意',
    warning_no_search_found: '没有找到 "{0}"',
    system_maintanance_title: '注意',
    system_maintanance: '系統維護中...請稍後刷新頁面再重試',
    time_message: '字幕时间轴有 <strong>{0}</strong> 处时间重叠，导出后可能会造成第三方软体无法正常读取。 ',
    time_message1: '字幕时间轴有 <strong>{0}</strong> 处时间过短需要注意。 ',
    time_message2: '(时间轴橘色相对位置，使用左下角「时间编辑器」来调整)',
    overlap: '时间与前一句或下一句重叠',
    short: '此段显示时间少于0.1秒',
    error_merge_one: '您目前有一支正在处理中的影片，请完成后再尝试。',
    retry_upload: '重新处理',
    label_required: '请选择至少一个标签',
    label_name_required: '标签名称不能为空白',
    label_name_duplicate: '标签名称不能重复',
    srt_file_required: '请上传SRT字幕档',
    srt_file_invalid: 'SRT档案格式有误',
    txt_file_required: '请上传TXT字幕档',
    txt_file_invalid: '此档案非TXT文字档案',
    media_file: '请上传影音档案',
    media_srt_mismatch: '影音档的长度小于SRT的长度',
    subscription_pay_failed_title: '您的订阅付款失败',
    subscription_pay_failed_desc: '参考以下几种解决方式：',
    subscription_pay_failed_resolve1: '到『我的帐号』下更换信用卡',
    subscription_pay_failed_resolve2: '确认余额足够或联络银行后与我们联系support@taption.com',
    subscription_pay_failed_resolve3: '取消订阅',
    maximum_videos_title: '免费帐号最多储存{0}部影片',
    maximum_videos_title1: '基本帐号最多储存{0}部影片',
    maximum_videos_resolve1: '删除现有影片保持总数量在{0}部以下',
    maximum_videos_resolve2: '购买或升级到其中一个<a href="https://app.taption.com/upgrade">方案</a>来扩充或移除此限制',
    manual_warning: '选择 <strong>手动输入</strong> 不会自动转成文字<br>会给予一个空白的影片文字对应编辑页面。 <br>如果需要自动转文字请选择 <strong>AI自动生成</strong>。 ',
    txt_mismatch_error: '影片选择的语言与汇入的文字档案不一样.<br>请确认两者相同来达到最佳转译效果。 ',
    txt_invalid_error: '无法侦测汇入文字档案的编码<br>请确认文字档案本身为正确的编码来避免乱码或其他不可预测的错误<br><span style="font-size:12px">(<strong>例</strong>: 开启文字档案, <strong>另存为</strong>→<strong>编码</strong> 选择 <strong>UTF-8</strong>)</span>',
    language_mismatch_error: '您上传的影片语言与所选择的语言可能不符。这会降低转录准确率，并导致无法顺利翻译成其他语言。 <br><br>如果您希望将影片翻译成其他语言，请务必在上传时选择与影片实际语言一致的选项，然后再透过右上角的<v-btn style="padding:0px 20px;margin: 0px 5px;" class="translate-btn"><i style="font-size:14px;" aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-translate"> </i><span class="text">翻译</span></v-btn>进行转换。 ',
    "auth/invalid-email": '信箱不正确',
    "auth/wrong-password": '您的密码有误或者此帐号不存在',
    "auth/email-already-in-use": '此信箱已经被注册',
    "auth/user-not-found": '您的密码有误或者此帐号不存在',
    confirm_password_desc: '请重新输入您的密码',
    confirm_password_not_match: '密码不匹配',
}