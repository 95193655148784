module.exports = { 
    update: 'Perbarui',
    cancel: 'Batal',
    remove: 'Hapus',
    session_title: 'Sesi Anda akan segera berakhir',
    session_description: 'Anda akan keluar dalam 5 menit',
    session_ok: 'Tetap masuk',
    session_no: 'Keluar',
    free_min_title: 'Selamat!',
    free_min_desc: 'Anda telah menerima <span style="color:#2fc59f!important">{0} menit</span> karena {1} rujukan baru.',
    email_placeholder: 'Masukkan email...',
    a_few_seconds_ago: 'beberapa detik yang lalu',
    short_seconds: 'd',
    short_minutes: 'm',
    minute: 'menit',
    minutes: 'menit',
    hour: 'jam',
    hours: 'jam',
    day: 'hari',
    days: 'hari',
    month: 'bulan',
    months: 'bulan',
    year: 'tahun',
    years: 'tahun',
    ago: 'yang lalu',
    a: 'sebuah',
    an: 'sebuah',
    title: 'Judul',
    welcome: 'Selamat datang',
    youtube: 'Youtube',
    send: 'Kirim',
    on: 'Aktif',
    off: 'Nonaktif',
    new: 'Baru',
    search: 'Cari',
    upgrade: 'Upgrade',
    feature_updating: 'Fitur sedang diperbarui, harap periksa kembali nanti.',
    copy: 'Salin',
    copied: 'Tersalin',
    trial: 'Uji coba',
    basic: 'Dasar',
    premium: 'Premium',
    corp: 'Korporat',
    speakers: 'pembicara',
    captions: 'teks/subtitel',
    article: 'artikel',
    char: 'karakter',
    language: 'Bahasa',
    duration: 'Durasi',
    type: 'Tipe',
    date: 'Tanggal',
    transcriptType1: 'Teks',
    transcriptType2: 'Pembicara',
    transcriptType3: 'Artikel',
    accounts: 'Akun',
    label: 'Label',
    none: 'Tidak ada',
    me: 'Saya',
    usd: 'USD',
    january: 'Januari',
    february: 'Februari',
    march: 'Maret',
    april: 'April',
    may: 'Mei',
    june: 'Juni',
    july: 'Juli',
    august: 'Agustus',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Desember',
    th: 'Nomor',
    pricing: 'Harga',
    privacy: 'Privasi',
    terms: 'Syarat dan Ketentuan',
    blog: 'Sumber Daya',
    video: 'Saluran Youtube',
    cookieSettings: 'Pengaturan Cookie',
    back: 'Kembali',
    credit: 'kredit',
    ok: 'Ok',
}