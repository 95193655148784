module.exports = {
  upload: 'Upload',
  upload_language: 'Select the spoken language',
  upload_method: 'Select the transcribing method',
  delete_title: 'Delete transcript?',
  delete_description: 'This action cannot be undone.',
  delete_ok: 'Delete',
  delete_cancel: 'Cancel',
  remember_selection: 'Remember me',
  send_email: 'Send me an email when complete.',
  card_share: 'Share',
  card_delete: 'Delete',
  upload_media: 'Upload media',
  upload_error_title:'Error',
  upload_error: 'You do not have enough minutes.',
  transcribing_description: 'We are transcribing your file. <br>The processing time takes about<br>the duration of the file.<br> We will email you when ready.',
  transcribing: 'Processing',
  preparing: 'Preparing',
  update_title: 'Update title',
  transcript_title: 'Title',
  add_more:'Add',

  
  upload_limit_exceeded_title:'Exceeded maximum length',
  upload_limit_exceeded: 'Please limit your video length to be less than 5 hours.',

  initializing_description: 'We are processing your file. <br>This will take a couple minutes, please wait.',
  initializing: 'Waiting',
  translating_description: 'We are translating your file. <br>This will take a couple minutes, please wait.',
  translating: 'Translating',
  error_description: 'Please contact us for more information',
  error_info: 'Error Code: {0}',
  error: 'Something went wrong',
  error_minutes: '{0} <br> Your minutes have been returned.<br> Refresh your browser for the most updated minutes.',

  please_wait: 'Please wait...',
  loading: 'Loading...',
  upgrade_to_share: 'Please {0} to invite colleagues to share and edit your transcripts.',
  share_link_with: 'Share with readonly permission',
  share_with_editable_permission: 'Share with editable permission',
  share_with: 'ex: sample1@gmail.com,sample2@gmail.com',
  share_with_currently: 'Currently shared with',
  share_empty:'Please go to <a href="\\management" style="color:#2FC59F;">Management</a> to add shareable users.',
  uploading: 'Uploading',
  shared: 'Shared',
  thumbnail:'Thumbnail',
  upload_purpose: 'Select your output format',
  upload_number: 'Number of speakers',
  upload_category: 'Choose the best category for your video',
  upload_clipped:'Time range',
  clipped_adjustments_desc:'Click the slider handle and press the left/right arrow keys for more granular adjustments.',
  clipped_description:'We will only extract and transcribe within the selected time range of the media file.<br>Supports Youtube, mp3 and mp4 type media.',
  clipped_warning:'This will increase processing time',
  clipped_length_warning:'This feature is available for media longer than 1 minute',
  purpose_description: 'This let us format the transcription to help you with your editing.',

  speaker_unsure: 'Auto-split',

  // purpose_transcript:'Segment each sections by 「{0}」',
  // purpose_transcript_desc:'(e.g. Adding captions to video)',
  // purpose_caption:'Segment each sections for 「{0}」',
  // purpose_caption_desc:'(e.g. Transcripts for your meetings)',
  // purpose_translate:'Segment each sections by 「{0}」',
  // purpose_translate_desc:'(e.g. Transcripts for producing articles)',
  purpose_speakers:'Automatically break down your transcript by <strong>labeling each speaker</strong>',
  purpose_transcript_desc:'(e.g. Adding captions to video, no punctuation)',
  purpose_captions:'Automatically break down your transcript for <strong>captions/subtitles</strong>',
  purpose_caption_desc:'(e.g. Transcripts for your meetings, punctuated)',
  purpose_article:'Transcript with punctuation <strong>no specific formatting method</strong>',
  purpose_translate_desc:'(e.g. Transcripts for producing articles, punctuated)',
  purpose_original:'Break down your transcript identical to your <strong>input file</strong>',

  speakers_time:'Speakers',
  captions_time:'Captions/Subtitles',
  article_time:'Input file',
  original_time:'Input file',
  none_time:'',
  
  captions_time_desc:'Automatically break down your transcript for the purpose of captioning.',
  speakers_time_desc:'Automatically break down your transcript by labeling each speaker.',
  article_time_desc:'Transcript will have identical format as the input text file you provided.',
  original_time_desc:'Transcript will have identical format as the input text file you provided.',
  none_time_desc:'',


  purpose_other:'I haven\'t decided yet',
  create:'Create',
  home:'Home',
  management_corp:'Management',
  management:'Management',
  account:'Account',
  upgrade:'Upgrade',
  faq:'FAQ',
  logout:'Logout',
  return:'Return to editing',
  drag_drop:'Drag and drop your video/audio file here',
  select_from:'Select from your device',
  or_select_from: 'Or select from',
  common_languages: 'Common languages',
  additional_languages: 'Additional languages',

  // friendly_notice: 'Friendly notice',
  // friendly_notice_desc: 'To get the best accuracy, please ensure the audio is clear and has little background music or noises'
  friendly_notice: 'Tips to improve accuracy',
  friendly_notice_desc0: '1.Selected language must match video itself, translation can be done after transcription',
  friendly_notice_desc: '2.Audio is clear and has little background music or noises',
  friendly_notice_desc2: '3.Avoid uploading music or singing related videos',
  friendly_notice_desc3: '4.Editing the sound track in anyway may fail to transcribe',
  friendly_notice_desc4: '5.Uploading *.mp4 or *.mp3 is preferred',

  google_drive_upgrade_title: 'Select from Google Drive',
  google_drive_upgrade_desc: 'You can skip the uploading wait and speed up your transcribing process when you subscribe.',
  trial_check_desc:'I agree to use the transcription only for my personal, non-commercial use. (Purchase minutes or premium subscription to obtain commercial license)',

  browser_unsupported_title: 'Browser not supported',
  browser_unsupported_desc: 'Please use Chrome on a computer for best editing experience.',
  browser_unsupported_mobile_desc: 'Please use Chrome for best editing experience.',
  browser_translated_injected_title: 'Please turn off web-page translation',
  browser_translated_injected_desc: 'Failure to turn off web-page translation may result in unpredictable behaviors. (ex: Editing not being able to save and disconnecting)',
  browser_unsupported_step1: '1. Click "..." or "⋮" in the lower right or upper right corner',
  browser_unsupported_step2: '2. Select Open in Browser',

  individual_account: 'My account',
  corp_account_selection_desc:'When a Bulk Subscription user add you to their account, it will appear in the dropdown selection',
  unsave_change_title:'You have unsaved changes',
  unsave_change_desc:'Are you sure you want to leave?',
  label_name:'Label name',
  new_label:'New label',
  select_label:'Select label access',
  label_dialog_header:'Label Management',
  label_dialog_desc:'Edit and reorder you labels',
  label_empty:'No label',
  label_all:'All files',
  label_select_header:'Label',
  label_limit_error:'Label limit has been reached. Upgrade to Premium Subscription or Bulk Subscription to unlock the limit',
  segment_sample:'Sample output',
  zoom_no_data:'There are no recordings in the last 2 years...',
  zoom_desc:'Will only show recordings in the past 2 years.',
  zoom_loading:'Loading recordings...',
  import_srt:'Import SRT',
  srt_header:'Transcribing method',
  srt_select:'Select SRT file',
  srt_desc:'Waiting for SRT file...',
  import_txt:'Import TXT',
  txt_select:'Select text file',
  txt_desc:'Waiting for TXT file...',
  direct_link:'<span class="directlink-text-en">Direct Link</span>',

  multi_upload:'Subscribers can transcribe multiple files at once.',
  category:'Category',
  max_char_header:'Max characters',
  max_char_desc:'The maximum number of characters for the captions to be shown at a time.<br> The transcript will split into sections according to this threshold.<br>Recommended value: 95<br> Recommended value for Chinese/Japanese/Korean: 20',
  language_tooltip:'Please select the spoken language for this video. <br> In order to translate into other languages, <br>go into the editing platform and translate from the upper right after transcribing.<br><br> If your selection for <span style="color:#2FC59F">Transcribing method</span> below is <span style="color:#2FC59F">Import SRT file</span>, <br> make sure to select the language identical to the provided SRT file.',
  import_txt_tooltip:'We will add timestamps to the provided text file. <br><strong>{0}</strong>: {1}',

  home_notice_title:'Save more minutes',
  home_notice_desc:'Our new minutes deduction system will now omit any extra seconds! <div class="desc">Example: Uploading a 15 minutes 59 seconds media will deduct 15 minutes. (Uploading a media less than 1 minute will be free of charge 🙂)</div>',
  no_name:'Untitled',
  upload_language_reminder_title:'Ensure the selected language matches the video\'s spoken language',
  upload_language_reminder_desc:'Example: If the video is in English, please select "English" as the language. <br> If you wish to translate video subtitles or transcripts, first convert them to text and then enter the editing page. <br> Once inside the editing page, use the "Translate" option in the upper right corner to translate the video.'
  
}
