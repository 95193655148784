module.exports = { 
    upload: 'アップロード',
    upload_language: '話し手の言語を選んでください',
    upload_method: '文字起こしの方法を選んでください',
    delete_title: '文字起こしを削除しますか？',
    delete_description: 'この操作は元に戻せません。',
    delete_ok: '削除',
    delete_cancel: '取り消し',
    remember_selection: '選択した設定を保存する',
    send_email: '完了時にメールでお知らせ',
    card_share: '共有',
    card_delete: '削除',
    upload_media: 'メディアをアップロード',
    upload_error_title: 'エラー',
    upload_error: '利用可能時間が不足しています。',
    transcribing_description: 'ファイルの文字起こし中です。<br>この処理にはファイルの再生時間ほど<br>時間がかかる場合があります。<br>処理が完了したらメールでお知らせします。',
    transcribing: '処理中',
    preparing: '準備中',
    update_title: 'タイトルを更新',
    transcript_title: 'タイトル',
    upload_limit_exceeded_title: '最大の再生時間を超えています。',
    upload_limit_exceeded: '動画の再生時間は最大5時間までです。',
    initializing_description: 'ファイルを処理しています。<br>これには数分かかりますのでお待ちください。',
    initializing: '待機中',
    translating_description: 'ファイルの文字起こし中です。<br>これには数分かかりますのでお待ちください。',
    translating: '翻訳中',
    error_description: '詳しくは、弊社までお問い合わせください。',
    error_info: 'エラーコード: {0}',
    error: '問題が発生しました',
    error_minutes: '{0} <br> 利用時間は返却されます。<br>ブラウザを更新し、利用時間を確認してください。',
    please_wait: 'お待ちください...',
    loading: 'ローディング中...',
    upgrade_to_share: '{0}をして、同僚を招待し文字起こしをシェアして一緒に編集しましょう。',
    share_link_with: '読み取り専用権限で共有',
    share_with_editable_permission: '編集可能権限で共有',
    share_with: '例: sample1@gmail.com,sample2@gmail.com',
    share_with_currently: '現在共有中のユーザー',
    share_empty: '<a href="\\management" style="color:#2FC59F;">管理</a>メニューで共有可能なユーザーを追加できます。',
    uploading: 'アップロード中',
    shared: '共有済み',
    thumbnail: 'サムネイル',
    upload_purpose: '出力形式を選択してください',
    upload_number: '話し手の数',
    upload_category: '動画に適合するカテゴリーを選んでください',
    upload_clipped: 'オーディオ クリップとビデオ クリップをキャプチャ',
    clipped_adjustments_desc: '秒を微調整するには、マウスを使用してプル軸をクリックしてください<br>キーボードの左右のキーを使用して調整してください',
    clipped_description: 'オーディオ ファイルとビデオ ファイルからクリップをクリップしてテキストを識別し、時間をより効率的に使用します。<br>YouTube リンク、mp3 および mp4 オーディオおよびビデオ ファイルをサポート',
    clipped_warning: 'この関数を使用すると処理時間が長くなります',
    clipped_length_warning: 'この機能を使用するには、ビデオ ファイルが 1 分より長くなければなりません',
    purpose_description: 'これによって、より編集しやすい文字起こしになります。',
    speaker_unsure: '自動区分け',
    purpose_speakers: 'AI が音声とビデオ内の<strong>話し手</strong>を自動的にマークし、セグメントに分割します',
    purpose_transcript_desc: '(例： 動画に字幕を追加，句読点なし)',
    purpose_captions: 'AI が自動的に<strong>字幕</strong>の形でセグメントに分割します',
    purpose_caption_desc: '(例：会議の文字起こし，句読点あり)',
    purpose_article: '句読点を含む逐語的な原稿 <strong>セグメンテーションなし</strong>',
    purpose_translate_desc: '(例：記事作成のための文字起こし，句読点あり)',
    purpose_original: 'セグメント化は、 提供された<strong>テキスト ファイル</strong>内のセグメントに従って実行されます',
    speakers_time: '話し手',
    captions_time: '字幕',
    article_time: 'ソーステキストファイル',
    original_time: 'ソーステキストファイル',
    none_time: '',
    speakers_time_desc: 'AI が音声とビデオ内の話し手を自動的にマークし、セグメントに分割します。',
    captions_time_desc: 'AI が自動的に字幕の形でセグメントに分割します。',
    article_time_desc: 'セグメント化は、 提供されたテキスト ファイル内のセグメントに従って実行されます。',
    original_time_desc: 'セグメント化は、 提供されたテキスト ファイル内のセグメントに従って実行されます。',
    none_time_desc: '',
    purpose_other: 'まだ決めていません',
    create: '作成',
    home: 'ホーム',
    management_corp: '管理',
    management: '管理',
    account: 'アカウント',
    upgrade: 'アップグレード',
    faq: 'よくある質問',
    logout: 'ログアウト',
    return: '編集に戻る',
    drag_drop: 'ここに動画/音声ファイルをドラッグ・ドロップします',
    select_from: 'デバイスから選択',
    or_select_from: 'または　から選択',
    common_languages: '基本言語設定',
    additional_languages: '追加の言語',
    friendly_notice: '精度を上げるコツ',
    friendly_notice_desc0: '1.選択した言語は動画の言語と一致しなければなりません。翻訳は文字起こしの後に行われます',
    friendly_notice_desc: '2.音声がクリアで背景に音楽やノイズが入らないようにしてください',
    friendly_notice_desc2: '3.音楽や音楽に関する動画のアップロードは避けてください',
    friendly_notice_desc3: '4.サウンドトラックを編集すると、いずれの場合でも文字起こしができません',
    friendly_notice_desc4: '5.*.mp4または*.mp3形式でアップロードすると精度が上がります',
    google_drive_upgrade_title: 'Googleドライブから選ぶ',
    google_drive_upgrade_desc: 'プランを購読して、アップロード待機や文字起こしをスピードアップできます。',
    trial_check_desc: '個人的で非商用の目的のみ文字起こしを使用することに同意します。(商用利用のライセンス取得には、利用時間を購入またはプレミアムプランを購読してください)',
    browser_unsupported_title: 'ブラウザはサポートされていません',
    browser_unsupported_desc: '最適な経験のために、デスクトップ版クロームを使用してください',
    browser_unsupported_mobile_desc: '最適な経験のために、クロームを使用してください',
    browser_translated_injected_title: 'ウェブページ翻訳をオフにしてくださいn',
    browser_translated_injected_desc: 'ウェブページ翻訳をオフにしなかった場合、予期しない動作が発生する可能性があります (例: 編集内容が保存されないなど)',
    browser_unsupported_step1: '1. 右下または右上の"..."または"⋮"をクリック',
    browser_unsupported_step2: '2. ブラウザで開くを選択',
    individual_account: 'アカウント情報',
    corp_account_selection_desc: 'エンタープライズプランのユーザーがあなたをアカウントに追加すると、ドロップダウンに表示されます',
    unsave_change_title: '保存されていない変更内容があります',
    unsave_change_desc: '閉じてもいいですか？',
    label_name: 'ラベル名',
    new_label: '新しいラベル',
    select_label: 'ラベルを選択',
    label_dialog_header: 'ラベル管理',
    label_dialog_desc: 'ラベルの編集および並べ替え',
    label_empty: 'ラベルがありません',
    label_all: 'すべてのファイル',
    label_select_header: 'ラベル',
    label_limit_error: 'ラベル数が最大値に達しました。制限を解除するには、プレミアムプランまたはエンタープライズプランを購読してください',
    segment_sample: 'サンプル出力',
    zoom_no_data: '過去2年間の録画記録はありません...',
    zoom_desc: '過去2年時間の録画記録のみ表示されます。',
    zoom_loading: '録画記録をローディング中...',
    import_srt: 'SRTファイルをインポート',
    srt_header: '文字起こしの方法',
    srt_select: 'SRTファイルを選択',
    srt_desc: 'SRTファイルを待っています...',
    import_txt: 'TXTファイルをインポート',
    txt_select: 'テキストファイルを選択',
    txt_desc: 'TXTファイルを待っています...',
    direct_link: '<span class="directlink-jap">ダウンロードURLリンク</span>',
    multi_upload: '購読者は一度に複数のファイルをアップロードできます',
    category: '動画カテゴリ',
    max_char_header: '字幕あたりの最大文字数',
    max_char_desc: '翻訳されたテキストはこの値に従ってセグメント化され、各セグメントはこの単語数を超えません。<br>推奨値:<br>日本語/韓国語/中国語: 20<br>その他の言語: 95',
    language_tooltip: 'ビデオまたはオーディオ ファイルの言語を選択します。<br>別の言語に変換する場合は、まずテキストに変換してから、翻訳用の編集プラットフォームに入る必要があります。 <br><br> 下の<span style="color:#2FC59F">「文字起こしの方法」</span>で<span style="color:#2FC59F">「SRTファイルをインポート」</span>を選択した場合、<br>SRT 字幕ファイルの言語を選択してください。 ',
    import_txt_tooltip: 'ご提供いただいたテキストを対応するタイムラインに追加します。<br><strong>{0}</strong>: {1}',
    home_notice_title: 'さらに時間を節約',
    home_notice_desc: '新しい分単位の課金方法により、余分な秒数が節約されます!<div class="desc">例: 15 分 59 秒の動画をアップロードすると、15 分しか差し引かれません。(1 分未満の動画をアップロードすると、分は差し引かれません)ビデオから差し引かれます 🙂)</div>',
    no_name: '名前なし',
    upload_language_reminder_title: '選択した言語がビデオの音声と一致していることを確認してください',
    upload_language_reminder_desc: '例：ビデオが英語の場合は、言語として「英語」を選択してください。<br>ビデオの字幕や逐語録の翻訳を希望する場合は、まずテキストに変換してから編集プラットフォームに進んでください。<br>その後、右上の「翻訳」オプションを使用してビデオを翻訳します。',
    add_more: '追加',
}