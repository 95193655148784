module.exports = {
    convert_to_text_title_meta:"Online KI-Videozusammenfassung: Themen hinzufügen, Besprechungen zusammenfassen, YouTube-Kapitel mit einem Klick erstellen",
    convert_to_text_desc_meta:"Unser KI-Analysetool ermöglicht es Ihnen, Inhalte effizient zusammenzufassen, wichtige Sprecher zu identifizieren, Erzählskripte zu erstellen und YouTube-Kapitel und -Zeitstempel zu integrieren. Entdecken Sie unsere verschiedenen Vorlagen, darunter Videozusammenfassungen, YouTube-Zeitstempelgeneratoren und Video-Themen-Zeitstempelgeneratoren. Starten Sie heute mit einer kostenlosen Testversion!",
    convert_to_text_title:"KI-Analyse",
    convert_to_text_desc:"Videozusammenfassung, YouTube-Kapitel, Besprechungsübersicht",
    start_your_trial:"Starten Sie Ihre kostenlose Testversion!",
    hero_img:"./11_English.webp",

    three_step_title:"3 Schritte zur Transkription",
    step_one:"Stellen Sie eine Frage oder geben Sie einen Befehl",
    step_one_img:"./aianalysis_step1_en_US.webp",
    step_one_desc:"Sie können wählen, ob das KI-Videotool Ihre Inhalte zusammenfassen, wichtige Sprecher identifizieren, Erzählskripte vorschlagen und YouTube-Kapitel und Zeitstempel hinzufügen soll.",
    
    step_two:"Wählen Sie aus vordefinierten Befehlen",
    step_two_img:"./aianalysis_step2_en_US.webp",
    step_two_desc:"Die KI-Analyse bietet vordefinierte Vorlagen wie einen Videozusammenfassungsgenerator, YouTube-Zeitstempelgenerator und Video-Themen-Zeitstempelgenerator.",

    step_three:"Zusammenfassen in Ihrer bevorzugten Sprache",
    step_three_img:"./aianalysis_step3_en_US.webp",
    step_three_desc:"Unabhängig von der Originalsprache des Videos können Sie in Ihrer bevorzugten Sprache zusammenfassen oder Befehle geben.",

    feature_tutorial_title:'Videoanalyse und Zusammenfassung mit Taption',
    feature_tutorial_desc:"Die Funktion „KI-Analyse“ ist ein leistungsstarkes Tool für die umfassende Analyse von Videoinhalten. Benutzer können Videos für eine detaillierte Zusammenfassung hochladen oder vordefinierte Vorlagen wie Videozusammenfassung, KI-Themengenerator, YouTube-Zeitstempel und YouTube-Kapitel verwenden, um eine Ein-Klick-Analyse durchzuführen. Dies verbessert das Verständnis und die Interaktion mit den wichtigsten Punkten des Videos erheblich.",
    feature_tutorial_poster_url:'./aianalysis_poster_en_US.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/PWOWwbHQCUM?autoplay=1',

    feature_why_image:'./ai_template_demo_en.webp',
    feature_extra_title:"Anwendungsbeispiele für die KI-Analyse",
    feature_extra_desc:"Um das volle Potenzial der KI-Analyse zu nutzen, ist es entscheidend, präzise Eingabeaufforderungen zu erstellen. So können Sie Ihre Anfragen formulieren, um aussagekräftige Einblicke zu erhalten: <ul><li><strong>Zusammenfassungsaufforderung</strong>: \"Fassen Sie das Video in 300 Wörtern zusammen.\" Diese Aufforderung ist ideal für schnelle Übersichten.</li><li><strong>Aufforderung für Aktionspunkte</strong>: \"Identifizieren Sie die Aktionspunkte aus der Besprechung.\" Diese Aufforderung hilft bei der Hervorhebung von Aufgaben und Verantwortlichkeiten.</li><li><strong>Aufforderung für Coaching-Feedback</strong>: \"Sie sind ein hilfreicher Coach. Analysieren Sie das Transkript und bieten Sie Verbesserungsmöglichkeiten mit genauen Zitaten an. Kein Vorwort einfügen.\"</li><li><strong>Videoskript</strong>: Bitte helfen Sie mir, ein Skript für ein etwa 5-10-minütiges Erklärvideo für diesen Film zu erstellen, das sich auf die Handlung und die Ideen konzentriert, die der Autor vermitteln möchte.</li></ul>Beginnen Sie beim Erstellen einer Eingabeaufforderung mit einer allgemeinen Zusammenfassung und fügen Sie dann zusätzliche detaillierte Anweisungen hinzu.",

    feature_what_title:"Wie füge ich YouTube-Zeitstempel und Zusammenfassungen hinzu?",
    feature_what_desc: "<ul>\n  <li>Melden Sie sich bei <a href=\"https://www.taption.com/login\">Taption</a> an</li>\n  <li>Klicken Sie auf „Hochladen“, um Ihr MP4-Video hochzuladen oder fügen Sie einen YouTube-Link zum Transkribieren ein</li>\n  <li>Nach Abschluss können Sie die Plattform zur Bearbeitung betreten und bei Bedarf bearbeiten oder übersetzen</li>\n  <li>Klicken Sie unten auf die Schaltfläche „KI-Analyse“</li>\n  <li>Klicken Sie auf „Analysieren“, um YouTube-Kapitel hinzuzufügen und eine Videozusammenfassung zu erstellen</li>\n</ul>"
}
