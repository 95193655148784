module.exports = { 
    video_quality_reminder: '¿Por qué se reduce la calidad de este video?',
    video_quality_tooltip: 'El propietario de esta transcripción deberá suscribirse para mostrarla en su calidad original.',
    export: 'Exportar',
    speaker: 'Altavoz',
    full_screen: 'Pantalla completa',
    full_screen_exit: 'Salir de pantalla completa',
    play: 'Reproducir (TAB)',
    pause: 'Pausa (TAB)',
    captions_overlay_on: 'Subtítulos: <span style="color:#20F7C4">Activados</span><br><span>(Deslice para ajustar la posición)</span>',
    captions_overlay_off: 'Subtítulos: <span style="color:rgb(230, 118, 117)">Desactivado</span><br><span>(Deslizar para ajustar posición)</span>',
}