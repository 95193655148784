module.exports = {
  upload: 'Subir',
  upload_language: 'Seleccionar el idioma hablado',
  upload_method: 'Seleccionar el método de transcripción',
  delete_title: '¿Eliminar transcripción?',
  delete_description: 'Esta acción no se puede deshacer.',
  delete_ok: 'Eliminar',
  delete_cancel: 'Cancelar',
  remember_selection: 'Recordar mi selección',
  send_email: 'Enviarme un correo electrónico cuando esté completo.',
  card_share: 'Compartir',
  card_delete: 'Eliminar',
  upload_media: 'Subir medio',
  upload_error_title:'Error',
  upload_error: 'No tienes suficientes minutos.',
  transcribing_description: 'Estamos transcribiendo tu archivo. <br>El tiempo de procesamiento toma aproximadamente<br>la duración del archivo.<br> Te enviaremos un correo electrónico cuando esté listo.',
  transcribing: 'Procesando',
  preparing: 'Preparando',
  update_title: 'Actualizar título',
  transcript_title: 'Título',
  add_more:'Añadir',

  
  upload_limit_exceeded_title:'Se excedió la duración máxima',
  upload_limit_exceeded: 'Por favor, limita la duración de tu video a menos de 5 horas.',

  initializing_description: 'Estamos procesando tu archivo. <br>Esto tomará un par de minutos, por favor espera.',
  initializing: 'Esperando',
  translating_description: 'Estamos traduciendo tu archivo. <br>Esto tomará un par de minutos, por favor espera.',
  translating: 'Traduciendo',
  error_description: 'Por favor, contáctanos para más información',
  error_info: 'Código de error: {0}',
  error: 'Algo salió mal',
  error_minutes: '{0} <br> Tus minutos han sido devueltos.<br> Actualiza tu navegador para ver los minutos más actualizados.',

  please_wait: 'Por favor, espera...',
  loading: 'Cargando...',
  upgrade_to_share: 'Por favor, {0} para invitar a colegas a compartir y editar tus transcripciones.',
  share_link_with: 'Compartir con permiso de solo lectura',
  share_with_editable_permission: 'Compartir con permiso de edición',
  share_with: 'ej: ejemplo1@gmail.com,ejemplo2@gmail.com',
  share_with_currently: 'Actualmente compartido con',
  share_empty:'Por favor, ve a <a href="\\management" style="color:#2FC59F;">Gestión</a> para añadir usuarios con los que compartir.',
  uploading: 'Subiendo',
  shared: 'Compartido',
  thumbnail:'Miniatura',
  upload_purpose: 'Selecciona tu formato de salida',
  upload_number: 'Número de hablantes',
  upload_category: 'Elige la mejor categoría para tu video',
  upload_clipped:'Rango de tiempo',
  clipped_adjustments_desc:'Haz clic en el control deslizante y presiona las teclas de flecha izquierda/derecha para ajustes más precisos.',
  clipped_description:'Solo extraeremos y transcribiremos dentro del rango de tiempo seleccionado del archivo multimedia.<br>Compatible con Youtube, mp3 y mp4.',
  clipped_warning:'Esto aumentará el tiempo de procesamiento',
  clipped_length_warning:'Esta función está disponible para medios de más de 1 minuto',
  purpose_description: 'Esto nos permite formatear la transcripción para ayudarte con tu edición.',

  speaker_unsure: 'División automática',

  purpose_speakers:'Desglosar automáticamente tu transcripción <strong>etiquetando a cada hablante</strong>',
  purpose_transcript_desc:'(ej. Añadir subtítulos al video, sin puntuación)',
  purpose_captions:'Desglosar automáticamente tu transcripción para <strong>subtítulos</strong>',
  purpose_caption_desc:'(ej. Transcripciones para tus reuniones, con puntuación)',
  purpose_article:'Transcripción con puntuación <strong>sin método de formato específico</strong>',
  purpose_translate_desc:'(ej. Transcripciones para producir artículos, con puntuación)',
  purpose_original:'Desglosar tu transcripción idéntica a tu <strong>archivo de entrada</strong>',

  speakers_time:'Hablantes',
  captions_time:'Subtítulos',
  article_time:'Archivo de entrada',
  original_time:'Archivo de entrada',
  none_time:'',
  
  captions_time_desc:'Desglosar automáticamente tu transcripción con el propósito de subtitular.',
  speakers_time_desc:'Desglosar automáticamente tu transcripción etiquetando a cada hablante.',
  article_time_desc:'La transcripción tendrá el mismo formato que el archivo de texto de entrada que proporcionaste.',
  original_time_desc:'La transcripción tendrá el mismo formato que el archivo de texto de entrada que proporcionaste.',
  none_time_desc:'',


  purpose_other:'Aún no he decidido',
  create:'Crear',
  home:'Inicio',
  management_corp:'Gestión',
  management:'Gestión',
  account:'Cuenta',
  upgrade:'Mejorar',
  faq:'Preguntas frecuentes',
  logout:'Cerrar sesión',
  return:'Volver a editar',
  drag_drop:'Arrastra y suelta tu archivo de video/audio aquí',
  select_from:'Seleccionar desde tu dispositivo',
  or_select_from: 'O seleccionar desde',
  common_languages: 'Idiomas comunes',
  additional_languages: 'Idiomas adicionales',

  friendly_notice: 'Consejos para mejorar la precisión',
  friendly_notice_desc0: '1. El idioma seleccionado debe coincidir con el del video, la traducción se puede hacer después de la transcripción',
  friendly_notice_desc: '2. El audio es claro y tiene poco ruido de fondo o música',
  friendly_notice_desc2: '3. Evita subir videos relacionados con música o canto',
  friendly_notice_desc3: '4. Editar la pista de sonido de cualquier manera puede fallar en la transcripción',
  friendly_notice_desc4: '5. Se prefiere subir archivos *.mp4 o *.mp3',

  google_drive_upgrade_title: 'Seleccionar desde Google Drive',
  google_drive_upgrade_desc: 'Puedes saltarte la espera de carga y acelerar tu proceso de transcripción cuando te suscribas.',
  trial_check_desc:'Acepto usar la transcripción solo para mi uso personal y no comercial. (Compra minutos o una suscripción premium para obtener una licencia comercial)',

  browser_unsupported_title: 'Navegador no compatible',
  browser_unsupported_desc: 'Por favor, usa Chrome en un ordenador para la mejor experiencia de edición.',
  browser_unsupported_mobile_desc: 'Por favor, usa Chrome para la mejor experiencia de edición.',
  browser_translated_injected_title: 'Por favor, desactiva la traducción de la página web',
  browser_translated_injected_desc: 'No desactivar la traducción de la página web puede resultar en comportamientos impredecibles. (ej: No poder guardar la edición y desconexiones)',
  browser_unsupported_step1: '1. Haz clic en "..." o "⋮" en la esquina inferior derecha o superior derecha',
  browser_unsupported_step2: '2. Selecciona Abrir en el navegador',

  individual_account: 'Mi cuenta',
  corp_account_selection_desc:'Cuando un usuario de Suscripción Masiva te añada a su cuenta, aparecerá en la selección desplegable',
  unsave_change_title:'Tienes cambios sin guardar',
  unsave_change_desc:'¿Estás seguro de que quieres salir?',
  label_name:'Nombre de la etiqueta',
  new_label:'Nueva etiqueta',
  select_label:'Seleccionar acceso a etiqueta',
  label_dialog_header:'Gestión de etiquetas',
  label_dialog_desc:'Edita y reordena tus etiquetas',
  label_empty:'Sin etiqueta',
  label_all:'Todos los archivos',
  label_select_header:'Etiqueta',
  label_limit_error:'Se ha alcanzado el límite de etiquetas. Mejora a Suscripción Premium o Suscripción Masiva para desbloquear el límite',
  segment_sample:'Ejemplo de salida',
  zoom_no_data:'No hay grabaciones en los últimos 2 años...',
  zoom_desc:'Solo se mostrarán las grabaciones de los últimos 2 años.',
  zoom_loading:'Cargando grabaciones...',
  import_srt:'Importar SRT',
  srt_header:'Método de transcripción',
  srt_select:'Seleccionar archivo SRT',
  srt_desc:'Esperando archivo SRT...',
  import_txt:'Importar TXT',
  txt_select:'Seleccionar archivo de texto',
  txt_desc:'Esperando archivo TXT...',
  direct_link:'<span class="directlink-text-en">Enlace directo</span>',

  multi_upload:'Los suscriptores pueden transcribir múltiples archivos a la vez.',
  category:'Categoría',
  max_char_header:'Máximo de caracteres',
  max_char_desc:'El número máximo de caracteres para los subtítulos que se mostrarán a la vez.<br> La transcripción se dividirá en secciones según este límite.<br>Valor recomendado: 95<br> Valor recomendado para chino/japonés/coreano: 20',
  language_tooltip:'Por favor, selecciona el idioma hablado en este video. <br> Para traducir a otros idiomas, <br>ve a la plataforma de edición y traduce desde la esquina superior derecha después de transcribir.<br><br> Si tu selección para <span style="color:#2FC59F">Método de transcripción</span> abajo es <span style="color:#2FC59F">Importar archivo SRT</span>, <br> asegúrate de seleccionar el idioma idéntico al del archivo SRT proporcionado.',
  import_txt_tooltip:'Añadiremos marcas de tiempo al archivo de texto proporcionado. <br><strong>{0}</strong>: {1}',

  home_notice_title:'Ahorra más minutos',
  home_notice_desc:'¡Nuestro nuevo sistema de deducción de minutos ahora omitirá los segundos extra! <div class="desc">Ejemplo: Subir un medio de 15 minutos y 59 segundos deducirá 15 minutos. (Subir un medio de menos de 1 minuto será gratis 🙂)</div>',
  no_name:'Sin título',
  upload_language_reminder_title:'Asegúrate de que el idioma seleccionado coincida con el idioma hablado en el video',
  upload_language_reminder_desc:'Ejemplo: Si el video está en inglés, por favor selecciona "Inglés" como idioma. <br> Si deseas traducir los subtítulos o transcripciones del video, primero conviértelos a texto y luego entra en la página de edición. <br> Una vez dentro de la página de edición, usa la opción "Traducir" en la esquina superior derecha para traducir el video.'
  
}