module.exports = { 
    affiliate: '聯盟行銷',
    page_title: '加入Taption聯盟行銷',
    page_description: '向新客戶介紹Taption。分享您的專屬聯盟行銷鏈接，每次成功推薦都可賺取佣金。讓我們一起成長吧！ ',
    tell_us_about_yourself: '告訴我們關於您的資訊',
    first_name: '名',
    last_name: '姓氏',
    business_name: '公司名稱（如適用）',
    location: '你住在哪裡？ （城市、省/州、國家）',
    contact: '聯絡資訊',
    email: '電子郵件地址',
    phone: '電話號碼',
    linkedin_url: 'LinkedIn網址',
    social_url: '社群媒體網址',
    tell_us_about_site: '告訴我們您的網站',
    website_url: '網站網址',
    traffic: '預計每月流量',
    what_site_about: '您的網站是關於什麼的？ ',
    anything_else: '還有其他需要我們了解的嗎？ ',
    must_agree: '您必須同意才能繼續',
    read_agree: '我已閱讀並同意',
    affiliate_tos: '聯盟條款與條件',
    submit: '提交申請',
    submit_success: '感謝您的興趣！您的申請已提交，我們的團隊將盡快與您聯繫。 ',
    name_required_err: '姓名為必填項',
    name_character_limit_err: '名稱必須少於50個字元',
    email_required_err: '電子郵件是必填項',
    email_invalid_err: '電子郵件必須有效',
    phone_needed_err: '請輸入有效的電話號碼',
    website_required_err: '需要填寫網站網址',
    invalid_website_err: '請輸入有效的URL',
    location_required_err: '所在地為必填項',
    estimate_traffic_err: '請選擇流量估算',
    describe_site_err: '請介紹您的網站',
    generic_err: '部分欄位需要您的注意，請檢查表單並修正錯誤',
    step1: '步驟1：註冊',
    step1_desc: '註冊只需不到3分鐘！填寫以下表單，我們將在 24 小時內與您聯繫。',
    step2: '步驟2：分享您的專屬連結',
    step2_desc: '一旦您成為 Taption 的合作夥伴，我們將提供一個專屬連結，供您分享並確保所有推薦與佣金都能準確追蹤。',
    step3: '步驟3：賺取收益',
    step3_desc: '當有人透過您的連結訂閱服務或購買分鐘數，您將賺取佣金—簡單又有收益！',
}