module.exports = {
    update: '更改',
    cancel: '取消',
    remove: '移除',
    session_title: '您即將被登出',
    session_description: '系統將在5分鐘後自動登出',
    session_ok: '保持登入',
    session_no: '登出',
    free_min_title: '恭喜!',
    free_min_desc: '您邀請的{1}位朋友訂閱了，因此我們贈送<span style="color:#2fc59f!important">{0}分鐘</span>給您',
    email_placeholder: '請輸入電子郵件...',
    a_few_seconds_ago:'幾秒前',
    short_seconds:'秒',
    short_minutes:'分',
    minute:'分鐘',
    minutes:'分鐘',
    hour:'小時',
    hours:'小時',
    day:'天',
    days:'天',
    month:'月',
    months:'月',
    year:'年',
    years:'年',
    ago:'之前',
    a:'一',
    an:'一',
    title: '名稱',
    welcome: '歡迎',
    youtube: 'Youtube',
    send:'送出',
    on:'開啟',
    off:'關閉',
    new: '新',
    search: '搜尋',
    upgrade: '升級',
    feature_updating: '功能更新中，請稍後再試',
    copy: '複製',
    copied: '複製成功',
    trial:'試用',
    basic:'基本',
    premium:'高級訂閱',
    corp:'大量訂閱',
    speakers:'發聲者',
    captions:'字幕',
    article:'逐字稿',
    char:'字',
    language:'語言',
    duration:'長度',
    type:'類型',
    date: '日期',
    transcriptType1:'字幕',
    transcriptType2:'發聲者',
    transcriptType3:'逐字稿',
    accounts:'帳號選擇',
    label:'標籤',
    none: '無',
    me: '我',
    usd:'美金',  
    january:'1月',
    february:'2月',
    march:'3月',
    april:'4月',
    may:'5月',
    june:'6月',
    july:'7月',
    august:'8月',
    september:'9月',
    october:'10月',
    november:'11月',
    december:'12月',
    th:'號',
    pricing:'價格',
    privacy:'隱私權政策',
    terms:'服務條款',
    blog:'部落格',
    video:'影片專區',
    cookieSettings:'Cookie設定',
    back:'返回',
    credit:'點',
    ok: '確認'
  }