module.exports = { 
    update: 'Aktualisieren',
    cancel: 'Abbrechen',
    remove: 'Entfernen',
    session_title: 'Ihre Sitzung läuft bald ab',
    session_description: 'Sie werden in 5 Minuten abgemeldet',
    session_ok: 'Angemeldet bleiben',
    session_no: 'Abmelden',
    free_min_title: 'Herzlichen Glückwunsch!',
    free_min_desc: 'Sie haben <span style="color:#2fc59f!important">{0} Minuten</span> erhalten dank {1} neuer Empfehlung(en).',
    email_placeholder: 'E-Mail eingeben...',
    a_few_seconds_ago: 'vor wenigen Sekunden',
    short_seconds: 'Sek.',
    short_minutes: 'Min.',
    minute: 'Minute',
    minutes: 'Minuten',
    hour: 'Stunde',
    hours: 'Stunden',
    day: 'Tag',
    days: 'Tage',
    month: 'Monat',
    months: 'Monate',
    year: 'Jahr',
    years: 'Jahre',
    ago: 'vor',
    a: 'ein',
    an: 'eine',
    title: 'Titel',
    welcome: 'Willkommen',
    youtube: 'YouTube',
    send: 'Senden',
    on: 'An',
    off: 'Aus',
    new: 'Neu',
    search: 'Suche',
    upgrade: 'Upgrade',
    feature_updating: 'Funktion wird aktualisiert, bitte später wiederkommen.',
    copy: 'Kopieren',
    copied: 'Kopiert',
    trial: 'Testversion',
    basic: 'Basic',
    premium: 'Premium',
    corp: 'Firmenkunden',
    speakers: 'Sprecher',
    captions: 'Untertitel',
    article: 'Artikel',
    char: 'Zeichen',
    language: 'Sprache',
    duration: 'Dauer',
    type: 'Typ',
    date: 'Datum',
    transcriptType1: 'Untertitel',
    transcriptType2: 'Sprecher',
    transcriptType3: 'Artikel',
    accounts: 'Konten',
    label: 'Label',
    none: 'Keine',
    me: 'Ich',
    usd: 'US-Dollar',
    january: 'Januar',
    february: 'Februar',
    march: 'März',
    april: 'April',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Dezember',
    th: 'Nummer',
    pricing: 'Preise',
    privacy: 'Datenschutz',
    terms: 'Nutzungsbedingungen',
    blog: 'Ressourcen',
    video: 'YouTube-Kanal',
    cookieSettings: 'Cookie-Einstellungen',
    back: 'Zurück',
    credit: 'Gutschrift(en)',
    ok: 'OK',
}