module.exports = { 
    error_title: '404',
    error_desc: 'お探しのページは存在しません 😟',
    share_network_error: 'アクセス許可がないか、ネットワークに問題があります。',
    permission_error: 'このアクションを実行する権限がありません。アカウント管理者に連絡してください。',
    share_email_exist: '登録済みのメールアドレスです。',
    user_not_found: 'ユーザーが見つかりませんでした。',
    email_invalid: '無効なメールアドレスです。',
    email_not_registered: 'このメールはTaptionに登録されていません',
    email_duplicate: 'このメールはすでにアカウントに追加されています。',
    email_slots_required: '新規メンバーのスロットがありません。追加のスロットを購入してください',
    youtube_url_invalid: '無効なYouTubeのURLです',
    youtube_url_required: 'YouTubeのURLは必須です',
    directlink_url_invalid: '提供されたURLリンクは現在サポートされていません。サポートについてはhelp@taption.comまでお問い合わせください',
    directlink_url_required: 'ビデオ ファイルのURLリンクを入力してください',
    required: '必須項目',
    size_error: 'ファイル サイズは {0}GB を超えることはできません。',
    size_error_mb: 'ファイル サイズは {0}MB を超えることはできません。',
    verify_email: 'メールを確認して、アカウントを有効にしてください。確認メッセージが届かない場合は、[迷惑メール フォルダ] を確認してください。',
    email_must_be_valid: '有効なメールアドレスを入力してください',
    password_not_match: 'パスワードが一致しません',
    invalid_youtube: '動画を取得できません。ほかの動画を使うか、後でもう一度試してください。',
    unsupported_youtube: '制限された動画です。ほかの動画を使ってください。',
    unsupported_direct_video: '動画リンクを読み取れません<br>(ライブリンクはサポートされていません)',
    must_be_more_than: '{0}文字以上の入力が必要です',
    must_be_less_than: '最大{0}文字まで入力可能です',
    must_be_more_than_words: '{0}文字以上の入力が必要です',
    trial_limit_error: 'トライアル版には15分の制限があります。この制限を解除するには、追加時間を購入するか、プレミアムプランを購読してください。',
    language_required: '言語選択は必須です',
    title_required: 'タイトル入力は必須です',
    title_length: 'タイトルは最大80文字まで入力可能です',
    purpose_required: '目的は必須項目です',
    speakers_number_required: '話し手の数は必須項目です',
    category_required: 'カテゴリーは必須項目です',
    method_required: 'メディアを文字起こしする方法を選択してください',
    max_length: '{0} 文字未満である必要があります',
    max_char_required: '{0} から 1000 の間の値を入力してください (推奨値:{1})',
    insufficient_fund_title: '残余時間が足りないです',
    insufficient_ai_desc: 'AI ポイントが不足しています。追加の AI ポイントを購入してください',
    exceed_trial_limit_title: '動画の再生時間がトライアルの制限を超えています',
    file_too_large_title: '動画サイズが最大サイズを超過しています',
    not_supported_type_title: 'サポートされていない動画の形式です',
    insufficient_fund_tip: '追加時間を購入してから再試行してください',
    exceed_trial_limit_tip: 'トライアルには15分以下の無料動画1つまで含まれます。制限を解除するには、追加時間を購入またはプレミアムプランを購読してください',
    file_too_large_tip: 'ファイルサイズは2GBを超えることはできません',
    not_supported_type_tip: 'サポートしている動画フォーマット: mov, avi, wmv, flv, mpeg, ogg, mp4',
    media_no_audio: '提供されたビデオ ファイルには音声がありません',
    network_upload_fail_title: 'ネットワークが不安定です。もう一度お試しいただくか、Googleドライブを使用してください',
    network_upload_fail_tip: 'アップロード時にGoogleドライブからメディアファイルを選択できます。',
    transcribe_prepare_fail_title: 'サーバーが大変込み合っております。しばらく経てからお試しください。',
    transcribe_upload_fail_title: ' サーバーが大変込み合っております。しばらく経てからお試しください。',
    transcribe_merge_fail_title: ' サーバーが大変込み合っております。しばらく経てからお試しください。',
    transcribe_progress_fail_title: ' サーバーが大変込み合っております。しばらく経てからお試しください。',
    transcribe_result_fail_title: ' サーバーが大変込み合っております。しばらく経てからお試しください。',
    transcribe_error_fail_title: ' サーバーが大変込み合っております。しばらく経てからお試しください。',
    directlink_url_error_title: 'URLにリンクされたファイルを読み取れません',
    directlink_url_error_tip: 'このURLリンクの許可が公開されていることを確認してください',
    exceed_trial_usage_title: 'トライアル利用制限を超過しました',
    exceed_trial_usage_tip: 'サービスの利用を継続するには、追加時間を購入またはプレミアムプランを購読してください',
    need_payment_title: '支払い方法が無効です',
    need_payment_tip: 'サービスを継続するには、お支払いの問題を解決してください',
    exceed_usage_title: '利用可能時間を超過しました',
    exceed_usage_tip: '弊社サポートまでご連絡ください。',
    file_too_long_title: 'メディアが長すぎます',
    file_too_long_tip: 'メディアの長さは 2 時間以内にしてください',
    download_youtube_video_title: 'YouTubeのリンクに失敗しました。もう一度お試しください',
    download_youtube_video_tip: 'もう一度アップロードしてください。 問題が解決しない場合は、別の動画をアップロードしてみるか、サポートにお問い合わせください。',
    video_corrupted_title: 'このファイルを読み取れません。ファイルが正常に再生できることを確認してください',
    video_corrupted_tip: 'ご質問がある場合は、help@taption.com までメールをお送りください',
    system_maintanance_title: '警告',
    system_maintanance: 'システムのメンテナンス中...',
    error_add_title: 'エラー',
    error_add_desc: '修正された文言をカスタム辞書に追加できませんでした',
    error_warning: '警告',
    warning_no_search_found: '"{0}"が見つかりません',
    time_message: '字幕のタイムラインに <strong>{0}</strong> 箇所の重複があります。エクスポート後、サードパーティのソフトウェアで正常に読み取れない可能性があります。',
    time_message1: '字幕のタイムラインに <strong>{0}</strong> 箇所の短すぎる時間があります。注意が必要です。',
    time_message2: '（タイムラインのオレンジ色の部分を、左下の「タイムエディター」を使用して調整してください）',
    overlap: '前後のタイムスタンプに重なります',
    short: 'この字幕の再生時間は0.1秒以下です',
    error_merge_one: '動画の抽出1件を処理しています。完了するまで待ってからもう一度お試しください。',
    retry_upload: '再試行',
    label_required: '少なくとも 1 つのラベルを選択してください',
    label_name_required: 'ラベル名の入力は必須です',
    label_name_duplicate: '同じラベル名がすでに存在します',
    srt_file_required: 'SRTファイルが必要です',
    srt_file_invalid: '無効または内容のないSRTファイルです',
    txt_file_required: 'TXTファイルが必要です',
    txt_file_invalid: '無効または内容のないTXTファイルです',
    media_file: 'メディアファイルは必須です',
    media_srt_mismatch: '選択されたメディアの再生時間が選択されたSRTファイルより短いです',
    subscription_pay_failed_title: 'プランの支払いに失敗しました',
    subscription_pay_failed_desc: '以下のいずれかを行ってください:',
    subscription_pay_failed_resolve1: 'アカウント情報で新しいクレジットカードをお支払い方法に登録する',
    subscription_pay_failed_resolve2: 'カード残高が十分であることを確認またはクレジット カード発行会社に問い合わせの上、support@taption.com までご連絡ください。',
    subscription_pay_failed_resolve3: '購読を廃止する',
    maximum_videos_title: '無料アカウントでは最大 {0} つのビデオを保存できます',
    minimum_videos_title1: '基本アカウントでは最大 {0} 本の動画を保存できます',
    minimum_videos_resolve1: '合計数が {0} 未満になるように既存のビデオを削除します',
    minimum_videos_resolve2: '<a href="https://app.taption.com/upgrade">プラン</a>のいずれかを購入またはアップグレードしてください',
    manual_warning: '<strong>マニュアル</strong> を選択すると、自動的にテキストに変換されません<br>編集ページに対応する空のビデオテキストが表示されます。 <br>テキストを自動的に変換する必要がある場合は、<strong>自動文字起こし </strong>を選択してください。',
    txt_mismatch_error: 'ビデオに選択された言語が、インポートされたテキスト ファイルと異なります。<br>最適な翻訳効果を得るには、言語が同じであることを確認してください。 ',
    txt_invalid_error: 'インポートされたテキスト ファイルのエンコーディングを検出できません<br>文字化けやその他の予期しないエラーを避けるために、テキスト ファイル自体が正しいエンコーディングであることを確認してください<br><span style="font-size:12px">(<strong>例</strong>: テキスト ファイルを開き、<strong>名前を付けて保存</strong>→<strong>エンコード</strong>で<strong>UTF-8</strong>を選択します)</span>',
    language_mismatch_error: 'アップロードした動画の言語が選択した言語と一致しない可能性があります。これにより、文字起こしの正確性が低下し、他の言語への翻訳が正常に行えないことがあります。<br><br>動画を他の言語に翻訳する場合は、アップロード時に動画の実際の言語と一致するオプションを必ず選択してください。その後、右上の<v-btn style="padding:0px 20px;margin:0px 5px;" class="translate-btn"><i style="font-size:14px;" aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-translate"></i><span class="text">翻訳</span></v-btn>ボタンをクリックして翻訳を行ってください。',
    "auth/invalid-email": '無効なメールアドレスです。',
    "auth/wrong-password": 'パスワードが正しくないか、アカウントが存在しません。',
    "auth/email-already-in-use": 'すでにほかのアカウントに登録されているメールアドレスです。',
    "auth/user-not-found": 'パスワードが正しくないか、アカウントが存在しません。',
    maximum_videos_title1: '基本アカウントは最大で{0}本の動画を保存できます',
    maximum_videos_resolve1: '既存のビデオを削除して、ビデオの総数を{0}未満に保ちます。',
    maximum_videos_resolve2: '<a href="https://app.taption.com/upgrade">プラン</a>の購入またはアップグレードを行い、制限を拡張または解除する',
    confirm_password_desc: 'パスワードを再入力してください',
    confirm_password_not_match: 'パスワードが一致しません。',
}