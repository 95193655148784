module.exports = { 
    error_title: '404',
    error_desc: 'Die gesuchte Seite existiert nicht 😟',
    share_network_error: 'Sie haben keine Berechtigung oder es liegt ein Netzwerkproblem vor.',
    permission_error: 'Sie haben keine Berechtigung, diese Aktion durchzuführen. Bitte kontaktieren Sie den Kontoadministrator.',
    share_email_exist: 'E-Mail existiert bereits.',
    user_not_found: 'Benutzer konnte nicht gefunden werden.',
    email_invalid: 'Ungültige E-Mail-Adresse',
    email_not_registered: 'Keine bei Taption registrierte E-Mail-Adresse',
    email_duplicate: 'Sie haben diese E-Mail-Adresse bereits zu Ihrem Konto hinzugefügt',
    email_slots_required: 'Bitte kaufen Sie weitere Plätze, um neue Mitglieder hinzuzufügen.',
    youtube_url_invalid: 'YouTube-URL ist ungültig',
    youtube_url_required: 'YouTube-URL ist erforderlich',
    directlink_url_invalid: 'Wir unterstützen die angegebene URL derzeit nicht. Bitte senden Sie eine E-Mail an help@taption.com, um Unterstützung anzufordern',
    directlink_url_required: 'URL ist erforderlich',
    required: 'Erforderlich',
    size_error: 'Ihre Datei darf nicht größer als {0} GB sein.',
    size_error_mb: 'Ihre Datei darf nicht größer als {0} MB sein.',
    verify_email: 'Bitte überprüfen Sie Ihre E-Mail, um Ihr Konto zu aktivieren. Wenn Sie die Bestätigungsnachricht nicht erhalten, überprüfen Sie bitte Ihren [Spam-Ordner].',
    email_must_be_valid: 'E-Mail-Adresse muss gültig sein',
    password_not_match: 'Passwort stimmt nicht überein',
    invalid_youtube: 'Video konnte nicht abgerufen werden. Bitte versuchen Sie es mit einem anderen Video oder später erneut.',
    unsupported_youtube: 'Dieses Video ist eingeschränkt. Bitte versuchen Sie es mit einem anderen Video.',
    unsupported_direct_video: 'Dieses Video ist eingeschränkt<br>(Live-Video-Links werden nicht unterstützt)',
    must_be_more_than: 'Muss mehr als {0} Zeichen haben',
    must_be_less_than: 'Muss weniger als {0} Zeichen haben',
    must_be_more_than_words: 'Muss mehr als {0} Wörter haben',
    trial_limit_error: 'Die Testversion hat ein Limit von 15 Minuten. Bitte kaufen Sie Minuten oder abonnieren Sie, um diese Beschränkung aufzuheben.',
    language_required: 'Sprache ist erforderlich',
    title_required: 'Titel ist erforderlich',
    title_length: 'Titel darf maximal 80 Zeichen lang sein',
    purpose_required: 'Zweck ist erforderlich',
    speakers_number_required: 'Anzahl der Sprecher ist erforderlich',
    category_required: 'Kategorie ist erforderlich',
    method_required: 'Bitte wählen Sie eine Methode zur Transkription Ihrer Medien',
    max_length: 'Darf nicht länger als {0} Zeichen sein',
    max_char_required: 'Bitte geben Sie einen Wert zwischen {0} und 1000 ein. (Empfohlener Wert: {1})',
    insufficient_fund_title: 'Nicht genügend Minuten',
    insufficient_ai_desc: 'Nicht genügend Guthaben, kaufen Sie mehr AI-Guthaben, um fortzufahren.',
    exceed_trial_limit_title: 'Videolänge überschreitet Testlimit',
    file_too_large_title: 'Videogröße zu groß',
    not_supported_type_title: 'Videotyp nicht unterstützt',
    insufficient_fund_tip: 'Kaufen Sie mehr Minuten und versuchen Sie es erneut.',
    exceed_trial_limit_tip: 'Die Testversion bietet Ihnen 1 kostenloses Video unter 15 Minuten. Kaufen Sie Minuten oder upgraden Sie auf Premium, um diese Beschränkung aufzuheben',
    file_too_large_tip: 'Dateigröße darf 2 GB nicht überschreiten',
    not_supported_type_tip: 'Unterstützte Videoformate: mov, avi, wmv, flv, mpeg, ogg, mp4',
    media_no_audio: 'Die bereitgestellten Medien haben keinen Ton',
    network_upload_fail_title: 'Ihr Netzwerk ist instabil. Bitte versuchen Sie es erneut oder verwenden Sie Google Drive',
    network_upload_fail_tip: 'Sie können Ihre Mediendatei beim Hochladen aus Google Drive auswählen.',
    transcribe_prepare_fail_title: 'Server ist ausgelastet, bitte versuchen Sie es später erneut',
    transcribe_upload_fail_title: 'Server ist ausgelastet, bitte versuchen Sie es später erneut',
    transcribe_merge_fail_title: 'Server ist ausgelastet, bitte versuchen Sie es später erneut',
    transcribe_progress_fail_title: 'Server ist ausgelastet, bitte versuchen Sie es später erneut',
    transcribe_result_fail_title: 'Server ist ausgelastet, bitte versuchen Sie es später erneut',
    transcribe_error_fail_title: 'Server ist ausgelastet, bitte versuchen Sie es später erneut',
    directlink_url_error_title: 'Der bereitgestellte Link ist nicht gültig',
    directlink_url_error_tip: 'Bitte stellen Sie sicher, dass die Berechtigung für den Link öffentlich verfügbar ist',
    exceed_trial_usage_title: 'Testnutzung überschritten',
    exceed_trial_usage_tip: 'Kaufen Sie Minuten oder abonnieren Sie, um den Service fortzusetzen',
    need_payment_title: 'Abonnement pausiert',
    need_payment_tip: 'Bitte beheben Sie das Zahlungsproblem, um den Service fortzusetzen',
    exceed_usage_title: 'Nutzung überschritten',
    exceed_usage_tip: 'Bitte kontaktieren Sie unseren Support.',
    file_too_long_title: 'Medien sind zu lang',
    file_too_long_tip: 'Bitte halten Sie Ihre Medienlänge innerhalb von 2 Stunden',
    download_youtube_video_title: 'Dieses Video kann nicht heruntergeladen werden. Bitte versuchen Sie es mit einem anderen Video oder später erneut.',
    download_youtube_video_tip: 'Bitte versuchen Sie erneut hochzuladen. Wenn das Problem weiterhin besteht, versuchen Sie, ein anderes Video hochzuladen oder kontaktieren Sie unseren Support.',
    video_corrupted_title: 'Wir konnten diese Datei nicht lesen. Bitte stellen Sie sicher, dass die Datei gültig ist',
    video_corrupted_tip: 'Stellen Sie sicher, dass die Datei gültig ist, oder kontaktieren Sie uns unter help@taption.com',
    system_maintanance_title: 'Warnung',
    system_maintanance: 'Systemwartung. Bitte versuchen Sie es später erneut, indem Sie die Seite aktualisieren.',
    error_add_title: 'Fehler',
    error_add_desc: 'Die korrigierte Phrase konnte nicht zum Wörterbuch hinzugefügt werden.',
    error_warning: 'Warnung',
    warning_no_search_found: '"{0}" konnte nicht gefunden werden',
    time_message: 'Es gibt <strong>{0}</strong> Fälle von überlappenden Untertitel-Zeitstempeln, die nach dem Export von Drittanbieter-Software möglicherweise falsch gelesen werden.',
    time_message1: 'Es gibt <strong>{0}</strong> Fälle von Untertitel-Zeitstempeln, die zu kurz sind und Aufmerksamkeit erfordern.',
    time_message2: '(Orange markierte Zeitstempelpositionen können mit dem <strong>Timeline-Editor</strong> in der unteren linken Ecke angepasst werden)',
    overlap: 'Zeitstempel überschneiden sich mit Nachbarn',
    short: 'Die Dauer dieses Untertitels beträgt weniger als 0,1 Sekunden',
    error_merge_one: 'Sie haben einen laufenden Videoexport. Bitte warten Sie, bis dieser abgeschlossen ist, und versuchen Sie es erneut.',
    retry_upload: 'Erneut versuchen',
    label_required: 'Bitte wählen Sie mindestens ein Label aus',
    label_name_required: 'Labelname ist erforderlich',
    label_name_duplicate: 'Labelname existiert bereits',
    srt_file_required: 'SRT-Datei erforderlich',
    srt_file_invalid: 'Ungültige oder leere SRT-Datei',
    txt_file_required: 'TXT-Datei erforderlich',
    txt_file_invalid: 'Ungültige oder leere TXT-Datei',
    media_file: 'Mediendatei erforderlich',
    media_srt_mismatch: 'Ausgewählte Medien sind kürzer als die ausgewählte SRT-Datei',
    subscription_pay_failed_title: 'Ihre Abonnementzahlung ist fehlgeschlagen',
    subscription_pay_failed_desc: 'Bitte führen Sie eine der folgenden Aktionen aus:',
    subscription_pay_failed_resolve1: 'Aktualisieren Sie Ihre Zahlungsmethode mit einer neuen Kreditkarte unter Konto',
    subscription_pay_failed_resolve2: 'Stellen Sie sicher, dass genügend Guthaben auf Ihrer Karte vorhanden ist, oder kontaktieren Sie den Kreditkartenaussteller und informieren Sie uns dann unter support@taption.com',
    subscription_pay_failed_resolve3: 'Kündigen Sie Ihr aktuelles Abonnement',
    maximum_videos_title: 'Kostenlose Konten können maximal {0} Videos speichern',
    maximum_videos_title1: 'Basic-Konten können maximal {0} Videos speichern',
    maximum_videos_resolve1: 'Löschen Sie bestehende Videos, um die Gesamtanzahl der Videos unter {0} zu halten',
    maximum_videos_resolve2: 'Kaufen oder upgraden Sie auf einen unserer <a href="https://app.taption.com/upgrade">Tarife</a>, um die Limits zu erweitern oder zu entfernen',
    manual_warning: 'Wenn Sie <strong>Manuell</strong> auswählen, erhalten Sie ein leeres Transkript, in das Sie Ihren eigenen Text eingeben müssen. <br>Wenn Sie eine automatische Transkription wünschen, wählen Sie bitte <strong>Automatische Transkription</strong>.',
    txt_mismatch_error: 'Die ausgewählte Sprache stimmt nicht mit der Eingabetextdatei überein.<br>Bitte stellen Sie sicher, dass die Sprachen für das beste Ergebnis übereinstimmen.',
    txt_invalid_error: 'Die Kodierung der Eingabedatei konnte nicht erkannt werden.<br>Bitte stellen Sie sicher, dass die Datei in der korrekten Kodierung gespeichert ist.<br><span style="font-size:12px">(<strong>Beispiel</strong>: Öffnen Sie Ihre Textdatei, <strong>Speichern unter</strong>→<strong>Kodierung</strong> wählen Sie <strong>UTF-8</strong>)</span>',
    language_mismatch_error: 'Die Sprache des hochgeladenen Videos stimmt möglicherweise nicht mit der von Ihnen ausgewählten Sprache überein. Dies könnte die Genauigkeit der Transkription verringern und zu Schwierigkeiten bei der Übersetzung in andere Sprachen führen.<br><br>Wenn Sie das Video in eine andere Sprache übersetzen möchten, stellen Sie bitte sicher, dass Sie während des Upload-Prozesses die korrekte Sprache des Videos auswählen. Verwenden Sie anschließend die Schaltfläche <v-btn style="padding:3px 20px;margin:0px 5px;text-transform: capitalize;letter-spacing:0px;" class="translate-btn"><i style="font-size:14px;" aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-translate"></i><span class="text">Übersetzen</span></v-btn> in der oberen rechten Ecke dieser Seite, um mit der Übersetzung fortzufahren.',
    "auth/invalid-email": 'Ihre E-Mail-Adresse ist ungültig.',
    "auth/wrong-password": 'Ihr Passwort ist falsch oder dieses Konto existiert nicht.',
    "auth/email-already-in-use": 'Diese E-Mail-Adresse wird bereits von einem anderen Konto verwendet.',
    "auth/user-not-found": 'Ihr Passwort ist falsch oder dieses Konto existiert nicht.',
    confirm_password_desc: 'Bitte geben Sie Ihr Passwort erneut ein',
    confirm_password_not_match: 'Das Passwort stimmt nicht überein.',
}