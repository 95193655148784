module.exports = { 
    error_title: '404',
    error_desc: '很抱歉，此頁面不存在 😟',
    share_network_error: '網路發生問題或無權限，請稍後再試或者聯絡我們客服',
    permission_error: '你沒有權限使用此功能，請聯絡帳號管理者',
    share_email_exist: '電子郵件已經存在',
    user_not_found: '找不到此用戶',
    email_invalid: '電子郵件無效',
    email_duplicate: '此Email已加入帳號',
    email_not_registered: '此Email未在Taption註冊',
    email_slots_required: '空間已滿，請購買空間來加入更多帳號',
    youtube_url_invalid: '這不是一個有效的Youtube網址',
    youtube_url_required: '請輸入Youtube網址',
    directlink_url_invalid: '我們目前不支援所提供的URL連結，請來信至help@taption.com詢問支援',
    directlink_url_required: '請輸入影音檔的URL連結',
    required: '此處必須填寫',
    size_error: '檔案大小不能超過{0}GB.',
    size_error_mb: '檔案大小不能超過{0}MB.',
    verify_email: '請到信箱點擊我們寄給您的連結來開啟帳號。如果沒收到請到 [垃圾郵件] 確認。',
    email_must_be_valid: '信箱不正確',
    password_not_match: '密碼不一樣',
    invalid_youtube: '無法取得影片，請換一個連結或者稍後再試',
    unsupported_youtube: '不支援此影片, 請換一個連結',
    unsupported_direct_video: '無法讀取影片連結<br>(不支援直播連結)',
    must_be_more_than: '必須多於{0}字元',
    must_be_less_than: '必須少於{0}字元',
    must_be_more_than_words: '必須多於{0}字',
    trial_limit_error: '試用版影音檔必須少於15分鐘。請購買時數或者訂閱來取消此限制',
    language_required: '請選擇語言',
    title_required: '請為此文本命名',
    title_length: '名稱不能超過80個字',
    purpose_required: '請選擇分段方式',
    speakers_number_required: '發聲者總人數',
    category_required: '請選擇影片類別',
    method_required: '請選擇轉譯方式',
    max_length: '必須小於{0}個字',
    max_char_required: '必須填入介於{0}和1000之間的數值(建議數值:{1})',
    insufficient_fund_title: '您的分鐘數不足',
    insufficient_ai_desc: '您的AI點數不足, 請購買更多AI點數',
    exceed_trial_limit_title: '影片長度超過試用版限制',
    file_too_large_title: '影片檔案太大',
    not_supported_type_title: '不支援此影片格式',
    insufficient_fund_tip: '請購買更多分鐘後，再試一次',
    exceed_trial_limit_tip: '試用版可免費上傳一支15分鐘以內的影片. 購買分鐘或訂閱為高級用戶來移除15分鐘的限制',
    file_too_large_tip: '影片檔案不能超過2GB',
    not_supported_type_tip: '支援影片格式: mov, avi, wmv, flv, mpeg, ogg, mp4',
    media_no_audio: '提供的影音檔沒有聲音',
    network_upload_fail_title: '您的網絡不穩定，稍後再試或是使用Google Drive.',
    network_upload_fail_tip: '上傳時可從Google Drive選取影音檔.',
    transcribe_prepare_fail_title: '伺服器忙線，請稍後再試',
    transcribe_upload_fail_title: '伺服器忙線，請稍後再試',
    transcribe_error_fail_title: '伺服器忙線，請稍後再試',
    transcribe_merge_fail_title: '伺服器忙線，請稍後再試',
    transcribe_progress_fail_title: '伺服器忙線，請稍後再試',
    transcribe_result_fail_title: '伺服器忙線，請稍後再試',
    directlink_url_error_title: '無法讀取URL連結的檔案',
    directlink_url_error_tip: '請確認此URL連結的權限為公開',
    exceed_trial_usage_title: '試用超額',
    exceed_trial_usage_tip: '請購買分鐘數或訂閱來繼續使用服務',
    need_payment_title: '訂閱暫停',
    need_payment_tip: '請確認目前綁定信用卡可支付或更換信用卡來恢復訂閱',
    exceed_usage_title: '試用超額',
    exceed_usage_tip: '請與我們聯絡來繼續使用服務',
    file_too_long_title: '影片過長',
    file_too_long_tip: '請上傳2小時以內的影片',
    download_youtube_video_title: '此Youtube連結失敗，請換連結或稍候再試一次',
    download_youtube_video_tip: '如果Youtube連結失敗持續發生，請換一個影片連結或是與我們聯絡',
    video_corrupted_title: '我們無法讀取此檔案，請確認檔案可正常播放',
    video_corrupted_tip: '如有問題請寄信到help@taption.com',
    system_maintanance_title: '注意',
    system_maintanance: '系統維護中...請稍後刷新頁面再重試',
    error_add_title: '錯誤',
    error_add_desc: '無法將此更改加入辭典',
    error_warning: '注意',
    warning_no_search_found: '沒有找到 "{0}"',
    time_message: '字幕時間軸有 <strong>{0}</strong> 處時間重疊，導出後可能會造成第三方軟體無法正常讀取。',
    time_message1: '字幕時間軸有 <strong>{0}</strong> 處時間過短需要注意。',
    time_message2: '(時間軸橘色相對位置，使用左下角「時間編輯器」來調整)',
    overlap: '時間與前一句或下一句重疊',
    short: '此段顯示時間少於0.1秒',
    error_merge_one: '您目前有一支正在處理中的影片，請完成後再嘗試。',
    retry_upload: '重新處理',
    label_required: '請選擇至少一個標籤',
    label_name_required: '標籤名稱不能為空白',
    label_name_duplicate: '標籤名稱不能重複',
    srt_file_required: '請上傳SRT字幕檔',
    srt_file_invalid: 'SRT檔案格式有誤',
    txt_file_required: '請上傳TXT字幕檔',
    txt_file_invalid: '此檔案非TXT文字檔案',
    media_file: '請上傳影音檔案',
    media_srt_mismatch: '影音檔的長度小於SRT的長度',
    subscription_pay_failed_title: '您的訂閱付款失敗',
    subscription_pay_failed_desc: '參考以下幾種解決方式：',
    subscription_pay_failed_resolve1: '到『我的帳號』下更換信用卡',
    subscription_pay_failed_resolve2: '確認餘額足夠或聯絡銀行後與我們聯繫support@taption.com',
    subscription_pay_failed_resolve3: '取消訂閱',
    maximum_videos_title: '免費帳號最多儲存{0}部影片',
    maximum_videos_title1: '基本帳號最多儲存{0}部影片',
    maximum_videos_resolve1: '刪除現有影片保持總數量在{0}部以下',
    maximum_videos_resolve2: '購買或升級到其中一個<a href="https://app.taption.com/upgrade">方案</a>來擴充或移除此限制',
    manual_warning: '選擇 <strong>手動輸入</strong> 不會自動轉成文字<br>會給予一個空白的影片文字對應編輯頁面。<br>如果需要自動轉文字請選擇 <strong>AI自動生成</strong>。',
    txt_mismatch_error: '影片選擇的語言與匯入的文字檔案不一樣.<br>請確認兩者相同來達到最佳轉譯效果。',
    txt_invalid_error: '無法偵測匯入文字檔案的編碼<br>請確認文字檔案本身為正確的編碼來避免亂碼或其他不可預測的錯誤<br><span style="font-size:12px">(<strong>例</strong>: 開啟文字檔案, <strong>另存為</strong>→<strong>編碼</strong> 選擇 <strong>UTF-8</strong>)</span>',
    language_mismatch_error: '您上傳的影片語言與所選擇的語言可能不符。這會降低轉錄準確率，並導致無法順利翻譯成其他語言。<br><br>如果您希望將影片翻譯成其他語言，請務必在上傳時選擇與影片實際語言一致的選項，然後再透過右上角的<v-btn style="padding:0px 20px;margin:0px 5px;" class="translate-btn"><i style="font-size:14px;" aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-translate"></i><span class="text">翻譯</span></v-btn>進行轉換。',
    "auth/invalid-email": '信箱不正確',
    "auth/wrong-password": '您的密碼有誤或者此帳號不存在',
    "auth/email-already-in-use": '此信箱已經被註冊',
    "auth/user-not-found": '您的密碼有誤或者此帳號不存在',
    confirm_password_desc: '請重新輸入您的密碼',
    confirm_password_not_match: '密碼不一樣',
}