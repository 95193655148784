module.exports = {
    account_settings: '帳戶設置',
    name: '名稱',
    company: '公司',
    company_description:'發票上的公司名稱會以此名稱顯示',
    institution_name:'公司名稱/帳號名稱',
    email: '電子郵件',
    card: ' 信用卡',
    create_password:'設置新密碼',
    password: '密碼',
    status: '帳戶等級',
    new_password: '新密碼',
    confirm_password: '確認新密碼',
    update: '更改',
    update_name: '更改名稱',
    update_corp_name: '更改公司名稱',
    update_taxid_name: '更改統一編號',
    update_email: '更改電子郵件',
    update_password: '更改密碼',
  
    upgrade: '升級',
    unsubscribe: '取消訂閱',
  
    update_name_description: '',
    update_email_description: '請提供您的新電子郵件',
    update_email_password_confirm: '為了安全，請輸入您的密碼',
    update_password_description: '',
    thank_you_interest:'歡迎與我們聯絡',
    reach_out:'電子信箱: {0}',
    update_language:'更改網站語言',
    reactivate:'繼續訂閱',

    credit_card_update:'您的信用卡已更新',
    password_update:'密碼更新成功',
    click_here:'點擊這',
    if_not_direct:'如果3秒內沒有自動返回登入頁面...',
    email_verified:'成功驗證了信箱',
    email_restored:'成功更新信箱',

    email_unable_to_authenticate: '我們不支援此類型電子信箱',
    email_unable_to_authenticate_desc: '請用有效信箱註冊來獲得免費分鐘',
    purchase_records: '訂閱發票',
    purchase_date: '日期',
    purchase_amount: '金額',
    purchase_download: '下載',
    purchase_view: '查看',
    purchase_recent:'最近20筆⟶',
    purchase_unit:'美元',

    coupon_title:'優惠碼',
    coupon_redeem: '輸入',

    
    role:'用戶角色',
    view:'閱讀',
    upload:'上傳並轉成文字',
    edit:'編輯',
    translate:'翻譯',
    export:'影片導出',
    analysis:'AI分析',

    admin: '管理員',
    producer: '全能',
    member: '標準',
    individual: '個人',
    editor: '編輯者',
    viewer: '監視員',
    disabled: '無權限',
    custom: '自訂',

    check_description:'全部權限',
    info_description:'用戶只對自己上傳的影片有權限',
    times_description:'無權限',
    
  member_name: "名稱",
  member_email: "電子信箱",
  member_last_login: "最近登入",
  member_minutes: "使用分鐘數",
  user_role:"角色權限",
  add_new_role:'邀請用戶加入',
  member_invite: '邀請',
  member_slot:'使用者人數',
  share_slot:'分享用戶人數',
  member_slot_price_desc: '每增加一位使用者上限每{0}收費${1}美金.',
  member_slot_increase: '增加使用者上限',
  member_slot_increase_header: '擴充上限',
  member_slot_price_tooltip: '此費用是從今天到下個訂閱日依比例計算出的總額',
  member_slot_agree:'我了解並允許:',
  member_slot_agree_desc1:'＊ {0}為本次訂閱從即日起至下次扣款日的按比例計算金額',
  member_slot_agree_desc2:'＊ 從下個訂閱週期開始，訂閱費用將在原有基礎上增加<span>{0}/{1}</span>',
  member_slot_agree_desc3:'＊ 金額明細：{0}',
  member_slot_purchase:'購買空間',
  member_invite_desc:'用戶加入後不能替換成其他用戶，但能透過給予{0}<br>來取消使用權',
  tax_id:'統一編號',
  optional:'(選填)',
  required:'(必填)',
  added_users:'已加入用戶',
  shareable_users:'可分享用戶',
  available_user_permissions:'用戶權限表',
  corp_manage:'管理',
  edit_credit_card:'更改綁定信用卡',
  third_party:'已連接軟體',
  disconnect:'取消串聯',

  personal_information:'個人資訊',
  billing_information:'付款管理',
  product_information:'訂閱相關',
  other_information:'其他',

  remaining_sub_minutes: '剩餘訂閱分鐘',
  remaining_ai_credits: '剩餘AI點數',
  remaining_forever_minutes: '剩餘永久分鐘',
  remaining_total_minutes: '剩餘總分鐘',
  add_more_minutes:'購買分鐘',

  account_removal:'帳號移除',
  account_removal_desc:'所有與<span style="color:#00D1B2;font-weight:bold;">{0}</span>分享的影片權限將被取消，<br/>確定要移除帳號？',
  branding:'商標圖示',
  branding_desc:'導出PDF時，可使用此客製化商標圖示來取代默認的Taption圖標',
  add_payment_method: '請先到<strong>「{0}」</strong> → <strong>「{1}」</strong> → <strong>「{1}」</strong> 添加付款方式',
  add_member_tips1:'請用逗號分隔多個電子郵件進行邀請',
  add_member_tips2:'每個輸入的電子郵件必須是已註冊的 Taption 用戶',
  }
  