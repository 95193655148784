module.exports = { 
    upload: '업로드',
    upload_language: '대화언어를 선택',
    upload_method: '받아쓰기 방법을 선택',
    delete_title: '받아쓰기를 삭제하시겠습니까?',
    delete_description: '이 작업은 취소할 수 없습니다.',
    delete_ok: '삭제',
    delete_cancel: '취소',
    remember_selection: '선택내용을 나중에도 사용',
    send_email: '완료시 이메일로 알림 수신',
    card_share: '공유',
    card_delete: '삭제',
    upload_media: '파일을 업로드',
    upload_error_title: '오류',
    upload_error: '이용가능시간이 부족합니다.',
    transcribing_description: '파일을 받아쓰기 중입니다.<br>이 작업은 <br>파일 재생시간만큼 시간이 소요될 수 있습니다.<br> 완료시 메일로 알림이 발신됩니다.',
    transcribing: '처리중',
    preparing: '준비중',
    update_title: '제목을 변경',
    transcript_title: '제목',
    upload_limit_exceeded_title: '최대 재생시간을 초과',
    upload_limit_exceeded: '5시간 이내의 영상을 사용해주세요.',
    initializing_description: '파일을 처리중입니다.<br>이 작업에는 수분이 소요됩니다. 잠시만 기다려주세요.',
    initializing: '대기중',
    translating_description: '파일을 번역중입니다. <br>이 작업에는 수분이 소요됩니다. 잠시만 기다려주세요.',
    translating: '번역중',
    error_description: '더 많은 정보를 원하시면 문의해주세요',
    error_info: '에러코드: {0}',
    error: '문제가 발생했습니다',
    error_minutes: '{0} <br> 이용가능시간은 차감되지 않습니다.<br> 이용가능시간을 확인하시려면 브라우저를 새로고침하세요',
    please_wait: '잠시만 기다려주세요...',
    loading: '로딩중...',
    upgrade_to_share: '팀원들과 받아쓰기를 공유하고 공동편집하려면 {0}하세요.',
    share_link_with: '읽기 전용 권한으로 공유',
    share_with_editable_permission: '편집가능 권한으로 공유',
    share_with: '예: sample1@gmail.com,sample2@gmail.com',
    share_with_currently: '현재 공유중인 유저',
    share_empty: '공유가능한 유저는 <a href="\\management" style="color:#2FC59F;">관리</a> 메뉴에서 추가가능합니다.',
    uploading: '업로드중',
    shared: '공유완료',
    thumbnail: '썸네일',
    upload_purpose: '내보낼 파일 형식을 선택하세요',
    upload_number: '발화자 수',
    upload_category: '영상에 가장 적합한 카테고리를 선택하세요',
    upload_clipped: '오디오 및 비디오 클립 캡처',
    clipped_adjustments_desc: '초를 미세 조정하려면 마우스를 사용하여 당김 축을 클릭하세요.<br>키보드의 왼쪽 및 오른쪽 키를 사용하여 조정하세요.',
    clipped_description: '오디오 및 비디오 파일에서 클립을 잘라서 텍스트를 식별하고 시간을 더 효율적으로 사용하세요.<br> Youtube 링크, mp3, mp4 오디오 및 비디오 파일 지원',
    clipped_warning: '이 기능을 사용하면 처리 시간이 늘어납니다.',
    clipped_length_warning: '이 기능을 사용하려면 동영상 파일의 길이가 1분보다 길어야 합니다.',
    purpose_description: '편집이 쉽도록 받아쓰기를 포맷팅하는 데 도움이 됩니다.',
    speaker_unsure: '자동 나누기',
    purpose_speakers: 'AI가 오디오 및 동영상의 <strong>발화자</strong>를 자동으로 표시하여 세그먼트로 나눕니다',
    purpose_transcript_desc: '(예: 영상에 자막 추가 구두점 없음)',
    purpose_captions: 'AI가 자동으로 <strong>자막</strong> 형태로 세그먼트를 나눕니다',
    purpose_caption_desc: '(예: 회의 내용 받아쓰기 구두점 포함)',
    purpose_article: '구두점을 포함한 축어적 원고 <strong>세분화 없음</strong>',
    purpose_translate_desc: '(예: 기사 작성을 위한 받아쓰기 구두점 포함)',
    purpose_original: '제공된 <strong>텍스트 파일</strong>의 세그먼트에 따라 세분화가 수행됩니다',
    speakers_time: '발화자',
    captions_time: '자막',
    article_time: '원본 텍스트 파일',
    original_time: '원본 텍스트 파일',
    none_time: '',
    speakers_time_desc: 'AI가 오디오 및 동영상의 발화자를 자동으로 표시하여 세그먼트로 나눕니다.',
    captions_time_desc: 'AI가 자동으로 자막 형태로 세그먼트를 나눕니다.',
    article_time_desc: '제공된 텍스트 파일의 세그먼트에 따라 세분화가 수행됩니다。',
    original_time_desc: '제공된 텍스트 파일의 세그먼트에 따라 세분화가 수행됩니다。',
    none_time_desc: '',
    purpose_other: '조금 더 생각해볼래요',
    create: '작성',
    home: '홈으로',
    management_corp: '관리',
    management: '관리',
    account: '계정',
    upgrade: '업그레이드',
    faq: '자주 묻는 질문',
    logout: '로그아웃',
    return: '편집화면으로 돌아가기',
    drag_drop: '영상/음성 파일을 여기에 드로그앤드롭하세요',
    select_from: '장치에서 파일 선택하기',
    or_select_from: '또는 에서 선택하기',
    common_languages: '공통언어',
    additional_languages: '추가 언어',
    friendly_notice: '정확도를 높이는 꿀팁',
    friendly_notice_desc0: '1.영상에 사용된 언어와 일치하는 언어를 선택해주세요. 번역작업은 받아쓰기가 완료된 후 처리됩니다.',
    friendly_notice_desc: '2.배경 음악이나 잡음이 거의 없이 깨끗하게 녹음된 음성파일이 권장됩니다',
    friendly_notice_desc2: '3.음악이나 음악관련 영상은 피해주세요',
    friendly_notice_desc3: '4.사운드트랙을 편집한 경우 받아쓰기를 할 수 없습니다',
    friendly_notice_desc4: '5.*.mp4 또는 *.mp3 형식이 권장됩니다',
    google_drive_upgrade_title: 'Google 드라이브에서 파일 선택',
    google_drive_upgrade_desc: '유료플랜을 구독하면 업로드 속도 및 받아쓰기 처리속도를 높일 수 있습니다.',
    trial_check_desc: '받아쓰기 내용은 오직 개인적인 사용에 한하며, 상업적으로 이용하지 않을 것에 동의합니다. (상업적 이용 허가를 얻으시려면 이용시간을 구매하거나 프리미엄 플랜을 구독하세요)',
    browser_unsupported_title: '지원하지 않는 브라우저입니다',
    browser_unsupported_desc: '최적의 편집 경험을 위해 데스크톱 크롬을 사용하세요.',
    browser_unsupported_mobile_desc: '최적의 편집 경험을 위해 크롬을 사용하세요.',
    browser_translated_injected_title: '페이지 자동번역을 끄세요',
    browser_translated_injected_desc: '페이지 자동번역을 끄지 않으면 예기치 못한 문제가 발생할 수 있습니다. (예: 편집내용이 보존되지 않고 연결이 끊기는 문제 등)',
    browser_unsupported_step1: '1. 우측하단 혹은 우측상단의 "..." 또는 "⋮" 표시를 클릭',
    browser_unsupported_step2: '2. 브라우저에서 열기를 선택',
    individual_account: '내 계정',
    corp_account_selection_desc: '엔터프라이즈 플랜 사용자의 계정에 추가된 경우, 드롭다운 메뉴에 표시됩니다n',
    unsave_change_title: '저장되지 않은 변경사항이 있습니다',
    unsave_change_desc: '저장하지않고 떠나시겠습니까?',
    label_name: '라벨명',
    new_label: '새 라벨',
    select_label: '라벨 선택',
    label_dialog_header: '라벨 관리',
    label_dialog_desc: '라벨을 편집 및 정렬',
    label_empty: '라벨 없음',
    label_all: '모든 파일',
    label_select_header: '라벨',
    label_limit_error: '작성가능한 라벨 개수를 초과했습니다. 개수 제한을 제거하려면 프리미엄 플랜 또는 엔터프라이즈 플랜으로 업그레이드하세요',
    segment_sample: '샘플 출력',
    zoom_no_data: '최근 2년간 녹화된 파일이 없습니다...',
    zoom_desc: '지난 2년간 녹화 파일만을 표시합니다.',
    zoom_loading: '녹화 파일 로딩중...',
    import_srt: 'SRT 불러오기',
    srt_header: '받아쓰기 방법',
    srt_select: 'SRT 파일 선택',
    srt_desc: 'SRT 파일을 기다리는중...',
    import_txt: 'TXT 불러오기',
    txt_select: '텍스트 파일 선택',
    txt_desc: 'TXT 파일을 기다리는중...',
    direct_link: '<span class="directlink-kr">URL 링크 다운로드</span>',
    multi_upload: '구독자는 한 번에 여러 파일을 업로드할 수 있습니다.',
    category: '동영상 카테고리',
    max_char_header: '자막당 최대 문자 수',
    max_char_desc: '번역된 텍스트는 이 값에 따라 분할되며 각 세그먼트는 이 단어 수를 초과하지 않습니다.<br>권장값:<br>한국어/중국어/일본어: 20<br>기타 언어: 95',
    language_tooltip: '비디오 또는 오디오 파일의 언어를 선택하세요.<br>다른 언어로 변환하려면 먼저 텍스트로 변환한 다음 번역을 위해 편집 플랫폼에 들어가야 합니다. <br><br> 아래의 <span style="color:#2FC59F">받아쓰기 방법</span> 에서 <span style="color:#2FC59F">SRT 파일 불러오기</span> 를 선택한 경우 SRT 자막 파일의 언어를 선택하세요. ',
    import_txt_tooltip: '제공한 텍스트를 해당 타임라인에 추가합니다. <br><strong>{0}</strong>: {1}',
    home_notice_title: '시간 절약',
    home_notice_desc: '새로운 분 청구 방법은 추가 초를 절약할 것입니다!<div class="desc">예: 15분 59초의 동영상을 업로드하면 15분만 차감됩니다.(1분 미만의 동영상을 업로드하면 분은 영상에서 차감 🙂)</div>',
    no_name: '이름 없음',
    upload_language_reminder_title: '선택한 언어가 비디오의 음성과 일치하는지 확인하세요',
    upload_language_reminder_desc: '예: 비디오가 영어인 경우, 언어 선택에서 \'영어\'를 선택하세요.<br>비디오 자막이나 전사본을 번역하고 싶다면, 먼저 텍스트로 변환한 후 편집 플랫폼으로 들어가세요.<br>그 다음 오른쪽 상단의 \'번역\'을 사용하여 비디오를 번역하세요.',
    add_more: '추가',
}