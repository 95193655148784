module.exports = {
    convert_to_text_title_meta:"Résumé vidéo IA en ligne : Ajouter des thèmes, résumer des réunions, créer des chapitres YouTube en un clic",
    convert_to_text_desc_meta:"Notre outil d'analyse IA vous permet de résumer efficacement le contenu, de repérer les principaux intervenants, de rédiger des scripts de narration et d'intégrer des chapitres YouTube avec horodatage. Explorez notre gamme de modèles, incluant les résumeurs de vidéos, générateurs de chapitres et générateurs de thèmes vidéo avec horodatage. Essayez dès maintenant avec une version d’essai gratuite !",
    convert_to_text_title:"Analyse IA",
    convert_to_text_desc:"Résumé de vidéo, Chapitres YouTube, Synthèse de réunion",
    start_your_trial:"Commencez votre essai gratuit !",
    hero_img:"./11_English.webp",

    three_step_title:"3 étapes pour transcrire",
    step_one:"Posez une question ou donnez une instruction",
    step_one_img:"./aianalysis_step1_en_US.webp",
    step_one_desc:"Vous pouvez choisir de demander à l’outil vidéo IA de résumer votre contenu, identifier les intervenants clés, suggérer des scripts de narration et ajouter des chapitres et horodatages YouTube.",
    
    step_two:"Choisissez parmi des commandes prédéfinies",
    step_two_img:"./aianalysis_step2_en_US.webp",
    step_two_desc:"L'Analyse IA propose des modèles prédéfinis comme un générateur de résumés vidéo, un générateur de chapitres YouTube et un générateur de thèmes avec horodatage.",

    step_three:"Résumé dans votre langue préférée",
    step_three_img:"./aianalysis_step3_en_US.webp",
    step_three_desc:"Quelle que soit la langue originale de la vidéo, vous pouvez la résumer ou donner des instructions dans votre langue préférée.",

    feature_tutorial_title:'Analyse et résumé de vidéos avec Taption',
    feature_tutorial_desc:"La fonctionnalité \"Analyse IA\" est un outil puissant conçu pour une analyse approfondie du contenu vidéo. Les utilisateurs peuvent télécharger des vidéos pour un résumé complet ou utiliser des modèles prédéfinis tels que le résumé vidéo, le générateur de thèmes IA, les chapitres YouTube et l’horodatage YouTube pour une analyse en un clic, éliminant le besoin de commandes manuelles. Cela améliore considérablement la compréhension et l’interaction avec les points clés de la vidéo.",
    feature_tutorial_poster_url:'./aianalysis_poster_en_US.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/PWOWwbHQCUM?autoplay=1',

    feature_why_image:'./ai_template_demo_en.webp',
    feature_extra_title:"Utilisation et exemples de l'Analyse IA",
    feature_extra_desc:"Pour exploiter tout le potentiel de la fonctionnalité Analyse IA, la clé est de formuler des indications précises. Voici quelques moyens de formuler vos demandes pour obtenir des insights pertinents：<ul><li><strong>Indication pour résumé</strong>：\"Résumez la vidéo en 300 mots.\" Cette instruction est idéale pour des aperçus rapides.</li><li><strong>Indication pour les éléments d'action</strong>：\"Identifiez les éléments d'action de la réunion.\" Cela peut aider à mettre en évidence les tâches et responsabilités.</li><li><strong>Indication pour un retour de coaching</strong>：\"Vous êtes un coach utile. Fournissez une analyse de la transcription et proposez des pistes d'amélioration avec des citations exactes. Pas d'introduction.\"</li><li><strong>Script vidéo</strong>：Aidez-moi à créer un script pour une vidéo explicative de 5 à 10 minutes sur ce film, en vous concentrant sur l'intrigue et les idées que l'auteur souhaite transmettre.</li></ul>Lors de la construction d’une indication, commencez par un résumé général, puis fournissez des détails supplémentaires.",

    feature_what_title:"Comment ajouter des chapitres et résumer votre vidéo YouTube ?",
    feature_what_desc: "<ul>\n  <li>Connectez-vous à <a href=\"https://www.taption.com/login\">Taption</a></li>\n  <li>Cliquez sur \"Télécharger\" pour importer votre vidéo MP4 ou collez un lien YouTube à transcrire</li>\n  <li>Une fois terminé, accédez à la plateforme d'édition pour éditer ou traduire selon vos besoins</li>\n  <li>Cliquez sur le bouton \"Analyse IA\" en bas</li>\n  <li>Cliquez sur \"Analyser\" pour ajouter des chapitres YouTube et générer un résumé de la vidéo</li>\n</ul>"
}
