module.exports = {
  login:'Masuk',
  or:'atau',
  member_login:'Login Anggota',
  signin_with:'Masuk dengan:',
  signin_fb:'Masuk dengan Facebook',
  signin_google:'Masuk dengan Google',
  email_address: 'Alamat email',
  password: 'Kata sandi',
  confirm_password: 'Ketik ulang kata sandi',
  been_logged_out_title: 'Anda telah keluar',
  been_logged_out_desc: 'Akun Anda telah diakses dari lokasi lain',
  forgot_password:'Lupa kata sandi',
  create_free_account:'Buat akun gratis Anda',
  create_an_account:'Buat akun',
  create_account:'Buat akun',
  or_go_back_to:'Atau kembali ke',
  name:'Nama',
  check_to_reset:'Silakan periksa kotak masuk Anda untuk mengatur ulang kata sandi.',
  check_to_verify_header:'Verifikasi email Anda',
  check_to_verify:'Akun Anda telah dibuat! Jika Anda tidak menerima pesan konfirmasi, silakan periksa <span style="font-weight:bold">Folder Spam</span> Anda.',
  send_to_verify_header:'Email verifikasi telah dikirim',
  send_to_verify:'Silakan periksa email Anda untuk tautan verifikasi untuk mengaktifkan akun Anda. Jika Anda tidak menerima pesan konfirmasi, silakan periksa <span style="font-weight:bold">Folder Spam</span> Anda.',
  term_of_service_description1: 'Dengan masuk ke Taption, Anda menyetujui ',
  term_of_service:'syarat layanan kami',
  term_of_service_description2: ' dan telah membaca serta memahami ',
  privacy_policy:'kebijakan privasi',
  resend_verification:'Kirim ulang email verifikasi',
  invite_desc:'Terima kasih kepada {0}<br>Daftar dan dapatkan 15 menit gratis',
  login_desc:'Daftar dan dapatkan 15 menit gratis',
  referral_note:'Tidak perlu kartu kredit.',
  redirecting:'Mengalihkan...'
}