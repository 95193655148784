module.exports = {
  account_settings: 'Configurações da Conta',
  name: 'Nome',
  company: 'Empresa',
  company_description: 'O nome da empresa será exibido nas faturas e pagamentos.',
  institution_name: 'Informações fiscais',
  email: 'Email',
  card: 'Cartão',
  create_password: 'Criar uma nova palavra-passe',
  password: 'Palavra-passe',
  status: 'Estado',
  new_password: 'Nova palavra-passe',
  confirm_password: 'Confirmar palavra-passe',
  update: 'Atualizar',
  update_name: 'Atualizar nome',
  update_corp_name: 'Atualizar nome da empresa',
  update_taxid_name: 'Atualizar identificação fiscal',
  update_email: 'Atualizar email',
  update_password: 'Atualizar palavra-passe',
  
  upgrade: 'Atualizar',
  unsubscribe: 'Cancelar subscrição',

  update_name_description: '',
  update_email_description: 'Por favor, introduza o seu novo endereço de email',
  update_email_password_confirm: 'Confirme a sua palavra-passe',
  update_password_description: '',
  
  thank_you_interest: 'Obrigado pelo seu interesse',
  reach_out: 'Entre em contacto connosco em: {0}',
  update_language: 'Preferência de idioma',
  reactivate: 'Reativar',

  credit_card_update: 'O seu cartão de crédito foi atualizado',
  password_update: 'A sua palavra-passe foi atualizada',
  click_here: 'Clique aqui',
  if_not_direct: 'se não for redirecionado em 3 segundos...',
  email_verified: 'O seu email foi verificado.',
  email_restored: 'O seu email foi atualizado',

  email_unable_to_authenticate: 'Não suportamos registo de email anónimo',
  email_unable_to_authenticate_desc: 'Por favor, registe-se com um email válido para receber minutos grátis.',
  purchase_records: 'Faturas',
  purchase_date: 'Data',
  purchase_amount: 'Montante',
  purchase_download: 'Transferir',
  purchase_view: 'Ver Todos',
  purchase_recent: '20 mais recentes⟶',
  purchase_unit: 'USD',

  coupon_title: 'Código promocional',
  coupon_redeem: 'Resgatar',

  role: 'Função',
  view: 'Ver',
  upload: 'Carregar',
  edit: 'Editar',
  translate: 'Traduzir',
  export: 'Exportar',
  analysis: 'Análise de IA',

  admin: 'Administrador',
  producer: 'Produtor',
  member: 'Membro',
  individual: 'Indivíduo',
  editor: 'Editor',
  viewer: 'Visualizador',
  disabled: 'Desativado',
  custom: 'Personalizado',

  check_description: 'Controlo total',
  info_description: 'Acesso apenas às transcrições carregadas por este utilizador',
  times_description: 'Sem acesso',

  member_name: 'Nome',
  member_email: 'Email',
  member_last_login: 'Último login',
  member_minutes: 'Minutos usados',
  user_role: 'Função',
  add_new_role: 'Adicionar novos membros',
  member_invite: 'Convidar',
  member_slot: 'Número de utilizadores',
  share_slot: 'Número de utilizadores partilháveis',
  member_slot_price_desc: 'Assentos adicionais custam {1} por {0}.',
  member_slot_increase: 'Aumentar número de utilizadores',
  member_slot_increase_header: 'Adicionar assentos',
  member_slot_price_tooltip: 'O preço é rateado até o próximo ciclo de faturação',
  member_slot_agree: 'Entendo e aceito o seguinte:',
  member_slot_agree_desc1: '• {0} é o montante rateado para esta subscrição desde hoje até à próxima data de faturação',
  member_slot_agree_desc2: '• A partir do próximo ciclo de subscrição, o preço da subscrição aumentará <span>{0} por {1}</span> do valor atual',
  member_slot_agree_desc3: '• Detalhe de custos: {0}',
  member_slot_purchase: 'Confirmar pagamento',
  member_invite_desc: 'Não pode substituir o utilizador após ser adicionado, atribua {0} <br> permissão para revogar o seu acesso',
  tax_id: 'Identificação fiscal',
  optional: '(Opcional)',
  required: '(Obrigatório)',
  added_users: 'Membros',
  shareable_users: 'Membros',
  available_user_permissions: 'Permissões disponíveis para utilizadores',
  corp_manage: 'Gestão',
  edit_credit_card: 'Editar cartão de crédito',

  third_party: 'Aplicações instaladas',
  disconnect: 'Desinstalar',

  personal_information: 'Informações pessoais',
  billing_information: 'Detalhes de faturação',
  product_information: 'Subscrição',
  other_information: 'Outros',

  remaining_sub_minutes: 'Minutos da subscrição',
  remaining_ai_credits: 'Créditos de IA',
  remaining_forever_minutes: 'Minutos',
  remaining_total_minutes: 'Total de minutos',
  add_more_minutes: 'Adicionar minutos',

  account_removal: 'Confirmação de remoção da conta',
  account_removal_desc: '<span style="color:#00D1B2;font-weight:bold;">{0}</span> <br/> perderá todo o acesso às transcrições partilhadas.<br/> Tem a certeza que deseja remover esta conta?',
  branding: 'Logotipo',
  branding_desc: 'Este logotipo pode substituir o logotipo padrão do Taption quando exportar transcrições como PDF.',
  add_payment_method: 'Por favor, vá a <strong>"{0}"</strong> → <strong>"{1}"</strong> → <strong>"{1}"</strong> para adicionar um método de pagamento',
  add_member_tips1: 'Convide vários destinatários separando os seus emails com vírgulas.',
  add_member_tips2: 'Cada email introduzido deve ser um utilizador registado do Taption.',
}
