module.exports = {
    login:'ログイン',
    or:'または',
    member_login:'メンバーログイン',
    signin_with:'会員登録方法:',
    signin_fb:'Facebookで会員登録',
    signin_google:'Googleで会員登録',
    email_address: 'メールアドレス',
    password: 'パスワード',
    confirm_password: 'パスワードを再入力',
    been_logged_out_title: 'ログアウトされました',
    // been_logged_out_desc: 'もう一度ログインしてください',
    been_logged_out_desc: 'あなたのアカウントは別の場所からアクセスされました',
    forgot_password:'パスワードをお忘れの場合',
    create_free_account:'無料アカウントを作成する',
    create_an_account:'アカウントを作成する',
    create_account:'アカウントを作成する',
    or_go_back_to:'または に戻る', //you need to put destination between または and に戻る e.g. or go back to the previous page = または前に戻る
    name:'お名前',
    check_to_reset:'メールボックスを確認してパスワードをリセットしてください。',
    check_to_verify_header:'メールの認証が必要です',
    check_to_verify:'アカウントが作成されました！確認メールが届かない場合は、<span style="font-weight:bold">迷惑メールフォルダ</span>をご確認ください。',
    send_to_verify_header:'認証メールを送信しました',
    send_to_verify:'メールボックスに届いた認証リンクを確認し、アカウントを有効化してください。確認メールが届かない場合は、<span style="font-weight:bold">迷惑メールフォルダ</span>をご確認ください。.',
    term_of_service_description1: 'タプションに会員登録することで、',
    term_of_service:'利用規約に同意し',
    term_of_service_description2: 'を読んで理解していることとなります', // row 27 should come first than row 26 in Japanese grammar.
    privacy_policy:'プライバシーポリシー',
    resend_verification:'認証メールを再送する',
    //invite_desc:'{0}のため、<br>購読時には{1}分の無料時間が追加されます。無料トライアルでは15分以内の文字起こし1回が提供されます。',
    invite_desc:'{0}のため、<br>登録すると15分の無料時間が付与されます',
    login_desc:'登録すると15分の無料時間が付与されます',
    referral_note:'決済情報の入力は不要です。',
    redirecting:'リダイレクト中...'
  }
  