module.exports = { 
    update: 'Actualizar',
    cancel: 'Cancelar',
    remove: 'Eliminar',
    session_title: 'Tu sesión está a punto de expirar',
    session_description: 'Se cerrará tu sesión en 5 minutos',
    session_ok: 'Mantenerme conectado',
    session_no: 'Cerrar sesión',
    free_min_title: '¡Felicidades!',
    free_min_desc: 'Has recibido <span style="color:#2fc59f!important">{0} minutos</span> gracias a {1} nueva(s) referencia(s).',
    email_placeholder: 'Introduce un correo electrónico...',
    a_few_seconds_ago: 'hace unos segundos',
    short_seconds: 's',
    short_minutes: 'm',
    minute: 'minuto',
    minutes: 'minutos',
    hour: 'hora',
    hours: 'horas',
    day: 'día',
    days: 'días',
    month: 'mes',
    months: 'meses',
    year: 'año',
    years: 'años',
    ago: 'hace',
    a: 'un',
    an: 'una',
    title: 'Título',
    welcome: 'Bienvenido',
    youtube: 'YouTube',
    send: 'Enviar',
    on: 'Activado',
    off: 'Desactivado',
    new: 'Nuevo',
    search: 'Buscar',
    upgrade: 'Actualizar',
    feature_updating: 'Actualización de característica, por favor vuelve más tarde.',
    copy: 'Copiar',
    copied: 'Copiado',
    trial: 'Prueba',
    basic: 'Básico',
    premium: 'Premium',
    corp: 'Corporativo',
    speakers: 'oradores',
    captions: 'captions/subtítulos',
    article: 'artículo',
    char: 'carácter',
    language: 'Idioma',
    duration: 'Duración',
    type: 'Tipo',
    date: 'Fecha',
    transcriptType1: 'Caption',
    transcriptType2: 'Orador',
    transcriptType3: 'Artículo',
    accounts: 'Cuentas',
    label: 'Etiqueta',
    none: 'Ninguno',
    me: 'Yo',
    usd: 'Dólar estadounidense',
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
    th: 'Número',
    pricing: 'Precios',
    privacy: 'Privacidad',
    terms: 'Términos',
    blog: 'Recursos',
    video: 'Canal de YouTube',
    cookieSettings: 'Configuración de cookies',
    back: 'Atrás',
    credit: 'crédito(s)',
    ok: 'Aceptar',
}