module.exports = {
  account_settings: 'Pengaturan Akun',
  name: 'Nama',
  company: 'Perusahaan',
  company_description:'Nama perusahaan akan ditampilkan pada faktur dan pembayaran.',
  // institution_name:'Institution Name/Account Name',
  institution_name:'Informasi Pajak',
  email: 'Email',
  card: 'Kartu',
  create_password:'Buat kata sandi baru',
  password: 'Kata Sandi',
  status: 'Status',
  new_password: 'Kata sandi baru',
  confirm_password: 'Konfirmasi kata sandi',
  update: 'Perbarui',
  update_name: 'Perbarui nama',
  update_corp_name: 'Perbarui nama perusahaan',
  update_taxid_name: 'Perbarui ID pajak',
  update_email: 'Perbarui email',
  update_password: 'Perbarui kata sandi',

  upgrade: 'Tingkatkan',
  unsubscribe: 'Berhenti berlangganan',

  update_name_description: '',
  update_email_description: 'Silakan masukkan alamat email baru Anda',
  update_email_password_confirm: 'Konfirmasi kata sandi Anda',
  update_password_description: '',
  
  thank_you_interest:'Terima kasih atas minat Anda',
  reach_out:'Silakan hubungi kami di: {0}',
  update_language:'Preferensi bahasa',
  reactivate:'Aktifkan kembali',

  credit_card_update:'Kartu kredit Anda telah diperbarui',
  password_update:'Kata sandi Anda telah diperbarui',
  click_here:'Klik di sini',
  if_not_direct:'jika Anda tidak dialihkan dalam 3 detik...',
  email_verified:'Email Anda telah diverifikasi.',
  email_restored:'Email Anda telah diperbarui',

  email_unable_to_authenticate: 'Kami tidak mendukung pendaftaran email anonim',
  email_unable_to_authenticate_desc: 'Silakan daftar dengan email yang valid untuk menerima menit gratis.',
  purchase_records: 'Faktur',
  purchase_date: 'Tanggal',
  purchase_amount: 'Jumlah',
  purchase_download: 'Unduh',
  purchase_view: 'Lihat Semua',
  purchase_recent:'20 terbaru⟶',
  purchase_unit:'USD',

  coupon_title:'Kode promosi',
  coupon_redeem: 'Tukarkan',

  role:'Peran',
  view:'Lihat',
  upload:'Unggah',
  edit:'Edit',
  translate:'Terjemahkan',
  export:'Ekspor',
  analysis:'Analisis AI',

  admin: 'Admin',
  producer: 'Produser',
  member: 'Anggota',
  individual: 'Individu',
  editor: 'Editor',
  viewer: 'Penonton',
  disabled: 'Dinonaktifkan',
  custom:'Kustom',

  check_description:'Kontrol penuh',
  info_description:'Akses ke transkrip yang diunggah oleh pengguna ini saja',
  times_description:'Tidak ada akses',

  member_name: "Nama",
  member_email: "Email",
  member_last_login: "Login terakhir",
  member_minutes: "Menit yang digunakan",
  user_role:"Peran",
  add_new_role:'Tambah anggota baru',
  member_invite: 'Undang',
  member_slot:'Jumlah pengguna',
  share_slot:'Jumlah pengguna yang dapat dibagikan',
  member_slot_price_desc: 'Kursi tambahan adalah ${1} per {0}.',
  member_slot_increase: 'Tambah kursi pengguna',
  member_slot_increase_header: 'Tambah kursi',
  member_slot_price_tooltip: 'Harga diprorata hingga siklus penagihan berikutnya',
  member_slot_agree:'Saya memahami dan menyetujui hal-hal berikut:',
  member_slot_agree_desc1: '• {0} adalah jumlah prorata untuk langganan ini dari hari ini hingga tanggal penagihan berikutnya',
  member_slot_agree_desc2: '• Mulai dari siklus langganan berikutnya, biaya langganan akan meningkat sebesar <span>{0} per {1}</span> dari tarif saat ini',
  member_slot_agree_desc3: '• Rincian biaya: {0}',
  member_slot_purchase:'Konfirmasi pembayaran',
  member_invite_desc:'Anda tidak dapat mengganti pengguna setelah ditambahkan, berikan izin {0} <br> untuk mencabut aksesnya',
  tax_id:'ID Pajak',
  optional:'(Opsional)',
  required:'(Wajib)',
  added_users:'Anggota',
  shareable_users:'Anggota',
  available_user_permissions:'Izin pengguna yang tersedia',
  corp_manage:'Manajemen',
  edit_credit_card:'Edit kartu kredit',

  third_party:'Aplikasi Terinstal',
  disconnect:'Copot pemasangan',

  personal_information:'Informasi Pribadi',
  billing_information:'Detail Penagihan',
  product_information:'Langganan',
  other_information:'Lainnya',

  remaining_sub_minutes: 'Menit langganan',
  remaining_ai_credits: 'Kredit AI',
  remaining_forever_minutes: 'Menit',
  remaining_total_minutes: 'Total menit',
  add_more_minutes:'Tambah menit',

  account_removal:'Konfirmasi penghapusan akun',
  account_removal_desc:'<span style="color:#00D1B2;font-weight:bold;">{0}</span> <br/> akan kehilangan semua akses ke transkrip yang dibagikan.<br/> Apakah Anda yakin ingin menghapus akun ini?',
  branding:'Logo',
  branding_desc:'Logo ini dapat menggantikan logo Taption default saat Anda mengekspor transkrip sebagai PDF.',
  add_payment_method: 'Silakan pergi ke <strong>"{0}"</strong> → <strong>"{1}"</strong> → <strong>"{1}"</strong> untuk menambahkan metode pembayaran',
  add_member_tips1:'Undang beberapa penerima dengan memisahkan email mereka menggunakan koma.',
  add_member_tips2:'Setiap email yang dimasukkan harus merupakan pengguna Taption yang terdaftar.',
}