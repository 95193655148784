module.exports = {
  account_settings: 'Paramètres du compte',
  name: 'Nom',
  company: 'Entreprise',
  company_description: 'Le nom de l\'entreprise apparaîtra sur les factures et les paiements.',
  institution_name: 'Informations fiscales',
  email: 'E-mail',
  card: 'Carte',
  create_password: 'Créer un nouveau mot de passe',
  password: 'Mot de passe',
  status: 'Statut',
  new_password: 'Nouveau mot de passe',
  confirm_password: 'Confirmer le mot de passe',
  update: 'Mettre à jour',
  update_name: 'Mettre à jour le nom',
  update_corp_name: 'Mettre à jour le nom de l\'entreprise',
  update_taxid_name: 'Mettre à jour l\'identifiant fiscal',
  update_email: 'Mettre à jour l\'e-mail',
  update_password: 'Mettre à jour le mot de passe',

  upgrade: 'Mettre à niveau',
  unsubscribe: 'Se désabonner',

  update_name_description: '',
  update_email_description: 'Veuillez saisir votre nouvelle adresse e-mail',
  update_email_password_confirm: 'Confirmez votre mot de passe',
  update_password_description: '',
  
  thank_you_interest: 'Merci pour votre intérêt',
  reach_out: 'Veuillez nous contacter à : {0}',
  update_language: 'Préférence de langue',
  reactivate: 'Réactiver',

  credit_card_update: 'Votre carte de crédit a été mise à jour',
  password_update: 'Votre mot de passe a été mis à jour',
  click_here: 'Cliquez ici',
  if_not_direct: 'si vous n\'êtes pas redirigé dans 3 secondes...',
  email_verified: 'Votre e-mail a été vérifié.',
  email_restored: 'Votre e-mail a été mis à jour',

  email_unable_to_authenticate: 'Nous ne prenons pas en charge l\'inscription par e-mail anonyme',
  email_unable_to_authenticate_desc: 'Veuillez vous inscrire avec un e-mail valide pour recevoir des minutes gratuites.',
  purchase_records: 'Factures',
  purchase_date: 'Date',
  purchase_amount: 'Montant',
  purchase_download: 'Télécharger',
  purchase_view: 'Voir tout',
  purchase_recent: '20 plus récents⟶',
  purchase_unit: 'USD',

  coupon_title: 'Code promotionnel',
  coupon_redeem: 'Utiliser',

  role: 'Rôle',
  view: 'Voir',
  upload: 'Télécharger',
  edit: 'Modifier',
  translate: 'Traduire',
  export: 'Exporter',
  analysis: 'Analyse IA',

  admin: 'Administrateur',
  producer: 'Producteur',
  member: 'Membre',
  individual: 'Individuel',
  editor: 'Éditeur',
  viewer: 'Visualiseur',
  disabled: 'Désactivé',
  custom: 'Personnalisé',

  check_description: 'Contrôle total',
  info_description: 'Accès uniquement aux transcriptions téléchargées par cet utilisateur',
  times_description: 'Aucun accès',

  member_name: "Nom",
  member_email: "E-mail",
  member_last_login: "Dernière connexion",
  member_minutes: "Minutes utilisées",
  user_role: "Rôle",
  add_new_role: 'Ajouter de nouveaux membres',
  member_invite: 'Inviter',
  member_slot: 'Nombre d\'utilisateurs',
  share_slot: 'Nombre d\'utilisateurs partageables',
  member_slot_price_desc: 'Les sièges supplémentaires coûtent ${1} par {0}.',
  member_slot_increase: 'Augmenter le nombre de sièges utilisateurs',
  member_slot_increase_header: 'Ajouter des sièges',
  member_slot_price_tooltip: 'Le prix est calculé au prorata jusqu\'au prochain cycle de facturation',
  member_slot_agree: 'Je comprends et j\'accepte ce qui suit :',
  member_slot_agree_desc1: '• {0} est le montant calculé au prorata pour cet abonnement à partir d\'aujourd\'hui jusqu\'à la prochaine date de facturation',
  member_slot_agree_desc2: '• À partir du prochain cycle d\'abonnement, les frais d\'abonnement augmenteront de <span>{0} par {1}</span> par rapport au tarif actuel',
  member_slot_agree_desc3: '• Détail des coûts : {0}',
  member_slot_purchase: 'Confirmer le paiement',
  member_invite_desc: 'Vous ne pouvez pas remplacer l\'utilisateur une fois ajouté, attribuez la permission {0} <br> pour révoquer son accès',
  tax_id: 'Numéro d\'identification fiscale',
  optional: '(Facultatif)',
  required: '(Obligatoire)',
  added_users: 'Membres',
  shareable_users: 'Membres',
  available_user_permissions: 'Autorisations utilisateur disponibles',
  corp_manage: 'Gestion',
  edit_credit_card: 'Modifier la carte de crédit',

  third_party: 'Applications installées',
  disconnect: 'Désinstaller',

  personal_information: 'Informations personnelles',
  billing_information: 'Détails de facturation',
  product_information: 'Abonnement',
  other_information: 'Autre',

  remaining_sub_minutes: 'Minutes d\'abonnement',
  remaining_ai_credits: 'Crédits IA',
  remaining_forever_minutes: 'Minutes',
  remaining_total_minutes: 'Total des minutes',
  add_more_minutes: 'Ajouter des minutes',

  account_removal: 'Confirmation de suppression du compte',
  account_removal_desc: '<span style="color:#00D1B2;font-weight:bold;">{0}</span> <br/> perdra tout accès aux transcriptions partagées.<br/> Êtes-vous sûr de vouloir supprimer ce compte ?',
  branding: 'Logo',
  branding_desc: 'Ce logo peut remplacer le logo Taption par défaut lorsque vous exportez la transcription en PDF.',
  add_payment_method: 'Veuillez aller dans <strong>"{0}"</strong> → <strong>"{1}"</strong> → <strong>"{1}"</strong> pour ajouter un moyen de paiement',
  add_member_tips1: 'Invitez plusieurs destinataires en séparant leurs e-mails par des virgules.',
  add_member_tips2: 'Chaque e-mail saisi doit correspondre à un utilisateur Taption enregistré.',
}