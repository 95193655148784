module.exports = { 
    video_quality_reminder: 'Por que a qualidade deste vídeo está reduzida?',
    video_quality_tooltip: 'O proprietário desta transcrição precisará assinar para exibi-la em sua qualidade original.',
    export: 'Exportar',
    speaker: 'Alto-falante',
    full_screen: 'Tela cheia',
    full_screen_exit: 'Sair do modo de tela cheia',
    play: 'Reproduzir (TAB)',
    pause: 'Pausa (TAB)',
    captions_overlay_on: 'Legendas: <span style="color:#20F7C4">Ativado</span><br><span>(Deslize para ajustar a posição)</span>',
    captions_overlay_off: 'Legendas: <span style="color:rgb(230, 118, 117)">Desligadas</span><br><span>(Deslize para ajustar a posição)</span>',
}