module.exports = { 
    update: 'Atualizar',
    cancel: 'Cancelar',
    remove: 'Remover',
    session_title: 'A sua sessão está prestes a expirar',
    session_description: 'Será desconectado em 5 minutos',
    session_ok: 'Mantenha-me conectado',
    session_no: 'Desconectar-me',
    free_min_title: 'Parabéns!',
    free_min_desc: 'Recebeu <span style="color:#2fc59f!important">{0} minutos</span> graças a {1} nova(s) recomendação(ões).',
    email_placeholder: 'Insira um e-mail...',
    a_few_seconds_ago: 'há poucos segundos',
    short_seconds: 's',
    short_minutes: 'm',
    minute: 'minuto',
    minutes: 'minutos',
    hour: 'hora',
    hours: 'horas',
    day: 'dia',
    days: 'dias',
    month: 'mês',
    months: 'meses',
    year: 'ano',
    years: 'anos',
    ago: 'atrás',
    a: 'um',
    an: 'uma',
    title: 'Título',
    welcome: 'Bem-vindo',
    youtube: 'Youtube',
    send: 'Enviar',
    on: 'Ligado',
    off: 'Desligado',
    new: 'Novo',
    search: 'Pesquisar',
    upgrade: 'Atualizar',
    feature_updating: 'Funcionalidade em atualização, volte mais tarde.',
    copy: 'Copiar',
    copied: 'Copiado',
    trial: 'Teste',
    basic: 'Básico',
    premium: 'Premium',
    corp: 'Empresa',
    speakers: 'oradores',
    captions: 'legendas',
    article: 'artigo',
    char: 'caractere',
    language: 'Idioma',
    duration: 'Duração',
    type: 'Tipo',
    date: 'Data',
    transcriptType1: 'Legenda',
    transcriptType2: 'Orador',
    transcriptType3: 'Artigo',
    accounts: 'Contas',
    label: 'Rótulo',
    none: 'Nenhum',
    me: 'Eu',
    usd: 'USD',
    january: 'Janeiro',
    february: 'Fevereiro',
    march: 'Março',
    april: 'Abril',
    may: 'Maio',
    june: 'Junho',
    july: 'Julho',
    august: 'Agosto',
    september: 'Setembro',
    october: 'Outubro',
    november: 'Novembro',
    december: 'Dezembro',
    th: 'Número',
    pricing: 'Preços',
    privacy: 'Privacidade',
    terms: 'Termos',
    blog: 'Recursos',
    video: 'Canal no YouTube',
    cookieSettings: 'Configurações de Cookies',
    back: 'Voltar',
    credit: 'crédito(s)',
    ok: 'Ok',
}