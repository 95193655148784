module.exports = { 
    tell_us_about_yourself: 'FALE-NOS SOBRE VOCÊ',
    first_name: 'Primeiro nome',
    last_name: 'Sobrenome',
    business_name: 'Nome da empresa (se aplicável)',
    location: 'Onde você mora? (Cidade, Estado, País)',
    contact: 'INFORMAÇÕES DE CONTATO',
    email: 'Endereço de e-mail',
    phone: 'Número de telefone',
    linkedin_url: 'URL do LinkedIn',
    social_url: 'URL de mídia social',
    tell_us_about_site: 'FALE-NOS SOBRE SEU SITE',
    website_url: 'URL do Site',
    traffic: 'Tráfego mensal estimado',
    what_site_about: 'Sobre o que é seu site?',
    anything_else: 'Mais alguma coisa que devemos saber?',
    must_agree: 'Você deve concordar para continuar',
    read_agree: 'Li e concordo com os',
    submit_success: 'Obrigado pelo seu interesse! Sua inscrição foi enviada, e nossa equipe entrará em contato com você o mais breve possível.',
    name_required_err: 'Nome é obrigatório',
    name_character_limit_err: 'O nome deve ter menos de 50 caracteres',
    email_required_err: 'Email é obrigatório',
    email_invalid_err: 'O e-mail deve ser válido',
    phone_needed_err: 'Por favor, insira um número de telefone válido',
    website_required_err: 'URL do site é obrigatório',
    invalid_website_err: 'Por favor, insira uma URL válida',
    location_required_err: 'Localização é obrigatória',
    estimate_traffic_err: 'Por favor, selecione a estimativa de tráfego',
    describe_site_err: 'Favor descrever seu site',
    affiliate: 'Marketing de Afiliados',
    page_title: 'Junte-se ao Programa de Afiliados Taption',
    page_description: '向新客户介绍Taption。分享您的专属联盟营销链接，每次成功推荐都可赚取佣金。让我们一起成长吧！',
    affiliate_tos: 'Termos e Condições da Aliança',
    submit: 'Enviar solicitação',
    generic_err: 'Por favor, revise o formulário e corrija quaisquer erros destacados antes de enviar.',
    step1: 'Passo 1: Inscreva-se',
    step1_desc: 'Leva menos de 3 minutos para se inscrever. Preencha o formulário abaixo e entraremos em contato dentro de 24 horas.',
    step2: 'Etapa 2: Compartilhe seu link personalizado',
    step2_desc: 'Uma vez aprovado como afiliado da Taption, você receberá um link único para compartilhar, garantindo que todas as referências e comissões sejam rastreadas.',
    step3: 'Passo 3: Ganhe dinheiro',
    step3_desc: 'Ganhe comissões quando alguém se inscrever ou comprar minutos através do seu link—simples e recompensador!',
}