module.exports = {
  login:'Iniciar sesión',
  or:'o',
  member_login:'Inicio de sesión de miembro',
  signin_with:'Iniciar sesión con:',
  signin_fb:'Iniciar sesión con Facebook',
  signin_google:'Iniciar sesión con Google',
  email_address: 'Dirección de correo electrónico',
  password: 'Contraseña',
  confirm_password: 'Vuelve a escribir la contraseña',
  been_logged_out_title: 'Has cerrado sesión',
  been_logged_out_desc: 'Tu cuenta ha sido accedida desde otra ubicación',
  forgot_password:'¿Olvidaste tu contraseña?',
  create_free_account:'Crea tu cuenta gratuita',
  create_an_account:'Crear una cuenta',
  create_account:'Crear cuenta',
  or_go_back_to:'O volver a',
  name:'Nombre',
  check_to_reset:'Por favor, revisa tu bandeja de entrada para restablecer tu contraseña.',
  check_to_verify_header:'Verifica tu correo electrónico',
  check_to_verify:'¡Tu cuenta ha sido creada! Si no recibes el mensaje de confirmación, por favor revisa tu <span style="font-weight:bold">Carpeta de Spam</span>.',
  send_to_verify_header:'Correo de verificación enviado',
  send_to_verify:'Por favor, revisa tu correo electrónico para encontrar el enlace de verificación y activar tu cuenta. Si no recibes el mensaje de confirmación, por favor revisa tu <span style="font-weight:bold">Carpeta de Spam</span>.',
  term_of_service_description1: 'Al iniciar sesión en Taption, aceptas nuestros ',
  term_of_service:'términos de servicio',
  term_of_service_description2: ' y has leído y entendido la ',
  privacy_policy:'política de privacidad',
  resend_verification:'Reenviar correo de verificación',
  invite_desc:'Gracias a {0}<br>Regístrate y obtén 15 minutos gratis',
  login_desc:'Regístrate y obtén 15 minutos gratis',
  referral_note:'No se requiere tarjeta de crédito.',
  redirecting:'Redirigiendo...'
}