module.exports = { 
    video_quality_reminder: '为什么这个视频的质量降低了？',
    video_quality_tooltip: '此文字记录的拥有者需要订阅才能以原始质量显示。',
    export: '导出',
    speaker: '扬声器',
    full_screen: '全屏',
    full_screen_exit: '退出全屏',
    play: '播放（TAB）',
    pause: '暂停 (TAB)',
    captions_overlay_on: '字幕：<span style="color:#20F7C4">开</span><br><span>（滑动调整位置）</span>',
    captions_overlay_off: '字幕：<span style="color:rgb(230, 118, 117)">关闭</span><br><span>（滑动以调整位置）</span>',
}