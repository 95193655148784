module.exports = {
    update: '変更',
    cancel: 'キャンセル',
    remove: '削除',
    session_title: 'ログアウトしようとしています',
    session_description: 'システムは 5 分後に自動的にログアウトします',
    session_ok: 'ログインしたままにする',
    session_no: 'ログアウト',
    free_min_title: 'おめでとうございます!',
    free_min_desc: 'あなたが招待した{1}人の友達が登録したので、あなたに<span style="color:#2fc59f!important">{0}分間</span>与えます',
    email_placeholder: 'メールアドレスを入力してください...',
    a_few_seconds_ago:'数秒前',
    short_seconds:'秒',
    short_minutes:'分',
    minute:'分',
    minutes:'分',
    hour:'時間',
    hours:'時間',
    day:'日間',
    days:'日間',
    month:'月',
    months:'月',
    year:'年',
    years:'年',
    ago:'前',
    a:'一',
    an:'一',
    title: '名前',
    welcome: 'いらっしゃいませ',
    youtube: 'Youtube',
    send:'送信',
    on:'オンにする',
    off:'閉鎖',
    new: '新着',
    search: '探す',
    upgrade: 'アップグレード',
    feature_updating: '機能アップデート、後でもう一度お試しください',
    copy: 'コピー',
    copied: 'コピー完了',
    trial:'試してみる',
    basic:'基本',
    premium:'プレミアムサブスクリプション',
    corp:'大きいサブスクリプション',
    speakers:'スピーカー',
    captions:'字幕',
    article:'トランスクリプト',
    char:'文字',
    language:'言語',
    duration:'長さ',
    type:'タイプ',
    date: '日にち',
    transcriptType1:'字幕',
    transcriptType2:'スピーカー',
    transcriptType3:'トランスクリプト',
    accounts:'アカウントの選択',
    label:'ラベル',
    none: 'なし',
    me: '私',
    usd:'ドル',  
    january:'1月',
    february:'2月',
    march:'3月',
    april:'4月',
    may:'5月',
    june:'6月',
    july:'7月',
    august:'8月',
    september:'9月',
    october:'10月',
    november:'11月',
    december:'12月',
    th:'日',
    pricing:'価格',
    privacy:'プライバシーポリシー',
    terms:'利用規約',
    blog:'インフォメーションエリア',
    video:'Youtubeチャンネル',
    cookieSettings:'Cookie設定',
    back:'戻る',
    credit:'ポイント',
    ok: 'はい'
  }