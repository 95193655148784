module.exports = {
    affiliate:'Affiliate',
    page_title:'Join the Taption Affiliate Program',
    page_description:'Introduce new customers to Taption. Share your unique affiliate link and earn commission for every successful referral. Let\'s grow together!',
    tell_us_about_yourself:'TELL US ABOUT YOURSELF',
    first_name:'First name',
    last_name:'Last name',
    business_name:'Business name (if applicable)',
    location:'Where do you live? (City, State, Country)',
    contact:'CONTACT INFORMATION',
    email:'Email address',
    phone:'Phone number',
    linkedin_url:'LinkedIn URL',
    social_url:'Social media URL',
    tell_us_about_site:'TELL US ABOUT YOUR SITE',
    website_url:'Website URL',
    traffic:'Estimated monthly traffic',
    what_site_about:'What is your site about?',
    anything_else:'Anything else we should know?',
    must_agree:'You must agree to continue',
    read_agree:'I have read and agree to the ',
    affiliate_tos:'Affiliate Terms and Conditions',
    submit:'Submit Affiliate Application',
    submit_success:'Thank you for your interest! Your application has been submitted, and our team will be in touch with you as soon as possible.',


    name_required_err:'Name is required',
    name_character_limit_err:'Name must be less than 50 characters',
    email_required_err:'Email is required',
    email_invalid_err:'Email must be valid',
    phone_needed_err:'Please enter valid phone number',
    website_required_err:'Website URL is required',
    invalid_website_err:'Please enter valid URL',
    location_required_err:'Location is required',
    estimate_traffic_err:'Please select traffic estimate',
    describe_site_err:'Please describe your site',
    generic_err:'Please review the form and correct any highlighted errors before submitting.',

    step1:'Step 1: Sign up',
    step1_desc:'It takes less than 3 minutes to sign up. Fill out the form below and we will reach out within 24 hours.',
    step2:'Step 2: Share your custom link',
    step2_desc:'Once approved as a Taption affiliate, you’ll get a unique link to share, ensuring all referrals and commissions are tracked.',
    step3:'Step 3: Earn money',
    step3_desc:'Earn commissions when someone subscribes or buys minutes through your link—simple and rewarding!',




}