module.exports = {
    convert_to_text_title_meta:"Convertir une vidéo en texte - Logiciel de transcription IA en ligne",
    convert_to_text_desc_meta:"Convertissez automatiquement des vidéos MP4, YouTube et des audios MP3 en texte en ligne. Supporte plus de 40 langues, propose des sous-titres multilingues, ainsi que le formatage automatique et l'étiquetage des intervenants. Inscrivez-vous pour un essai gratuit dès aujourd'hui!",
    convert_to_text_title:"Convertir une vidéo en texte",
    convert_to_text_desc:"Convertissez automatiquement votre vidéo MP4 ou audio MP3 en texte. Supporte les sous-titres multilingues, le formatage automatique et l'étiquetage des intervenants.",
    start_your_trial:"Commencez votre essai gratuit!",
    hero_img:"./01_English.webp",

    three_step_title:"Étapes de transcription",
    step_one:"Télécharger le fichier",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"Sélectionnez une source parmi Appareil, YouTube, Google Drive ou Zoom pour convertir en texte.",
    
    step_two:"Choisir une méthode",
    step_two_img:"./select_split_en.webp",
    step_two_desc:"Choisissez votre méthode de transcription parmi : 1) Transcription automatisée 2) Importer un fichier texte 3) Manuel, puis choisissez Segmentez chaque section pour « article ».",

    step_three:"Exporter",
    step_three_img:"./select_export_en.webp",
    step_three_desc:"Après l'édition dans la plateforme, exportez (srt, txt, pdf, mp4) en cliquant sur le bouton d'exportation en haut à droite.",

    feature_tutorial_title:'Conversion vidéo-texte simplifiée',
    feature_tutorial_desc:'Taption propose quatre méthodes distinctes pour ajouter des sous-titres à votre vidéo : <span class="bullet-point">•</span>Transcription automatique <span class="bullet-point">•</span>Importation de SRT <span class="bullet-point">•</span>Importation de TXT <span class="bullet-point">•</span>Saisie manuelle.<br><br>Notre plateforme d\'édition est un outil intuitif pour la conversion audio-texte, parole-texte et vidéo-texte, ce qui simplifie vos tâches de transcription. Pour ceux qui s\'adressent à un public international, nous proposons également des sous-titres multilingues. Regardez notre tutoriel pour une explication simple des étapes de transcription, et apprenez à gérer efficacement vos besoins de conversion vidéo-texte.',
    feature_tutorial_poster_url:'./add_subtitles_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/KvyaH-QU9is?autoplay=1',

    feature_extra_title:"Les nombreux avantages de la conversion vidéo-texte",
    feature_extra_desc:"La conversion vidéo-texte, qui comprend les processus audio-texte et parole-texte, va au-delà de la simple génération de sous-titres. Ces services offrent une suite complète d'avantages qui peuvent considérablement renforcer votre stratégie de contenu grâce à la transcription. Considérez les avantages clés suivants de l'utilisation des conversions vidéo-texte, audio-texte et parole-texte dans vos opérations : <ul><li><strong>Média consultable :</strong> Vos fichiers multimédias seront entièrement consultables, ce qui améliorera la découvrabilité de votre contenu. </li><li><strong>Revue efficace et modification de contenu :</strong> Vous accélérerez vos processus de révision et d'édition sans compromettre la qualité. </li><li><strong>Traduction facile :</strong> Vous pouvez facilement gérer plusieurs langues et ainsi élargir votre portée mondiale. </li><li><strong>Optimisation SEO :</strong> Rendre votre média consultable signifie également que vous améliorez les performances SEO de votre site. </li><li><strong>Conformité ADA :</strong> Votre contenu sera accessible à tous et répondra aux normes de conformité ADA. </li></ul>",

    feature_why_image: "./translate_step1_en.webp",
    feature_what_title:'5 étapes pour convertir un audio en texte',
    feature_what_desc: "<ul>\n  <li>Créez un compte ou connectez-vous avec Gmail : Accédez à la <a href=\"https://www.taption.com/login\">page de connexion Taption</a>.</li>\n  <li>Cliquez sur le bouton \"Télécharger\" et sélectionnez la source média, telle que votre ordinateur, YouTube, Google Drive, Zoom, ou collez un lien média.</li>\n  <li>Sélectionnez la \"Langue\" et la \"Méthode de segmentation\" nécessaires pour la transcription.</li>\n  <li>Après le téléchargement, le fichier sera automatiquement converti en texte, et vous pourrez accéder à la plateforme d'édition pour des modifications supplémentaires.</li>\n  <li>Sur la plateforme d'édition, vous pouvez traduire, utiliser l'analyse IA, et enfin exporter le document ou intégrer les sous-titres dans le fichier vidéo.</li>\n</ul>"
}
