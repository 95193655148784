module.exports = { 
    video_quality_reminder: '이 비디오의 품질이 저하된 이유는 무엇인가요?',
    video_quality_tooltip: '이 원본 품질로 표시하려면 이 기록의 소유자가 구독해야 합니다.',
    export: '내보내기',
    speaker: '스피커',
    full_screen: '전체 화면',
    full_screen_exit: '전체 화면 종료',
    play: '재생 (TAB)',
    pause: '일시 중지 (탭)',
    captions_overlay_on: '자막: <span style="color:#20F7C4">켜짐</span><br><span>(위치를 조정하려면 슬라이드를 움직이세요)</span>',
    captions_overlay_off: '자막: <span style="color:rgb(230, 118, 117)">끔</span><br><span>(위치를 조정하려면 슬라이드하세요)</span>',
}