module.exports = {
    login:'登入',
    or:'或',
    member_login:'会员登入',
    signin_with:'以此身分登入:',
    signin_fb:'以 Facebook 登入',
    signin_google:'用Google登录',
    email_address: '电子邮件',
    password: '密码',
    confirm_password: '重新输入密码',
    been_logged_out_title: '您被登出了',
    //been_logged_out_desc: '请重新登入',
    been_logged_out_desc: '您的帐号在别的地点登入',
    forgot_password:'忘记密码',
    create_free_account:'创建新帐号',
    create_an_account:'创建帐号',
    create_account:'创建帐号',
    or_go_back_to:'或者返回',
    name:'名子',
    check_to_reset:'请去信箱点击连结来重置密码',
    check_to_verify_header:'验证信箱',
    check_to_verify:'您的帐号已经建立，请到信箱点击我们寄给你的连结来启用。如果没收到请到 <span style="font-weight:bold">垃圾邮件</span> 确认',
    send_to_verify_header:'验证信已寄出',
    send_to_verify:'请到信箱点击我们寄给你的连结来启用。如果没收到请到 <span style="font-weight:bold">垃圾邮件</span> 确认',
    term_of_service_description1: '当您注册完成或开始使用本服务时，即表示您已阅读、了解并同意接受本',
    term_of_service:'服务条款',
    term_of_service_description2: '之所有内容和',
    privacy_policy:'隐私权政策',
    resend_verification:'重新寄验证EMAIL',
   // invite_desc:'感谢{0}的邀请<br>你将在你订阅时免费获得额外的{1}分钟。你注册将给你免费一次转换15分钟以内的影片',
    invite_desc:'感谢{0}的邀请<br>注册立即送你15分钟',
    login_desc:'注册立即送你15分钟',
    referral_note:'注册时无需信用卡',
    redirecting:'载入页面中...'
  }