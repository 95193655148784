module.exports = { 
    error_title: '404',
    error_desc: 'La page que vous recherchez n’existe pas 😟',
    share_network_error: 'Vous n\'avez pas la permission ou il s\'agit d\'un problème de réseau.',
    permission_error: 'Vous n\'avez pas la permission d\'effectuer cette action, veuillez contacter l\'administrateur du compte.',
    share_email_exist: 'L\'e-mail existe déjà.',
    user_not_found: 'Impossible de trouver l\'utilisateur.',
    email_invalid: 'E-mail invalide',
    email_not_registered: 'Cet e-mail n\'est pas enregistré sur Taption',
    email_duplicate: 'Vous avez déjà ajouté cet e-mail à votre compte',
    email_slots_required: 'Veuillez acheter plus de sièges pour ajouter de nouveaux membres.',
    youtube_url_invalid: 'L\'URL YouTube est invalide',
    youtube_url_required: 'L\'URL YouTube est requise',
    directlink_url_invalid: 'Nous ne prenons pas en charge l\'URL fournie pour le moment, veuillez nous contacter à help@taption.com pour demander de l\'aide',
    directlink_url_required: 'L\'URL est requise',
    required: 'Obligatoire',
    size_error: 'La taille de votre fichier ne peut pas dépasser {0} Go.',
    size_error_mb: 'La taille de votre fichier ne peut pas dépasser {0} Mo.',
    verify_email: 'Veuillez vérifier votre e-mail pour activer votre compte. Si vous ne recevez pas le message de confirmation, veuillez vérifier votre [Dossier Spam].',
    email_must_be_valid: 'L\'e-mail doit être valide',
    password_not_match: 'Le mot de passe ne correspond pas',
    invalid_youtube: 'Impossible de récupérer la vidéo, veuillez essayer une autre vidéo ou réessayer plus tard.',
    unsupported_youtube: 'Cette vidéo est restreinte, veuillez essayer une autre vidéo.',
    unsupported_direct_video: 'Cette vidéo est restreinte<br>(Lien vidéo en direct non pris en charge)',
    must_be_more_than: 'Doit contenir plus de {0} caractères',
    must_be_less_than: 'Doit contenir moins de {0} caractères',
    must_be_more_than_words: 'Doit contenir plus de {0} mots',
    trial_limit_error: 'La version d\'essai a une limite de 15 minutes. Veuillez acheter des minutes ou vous abonner pour supprimer cette limite.',
    language_required: 'La langue est requise',
    title_required: 'Le titre est requis',
    title_length: 'Le titre doit comporter au maximum 80 caractères',
    purpose_required: 'L\'objectif est requis',
    speakers_number_required: 'Le nombre d\'intervenants est requis',
    category_required: 'La catégorie est requise',
    method_required: 'Veuillez sélectionner une méthode pour transcrire votre média',
    max_length: 'Doit contenir moins de {0} caractères',
    max_char_required: 'Veuillez entrer une valeur entre {0} et 1000. (Valeur suggérée : {1})',
    insufficient_fund_title: 'Pas assez de minutes',
    insufficient_ai_desc: 'Pas assez de crédits, achetez plus de crédits IA pour continuer.',
    exceed_trial_limit_title: 'La durée de la vidéo dépasse la limite d\'essai',
    file_too_large_title: 'La taille de la vidéo est trop importante',
    not_supported_type_title: 'Type de vidéo non pris en charge',
    insufficient_fund_tip: 'Achetez plus de minutes et réessayez.',
    exceed_trial_limit_tip: 'L\'essai vous offre 1 vidéo gratuite de moins de 15 minutes. Achetez des minutes ou passez à la version Premium pour supprimer cette limite',
    file_too_large_tip: 'La taille du fichier ne peut pas dépasser 2 Go',
    not_supported_type_tip: 'Formats vidéo pris en charge : mov, avi, wmv, flv, mpeg, ogg, mp4',
    media_no_audio: 'Le média fourni n\'a pas de son',
    network_upload_fail_title: 'Votre réseau est instable, veuillez réessayer ou utiliser Google Drive',
    network_upload_fail_tip: 'Vous pouvez sélectionner votre fichier média depuis Google Drive lors du téléchargement.',
    transcribe_prepare_fail_title: 'Le serveur est occupé, veuillez réessayer plus tard',
    transcribe_upload_fail_title: 'Le serveur est occupé, veuillez réessayer plus tard',
    transcribe_merge_fail_title: 'Le serveur est occupé, veuillez réessayer plus tard',
    transcribe_progress_fail_title: 'Le serveur est occupé, veuillez réessayer plus tard',
    transcribe_result_fail_title: 'Le serveur est occupé, veuillez réessayer plus tard',
    transcribe_error_fail_title: 'Le serveur est occupé, veuillez réessayer plus tard',
    directlink_url_error_title: 'Le lien fourni n\'est pas valide',
    directlink_url_error_tip: 'Veuillez vous assurer que les autorisations pour le lien sont disponibles pour le grand public',
    exceed_trial_usage_title: 'Utilisation d\'essai dépassée',
    exceed_trial_usage_tip: 'Achetez des minutes ou abonnez-vous pour continuer le service',
    need_payment_title: 'Abonnement en pause',
    need_payment_tip: 'Veuillez résoudre le problème de paiement pour continuer le service',
    exceed_usage_title: 'Utilisation dépassée',
    exceed_usage_tip: 'Veuillez contacter notre support.',
    file_too_long_title: 'Le média est trop long',
    file_too_long_tip: 'Veuillez garder la durée de votre média dans la limite de 2 heures',
    download_youtube_video_title: 'Impossible de télécharger cette vidéo, veuillez essayer une autre vidéo ou réessayer plus tard.',
    download_youtube_video_tip: 'Veuillez essayer de télécharger à nouveau. Si le problème persiste, essayez de télécharger une vidéo différente ou contactez notre support.',
    video_corrupted_title: 'Nous n\'avons pas pu lire ce fichier, veuillez vous assurer que le fichier est valide',
    video_corrupted_tip: 'Assurez-vous que le fichier est valide ou contactez-nous à help@taption.com',
    system_maintanance_title: 'Avertissement',
    system_maintanance: 'Maintenance du système. Veuillez réessayer plus tard en rafraîchissant la page.',
    error_add_title: 'Erreur',
    error_add_desc: 'Impossible d\'ajouter la phrase corrigée au dictionnaire.',
    error_warning: 'Avertissement',
    warning_no_search_found: 'Impossible de trouver "{0}"',
    time_message: 'Il y a <strong>{0}</strong> cas de chevauchement des horodatages de sous-titres, ce qui peut entraîner une lecture incorrecte par des logiciels tiers après l\'exportation.',
    time_message1: 'Il y a <strong>{0}</strong> cas d\'horodatages de sous-titres trop courts qui nécessitent une attention particulière.',
    time_message2: '(Les positions d\'horodatage en orange peuvent être ajustées à l\'aide de l\'<strong>Éditeur de chronologie</strong> dans le coin inférieur gauche)',
    overlap: 'Les horodatages se chevauchent avec les voisins',
    short: 'La durée de ce sous-titre est inférieure à 0,1 seconde',
    error_merge_one: 'Vous avez une exportation de vidéo en cours, veuillez attendre qu\'elle soit terminée et réessayez.',
    retry_upload: 'Réessayer',
    label_required: 'Veuillez sélectionner au moins une étiquette',
    label_name_required: 'Le nom de l\'étiquette est requis',
    label_name_duplicate: 'Le nom de l\'étiquette existe déjà',
    srt_file_required: 'Fichier SRT requis',
    srt_file_invalid: 'Fichier SRT invalide ou vide',
    txt_file_required: 'Fichier TXT requis',
    txt_file_invalid: 'Fichier TXT invalide ou vide',
    media_file: 'Fichier média requis',
    media_srt_mismatch: 'Le média sélectionné est plus court que le fichier SRT sélectionné',
    subscription_pay_failed_title: 'Le paiement de votre abonnement a échoué',
    subscription_pay_failed_desc: 'Veuillez effectuer l\'une des actions suivantes :',
    subscription_pay_failed_resolve1: 'Mettez à jour votre mode de paiement avec une nouvelle carte de crédit dans la section Compte',
    subscription_pay_failed_resolve2: 'Assurez-vous qu\'il y a suffisamment de fonds sur votre carte ou contactez l\'émetteur de la carte de crédit, puis informez-nous à support@taption.com',
    subscription_pay_failed_resolve3: 'Annulez votre abonnement actuel',
    maximum_videos_title: 'Un compte gratuit peut stocker un maximum de {0} vidéos',
    maximum_videos_title1: 'Un compte Basic peut stocker un maximum de {0} vidéos',
    maximum_videos_resolve1: 'Supprimez des vidéos existantes pour maintenir le nombre total de vidéos en dessous de {0}',
    maximum_videos_resolve2: 'Achetez ou passez à l\'un de nos <a href="https://app.taption.com/upgrade">forfaits</a> pour étendre ou supprimer les limites',
    manual_warning: 'Sélectionner <strong>Manuel</strong> vous donnera une transcription vide qui nécessite que vous entriez votre propre texte. <br>Si l\'obtention d\'une transcription automatisée est l\'objectif, veuillez sélectionner <strong>Transcription automatisée</strong>.',
    txt_mismatch_error: 'La langue sélectionnée ne correspond pas au fichier texte d\'entrée.<br>Veuillez vous assurer que les langues correspondent pour un meilleur résultat.',
    txt_invalid_error: 'L\'encodage du fichier d\'entrée n\'a pas pu être détecté.<br>Veuillez vous assurer que le fichier est enregistré dans son encodage correct.<br><span style="font-size:12px">(<strong>Exemple</strong> : Ouvrez votre fichier texte, <strong>Enregistrer sous</strong>→<strong>Encodage</strong> sélectionnez <strong>UTF-8</strong>)</span>',
    language_mismatch_error: 'La langue de la vidéo téléchargée peut ne pas correspondre à la langue que vous avez sélectionnée. Cela pourrait réduire la précision de la transcription et entraîner des difficultés pour traduire dans d\'autres langues.<br><br>Si vous souhaitez traduire la vidéo dans une autre langue, assurez-vous de sélectionner la langue correcte de la vidéo lors du processus de téléchargement. Une fois terminé, utilisez le bouton <v-btn style="padding:3px 20px;margin:0px 5px;text-transform: capitalize;letter-spacing:0px;" class="translate-btn"><i style="font-size:14px;" aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-translate"></i><span class="text">Traduire</span></v-btn> situé en haut à droite de cette page pour procéder à la traduction.',
    "auth/invalid-email": 'Votre e-mail est invalide.',
    "auth/wrong-password": 'Votre mot de passe est incorrect ou ce compte n\'existe pas.',
    "auth/email-already-in-use": 'L\'adresse e-mail est déjà utilisée par un autre compte.',
    "auth/user-not-found": 'Votre mot de passe est incorrect ou ce compte n\'existe pas.',
    confirm_password_desc: 'Veuillez saisir à nouveau votre mot de passe',
    confirm_password_not_match: 'Le mot de passe ne correspond pas',
}