module.exports = { 
    tell_us_about_yourself: 'CERITAKAN TENTANG DIRI ANDA',
    first_name: 'Nama depan',
    last_name: 'Nama belakang',
    business_name: 'Nama bisnis (jika ada)',
    location: 'Di mana Anda tinggal? (Kota, Provinsi, Negara)',
    contact: 'INFORMASI KONTAK',
    email: 'Alamat email',
    phone: 'Nomor telepon',
    linkedin_url: 'URL LinkedIn',
    social_url: 'URL media sosial',
    tell_us_about_site: 'CERITAKAN TENTANG SITUS ANDA',
    website_url: 'URL Website',
    traffic: 'Perkiraan lalu lintas bulanan',
    what_site_about: 'Apa isi situs Anda?',
    anything_else: 'Apakah ada hal lain yang perlu kami ketahui?',
    must_agree: 'Anda harus menyetujui untuk melanjutkan',
    read_agree: 'Saya telah membaca dan menyetujui',
    submit_success: 'Terima kasih atas minat Anda! Aplikasi Anda telah diajukan, dan tim kami akan segera menghubungi Anda.',
    name_required_err: 'Nama diperlukan',
    name_character_limit_err: 'Nama harus kurang dari 50 karakter',
    email_required_err: 'Email diperlukan',
    email_invalid_err: 'Email harus valid',
    phone_needed_err: 'Silakan masukkan nomor telepon yang valid',
    website_required_err: 'URL situs web diperlukan',
    invalid_website_err: 'Silakan masukkan URL yang valid',
    location_required_err: 'Lokasi diperlukan',
    estimate_traffic_err: 'Silakan pilih estimasi lalu lintas',
    describe_site_err: 'Silakan deskripsikan situs Anda',
    affiliate: 'Pemasaran Afiliasi',
    page_title: 'Bergabung dengan Pemasaran Afiliasi Taption',
    page_description: 'Perkenalkan Taption kepada pelanggan baru. Bagikan tautan pemasaran afiliasi eksklusif Anda dan dapatkan komisi untuk setiap referensi yang berhasil. Mari tumbuh bersama!',
    affiliate_tos: 'Syarat dan Ketentuan Aliansi',
    submit: 'Kirim Aplikasi',
    generic_err: 'Silakan tinjau formulir dan perbaiki kesalahan yang diberi tanda sebelum mengirim.',
    step1: 'Langkah 1: Daftar',
    step1_desc: 'Mendaftar hanya membutuhkan waktu kurang dari 3 menit. Isi formulir di bawah ini dan kami akan menghubungi Anda dalam waktu 24 jam.',
    step2: 'Langkah 2: Bagikan tautan kustom Anda',
    step2_desc: 'Setelah disetujui sebagai afiliasi Taption, Anda akan mendapatkan tautan unik untuk dibagikan, memastikan semua referensi dan komisi tercatat.',
    step3: 'Langkah 3: Dapatkan uang',
    step3_desc: 'Dapatkan komisi ketika seseorang berlangganan atau membeli menit melalui tautan Anda—sederhana dan menguntungkan!',
}