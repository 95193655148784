module.exports = { 
    update: 'Update',
    cancel: 'Cancel',
    remove: 'Remove',
    session_title: 'Your session is about to expired',
    session_description: 'You will be logged out in 5 minutes',
    session_ok: 'Keep me signed in',
    session_no: 'Sign me out',
    free_min_title: 'Congratulations!',
    free_min_desc: 'You have received <span style="color:#2fc59f!important">{0} minutes</span> due to {1} new referral(s).',
    email_placeholder: 'Enter an email...',
    a_few_seconds_ago: 'a few seconds ago',
    short_seconds: 's',
    short_minutes: 'm',
    minute: 'minute',
    minutes: 'minutes',
    hour: 'hour',
    hours: 'hours',
    day: 'day',
    days: 'days',
    month: 'month',
    months: 'months',
    year: 'year',
    years: 'years',
    ago: 'ago',
    a: 'a',
    an: 'an',
    title: 'Title',
    welcome: 'Welcome',
    youtube: 'Youtube',
    send: 'Send',
    on: 'On',
    off: 'Off',
    new: 'New',
    search: 'Search',
    upgrade: 'Upgrade',
    feature_updating: 'Feature updating, please check back later.',
    copy: 'Copy',
    copied: 'Copied',
    trial: 'Trial',
    basic: 'Basic',
    premium: 'Premium',
    corp: 'Bulk',
    speakers: 'speakers',
    captions: 'captions/subtitles',
    article: 'article',
    char: 'char',
    language: 'Language',
    duration: 'Duration',
    type: 'Type',
    date: 'Date',
    transcriptType1: 'Caption',
    transcriptType2: 'Speaker',
    transcriptType3: 'Article',
    accounts: 'Accounts',
    label: 'Label',
    none: 'None',
    me: 'Me',
    usd: 'USD',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    th: 'th',
    pricing: 'Pricing',
    privacy: 'Privacy',
    terms: 'Terms',
    blog: 'Resources',
    video: 'Youtube Channel',
    cookieSettings: 'Cookie Settings',
    back: 'Back',
    credit: 'credit(s)',
    ok: 'OK',
}