module.exports = {
    upload: '上传',
    upload_language: '选择此档案的发声语言',
    upload_method: '选择影音转文字的方式',
    delete_title: '删除此文档?',
    delete_description: '此操作无法撤消。 ',
    delete_ok: '删除',
    delete_cancel: '取消',
    remember_selection: '记住我的选项',
    send_email: '完成时,寄E-mail来通知我',
    card_share: '分享',
    card_delete: '删除',
    upload_media: '上传影音档',
    upload_error_title:'错误讯息',
    upload_error: '您需要购买更多上传分钟',
    transcribing_description: '我们正在处理您的档案。 <br>处理时间大约是影音档的总时间。 <br>完成后，我们会通过电子邮件通知您。',
    transcribing: '处理中',
    preparing: '音档转影片准备中',
    update_title: '更改档案名称',
    transcript_title: '档案名称',
    add_more:'新增',
    
    upload_limit_exceeded_title:'超出影片长度最大上限',
    upload_limit_exceeded: '影片长度请保持在5小时以内.',

    initializing_description: '我们正在初始化您的档案。 <br>请稍后',
    initializing: '等待中',
    translating_description: '我们正在翻译您的档案。 <br>需要几分钟的时间，请稍后',
    translating: '翻译中',
    error_description: '如有需要，请与我们联络',
    error_info: '错误代码: {0}',
    error: '处理中断',
    error_minutes: '{0} <br>此影片分钟数已自动返还<br>请刷新页面来得到最新分钟数',

    please_wait: '请稍候...',
    loading: '加载中...',
    upgrade_to_share: '请{0}后邀请朋友或同事来编辑此文档',
    share_link_with: '分享此文档 (不可编辑)',
    share_with_editable_permission: '分享此文档 (可编辑)',
    //share_with: '输入Taption用户的电子信箱',
    share_with: '例: sample1@gmail.com,sample2@gmail.com',
    share_with_currently: '目前已分享',
    share_empty:'没有可分享用户，请到<a href="\\management" style="color:#2FC59F;"> 管理 </a>页面新增分享用户。 ',
    uploading: '上传中',
    shared: '分享',
    thumbnail:'缩图',
    upload_purpose: '文字分段方式',
    upload_number: '发声者人数',
    upload_category: '影片类别',
    upload_clipped:'撷取影音片段',
    clipped_adjustments_desc:'微调秒数请用滑鼠点选拉轴后<br>使用键盘上左右键来调整',
    clipped_description:'从影音档中撷取片段来辨识文字，更有效地利用分钟数<br>支援Youtube连结,mp3及mp4类型影音档',
    clipped_warning:'使用此功能将增加处理时间',
    clipped_length_warning:'影音档必须大于1分钟来使用此功能',
    purpose_description: '这让我们可以以更精准的格式呈现，来帮助您进行编辑。',
    speaker_unsure: '不确定',
    purpose_header:'分段方式',
    // purpose_transcript:'以「{0}」来分段',
    // purpose_transcript_desc:'(例：给影片上字幕)',
    // purpose_caption:'以「{0}」型式分段',
    // purpose_caption_desc:'(例：导出会议记录文字逐字稿)',
    // purpose_translate:'以「{0}」型式分段',
    // purpose_translate_desc:'(例：纯文字逐字稿)',

    purpose_speakers:'AI自动<strong>标记</strong>影音里说话<strong>人物并分段</strong>',
    purpose_transcript_desc:'(例：给影片上字幕，无标点符号)',
    purpose_captions:'AI自动以上<strong>字幕</strong>的形式来<strong>分段</strong>',
    purpose_caption_desc:'(例：导出会议记录文字逐字稿，有标点符号)',
    purpose_article:'有标点符号的逐字稿 <strong>无特别分段方式</strong>',
    purpose_translate_desc:'(例：纯文字逐字稿，有标点符号)',
    purpose_original:'会依照提供的<strong>文字档案</strong>里面的分段进行分段',
  
        
    speakers_time:'说话的人',
    captions_time:'字幕',
    article_time:'原文字档案',
    original_time:'原文字档案',
    none_time:'',
    
    speakers_time_desc:'AI自动标记不同人物的说话并分段',
    captions_time_desc:'AI自动以上字幕的形式来分段',
    article_time_desc:'会依照提供的文字档案里面的分段进行分段。 ',
    original_time_desc:'会依照提供的文字档案里面的分段进行分段。 ',
    none_time_desc:'',

    purpose_other:'我还没决定',
    create:'开始转换',
    home:'首页',
    management_corp:'企业管理',
    management:'管理',
    account:'我的帐号',
    upgrade:'升级',
    faq:'常见问题',
    logout:'登出',
    return:'返回編輯頁面',
    drag_drop:'将要上传的影音档拉至此',
    select_from:'从电脑选取档案',
    or_select_from: '或从以下清单选取',
    common_languages: '常用语言',
    additional_languages: '其他语言',
    
    // friendly_notice: '贴心叮咛',
    // friendly_notice_desc: '请确保影片说话者的分辨率和避免背景音乐或噪音，来达到最高准确率'
    friendly_notice: '增进准确率诀窍',
    friendly_notice_desc0: '1.确保所选语言和影片本身相同，如需翻译可在转成文字后进行',
    friendly_notice_desc: '2.确保影片说话者的清晰度和避免背景音乐或噪音',
    friendly_notice_desc2: '3.请勿上传MV或歌声相关影片',
    friendly_notice_desc3: '4.音轨如有修改将无法顺利转档',
    friendly_notice_desc4: '5.建议上传MP4或MP3影音档',

    google_drive_upgrade_title: '从Google Drive选取',
    google_drive_upgrade_desc: '订阅后可以跳过档案上传过程，大幅度的减少您等待的时间!',
    trial_check_desc: '我同意在未购买分钟或订阅前，不得将字幕用在商业相关之用途。',

    browser_unsupported_title: '不支援此浏览器',
    browser_unsupported_desc: '请使用「电脑Chrome浏览器」来做字幕编辑。',
    browser_unsupported_mobile_desc: '请使用「Chrome」浏览器。',
    browser_translated_injected_title: '请关闭网页翻译或相关套件',
    browser_translated_injected_desc: '我们侦测到浏览器正在使用套件翻译此网页，请关闭网页翻译后使用我们内建翻译来避免无可预测的错误 (例:编辑断线无法储存)',
    browser_unsupported_step1:'1. 点选右下角或右上角的 「...」或「 ⋮」 ',
    browser_unsupported_step2:'2. 点选外部浏览器开启连结 ',

    individual_account: '个人帐号',
    corp_account_selection_desc:'当大量订阅用户将你加入后，可从选单登入其帐号',
    unsave_change_title:'您有未保存的更改',
    unsave_change_desc:'您确定要离开吗?',
    label_name:'标签名称',
    new_label:'增加新标签',
    select_label:'选择标签',
    label_dialog_header:'标签管理',
    label_dialog_desc:'可直接编辑标签顺序，颜色，文字',
    label_empty:'无标签',
    label_all:'所有档案',
    label_select_header:'标签选择',
    label_limit_error:'已达标签上限，请升级到「高级订阅」或「大量订阅」来取消此限制',
    segment_sample:'输出范例',
    zoom_no_data:'过去2年没有任何影片...',
    zoom_desc:'仅会显示最近2年内的影片',
    zoom_loading:'载入中...',
    import_srt:'汇入SRT',
    srt_header:'文字生成方式',
    srt_select:'选择SRT档案',
    srt_desc:'等待SRT档案中...',
    import_txt:'汇入TXT纯文字',
    txt_select:'选择文字档案',
    txt_desc:'等待TXT档案中...',
    direct_link:'<span class="directlink-text-zh">影音档网址连接</span>',

    multi_upload:'订阅用户一次可上传多个档案',
    category:'影片类别',
    max_char_header:'每段最大字符',
    max_char_desc:'转译后的文字依据此数值来分段，<br>并且每段不会超过此字符數。<br>建议数值：<br>中文/日文/韩文 : 20<br>其他语言:95',
    language_tooltip:'选择影片或录音档的发生语言，<br>如希望转成其他语言要先转成文字后进入编辑平台再进行翻译。 <br><br> 如下方<span style="color:#2FC59F">「字幕字幕生成方式」</span>选择了<span style="color:#2FC59F">「汇入SRT时间字幕档」</span>，<br>请选择SRT字幕档的语言。',
    import_txt_tooltip:'我们会将您提供的文字加上相对应的时间轴。<br><strong>{0}</strong>: {1}',
    home_notice_title:'省下更多分钟数',
    home_notice_desc:'新的分钟计费方式将免去多余的秒数!<div class="desc">例: 上传一支15分59秒的影片只会扣除15分钟. (上传一支小于1分钟的影片将不会扣除分钟数 🙂)</div>',
    no_name:'未命名',
    upload_language_reminder_title: "请确认所选择的语言与视频的语音相同",
    upload_language_reminder_desc: "例如：如果视频本身是英文的，请选择「英文」作为语言。<br>如果希望翻译视频字幕、逐字稿，请先转换成文字后进入编辑平台。<br>然后通过右上方的「翻译」来翻译视频。"

  }