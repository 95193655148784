
export default {
  getAppLoginRoute(state){
    let language = window.localStorage.getItem('PrefferedLanguage') || this.getDisplayLanguage();
    let advertiserId = window.localStorage.getItem('advertiserId');
    let platform = window.localStorage.getItem('platform') || 'other';

    language = language === 'en-US' ? '' : language;
    const routeObj = {
      name: 'login',
    }
    if(language){
      routeObj.name = 'loginlang';
      routeObj.params = {language: language};
    }

    if(advertiserId && state){
      routeObj.query = { advertiserId:advertiserId,platform:platform, state: state}
    }
    else if(advertiserId){
      routeObj.query = { advertiserId:advertiserId,platform:platform}
    }

    return routeObj;

    // if(advertiserId && state){
    //   return {name: "loginlang",params: { language: language },  query : { advertiserId:advertiserId,platform:platform, state: state}};
    //   //return this.getSiteUrl()+'/login'+'/'+language+`?advertiserId=${advertiserId}&platform=${platform}&state=${state}`
    // }
    // else if(advertiserId){
    //   return {name: "loginlang",params: { language: language }, query : { advertiserId:advertiserId,platform:platform, state: state}};
    // //  return this.getSiteUrl()+'/login'+'/'+language+`?advertiserId=${advertiserId}&platform=${platform}`
    // }
    // else if(state){
    //   return {name: "loginlang",params: { language: language }, query : { state: state}};
    //  // return this.getSiteUrl()+'/login'+'/'+language+`?state=${state}`
    // }
    // else if(language){
    //   return {name: "loginlang",params: { language: language }};
    //  // return this.getSiteUrl()+'/login'+'/'+language+`?state=${state}`
    // }
    // else{
    //   return {name: "login"};
    // }
  },
  getAppLoginUrl(state){
    let language = window.localStorage.getItem('PrefferedLanguage') || this.getDisplayLanguage();
    let advertiserId = window.localStorage.getItem('advertiserId');
    let platform = window.localStorage.getItem('platform') || 'other';

    const languagePath = language === 'en-US' ? '' : ('/'+language);
    if(advertiserId && state){
      return this.getSiteUrl()+'/login'+languagePath+`?advertiserId=${advertiserId}&platform=${platform}&state=${state}`
    }
    else if(advertiserId){
      return this.getSiteUrl()+'/login'+languagePath+`?advertiserId=${advertiserId}&platform=${platform}`
    }
    else if(state){
      return this.getSiteUrl()+'/login'+languagePath+`?state=${state}`
    }
    else if(language){
      return this.getSiteUrl()+'/login'+languagePath
    }
    else{
      return this.getSiteUrl()+'/login'
    }
  },
    // getAppLoginUrl(state){
    //   let language = window.localStorage.getItem('PrefferedLanguage') || this.getDisplayLanguage();
    //   let advertiserId = window.localStorage.getItem('advertiserId');
    //   let platform = window.localStorage.getItem('platform') || 'other';
    //   if(advertiserId && state){
    //     return this.getSiteUrl()+'/login'+'/'+language+`?advertiserId=${advertiserId}&platform=${platform}&state=${state}`
    //   }
    //   else if(advertiserId){
    //     return this.getSiteUrl()+'/login'+'/'+language+`?advertiserId=${advertiserId}&platform=${platform}`
    //   }
    //   else if(state){
    //     return this.getSiteUrl()+'/login'+'/'+language+`?state=${state}`
    //   }
    //   else{
    //     return this.getSiteUrl()+'/login'+'/'+language
    //   }
    // },
    getAppLoginRelativeUrl(state){
      let language = window.localStorage.getItem('PrefferedLanguage') || this.getDisplayLanguage();
      let advertiserId = window.localStorage.getItem('advertiserId');
      let platform = window.localStorage.getItem('platform') || 'other';
      if(advertiserId && state){
        return '/login'+'/'+language+`?advertiserId=${advertiserId}&platform=${platform}&state=${state}`
      }
      else if(advertiserId){
        return '/login'+'/'+language+`?advertiserId=${advertiserId}&platform=${platform}`
      }
      else if(state){
        return '/login'+'/'+language+`?state=${state}`
      }
      else{
        return '/login'+'/'+language
      }
    },
    getPricingUrl(state){
      let language = window.localStorage.getItem('PrefferedLanguage') || this.getDisplayLanguage();
      let advertiserId = window.localStorage.getItem('advertiserId');
      let platform = window.localStorage.getItem('platform') || 'other';

      if(language.toLowerCase() === 'en-us'){
        language = '';
      }
      if(language){
        if(advertiserId && state){
          return this.getSiteUrl()+'/pricing'+'/'+language+`?advertiserId=${advertiserId}&platform=${platform}&state=${state}`
        }
        else if(advertiserId){
          return this.getSiteUrl()+'/pricing'+'/'+language+`?advertiserId=${advertiserId}&platform=${platform}`
        }
        else if(state){
          return this.getSiteUrl()+'/pricing'+'/'+language+`?state=${state}`
        }
        else{
          return this.getSiteUrl()+'/pricing'+'/'+language
        }
      }
      else{

      if(advertiserId && state){
        return this.getSiteUrl()+'/pricing'+`?advertiserId=${advertiserId}&platform=${platform}&state=${state}`
      }
      else if(advertiserId){
        return this.getSiteUrl()+'/pricing'+`?advertiserId=${advertiserId}&platform=${platform}`
      }
      else if(state){
        return this.getSiteUrl()+'/pricing'+`?state=${state}`
      }
      else{
        return this.getSiteUrl()+'/pricing'
      }
      }
    },
    

    getDisplayLanguage(){
        const urlChunks = window.location.href.replace(window.location.origin+'/','').split('/');
        let language = urlChunks[urlChunks.length-1].split('&')[0].split('?')[0];
       // const supportedLanguages = ['zh','zh-cn','en-us','jp','kr'];
        const supportedLanguages = process.env.VUE_APP_BROWSER_SUPPORTED_LANGUAGE.split(',');
        if(supportedLanguages.indexOf(language.toLowerCase()) === -1){
          language = '';
        }
        return language;
    },
    distinctLanguages(languageObj){
      const distinctLang = {};
      const results = [];
      
      Object.keys(languageObj).forEach(function(code) {
        const langDisplay = languageObj[code].split(" ")[0];
        if(distinctLang[langDisplay] !== true){
          results.push(langDisplay);
          distinctLang[langDisplay] = true;
        }
      });
  
      return results;
    },

    getAppSiteUrl(){
      if(process.env.VUE_APP_MODE === 'production'){
        return 'https://app.taption.com';
      }
      else{
        return 'https://app.taptiontest.com';
      }
    },
    getSiteUrl(){
      return window.location.origin;
      // if(process.env.VUE_APP_MODE === 'production'){
      //   return 'https://www.taption.com';
      // }
      // else{
      //   return 'https://www.taptiontest.com';
      // }
    },
    _arrayBufferToBase64( buffer ) {
      var binary = '';
      var bytes = new Uint8Array( buffer );
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
          binary += String.fromCharCode( bytes[ i ] );
      }
      return window.btoa( binary );
    },
    b64decode(str) {
      const binary_string = window.atob(str);
      const len = binary_string.length;
      const bytes = new Uint8Array(new ArrayBuffer(len));
      for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes;
    },
    async getCompressedJsonString(data){
      const me = this;
        try{
            const stream = new Blob([JSON.stringify(data)], {
              type: 'application/json',
          }).stream();
          const compressedReadableStream = stream.pipeThrough(
            // eslint-disable-next-line no-undef
                new CompressionStream("gzip")
            );

            // create Response
          const compressedResponse = await new Response(compressedReadableStream);

          // Get response Blob
          const blob = await compressedResponse.blob();
          // Get the ArrayBuffer
          const buffer = await blob.arrayBuffer();

          // convert ArrayBuffer to base64 encoded string
          const compressedBase64 = me._arrayBufferToBase64( buffer );

          return compressedBase64;
        }
        catch{
          return null;
        }
    },
    async getDecompressedJsonString(compressedBase64){
      const me = this;

      try{
        const stream = new Blob([me.b64decode(compressedBase64)], {
          type: "application/json",
        }).stream();
  
  
        const compressedReadableStream = stream.pipeThrough(
          // eslint-disable-next-line no-undef
          new DecompressionStream("gzip")
        );
        const resp = await new Response(compressedReadableStream);
        const blob = await resp.blob();
        const blobText =await blob.text();
        const data = JSON.parse(blobText);
  
        return data;
      }
      catch{
        return null;
      }
    },
    getCaretCharacterOffsetWithinStart (element) {
      var caretOffset = 0
      var doc = element.ownerDocument || element.document
      var win = doc.defaultView || doc.parentWindow
      var sel
      if (typeof win.getSelection !== 'undefined') {
        sel = win.getSelection()
        if (sel.rangeCount > 0) {
          var range = win.getSelection().getRangeAt(0)
          var preCaretRange = range.cloneRange()
          preCaretRange.selectNodeContents(element)
          preCaretRange.setEnd(range.startContainer, range.startOffset)
          caretOffset = preCaretRange.toString().length
        }
      } else if ((sel = doc.selection) && sel.type != 'Control') {
        var textRange = sel.createRange()
        var preCaretTextRange = doc.body.createTextRange()
        preCaretTextRange.moveToElementText(element)
        preCaretTextRange.setEndPoint('StartToStart', textRange)
        caretOffset = preCaretTextRange.text.length
      }
      return caretOffset
    },
    getCaretCharacterOffsetWithinEnd (element) {
      var caretOffset = 0
      var doc = element.ownerDocument || element.document
      var win = doc.defaultView || doc.parentWindow
      var sel
      if (typeof win.getSelection !== 'undefined') {
        sel = win.getSelection()
        if (sel.rangeCount > 0) {
          var range = win.getSelection().getRangeAt(0)
          var preCaretRange = range.cloneRange()
          preCaretRange.selectNodeContents(element)
          preCaretRange.setEnd(range.endContainer, range.endOffset)
          caretOffset = preCaretRange.toString().length
        }
      } else if ((sel = doc.selection) && sel.type != 'Control') {
        var textRange = sel.createRange()
        var preCaretTextRange = doc.body.createTextRange()
        preCaretTextRange.moveToElementText(element)
        preCaretTextRange.setEndPoint('EndToEnd', textRange)
        caretOffset = preCaretTextRange.text.length
      }
      return caretOffset
    },
    getRoundTo2Decimal(val){
      if(val < 0.005){
        return 0;
      }
  
      return this.toFixed(val,2);
      
    },
    getRoundTo3Decimal(val){
      if(val < 0.005){
        return 0;
      }
  
      return this.toFixed(val,3);
      
    },
    toFixed( num, precision ) {
      return Number((+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision));
    },
    getPhraseText(phrase, isTextOnly){
      var text = '';
      var me = this;
      let videoCutTime = 0;
  
      if(isTextOnly){
        for(var h = 0 ; h < phrase.words.length; h++){
          const word = phrase.words[h];
          if(!word.isVideoCut){
            text += word.word;
          }
        }
      }
      else{
        let wordStartTime = -1;
        let wordEndTime = -1;
        phrase.startTime -= videoCutTime;
        phrase.startTime  = me.getRoundTo3Decimal(phrase.startTime);
        for(var i = 0 ; i < phrase.words.length; i++){
          const word = phrase.words[i];
          if(!word.isVideoCut){
            text += word.word;
            if(wordStartTime >= 0 ){
            //  videoCutTime += (me.getRoundTo3Decimal(wordEndTime) - me.getRoundTo3Decimal(wordStartTime))
              videoCutTime += (wordEndTime - wordStartTime);
              wordStartTime = -1;
              wordEndTime = -1;
            }
            phrase.words[i].startTime -= videoCutTime;
            phrase.words[i].endTime -= videoCutTime;
  
            phrase.words[i].startTime  = me.getRoundTo3Decimal(phrase.words[i].startTime);
            phrase.words[i].endTime  = me.getRoundTo3Decimal(phrase.words[i].endTime);
          }
          // else if(word.isVideoCut && i == 0){
          //   videoCutTime += (word.endTime - phraseStartTime)
          // }
          // else if(word.isVideoCut && i == phrase.words.length -1){
          //   videoCutTime += (phraseEndTime - word.startTime)
          // }
          else{
            if(wordStartTime === -1){
              wordStartTime = word.startTime;
  
              wordStartTime  = me.getRoundTo3Decimal(wordStartTime);
            }
  
            wordEndTime = word.endTime;
          //  videoCutTime += (Math.round(wwordEndTime* 100) / 100 - Math.round(wordStartTime * 100) / 100)
          //  videoCutTime += (word.endTime - word.startTime)
          }
        }
  
        if(wordStartTime >= 0 ){
          
         // videoCutTime += (me.getRoundTo3Decimal(wordEndTime) - me.getRoundTo3Decimal(wordStartTime))
          videoCutTime += (wordEndTime - wordStartTime);
          wordStartTime = -1;
          wordEndTime = -1;
        }
        
        phrase.endTime -= videoCutTime;
        phrase.endTime  = me.getRoundTo3Decimal(phrase.endTime);
      }
      
  
      return text;
    },
    fromBase64(base64String) {
      // Decode from Base64 to binary string
      const binaryString = window.atob(base64String);
  
      // Convert binary string to a UTF-8 string
      const byteArray = Uint8Array.from(binaryString, char => char.charCodeAt(0));
      const utf8String = new TextDecoder().decode(byteArray);
  
      return utf8String;
  },
  toBase64(input) {
    // Encode the string as UTF-8
    const utf8Bytes = new TextEncoder().encode(input);

    // Convert the byte array to a Base64 string
    const base64String = window.btoa(String.fromCharCode(...utf8Bytes));

    return base64String;
  }
}