module.exports = {
    video_quality_reminder: 'Why is the quality of this video reduced?',
    video_quality_tooltip:'The owner of this transcript will need to subscribe in order to display in its original quality.',
    export: 'Export',
    speaker:'Speaker',  
    full_screen: 'Fullscreen',
    full_screen_exit: 'Exit fullscreen',
    play: 'Play (TAB)',
    pause: 'Pause (TAB)',
    captions_overlay_on: 'Captions: <span style="color:#20F7C4">On</span><br><span>(Slide to adjust position)</span>',
    captions_overlay_off: 'Captions: <span style="color:rgb(230, 118, 117)">Off</span><br><span>(Slide to adjust position)</span>',

}