module.exports = { 
    affiliate: '联盟行销',
    page_title: '加入Taption联盟行销',
    page_description: '向新客户介绍Taption。分享您的专属联盟行销链接，每次成功推荐都可赚取佣金。让我们一起成长吧！ ',
    tell_us_about_yourself: '告诉我们关于您的资讯',
    first_name: '名',
    last_name: '姓氏',
    business_name: '公司名称（如适用）',
    location: '你住在哪里？ （城市、省/州、国家）',
    contact: '联络资讯',
    email: '电子邮件地址',
    phone: '电话号码',
    linkedin_url: 'LinkedIn网址',
    social_url: '社群媒体网址',
    tell_us_about_site: '告诉我们您的网站',
    website_url: '网站网址',
    traffic: '预计每月流量',
    what_site_about: '您的网站是关于什么的？ ',
    anything_else: '还有其他需要我们了解的吗？ ',
    must_agree: '您必须同意才能继续',
    read_agree: '我已阅读并同意',
    affiliate_tos: '联盟条款与条件',
    submit: '提交申请',
    submit_success: '感谢您的兴趣！您的申请已提交，我们的团队将尽快与您联系。 ',
    name_required_err: '姓名为必填项',
    name_character_limit_err: '名称必须少于50个字元',
    email_required_err: '电子邮件是必填项',
    email_invalid_err: '电子邮件必须有效',
    phone_needed_err: '请输入有效的电话号码',
    website_required_err: '需要填写网站网址',
    invalid_website_err: '请输入有效的URL',
    location_required_err: '所在地为必填项',
    estimate_traffic_err: '请选择流量估算',
    describe_site_err: '请介绍您的网站',
    generic_err: '部分栏位需要您注意，请检查表单并修改错误',
    step1: '步骤1：注册',
    step1_desc: '注册仅需不到3分钟。填写以下表格，我们将在24小时内与您联系。',
    step2: '步骤2：分享您的自定义链接',
    step2_desc: '一旦被批准为Taption联盟成员，您将获得一个专属链接用于分享，确保所有推荐和佣金都被跟踪。',
    step3: '步骤3：赚钱',
    step3_desc: '通过您的链接订阅或购买分钟数即可赚取佣金—简单且有益！',
}