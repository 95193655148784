module.exports = {
    upload: '上傳',
    upload_language: '選擇此檔案的發聲語言',
    upload_method: '選擇影音轉文字的方式',
    delete_title: '刪除此文檔?',
    delete_description: '此操作無法撤消。',
    delete_ok: '刪除',
    delete_cancel: '取消',
    remember_selection: '記住我的選項',
    send_email: '完成時,寄E-mail來通知我',
    card_share: '分享',
    card_delete: '刪除',
    upload_media: '上傳影音檔',
    upload_error_title:'錯誤訊息',
    upload_error: '您需要購買更多上傳分鐘',
    transcribing_description: '我們正在處理您的檔案。 <br>處理時間大約是影音檔的總時間。<br>完成後，我們會通過電子郵件通知您。',
    transcribing: '處理中',
    preparing: '音檔轉影片準備中',
    update_title: '更改檔案名稱',
    transcript_title: '檔案名稱',
    add_more:'新增',
    
    upload_limit_exceeded_title:'超出影片長度最大上限',
    upload_limit_exceeded: '影片長度請保持在5小時以內.',

    initializing_description: '我們正在初始化您的檔案。<br>請稍後',
    initializing: '等待中',
    translating_description: '我們正在翻譯您的檔案。<br>需要幾分鐘的時間，請稍後',
    translating: '翻譯中',
    error_description: '如有需要，請與我們聯絡',
    error_info: '錯誤代碼: {0}',
    error: '處理中斷',
    error_minutes: '{0} <br>此影片分鐘數已自動返還<br>請刷新頁面來得到最新分鐘數',

    please_wait: '請稍候...',
    loading: '加載中...',
    upgrade_to_share: '請{0}後邀請朋友或同事來編輯此文檔',
    share_link_with: '分享此文檔 (不可編輯)',
    share_with_editable_permission: '分享此文檔 (可編輯)',
    // share_with: '輸入Taption用戶的電子信箱',
    share_with: '例: sample1@gmail.com,sample2@gmail.com',
    share_with_currently: '目前已分享',
    share_empty:'沒有可分享用戶，請到<a href="\\management" style="color:#2FC59F;"> 管理 </a>頁面新增分享用戶。',
    uploading: '上傳中',
    shared: '分享',
    thumbnail:'縮圖',
    upload_purpose: '文字分段方式',
    upload_number: '發聲者人數',
    upload_category: '影片類別',
    upload_clipped:'擷取影音片段',
    clipped_adjustments_desc:'微調秒數請用滑鼠點選拉軸後<br>使用鍵盤上左右鍵來調整',
    clipped_description:'從影音檔中擷取片段來辨識文字，更有效地利用分鐘數<br>支援Youtube連結,mp3及mp4類型影音檔',
    clipped_warning:'使用此功能將增加處理時間',
    clipped_length_warning:'影音檔必須大於1分鐘來使用此功能',
    purpose_description: '這讓我們可以以更精準的格式呈現，來幫助您進行編輯。',
    speaker_unsure: '不確定',
    // purpose_transcript:'以「{0}」來分段',
    // purpose_transcript_desc:'(例：給影片上字幕)',
    // purpose_caption:'以「{0}」型式分段',
    // purpose_caption_desc:'(例：導出會議記錄文字逐字稿)',
    // purpose_translate:'以「{0}」型式分段',
    // purpose_translate_desc:'(例：純文字逐字稿)',

    purpose_speakers:'AI自動<strong>標記</strong>影音裡說話<strong>人物並分段</strong>',
    purpose_transcript_desc:'(例：給影片上字幕，無標點符號)',
    purpose_captions:'AI自動以上<strong>字幕</strong>的形式來<strong>分段</strong>',
    purpose_caption_desc:'(例：導出會議記錄文字逐字稿，有標點符號)',
    purpose_article:'有標點符號的逐字稿 <strong>無特別分段方式</strong>',
    purpose_translate_desc:'(例：純文字逐字稿，有標點符號)',
    purpose_original:'會依照提供的<strong>文字檔案</strong>裡面的分段進行分段',
  
        
    speakers_time:'說話的人',
    captions_time:'字幕',
    article_time:'原文字檔案',
    original_time:'原文字檔案',
    none_time:'',
    
    speakers_time_desc:'AI自動標記不同人物的說話並分段',
    captions_time_desc:'AI自動以上字幕的形式來分段',
    article_time_desc:'會依照提供的文字檔案裡面的分段進行分段。',
    original_time_desc:'會依照提供的文字檔案裡面的分段進行分段。',
    none_time_desc:'',

    purpose_other:'我還沒決定',
    create:'開始轉換',
    home:'首頁',
    management_corp:'企業管理',
    management:'管理',
    account:'我的帳號',
    upgrade:'升級',
    faq:'常見問題',
    logout:'登出',
    return:'返回編輯頁面',
    drag_drop:'將要上傳的影音檔拉至此',
    select_from:'從電腦選取檔案',
    or_select_from: '或從以下清單選取',
    common_languages: '常用語言',
    additional_languages: '其他語言',

    // friendly_notice: '貼心叮嚀',
    // friendly_notice_desc: '請確保影片說話者的清晰度和避免背景音樂或噪音，來達到最高準確率'
    friendly_notice: '增進準確率訣竅',
    friendly_notice_desc0: '1.確保所選語言和影片本身相同，如需翻譯可在轉成文字後進行',
    friendly_notice_desc: '2.確保影片說話者的清晰度和避免背景音樂或噪音',
    friendly_notice_desc2: '3.請勿上傳MV或歌聲相關影片',
    friendly_notice_desc3: '4.音軌如有修改將無法順利轉檔',
    friendly_notice_desc4: '5.建議上傳MP4或MP3影音檔',
    
    google_drive_upgrade_title: '從Google Drive選取',
    google_drive_upgrade_desc: '訂閱後可以跳過檔案上傳過程，大幅度的減少您等待的時間!',
    trial_check_desc: '我同意在未購買分鐘或訂閱前，不得將字幕用在商業相關之用途。',
    
    browser_unsupported_title: '不支援此瀏覽器',
    browser_unsupported_desc: '請使用「電腦Chrome瀏覽器」來做字幕編輯。',
    browser_unsupported_mobile_desc: '請使用「Chrome」瀏覽器。',
    browser_translated_injected_title: '請關閉網頁翻譯或相關套件',
    browser_translated_injected_desc: '我們偵測到瀏覽器正在使用套件翻譯此網頁，請關閉網頁翻譯後使用我們內建翻譯來避免無可預測的錯誤 (例:編輯斷線無法儲存)',
    browser_unsupported_step1:'1. 點選右下角或右上角的 「...」或「 ⋮」 ',
    browser_unsupported_step2:'2. 點選外部瀏覽器開啟連結 ',

    individual_account: '個人帳號',
    corp_account_selection_desc:'當大量訂閱用戶將你加入後，可從選單登入其帳號',
    unsave_change_title:'您有未保存的更改',
    unsave_change_desc:'您確定要離開嗎?',
    label_name:'標籤名稱',
    new_label:'增加新標籤',
    select_label:'選擇標籤',
    label_dialog_header:'標籤管理',
    label_dialog_desc:'可直接編輯標籤順序，顏色，文字',
    label_empty:'無標籤',
    label_all:'所有檔案',
    label_select_header:'標籤選擇',
    label_limit_error:'已達標籤上限，請升級到「高級訂閱」或「大量訂閱」來取消此限制',
    segment_sample:'輸出範例',
    zoom_no_data:'過去2年沒有任何影片...',
    zoom_desc:'僅會顯示最近2年內的影片',
    zoom_loading:'載入中...',
    import_srt:'匯入SRT',
    srt_header:'文字生成方式',
    srt_select:'選擇SRT檔案',
    srt_desc:'等待SRT檔案中...',
    import_txt:'匯入TXT純文字',
    txt_select:'選擇文字檔案',
    txt_desc:'等待TXT檔案中...',
    direct_link:'<span class="directlink-text-zh">影音檔網址連接</span>',

    multi_upload:'訂閱用戶一次可上傳多個檔案',
    category:'影片類別',
    max_char_header:'每段最大字符',
    max_char_desc:'轉譯後的文字依據此數值來分段，<br>並且每段不會超過此字符數。<br>建議數值：<br>中文/日文/韓文 : 20<br>其他語言:95',
    language_tooltip:'選擇影片或錄音檔的發生語言，<br>如希望轉成其他語言要先轉成文字後進入編輯平台再進行翻譯。<br><br> 如下方<span style="color:#2FC59F">「字幕字幕生成方式」</span>選擇了<span style="color:#2FC59F">「匯入SRT時間字幕檔」</span>，<br>請選擇SRT字幕檔的語言。',
    import_txt_tooltip:'我們會將您提供的文字加上相對應的時間軸。<br><strong>{0}</strong> {1}',


  home_notice_title:'省下更多分鐘數',
  home_notice_desc:'新的分鐘計費方式將免去多餘的秒數!<div class="desc">例: 上傳一支15分59秒的影片只會扣除15分鐘. (上傳一支小於1分鐘的影片將不會扣除分鐘數 🙂)</div>',
  no_name:'未命名',
  upload_language_reminder_title:'請確認所選擇的語言與影片的語音相同',
  upload_language_reminder_desc:'例：影片本身是英文的影片，語言請選擇「英文」。<br>如果希望翻譯影片字幕、逐字稿，請先轉成文字後進入編輯平台。<br>然後透過右上方的「翻譯」來翻譯影片。'

    
    
  }