module.exports = {
    convert_to_text_title_meta:"Resumidor de vídeo por IA online: Adicione tópicos, resuma reuniões e crie capítulos do YouTube com um clique",
    convert_to_text_desc_meta:"Nossa ferramenta de análise de IA permite resumir conteúdo de forma eficiente, identificar os principais oradores, criar scripts de narração e adicionar capítulos e timestamps no YouTube. Explore nossa variedade de modelos, incluindo resumidores de vídeo, geradores de timestamps do YouTube e geradores de tópicos de vídeo com timestamps. Experimente com um teste grátis hoje mesmo!",
    convert_to_text_title:"Análise por IA",
    convert_to_text_desc:"Resumidor de vídeo, capítulos do YouTube, resumo de reuniões",
    start_your_trial:"Comece o seu teste grátis!",
    hero_img:"./11_English.webp",

    three_step_title:"3 passos para transcrever",
    step_one:"Faça uma pergunta ou dê um comando",
    step_one_img:"./aianalysis_step1_en_US.webp",
    step_one_desc:"Pode escolher se deseja que a ferramenta de IA para vídeos resuma seu conteúdo, identifique os principais oradores, sugira scripts de narração e adicione capítulos e timestamps do YouTube.",
    
    step_two:"Escolha de comandos pré-definidos",
    step_two_img:"./aianalysis_step2_en_US.webp",
    step_two_desc:"A Análise por IA oferece modelos pré-definidos, como gerador de resumos de vídeo, gerador de timestamps para o YouTube e gerador de tópicos de vídeo com timestamps.",

    step_three:"Resuma no idioma que preferir",
    step_three_img:"./aianalysis_step3_en_US.webp",
    step_three_desc:"Independentemente do idioma original do vídeo, pode resumir ou dar comandos no idioma que preferir.",

    feature_tutorial_title:'Análise e resumo de vídeos com Taption',
    feature_tutorial_desc:"A funcionalidade \"Análise por IA\" é uma ferramenta poderosa, projetada para análise aprofundada de conteúdo de vídeo. Os utilizadores podem fazer o upload de vídeos para um resumo completo ou usar modelos pré-definidos como resumo de vídeo, gerador de tópicos de IA, timestamps e capítulos do YouTube para uma análise rápida, sem necessidade de comandos manuais. Isso facilita a compreensão e interação com os pontos principais do vídeo.",
    feature_tutorial_poster_url:'./aianalysis_poster_en_US.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/PWOWwbHQCUM?autoplay=1',

    feature_why_image:'./ai_template_demo_en.webp',
    feature_extra_title:"Como usar a Análise por IA e exemplos",
    feature_extra_desc:"Para aproveitar ao máximo a funcionalidade de Análise por IA, a chave está em criar prompts precisos. Veja como pode formular as suas solicitações para obter insights significativos: <ul><li><strong>Prompt para Resumo</strong>: \"Resuma o vídeo em 300 palavras.\" Este prompt é ideal para uma visão geral rápida.</li><li><strong>Prompt para Itens de Ação</strong>: \"Identifique os itens de ação da reunião.\" Este prompt ajuda a destacar tarefas e responsabilidades.</li><li><strong>Prompt para Feedback de Coaching</strong>: \"Seja um coach útil. Forneça uma análise da transcrição e sugira áreas de melhoria com citações exatas. Não inclua introdução.\"</li><li><strong>Script de vídeo</strong>: Ajude-me a criar um script para um vídeo explicativo de cerca de 5-10 minutos sobre este filme, focando na trama e nas ideias que o autor deseja transmitir.</li></ul>Ao criar um prompt, comece com um resumo geral e depois forneça detalhes adicionais.",

    feature_what_title:"Como adicionar timestamps e resumir o seu vídeo no YouTube?",
    feature_what_desc: "<ul>\n  <li>Faça login no <a href=\"https://www.taption.com/login\">Taption</a></li>\n  <li>Clique em \"Carregar\" para fazer o upload do seu vídeo em MP4 ou cole um link do YouTube para transcrever</li>\n  <li>Quando a transcrição estiver concluída, acesse a plataforma de edição para editar ou traduzir conforme necessário</li>\n  <li>Clique no botão \"Análise por IA\" na parte inferior</li>\n  <li>Clique em \"Analisar\" para adicionar capítulos ao YouTube e gerar um resumo do vídeo</li>\n</ul>"
}
