module.exports = { 
    error_title: '404',
    error_desc: '찾으시는 페이지가 존재하지 않습니다 😟',
    share_network_error: '권한이 없거나 네트워크 문제가 발생했습니다.',
    permission_error: '이 작업을 수행할 수 있는 권한이 없습니다. 계정 관리자에게 문의하십시오.',
    share_email_exist: '이미 등록된 이메일입니다.',
    user_not_found: '유저 정보를 찾을 수 없습니다.',
    email_invalid: '이메일이 유효하지 않습니다.',
    email_not_registered: '이 이메일은 Taption에 등록된 이메일이 아닙니다',
    email_duplicate: '이미 계정에 추가된 이메일 주소입니다.',
    email_slots_required: '멤버를 추가하기 위한 슬롯이 부족합니다. 슬롯을 구매해주세요.',
    youtube_url_invalid: 'Youtube주소가 유효하지 않습니다',
    youtube_url_required: 'Youtube주소는 필수입력항목입니다',
    directlink_url_invalid: '현재 제공된 URL 링크를 지원하지 않습니다. 지원을 받으려면 help@taption.com으로 문의하세요.',
    directlink_url_required: '동영상 파일의 URL 링크를 입력하세요',
    required: '필수항목',
    size_error: '파일 사이즈는 {0}GB를 초과할 수 없습니다.',
    size_error_mb: '파일 사이즈는 {0}MB를 초과할 수 없습니다.',
    verify_email: '계정 활성화를 위한 인증메일이 발송되었습니다. 메일 수신함을 확인해주세요. 확인 메일을 받지 못하셨다면, [스팸메일]을 확인해주세요.',
    email_must_be_valid: '유효한 이메일을 입력해주세요',
    password_not_match: '비밀번호가 일치하지 않습니다',
    invalid_youtube: '영상을 불러올 수 없습니다. 다른 영상을 사용하거나 나중에 다시 시도해주세요.',
    unsupported_youtube: '제한된 영상입니다. 다른 영상을 시도해주세요.',
    unsupported_direct_video: '동영상 링크를 읽을 수 없습니다<br>(라이브 링크는 지원되지 않습니다)',
    must_be_more_than: '{0}자 이장 입력 필수입니다.',
    must_be_less_than: '{0}자를 초과할 수 없습니다.',
    must_be_more_than_words: '{0}자 이장 입력 필수입니다.',
    trial_limit_error: '시험판은 15분의 시간 제한이 있습니다. 시간 제한을 없애시려면 이용시간을 구매하거나 유료플랜을 구독하세요.',
    language_required: '언어설정은 필수값입니다.',
    title_required: '제목은 필수항목입니다',
    title_length: '제목은 최대 80자까지 입력가능합니다',
    purpose_required: '목적은 필수항목입니다',
    speakers_number_required: '발화자 수는 필수입력값입니다',
    category_required: '카테고리는 필수항목입니다',
    method_required: '미디어를 받아쓰기할 방법을 선택해주세요',
    max_length: '{0}자 미만이어야 합니다.',
    max_char_required: '{0}에서 1000 사이의 값을 입력하세요 (권장값:{1})',
    insufficient_fund_title: '이용가능시간이 부족합니다',
    insufficient_ai_desc: 'AI 포인트가 부족합니다. AI 포인트를 더 구매해 주세요',
    exceed_trial_limit_title: '영상 재생시간이 시험판 제한을 초과합니다',
    file_too_large_title: '영상 파일 크기가 너무 큽니다',
    not_supported_type_title: '지원되지 않는 영상 포맷입니다',
    insufficient_fund_tip: '추가 이용시간을 구매한 후 다시 시도해주세요.',
    exceed_trial_limit_tip: '시험판은 15분 이내의 무료 영상 1개까지 제공합니다. 제한을 없애시려면 이용시간을 구매하거나 프리미엄 플랜으로 업그레이드하세요.',
    file_too_large_tip: '파일 사이즈는 2GB를 초과할 수 없습니다',
    not_supported_type_tip: '지원되는 영상 포맷: mov, avi, wmv, flv, mpeg, ogg, mp4',
    media_no_audio: '제공된 동영상 파일에 소리가 없습니다.',
    network_upload_fail_title: '네트워크가 불안정합니다. 다시 시도하거나 Google 드라이브를 사용하세요.',
    network_upload_fail_tip: '업로드 시, Google 드라이브에서 미디어 파일을 선택할 수 있습니다.',
    transcribe_prepare_fail_title: '서버가 사용 중입니다. 나중에 다시 시도하십시오.',
    transcribe_upload_fail_title: '서버가 사용 중입니다. 나중에 다시 시도하십시오.',
    transcribe_merge_fail_title: '서버가 사용 중입니다. 나중에 다시 시도하십시오.',
    transcribe_progress_fail_title: '서버가 사용 중입니다. 나중에 다시 시도하십시오.',
    transcribe_result_fail_title: '서버가 사용 중입니다. 나중에 다시 시도하십시오.',
    transcribe_error_fail_title: '서버가 사용 중입니다. 나중에 다시 시도하십시오.',
    directlink_url_error_title: 'URL 링크 파일을 읽을 수 없습니다',
    directlink_url_error_tip: '이 URL 링크의 권한이 공개인지 확인하세요',
    exceed_trial_usage_title: '시험판 이용시간을 초과했습니다',
    exceed_trial_usage_tip: '서비스를 계속 이용하시려면 이용시간을 구매하거나 유료플랜을 구독하세요',
    need_pay_title: '구독이 일시중지되었습니다',
    need_pay_tip: '서비스를 계속하려면 결제 문제를 해결해 주세요',
    exceed_usage_title: '이용이 초과되었습니다',
    exceed_usage_tip: '서포트팀으로 문의해주세요.',
    file_too_long_title: '미디어 재생시간이 초과되었습니다',
    file_too_long_tip: '2시간 이내의 미디어 파일을 사용하세요',
    download_youtube_video_title: 'Youtube 연결에 실패했습니다. 다시 시도해주세요',
    download_youtube_video_tip: '다시 업로드해주세요. 문제가 해결되지 않는 경우에는 다른 영상을 업로드하거나 서포트팀으로 문의해주세요.',
    video_corrupted_title: '이 파일을 읽을 수 없습니다. 파일이 정상적으로 재생되는지 확인하세요.',
    video_corrupted_tip: '질문이 있으시면 help@taption.com으로 이메일을 보내주세요',
    system_maintanance_title: '주의',
    system_maintanance: '시스템 정비중...',
    error_add_title: '에러',
    error_add_desc: '수정된 구절을 맞춤사전에 등록하지 못했습니다.',
    error_warning: '주의',
    warning_no_search_found: '"{0}"를 찾을 수 없습니다',
    time_message: '자막 타임라인에 <strong>{0}/strong> 개의 시간 중복이 있어 내보내기 후 타사 소프트웨어에서 정상적으로 읽지 못할 수 있습니다.',
    time_message1: '자막 타임라인에 <strong>{0}</strong> 개의 너무 짧은 시간 구간이 있어 주의가 필요합니다.',
    time_message2: '(타임라인의 주황색 상대 위치, 좌측 하단의 "시간 편집기"를 사용하여 조정)',
    overlap: '타임스탬프가 전후 다른 타임스탬프와 겹칩니다',
    short: '자막의 재생시간이 0.1초보다 짧습니다',
    error_merge_one: '1개의 영상이 이미 내보내기 중입니다. 내보내기가 완료될때까지 기다렸다가 다시 시도하세요.',
    retry_upload: '재시도',
    label_required: '최소 하나의 라벨을 선택하세요',
    label_name_required: '라벨명은 필수항목입니다',
    label_name_duplicate: '같은 라벨명이 이미 존재합니다',
    srt_file_required: 'SRT파일은 필수값입니다',
    srt_file_invalid: '유효하지 않거나 내용이 없는 SRT파일입니다',
    txt_file_required: 'TXT파일은 필수값입니다',
    txt_file_invalid: '유효하지 않거나 내용이 없는 TXT파일입니다',
    media_file: '미디어 파일은 필수값입니다',
    media_srt_mismatch: '선택된 미디어 파일의 재생시간이 SRT파일보다 짧습니다',
    subscription_pay_failed_title: '유료플랜 결제에 실패했습니다',
    subscription_pay_failed_desc: '아래 중 하나를 시도해주세요:',
    subscription_pay_failed_resolve1: '계정 정보에서 새로운 신용카드 정보를 입력하여 결제 정보를 변경해주세요',
    subscription_pay_failed_resolve2: '신용카드 잔액이 충분한지 확인하거나 신용카드 발행회사로 문의 후 support@taption.com로 연락해주세요',
    subscription_pay_failed_resolve3: '구독을 취소합니다',
    maximum_videos_title: '무료 계정은 최대 {0}개의 동영상을 저장할 수 있습니다.',
    maximum_videos_title1: '기본 계정은 최대 {0}개의 동영상을 저장할 수 있습니다.',
    maximum_videos_resolve1: '총 개수를 {0}개 미만으로 유지하려면 기존 동영상을 삭제하세요.',
    maximum_videos_resolve2: '<a href="https://app.taption.com/upgrade">요금제</a> 중 하나로 구매 또는 업그레이드하세요.',
    manual_warning: '"수동 입력"을 선택하면 자동으로 텍스트로 변환되지 않습니다<br>편집 페이지에 해당하는 빈 비디오 텍스트를 제공합니다. <br>텍스트를 자동으로 변환해야 하는 경우 <strong>자동 받아쓰기</strong>을 선택하십시오. ',
    txt_mismatch_error: '동영상에 대해 선택한 언어가 가져온 텍스트 파일과 다릅니다.<br>최상의 번역 효과를 얻으려면 동일한지 확인하세요. ',
    txt_invalid_error: '가져온 텍스트 파일의 인코딩을 감지할 수 없습니다.<br>깨진 문자 또는 기타 예측할 수 없는 오류를 방지하기 위해 텍스트 파일 자체가 올바른 인코딩인지 확인하십시오.<br><span style="font-size:12px">(<strong>예</strong>: 텍스트 파일 열기, <strong>다른 이름으로 저장</strong>→<strong>인코딩</strong> <strong>UTF-8</strong> 선택)</span>',
    language_mismatch_error: '업로드하신 동영상의 언어와 선택하신 언어가 일치하지 않을 수 있습니다. 이는 전사(텍스트 변환) 정확도를 떨어뜨리고 다른 언어로의 번역에 어려움을 줄 수 있습니다.<br><br>동영상을 다른 언어로 번역하려면, 업로드 시 동영상의 실제 언어와 일치하는 옵션을 선택해야 합니다. 그 후, 오른쪽 상단에 있는 <v-btn style="padding:0px 20px;margin:0px 5px;" class="translate-btn"><i style="font-size:14px;" aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-translate"></i><span class="text">번역</span></v-btn> 버튼을 통해 번역을 진행할 수 있습니다.',
    "auth/invalid-email": '이메일 주소가 유효하지 않습니다.',
    "auth/wrong-password": '비밀번호가 정확하지 않거나 존재하지 않는 계정입니다.',
    "auth/email-already-in-use": '이미 사용중인 이메일 주소입니다.',
    "auth/user-not-found": '비밀번호가 정확하지 않거나 존재하지 않는 계정입니다.',
    need_payment_title: '구독 일시 중지됨',
    need_payment_tip: '결제 문제를 해결하여 서비스를 계속 이용하세요.',
    confirm_password_desc: '비밀번호를 다시 입력하세요',
    confirm_password_not_match: '비밀번호가 일치하지 않습니다',
}