module.exports = { 
    video_quality_reminder: 'Warum ist die Qualität dieses Videos verringert?',
    video_quality_tooltip: 'Der Inhaber dieses Transkripts muss ein Abonnement abschließen, um es in seiner ursprünglichen Qualität anzuzeigen.',
    export: 'Exportieren',
    speaker: 'Sprecher',
    full_screen: 'Vollbild',
    full_screen_exit: 'Vollbildmodus beenden',
    play: 'Abspielen (TAB)',
    pause: 'Stopp (TAB)',
    captions_overlay_on: 'Untertitel: <span style="color:#20F7C4">Ein</span><br><span>(Schieben zum Positionieren)</span>',
    captions_overlay_off: 'Untertitel: <span style="color:rgb(230, 118, 117)">Aus</span><br><span>(Schieben zum Position anpassen)</span>',
}