module.exports = { 
    tell_us_about_yourself: '자기소개를 해주세요.',
    first_name: '이름',
    last_name: '성씨',
    business_name: '업체 이름 (해당되는 경우)',
    location: '어디에 거주하시나요? (도시, 주, 국가)',
    contact: '연락처 정보',
    email: '이메일 주소',
    phone: '전화번호',
    linkedin_url: '링크드인 URL',
    social_url: '소셜 미디어 URL',
    tell_us_about_site: '사이트에 대해 알려주세요',
    website_url: '웹사이트 URL',
    traffic: '예상 월간 트래픽',
    what_site_about: '귀하의 사이트는 무엇에 관한 것인가요?',
    anything_else: '다른 필요한 정보가 있습니까?',
    must_agree: '계속하려면 동의해야 합니다.',
    read_agree: '저는 읽고 이에 동의합니다.',
    submit_success: '관심을 가져주셔서 감사합니다! 귀하의 신청이 제출되었습니다. 저희 팀이 최대한 빨리 연락드리겠습니다.',
    name_required_err: '이름은 필수 항목입니다.',
    name_character_limit_err: '이름은 50자 미만이어야 합니다.',
    email_required_err: '이메일이 필요합니다',
    email_invalid_err: '이메일 주소가 유효해야 합니다.',
    phone_needed_err: '유효한 전화번호를 입력하세요',
    website_required_err: '웹사이트 URL이 필요합니다',
    invalid_website_err: '유효한 URL을 입력하세요',
    location_required_err: '위치는 필수 항목입니다',
    estimate_traffic_err: '트래픽 추정치를 선택하십시오',
    describe_site_err: '사이트를 설명해 주세요.',
    affiliate: '제휴 마케팅',
    page_title: '가입 Taption 제휴 마케팅',
    page_description: 'Taption을 새 고객에게 소개하세요. 귀하의 전용 제휴 마케팅 링크를 공유하여, 성공적인 추천마다 수수료를 벌어보세요. 함께 성장합시다!',
    affiliate_tos: '연합 약관 및 조건',
    submit: '제출 신청',
    generic_err: '양식을 검토하고 제출하기 전에 강조 표시된 오류를 수정하십시오.',
    step1: '1단계: 가입하기',
    step1_desc: '가입하는 데 3분도 걸리지 않습니다. 아래 양식을 작성해 주시면 24시간 이내에 연락드리겠습니다.',
    step2: '2단계: 맞춤 링크 공유하기',
    step2_desc: 'Taption 제휴자로 승인되면 공유할 수 있는 고유 링크를 받게 되며, 이를 통해 모든 추천 및 수수료가 추적됩니다.',
    step3: '3단계: 수익 창출',
    step3_desc: '귀하의 링크를 통해 누군가가 구독하거나 분을 구매할 때마다 커미션을 받으세요—간단하고 보람 있는 방법입니다!',
}