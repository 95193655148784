module.exports = {
    error_title:'404',
    error_desc:'The page you are looking for does not exist 😟',

    share_network_error: 'You do not have the permission or this is a network problem.',
  permission_error: 'You do not have the permission to perform this action, please contact the account administrator.',
  share_email_exist: 'Email exist.',
  user_not_found: 'Could not find the user.',
  email_invalid: 'Email invalid',
  email_not_registered: 'Not a Taption registered email',
  email_duplicate: 'You have already added this email to your account',
  //email_slots_required: 'You do not have seats for new members, please purchase more seats',
  email_slots_required: 'Please purchase more seats to add new members.',
  youtube_url_invalid: 'Youtube url is invalid',
  youtube_url_required: 'Youtube url is required',
  directlink_url_invalid: 'We do not support the provided url at the moment, please email us at at help@taption.com to request support',
  directlink_url_required: 'Url is required',
  required:'Required',
  size_error:'Your file size cannot be larger than {0}GB.',
  size_error_mb:'Your file size cannot be larger than {0}MB.',
  verify_email:'Please check your email to activate your account.If you do not receive the confirmation message, please check your [Spam Folder].',
  email_must_be_valid:'E-mail must be valid',
  password_not_match:'Password does not match',
  invalid_youtube:'Unable to retrieve video, please try another video or try again later.',
  unsupported_youtube:'This video is restricted, please try another video.',
  unsupported_direct_video:'This video is restricted<br>(Live video link not supported)',
  must_be_more_than:'Must be more than {0} characters',
  must_be_less_than:'Must be less than {0} characters',
  must_be_more_than_words:'Must be more than {0} words',
  trial_limit_error:'Trial version has a 15 minute limit. Please purchase minutes or subscribe to remove this limit.',
  language_required:'Language is required',
  title_required:'Title is required',
  title_length:'Title must be at most 80 characters long',
  purpose_required:'Purpose is required',
  speakers_number_required:'Number of speakers is required',
  category_required:'Category is required',
  method_required:'Please select a way to transcribe your media',
  max_length:'Must be less than {0} characters',
  max_char_required:'Please enter a value between {0} and 1000. (Suggested value:{1})',

  insufficient_fund_title:'Not enough minutes',
  insufficient_ai_desc:'Not enough credits, purchase more AI credits to continue.',
  exceed_trial_limit_title:'Video length exceed trial limit',
  file_too_large_title:'Video size too large',
  not_supported_type_title: 'Video type not supported',
  insufficient_fund_tip:'Purchase more minutes and try again.',
  exceed_trial_limit_tip:'Trial gives you 1 free video under 15 minutes. Purchase minutes or upgrade to Premium to remove this limit',
  file_too_large_tip:'File size cannot exceed 2GB',
  not_supported_type_tip: 'Supported video format: mov, avi, wmv, flv, mpeg, ogg, mp4',
  media_no_audio:'The provided media has no audio',

  network_upload_fail_title: 'Your network is unstable, please try again or use Google Drive',
  network_upload_fail_tip: 'You can select your media file from Google Drive when uploading.',
  transcribe_prepare_fail_title: 'Server is busy, please try again later',
  transcribe_upload_fail_title: 'Server is busy, please try again later',
  transcribe_merge_fail_title: 'Server is busy, please try again later',
  transcribe_progress_fail_title: 'Server is busy, please try again later',
  transcribe_result_fail_title: 'Server is busy, please try again later',
  transcribe_error_fail_title: 'Server is busy, please try again later',
  directlink_url_error_title: 'The provided link is not valid',
  directlink_url_error_tip: 'Please ensure the permission for the link is available to the general public',

  exceed_trial_usage_title: 'Exceed trial usage',
  exceed_trial_usage_tip: 'Purchase minutes or subscribe to continue service',

  need_payment_title: 'Subscription paused',
  need_payment_tip: 'Please resolve the payment issue to continue service',

  exceed_usage_title: 'Exceed usage',
  exceed_usage_tip: 'Please contact our support.',

  file_too_long_title: 'Media is too long',
  file_too_long_tip: 'Please keep your media length within 2 hours',

  download_youtube_video_title: 'Unable to download this video, please try another video or try again later.',
  download_youtube_video_tip: 'Please try uploading again. If the problem persists, try uploading a different video or contact our support.',

  video_corrupted_title:'We were unable to read this file, please ensure the file is valid',
  video_corrupted_tip:'Ensure the file is valid or contact us at help@taption.com',

  //system_maintanance: 'System maintenance... Estimate live time: September 21st 9:00pm PST',
    system_maintanance_title: 'Warning',
  system_maintanance: 'System maintenance. Please try again later by refreshing the page.',

  error_add_title:'Error',
  error_add_desc:'Could not add the corrected phrase into dictionary.',
  error_warning:'Warning',
  warning_no_search_found:'Cannot find "{0}"',

  //time_message:'You have {0} timestamps that needs attention, this could cause third party tools to load unsucessfully.<br>(Hover over the orange spot on the media slider, correct with「Timeline Editor」on lower left)',
  time_message: 'There are <strong>{0}</strong> instances of overlapping subtitle timestamps, which may cause third-party software to read them incorrectly after export.',
time_message1: 'There are <strong>{0}</strong> instances of subtitle timestamps that are too short and require attention.',
time_message2: '(Orange-colored timestamp positions can be adjusted using the <strong>Timeline Editor</strong> in the bottom left corner)',

  overlap:'Timestamps overlaps with neighbors',
  short:'This caption\'s duration is less than 0.1 seconds',

  error_merge_one: 'You have one video exporting in progress, please wait until completion and try again.',
  retry_upload:'Retry',
  label_required:'Please select at least one label',
  label_name_required:'Label name is required',
  label_name_duplicate:'Label name already exist',
  srt_file_required:'SRT file required',
  srt_file_invalid:'Invalid or empty SRT file',
  txt_file_required:'TXT file required',
  txt_file_invalid:'Invalid or empty TXT file',
  media_file:'Media file required',
  media_srt_mismatch:'Selected media is shorter than selected SRT file',

  subscription_pay_failed_title:'Your subscription payment failed',
  subscription_pay_failed_desc:'Please do one of the followings:',
  subscription_pay_failed_resolve1:'Update your payment method with a new credit card under Account',
  subscription_pay_failed_resolve2:'Ensure there is enough fund in your card or contact the credit card issuer then notify us at support@taption.com',
  subscription_pay_failed_resolve3:'Cancel your current subscription',

  maximum_videos_title:'Free account can store a maximum of {0} videos',
  maximum_videos_title1:'Basic account can store a maximum of {0} videos',
  maximum_videos_resolve1:'Delete existing videos to maintain the total number of videos below {0}',
  maximum_videos_resolve2:'Purchase or upgrade to one of our <a href="https://app.taption.com/upgrade">plans</a> to extend or remove the limits',

  manual_warning:'Selecting <strong>Manual</strong> would give you an empty transcript which requires you to enter your own text. <br>If getting automated transcript is the purpose please select <strong>Automated transcription</strong>.',
  txt_mismatch_error:'The selected language does not match the input text file.<br>Please ensure the languages match for best result.',
  txt_invalid_error:'The input file\'s encoding could not be detected.<br>Please ensure the file is saved in its correct encoding.<br><span style="font-size:12px">(<strong>Example</strong>: Open your text file, <strong>Save as</strong>→<strong>Encoding</strong> select <strong>UTF-8</strong>)</span>',
  language_mismatch_error: 'The language of the uploaded video may not match the language you selected. This could reduce the accuracy of the transcription and lead to difficulties in translating to other languages.<br><br>If you want to translate the video into another language, please make sure to select the correct language of the video during the upload process. Once complete, use the <v-btn style="padding:3px 20px;margin:0px 5px;text-transform: capitalize;letter-spacing:0px;" class="translate-btn"><i style="font-size:14px;" aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-translate"></i><span class="text">Translate</span></v-btn>  button located at the top right corner on this page to proceed with the translation.',

  "auth/invalid-email":'Your email is invalid.',
  "auth/wrong-password":'Your password is incorrect or this account doesn’t exist.',
  "auth/email-already-in-use":'The email address is already in use by another account.',
  "auth/user-not-found":'Your password is incorrect or this account doesn’t exist.',

  confirm_password_desc: 'Please re-enter your password',
  confirm_password_not_match: 'The password does not match',
}