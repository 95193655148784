import Vue from 'vue'
import VueRouter from 'vue-router'
import userService from '../connections/user.service';
const NavBar = () => import('@/components/NavBar')

Vue.use(VueRouter)
const allowedLanguages =  process.env.VUE_APP_BROWSER_SUPPORTED_LANGUAGE.split(',');
const routes = [
  { 
    path: '/', 
    name: 'taption',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/LandingPageEnglish.vue'),
      navbar: NavBar
    }
  },
  {
    path: '*/en-US',
    name: 'taptionen',
    redirect: () => {
      return '/';
    }
  },
  {
    path: '/zh',
    name: 'taptiontw',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/LandingPageZh.vue'),
      navbar: NavBar
    }
  },
  // {
  //   path: '/zh-CN',
  //   name: 'taptioncn',
  //   components: {
  //     default: () => import(/* webpackChunkName: "detail" */ '../views/LandingPageZhCn.vue'),
  //     navbar: NavBar
  //   }
  // },
  {
    path: '/jp',
    name: 'taptionjp',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/LandingPageJp.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/kr',
    name: 'taptionkr',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/LandingPageKr.vue'),
      navbar: NavBar
    }
  },

  {
    path: '/es',
    name: 'taptiones',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/LandingPageEs.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/de',
    name: 'taptionde',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/LandingPageDe.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/fr',
    name: 'taptionfr',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/LandingPageFr.vue'),
      navbar: NavBar
    }
  },

  {
    path: '/id',
    name: 'taptionid',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/LandingPageId.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/pt',
    name: 'taptionpt',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/LandingPagePt.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/share/:id',
    name: 'share',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/Share.vue')
    }
  },
  {
    path: '/pricing',
    name: 'pricing',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/Pricing.vue'),
      navbar: NavBar
    }
  },
  
  {
    path: '/pricing/:language',
    name: 'pricinglang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/Pricing.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/faq',
    name: 'faq',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/FAQ.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/faq/:language',
    name: 'faqlang',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/FAQ.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },

  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "about" */ '../views/Privacy.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: "about" */ '../views/Signup.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    
     component: () => import(/* webpackChunkName: "about" */ '../views/Terms.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/Blog.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/affiliate',
    name: 'affiliate',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/AffiliateView.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/affiliate/:language',
    name: 'affiliatelang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/AffiliateView.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/invite/:id',
    name: 'invite',
    component: () => import(/* webpackChunkName: "about" */ '../views/Invite.vue')
  },
  {
    path: '/invite/:id/:language',
    name: 'invite',
    component: () => import(/* webpackChunkName: "about" */ '../views/Invite.vue')
  },
  {
    path: '/login',
    name: 'login',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/Login.vue')
    },
    beforeEnter: async (to, from, next) => {
      const sessionCookie = await userService.checkCookie();

      const hasCookie = sessionCookie.data && sessionCookie.data.hasCookie;
      if(hasCookie){
        userService.navigateToAppLoginPage();
      }
      else{
        await userService.getSignOutPromise();
        next();
      }
    }
  },
  {
    path: '/login/:language',
    name: 'loginlang',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/Login.vue')
    },
    beforeEnter: async (to, from, next) => {
      const sessionCookie = await userService.checkCookie();

      const hasCookie = sessionCookie.data && sessionCookie.data.hasCookie;
      if(hasCookie){
        userService.navigateToAppLoginPage();
      }
      else{
        await userService.getSignOutPromise();
        next();
      }
    }
  },
  {
    path: '/blog/:language',
    name: 'blogpostlang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/Blog.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/blog/:language/:post',
    name: 'blogpost',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/BlogPost.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      allowedLanguages.push('en');
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/convert-to-text',
    name: 'converttext',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/ConvertToText.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/convert-to-text/:language',
    name: 'converttextlang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/ConvertToText.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/add-subtitles',
    name: 'addsubtitles',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/AddSubtitles.vue'),
      navbar: NavBar
    }
  }, {
    path: '/add-subtitles/:language',
    name: 'addsubtitleslang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/AddSubtitles.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/ai-analysis',
    name: 'aianalysis',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/AIAnalysis.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/ai-analysis/:language',
    name: 'aianalysislang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/AIAnalysis.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/translate',
    name: 'translate',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/Translate.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/translate/:language',
    name: 'translatelang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/Translate.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/speaker',
    name: 'speaker',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/Speaker.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/speaker/:language',
    name: 'speakerlang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/Speaker.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/audio-to-video',
    name: 'audiotovideo',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/AudioToVideo.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/audio-to-video/:language',
    name: 'audiotovideolang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/AudioToVideo.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/collaborate',
    name: 'collaborate',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/Collaborate.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/collaborate/:language',
    name: 'collaboratelang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/Collaborate.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/transcripts-to-subtitles',
    name: 'transcriptstosubtitles',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/TranscriptsToSubtitles.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/transcripts-to-subtitles/:language',
    name: 'transcriptstosubtitleslang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/TranscriptsToSubtitles.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/transcripts-to-speakers',
    name: 'transcriptstospeakers',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/TranscriptsToSpeakers.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/transcripts-to-speakers/:language',
    name: 'transcriptstospeakerslang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/TranscriptsToSpeakers.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import(/* webpackChunkName: "about" */ '../views/Verify.vue')
  },
  { 
    path: '/error', 
    name: 'error',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/ErrorPage.vue'),
      navbar: NavBar
    }
  },
  { 
    path: '*', 
    name: 'errorpage',
    redirect: () => {
      return '/error';
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
});

export default router
