module.exports = {
  upload: 'Unggah',
  upload_language: 'Pilih bahasa yang diucapkan',
  upload_method: 'Pilih metode transkripsi',
  delete_title: 'Hapus transkrip?',
  delete_description: 'Tindakan ini tidak dapat dibatalkan.',
  delete_ok: 'Hapus',
  delete_cancel: 'Batal',
  remember_selection: 'Ingat pilihan saya',
  send_email: 'Kirim email kepada saya ketika selesai.',
  card_share: 'Bagikan',
  card_delete: 'Hapus',
  upload_media: 'Unggah media',
  upload_error_title:'Kesalahan',
  upload_error: 'Anda tidak memiliki cukup menit.',
  transcribing_description: 'Kami sedang mentranskripsi file Anda. <br>Waktu pemrosesan memakan waktu sekitar<br>durasi file tersebut.<br> Kami akan mengirimkan email kepada Anda ketika siap.',
  transcribing: 'Memproses',
  preparing: 'Mempersiapkan',
  update_title: 'Perbarui judul',
  transcript_title: 'Judul',
  add_more:'Tambah',

  upload_limit_exceeded_title:'Melebihi panjang maksimum',
  upload_limit_exceeded: 'Harap batasi panjang video Anda kurang dari 5 jam.',

  initializing_description: 'Kami sedang memproses file Anda. <br>Ini akan memakan waktu beberapa menit, harap tunggu.',
  initializing: 'Menunggu',
  translating_description: 'Kami sedang menerjemahkan file Anda. <br>Ini akan memakan waktu beberapa menit, harap tunggu.',
  translating: 'Menerjemahkan',
  error_description: 'Silakan hubungi kami untuk informasi lebih lanjut',
  error_info: 'Kode Kesalahan: {0}',
  error: 'Terjadi kesalahan',
  error_minutes: '{0} <br> Menit Anda telah dikembalikan.<br> Segarkan browser Anda untuk melihat menit terbaru.',

  please_wait: 'Mohon tunggu...',
  loading: 'Memuat...',
  upgrade_to_share: 'Silakan {0} untuk mengundang rekan kerja berbagi dan mengedit transkrip Anda.',
  share_link_with: 'Bagikan dengan izin hanya baca',
  share_with_editable_permission: 'Bagikan dengan izin dapat diedit',
  share_with: 'contoh: sample1@gmail.com,sample2@gmail.com',
  share_with_currently: 'Saat ini dibagikan dengan',
  share_empty:'Silakan pergi ke <a href="\\management" style="color:#2FC59F;">Manajemen</a> untuk menambahkan pengguna yang dapat dibagikan.',
  uploading: 'Mengunggah',
  shared: 'Dibagikan',
  thumbnail:'Gambar mini',
  upload_purpose: 'Pilih format keluaran Anda',
  upload_number: 'Jumlah pembicara',
  upload_category: 'Pilih kategori terbaik untuk video Anda',
  upload_clipped:'Rentang waktu',
  clipped_adjustments_desc:'Klik pegangan slider dan tekan tombol panah kiri/kanan untuk penyesuaian yang lebih detail.',
  clipped_description:'Kami hanya akan mengekstrak dan mentranskripsi dalam rentang waktu yang dipilih dari file media.<br>Mendukung Youtube, mp3 dan mp4 jenis media.',
  clipped_warning:'Ini akan meningkatkan waktu pemrosesan',
  clipped_length_warning:'Fitur ini tersedia untuk media yang lebih panjang dari 1 menit',
  purpose_description: 'Ini memungkinkan kami memformat transkripsi untuk membantu Anda dalam pengeditan.',

  speaker_unsure: 'Pisah otomatis',

  purpose_speakers:'Secara otomatis memecah transkrip Anda dengan <strong>memberi label setiap pembicara</strong>',
  purpose_transcript_desc:'(misalnya Menambahkan teks pada video, tanpa tanda baca)',
  purpose_captions:'Secara otomatis memecah transkrip Anda untuk <strong>teks/subtitle</strong>',
  purpose_caption_desc:'(misalnya Transkrip untuk rapat Anda, dengan tanda baca)',
  purpose_article:'Transkrip dengan tanda baca <strong>tanpa metode pemformatan khusus</strong>',
  purpose_translate_desc:'(misalnya Transkrip untuk membuat artikel, dengan tanda baca)',
  purpose_original:'Memecah transkrip Anda identik dengan <strong>file input</strong> Anda',

  speakers_time:'Pembicara',
  captions_time:'Teks/Subtitle',
  article_time:'File input',
  original_time:'File input',
  none_time:'',
  
  captions_time_desc:'Secara otomatis memecah transkrip Anda untuk tujuan pemberian teks.',
  speakers_time_desc:'Secara otomatis memecah transkrip Anda dengan memberi label setiap pembicara.',
  article_time_desc:'Transkrip akan memiliki format yang identik dengan file teks input yang Anda berikan.',
  original_time_desc:'Transkrip akan memiliki format yang identik dengan file teks input yang Anda berikan.',
  none_time_desc:'',

  purpose_other:'Saya belum memutuskan',
  create:'Buat',
  home:'Beranda',
  management_corp:'Manajemen',
  management:'Manajemen',
  account:'Akun',
  upgrade:'Tingkatkan',
  faq:'FAQ',
  logout:'Keluar',
  return:'Kembali ke pengeditan',
  drag_drop:'Seret dan lepas file video/audio Anda di sini',
  select_from:'Pilih dari perangkat Anda',
  or_select_from: 'Atau pilih dari',
  common_languages: 'Bahasa umum',
  additional_languages: 'Bahasa tambahan',

  friendly_notice: 'Tips untuk meningkatkan akurasi',
  friendly_notice_desc0: '1. Bahasa yang dipilih harus sesuai dengan video itu sendiri, penerjemahan dapat dilakukan setelah transkripsi',
  friendly_notice_desc: '2. Audio jelas dan memiliki sedikit musik latar atau kebisingan',
  friendly_notice_desc2: '3. Hindari mengunggah video yang berkaitan dengan musik atau nyanyian',
  friendly_notice_desc3: '4. Mengedit trek suara dengan cara apapun mungkin gagal untuk ditranskripsi',
  friendly_notice_desc4: '5. Mengunggah *.mp4 atau *.mp3 lebih disukai',

  google_drive_upgrade_title: 'Pilih dari Google Drive',
  google_drive_upgrade_desc: 'Anda dapat melewati waktu tunggu pengunggahan dan mempercepat proses transkripsi Anda ketika Anda berlangganan.',
  trial_check_desc:'Saya setuju untuk menggunakan transkripsi hanya untuk penggunaan pribadi, non-komersial saya. (Beli menit atau langganan premium untuk mendapatkan lisensi komersial)',

  browser_unsupported_title: 'Browser tidak didukung',
  browser_unsupported_desc: 'Silakan gunakan Chrome di komputer untuk pengalaman pengeditan terbaik.',
  browser_unsupported_mobile_desc: 'Silakan gunakan Chrome untuk pengalaman pengeditan terbaik.',
  browser_translated_injected_title: 'Harap matikan terjemahan halaman web',
  browser_translated_injected_desc: 'Gagal mematikan terjemahan halaman web dapat mengakibatkan perilaku yang tidak terduga. (misalnya: Pengeditan tidak dapat disimpan dan terputus)',
  browser_unsupported_step1: '1. Klik "..." atau "⋮" di pojok kanan bawah atau kanan atas',
  browser_unsupported_step2: '2. Pilih Buka di Browser',

  individual_account: 'Akun saya',
  corp_account_selection_desc:'Ketika pengguna Langganan Massal menambahkan Anda ke akun mereka, itu akan muncul dalam pilihan dropdown',
  unsave_change_title:'Anda memiliki perubahan yang belum disimpan',
  unsave_change_desc:'Apakah Anda yakin ingin keluar?',
  label_name:'Nama label',
  new_label:'Label baru',
  select_label:'Pilih akses label',
  label_dialog_header:'Manajemen Label',
  label_dialog_desc:'Edit dan urutkan kembali label Anda',
  label_empty:'Tidak ada label',
  label_all:'Semua file',
  label_select_header:'Label',
  label_limit_error:'Batas label telah tercapai. Tingkatkan ke Langganan Premium atau Langganan Massal untuk membuka batas',
  segment_sample:'Contoh keluaran',
  zoom_no_data:'Tidak ada rekaman dalam 2 tahun terakhir...',
  zoom_desc:'Hanya akan menampilkan rekaman dalam 2 tahun terakhir.',
  zoom_loading:'Memuat rekaman...',
  import_srt:'Impor SRT',
  srt_header:'Metode transkripsi',
  srt_select:'Pilih file SRT',
  srt_desc:'Menunggu file SRT...',
  import_txt:'Impor TXT',
  txt_select:'Pilih file teks',
  txt_desc:'Menunggu file TXT...',
  direct_link:'<span class="directlink-text-en">Tautan Langsung</span>',

  multi_upload:'Pelanggan dapat mentranskripsi beberapa file sekaligus.',
  category:'Kategori',
  max_char_header:'Karakter maksimum',
  max_char_desc:'Jumlah maksimum karakter untuk teks yang akan ditampilkan pada satu waktu.<br> Transkrip akan dibagi menjadi bagian-bagian sesuai dengan ambang batas ini.<br>Nilai yang direkomendasikan: 95<br> Nilai yang direkomendasikan untuk Bahasa Cina/Jepang/Korea: 20',
  language_tooltip:'Silakan pilih bahasa yang diucapkan untuk video ini. <br> Untuk menerjemahkan ke bahasa lain, <br>masuk ke platform pengeditan dan terjemahkan dari pojok kanan atas setelah mentranskripsi.<br><br> Jika pilihan Anda untuk <span style="color:#2FC59F">Metode transkripsi</span> di bawah adalah <span style="color:#2FC59F">Impor file SRT</span>, <br> pastikan untuk memilih bahasa yang identik dengan file SRT yang disediakan.',
  import_txt_tooltip:'Kami akan menambahkan timestamp ke file teks yang disediakan. <br><strong>{0}</strong>: {1}',

  home_notice_title:'Hemat lebih banyak menit',
  home_notice_desc:'Sistem pengurangan menit baru kami sekarang akan menghilangkan detik tambahan! <div class="desc">Contoh: Mengunggah media 15 menit 59 detik akan mengurangi 15 menit. (Mengunggah media kurang dari 1 menit akan gratis 🙂)</div>',
  no_name:'Tanpa judul',
  upload_language_reminder_title:'Pastikan bahasa yang dipilih sesuai dengan bahasa yang diucapkan dalam video',
  upload_language_reminder_desc:'Contoh: Jika video dalam bahasa Inggris, pilih "Inggris" sebagai bahasanya. <br> Jika Anda ingin menerjemahkan subtitle atau transkrip video, pertama-tama ubah menjadi teks dan kemudian masuk ke halaman pengeditan. <br> Setelah di dalam halaman pengeditan, gunakan opsi "Terjemahkan" di pojok kanan atas untuk menerjemahkan video.'
}