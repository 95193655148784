module.exports = { 
    affiliate: 'Affiliate-Marketing',
    page_title: 'Treten Sie dem Taption-Partnerprogramm bei',
    page_description: 'Stellen Sie neuen Kunden Taption vor. Teilen Sie Ihren speziellen Affiliate-Marketing-Link und verdienen Sie bei jeder erfolgreichen Empfehlung eine Provision. Lassen Sie uns gemeinsam wachsen!',
    tell_us_about_yourself: 'ERZÄHLEN SIE UNS ÜBER SICH SELBST',
    first_name: 'Vorname',
    last_name: 'Nachname',
    business_name: 'Firmenname (falls zutreffend)',
    location: 'Wo wohnen Sie? (Stadt, Bundesland, Land)',
    contact: 'KONTAKTINFORMATIONEN',
    email: 'E-Mail-Adresse',
    phone: 'Telefonnummer',
    linkedin_url: 'LinkedIn-URL',
    social_url: 'Soziale Medien URL',
    tell_us_about_site: 'ERZÄHLEN SIE UNS VON IHRER WEBSEITE',
    website_url: 'Website-URL',
    traffic: 'Geschätzter monatlicher Traffic',
    what_site_about: 'Worum geht es auf Ihrer Website?',
    anything_else: 'Gibt es noch etwas, das wir wissen sollten?',
    must_agree: 'Sie müssen zustimmen, um fortzufahren',
    read_agree: 'Ich habe die gelesen und stimme den ',
    affiliate_tos: 'Allianz-Bedingungen und -Konditionen',
    submit: 'Antrag einreichen',
    submit_success: 'Vielen Dank für Ihr Interesse! Ihre Bewerbung wurde eingereicht, und unser Team wird sich so schnell wie möglich mit Ihnen in Verbindung setzen.',
    name_required_err: 'Name ist erforderlich',
    name_character_limit_err: 'Der Name muss weniger als 50 Zeichen enthalten',
    email_required_err: 'E-Mail ist erforderlich',
    email_invalid_err: 'E-Mail muss gültig sein',
    phone_needed_err: 'Bitte geben Sie eine gültige Telefonnummer ein',
    website_required_err: 'Website-URL ist erforderlich',
    invalid_website_err: 'Bitte geben Sie eine gültige URL ein.',
    location_required_err: 'Standort ist erforderlich',
    estimate_traffic_err: 'Bitte wählen Sie eine Verkehrsschätzung aus',
    describe_site_err: 'Bitte beschreiben Sie Ihre Website.',
    generic_err: 'Bitte überprüfen Sie das Formular und korrigieren Sie alle hervorgehobenen Fehler, bevor Sie es absenden.',
    step1: 'Schritt 1: Registrieren',
    step1_desc: 'Die Anmeldung dauert weniger als 3 Minuten. Füllen Sie das untenstehende Formular aus und wir melden uns innerhalb von 24 Stunden bei Ihnen.',
    step2: 'Schritt 2: Teilen Sie Ihren benutzerdefinierten Link',
    step2_desc: 'Sobald Sie als Taption-Partner zugelassen sind, erhalten Sie einen einzigartigen Link zum Teilen, der sicherstellt, dass alle Empfehlungen und Provisionen nachverfolgt werden.',
    step3: 'Schritt 3: Geld verdienen',
    step3_desc: 'Verdienen Sie Provisionen, wenn jemand über Ihren Link ein Abonnement abschließt oder Minuten kauft – einfach und lohnend!',
}