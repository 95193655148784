module.exports = {
    login:'Login',
    or:'or',
    member_login:'Member Login',
    signin_with:'Sign in with:',
    signin_fb:'Sign in with Facebook',
    signin_google:'Sign in with Google',
    email_address: 'Email address',
    password: 'Password',
    confirm_password: 'Retype Password',
    been_logged_out_title: 'You have been logged out',
    // been_logged_out_desc: 'Please log in again',
    been_logged_out_desc: 'Your account has been accessed from another location',
    forgot_password:'Forgot password',
    create_free_account:'Create your free account',
    create_an_account:'Create an account',
    create_account:'Create account',
    or_go_back_to:'Or go back to',
    name:'Name',
    check_to_reset:'Please check your inbox to reset your password.',
    check_to_verify_header:'Verify your email',
    check_to_verify:'Your account has been created! If you do not receive the confirmation message, please check your <span style="font-weight:bold">Spam Folder</span>.',
    send_to_verify_header:'Verification email sent',
    send_to_verify:'Please check your email for verification link to activate your account. If you do not receive the confirmation message, please check your <span style="font-weight:bold">Spam Folder</span>.',
    term_of_service_description1: 'By signing in to Taption, you agree to our ',
    term_of_service:'terms of service',
    term_of_service_description2: ' and have read and understood the ',
    privacy_policy:'privacy policy',
    resend_verification:'Resend verification email',
    //invite_desc:'Thanks to {0}<br>You will receive an additional {1} minutes for free when you subscribe. Free trial includes 1 free transcription within 15 minutes.',
    invite_desc:'Thanks to {0}<br>Sign up and get 15 minutes for free',
    login_desc:'Sign up and get 15 minutes for free',
    referral_note:'No credit card required.',
    redirecting:'Redirecting...'
  }
  