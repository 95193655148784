module.exports = {
  login:'Anmelden',
  or:'oder',
  member_login:'Mitglieder-Anmeldung',
  signin_with:'Anmelden mit:',
  signin_fb:'Mit Facebook anmelden',
  signin_google:'Mit Google anmelden',
  email_address: 'E-Mail-Adresse',
  password: 'Passwort',
  confirm_password: 'Passwort erneut eingeben',
  been_logged_out_title: 'Sie wurden abgemeldet',
  been_logged_out_desc: 'Ihr Konto wurde von einem anderen Ort aus aufgerufen',
  forgot_password:'Passwort vergessen',
  create_free_account:'Kostenloses Konto erstellen',
  create_an_account:'Konto erstellen',
  create_account:'Konto erstellen',
  or_go_back_to:'Oder zurück zu',
  name:'Name',
  check_to_reset:'Bitte überprüfen Sie Ihren Posteingang, um Ihr Passwort zurückzusetzen.',
  check_to_verify_header:'Bestätigen Sie Ihre E-Mail-Adresse',
  check_to_verify:'Ihr Konto wurde erstellt! Wenn Sie die Bestätigungsnachricht nicht erhalten, überprüfen Sie bitte Ihren <span style="font-weight:bold">Spam-Ordner</span>.',
  send_to_verify_header:'Bestätigungs-E-Mail gesendet',
  send_to_verify:'Bitte überprüfen Sie Ihre E-Mail auf den Bestätigungslink, um Ihr Konto zu aktivieren. Wenn Sie die Bestätigungsnachricht nicht erhalten, überprüfen Sie bitte Ihren <span style="font-weight:bold">Spam-Ordner</span>.',
  term_of_service_description1: 'Durch die Anmeldung bei Taption stimmen Sie unseren ',
  term_of_service:'Nutzungsbedingungen',
  term_of_service_description2: ' zu und haben die ',
  privacy_policy:'Datenschutzrichtlinie',
  resend_verification:'Bestätigungs-E-Mail erneut senden',
  invite_desc:'Dank {0}<br>Melden Sie sich an und erhalten Sie 15 Minuten kostenlos',
  login_desc:'Melden Sie sich an und erhalten Sie 15 Minuten kostenlos',
  referral_note:'Keine Kreditkarte erforderlich.',
  redirecting:'Weiterleitung...'
}