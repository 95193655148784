module.exports = { 
    video_quality_reminder: 'Pourquoi la qualité de cette vidéo est-elle réduite ?',
    video_quality_tooltip: 'Le propriétaire de cette transcription devra s\'abonner pour l\'afficher dans sa qualité d\'origine.',
    export: 'Exporter',
    speaker: 'Haut-parleur',
    full_screen: 'Plein écran',
    full_screen_exit: 'Quitter le mode plein écran',
    play: 'Lire (TAB)',
    pause: 'Pause (TAB)',
    captions_overlay_on: 'Sous-titres : <span style="color:#20F7C4">Activés</span><br><span>(Glissez pour ajuster la position)</span>',
    captions_overlay_off: 'Sous-titres : <span style="color:rgb(230, 118, 117)">Désactivés</span><br><span>(Glissez pour ajuster la position)</span>',
}