import FirebaseService from './firebase.service'
import { onAuthStateChanged, getIdTokenResult,signInWithEmailLink,signOut, applyActionCode, checkActionCode, confirmPasswordReset, getAuth, signInWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail,createUserWithEmailAndPassword, setPersistence,browserSessionPersistence, GoogleAuthProvider,signInWithPopup,verifyPasswordResetCode } from 'firebase/auth';
import axios from 'axios'
// const convert = require('amrhextotext')
var IdToken = null
const authEndpoint = process.env.VUE_APP_MODE === 'production' ? 'https://api.taption.com/authentication':'https://api.taptiontest.com/authentication';
const cdnEndpoint = process.env.VUE_APP_MODE === 'production' ? 'https://tapprod.b-cdn.net' : 'https://taptesttemp.b-cdn.net';
const getParameter = require('get-parameter');

export default {
  createIdToken (forceRefresh) {
    return FirebaseService.createIdToken(forceRefresh).then(function (idToken) {
      IdToken = idToken
      return idToken
    })
  },

  onAuthStateChanged(callback){
    return onAuthStateChanged(FirebaseService.getAuth(), callback)
  },

  getIdTokenResult(){
    return getIdTokenResult(FirebaseService.getAuthUser(), true)
  },

  setPersistence () {
    return setPersistence(FirebaseService.getAuthUser(), browserSessionPersistence);
  },

  verifyPasswordResetCode(actionCode){
    return verifyPasswordResetCode(FirebaseService.getAuth(), actionCode)
  },

  confirmPasswordReset(actionCode, newPassword){
    return confirmPasswordReset(FirebaseService.getAuth(), actionCode, newPassword)
  },
  checkActionCode(actionCode){
    return checkActionCode(FirebaseService.getAuth(), actionCode)
  },
  applyActionCode(actionCode){
    return applyActionCode(FirebaseService.getAuth(), actionCode)
  },
  signInWithEmailLink(email, emailLink){
    return signInWithEmailLink(FirebaseService.getAuth(), email, emailLink)
  },
  
  

  getAuth () {
    return FirebaseService.getAuth()
  },
  getAuthUser () {
    return FirebaseService.getAuthUser()
  },
  
  // getPasswordEmailCredential(password){
  //   return EmailAuthProvider.credential(FirebaseService.getAuthUser().email, password);
  // },

  // reauthenticateWithCredential(credential){
  //   return reauthenticateWithCredential(FirebaseService.getAuthUser(), credential)
  // },
  getIdToken () {
    if (IdToken) {
      return IdToken
    }
    throw ('Need to create the IdToken.')
  },
  sendEmailVerification(){
    return sendEmailVerification(FirebaseService.getAuthUser())
  },
  sendPasswordResetEmail(email){
    return sendPasswordResetEmail(FirebaseService.getAuth(), email)
  },
  getUserSignupPromise (email, password) {
    return createUserWithEmailAndPassword(FirebaseService.getAuth(), email, password)
  },
  getUserLoginPromise (email, password) {
    const auth = getAuth();
    return signInWithEmailAndPassword(auth, email, password)
  },
  getGoogleAuthProvider () {
    return new GoogleAuthProvider()
  },

  // getFacebookAuthProvider () {
  //   return new FacebookAuthProvider()
  // },
  getSignInWithPopupPromise (provider) {
    return signInWithPopup(FirebaseService.getAuth(), provider)
  },
  navigateToAppLoginPage(){
    const state = getParameter('state');
    const platform = getParameter('platform') || getParameter('utm_source');
    const advertiserId = getParameter('advertiserId') ? getParameter('advertiserId') : (getParameter('utm_source') ? 'f0e7jXwyreY9XZONHdeQtZd4mko1' : '');
    const removeTrailingAmpersand = function(str) {
      // Check if the string ends with '&'
      if (str && str.charAt(str.length - 1) === '&') {
          // Remove the last character
          return str.slice(0, -1);
      }
      // Return the string unchanged if it doesn't end with '&'
      return str;
  }
    let query = ''
    if(state || platform || advertiserId){
      query = '?';
      if(state){
        query += ('state='+state+'&');
      }
      if(advertiserId){
        query += ('advertiserId='+advertiserId+'&');
      }
      if(platform){
        query += ('platform='+platform+'&');
      }
    }

    query = removeTrailingAmpersand(query);
    // if(state){
    //   query = '?state=' +state;
    // }
    window.location.href = process.env.VUE_APP_MODE === 'production' ? 'https://app.taption.com/home'+ query : 'https://app.taptiontest.com/home' + query;
  },
  checkCookie() {
    return axios.get(authEndpoint, {
        withCredentials: true ,
        params: {
            type: 'check'
        }
    });
  },
  appSignIn(type,idToken){
    return axios.post(authEndpoint, {
      type: type,
      idToken: idToken
    }, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      }
    });
  },
  async readTextFile(path, fileName) {
    const cacheBuster = `?cb=${new Date().getTime()}`;
    const fileUrl = `${cdnEndpoint}/${path}/${fileName}${cacheBuster}`;
  
    try {
      // Fetch the file contents
      const response = await axios.get(fileUrl, {
        responseType: 'text', // Specify that the response type is plain text,
        headers: {
          'Content-Type': 'text/plain'
        }
      });
  
      // Log the content of the file
      //console.log('File Content:', response.data);
      return response.data;
    } catch (error) {
      //console.error('Error fetching the file:', error.message);
      return null;
    }
  },
  async appSignOut(){
    try {
      await axios.get(authEndpoint, {
          withCredentials: true ,
          params: {
              type: 'logout'
          }
      })
      console.log('Logged out successfully');
      window.location.href = process.env.VUE_APP_MODE === 'production' ? 'https://www.taption.com/login' : 'https://www.taptiontest.com/login';
    } catch (error) {
      console.error('Error during logout:', error);
    }
  },
  getSignOutPromise () {
    return signOut(FirebaseService.getAuth())
  },
  hasUserExistPromise (otherUid) {
    return FirebaseService.getCloudFunctionPromise('isUserExist', otherUid)
  },
  inviteMember (emails,role, permissions) {
    return FirebaseService.getCloudFunctionPromise('inviteMember', {emails:emails,role:role, permissions:permissions})
  },
  updateUser (fields) {
    return FirebaseService.getCloudFunctionPromise('userUpdate', { fields:fields})
  },
  reqeustAffiliate (formData) {
    return FirebaseService.getCloudFunctionPromise('requestAffiliate', formData, { limitedUseAppCheckTokens: true });
  },
  async getGeoLocation(){
    try{
     var result =await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.VUE_APP_IPGEO_API}`);
      
     return result.data;
    }
    catch{
      return null;
    }
  },
  async getLocationFree(){
    try{
      var result =await axios.get(`https://freeipapi.com/api/json`);
       
      return result.data;
     }
     catch{
       return null;
     }
    
  }
}
