module.exports = {
  upload: 'Hochladen',
  upload_language: 'Wählen Sie die gesprochene Sprache aus',
  upload_method: 'Wählen Sie die Transkriptionsmethode',
  delete_title: 'Transkript löschen?',
  delete_description: 'Diese Aktion kann nicht rückgängig gemacht werden.',
  delete_ok: 'Löschen',
  delete_cancel: 'Abbrechen',
  remember_selection: 'Auswahl merken',
  send_email: 'Senden Sie mir eine E-Mail, wenn der Vorgang abgeschlossen ist.',
  card_share: 'Teilen',
  card_delete: 'Löschen',
  upload_media: 'Medien hochladen',
  upload_error_title: 'Fehler',
  upload_error: 'Sie haben nicht genügend Minuten.',
  transcribing_description: 'Wir transkribieren Ihre Datei. <br>Die Verarbeitungszeit entspricht etwa<br>der Dauer der Datei.<br> Wir werden Ihnen eine E-Mail senden, wenn sie fertig ist.',
  transcribing: 'Verarbeitung',
  preparing: 'Vorbereitung',
  update_title: 'Titel aktualisieren',
  transcript_title: 'Titel',
  add_more: 'Hinzufügen',

  upload_limit_exceeded_title: 'Maximale Länge überschritten',
  upload_limit_exceeded: 'Bitte begrenzen Sie die Länge Ihres Videos auf weniger als 5 Stunden.',

  initializing_description: 'Wir verarbeiten Ihre Datei. <br>Dies wird ein paar Minuten dauern, bitte warten Sie.',
  initializing: 'Warten',
  translating_description: 'Wir übersetzen Ihre Datei. <br>Dies wird ein paar Minuten dauern, bitte warten Sie.',
  translating: 'Übersetzung',
  error_description: 'Bitte kontaktieren Sie uns für weitere Informationen',
  error_info: 'Fehlercode: {0}',
  error: 'Etwas ist schiefgelaufen',
  error_minutes: '{0} <br> Ihre Minuten wurden zurückerstattet.<br> Aktualisieren Sie Ihren Browser für die aktuellsten Minutenangaben.',

  please_wait: 'Bitte warten...',
  loading: 'Laden...',
  upgrade_to_share: 'Bitte {0}, um Kollegen einzuladen, Ihre Transkripte zu teilen und zu bearbeiten.',
  share_link_with: 'Mit Nur-Lese-Berechtigung teilen',
  share_with_editable_permission: 'Mit Bearbeitungsberechtigung teilen',
  share_with: 'z.B.: beispiel1@gmail.com,beispiel2@gmail.com',
  share_with_currently: 'Derzeit geteilt mit',
  share_empty: 'Bitte gehen Sie zu <a href="\\management" style="color:#2FC59F;">Verwaltung</a>, um teilbare Benutzer hinzuzufügen.',
  uploading: 'Hochladen',
  shared: 'Geteilt',
  thumbnail: 'Vorschaubild',
  upload_purpose: 'Wählen Sie Ihr Ausgabeformat',
  upload_number: 'Anzahl der Sprecher',
  upload_category: 'Wählen Sie die beste Kategorie für Ihr Video',
  upload_clipped: 'Zeitbereich',
  clipped_adjustments_desc: 'Klicken Sie auf den Schieberegler und drücken Sie die Pfeiltasten links/rechts für feinere Anpassungen.',
  clipped_description: 'Wir extrahieren und transkribieren nur innerhalb des ausgewählten Zeitbereichs der Mediendatei.<br>Unterstützt Youtube, mp3 und mp4 Medientypen.',
  clipped_warning: 'Dies wird die Verarbeitungszeit erhöhen',
  clipped_length_warning: 'Diese Funktion ist für Medien verfügbar, die länger als 1 Minute sind',
  purpose_description: 'Dies ermöglicht es uns, die Transkription zu formatieren, um Ihnen bei der Bearbeitung zu helfen.',

  speaker_unsure: 'Automatische Aufteilung',

  purpose_speakers: 'Teilen Sie Ihr Transkript automatisch auf, indem Sie <strong>jeden Sprecher kennzeichnen</strong>',
  purpose_transcript_desc: '(z.B. Hinzufügen von Untertiteln zu Videos, ohne Interpunktion)',
  purpose_captions: 'Teilen Sie Ihr Transkript automatisch für <strong>Untertitel</strong> auf',
  purpose_caption_desc: '(z.B. Transkripte für Ihre Meetings, mit Interpunktion)',
  purpose_article: 'Transkript mit Interpunktion <strong>ohne spezifische Formatierungsmethode</strong>',
  purpose_translate_desc: '(z.B. Transkripte für die Erstellung von Artikeln, mit Interpunktion)',
  purpose_original: 'Teilen Sie Ihr Transkript identisch zu Ihrer <strong>Eingabedatei</strong> auf',

  speakers_time: 'Sprecher',
  captions_time: 'Untertitel',
  article_time: 'Eingabedatei',
  original_time: 'Eingabedatei',
  none_time: '',
  
  captions_time_desc: 'Teilen Sie Ihr Transkript automatisch für den Zweck der Untertitelung auf.',
  speakers_time_desc: 'Teilen Sie Ihr Transkript automatisch auf, indem Sie jeden Sprecher kennzeichnen.',
  article_time_desc: 'Das Transkript wird das gleiche Format haben wie die von Ihnen bereitgestellte Textdatei.',
  original_time_desc: 'Das Transkript wird das gleiche Format haben wie die von Ihnen bereitgestellte Textdatei.',
  none_time_desc: '',

  purpose_other: 'Ich habe mich noch nicht entschieden',
  create: 'Erstellen',
  home: 'Startseite',
  management_corp: 'Verwaltung',
  management: 'Verwaltung',
  account: 'Konto',
  upgrade: 'Upgrade',
  faq: 'FAQ',
  logout: 'Abmelden',
  return: 'Zurück zur Bearbeitung',
  drag_drop: 'Ziehen Sie Ihre Video-/Audiodatei hierher',
  select_from: 'Von Ihrem Gerät auswählen',
  or_select_from: 'Oder auswählen aus',
  common_languages: 'Häufige Sprachen',
  additional_languages: 'Zusätzliche Sprachen',

  friendly_notice: 'Tipps zur Verbesserung der Genauigkeit',
  friendly_notice_desc0: '1. Die ausgewählte Sprache muss mit dem Video selbst übereinstimmen, die Übersetzung kann nach der Transkription erfolgen',
  friendly_notice_desc: '2. Der Ton ist klar und hat wenig Hintergrundmusik oder Geräusche',
  friendly_notice_desc2: '3. Vermeiden Sie das Hochladen von Musik- oder Gesangsvideos',
  friendly_notice_desc3: '4. Jegliche Bearbeitung der Tonspur kann zu Fehlern bei der Transkription führen',
  friendly_notice_desc4: '5. Das Hochladen von *.mp4 oder *.mp3 wird bevorzugt',

  google_drive_upgrade_title: 'Aus Google Drive auswählen',
  google_drive_upgrade_desc: 'Sie können die Wartezeit beim Hochladen überspringen und Ihren Transkriptionsprozess beschleunigen, wenn Sie abonnieren.',
  trial_check_desc: 'Ich stimme zu, die Transkription nur für meinen persönlichen, nicht-kommerziellen Gebrauch zu verwenden. (Kaufen Sie Minuten oder ein Premium-Abonnement, um eine kommerzielle Lizenz zu erhalten)',

  browser_unsupported_title: 'Browser nicht unterstützt',
  browser_unsupported_desc: 'Bitte verwenden Sie Chrome auf einem Computer für die beste Bearbeitungserfahrung.',
  browser_unsupported_mobile_desc: 'Bitte verwenden Sie Chrome für die beste Bearbeitungserfahrung.',
  browser_translated_injected_title: 'Bitte schalten Sie die Webseiten-Übersetzung aus',
  browser_translated_injected_desc: 'Wenn Sie die Webseiten-Übersetzung nicht ausschalten, kann dies zu unvorhersehbarem Verhalten führen. (z.B. Bearbeitungen können nicht gespeichert werden und die Verbindung wird unterbrochen)',
  browser_unsupported_step1: '1. Klicken Sie auf "..." oder "⋮" in der unteren rechten oder oberen rechten Ecke',
  browser_unsupported_step2: '2. Wählen Sie "Im Browser öffnen"',

  individual_account: 'Mein Konto',
  corp_account_selection_desc: 'Wenn ein Bulk-Abonnement-Benutzer Sie zu seinem Konto hinzufügt, erscheint es in der Dropdown-Auswahl',
  unsave_change_title: 'Sie haben ungespeicherte Änderungen',
  unsave_change_desc: 'Sind Sie sicher, dass Sie die Seite verlassen möchten?',
  label_name: 'Bezeichnungsname',
  new_label: 'Neue Bezeichnung',
  select_label: 'Bezeichnungszugriff auswählen',
  label_dialog_header: 'Bezeichnungsverwaltung',
  label_dialog_desc: 'Bearbeiten und ordnen Sie Ihre Bezeichnungen',
  label_empty: 'Keine Bezeichnung',
  label_all: 'Alle Dateien',
  label_select_header: 'Bezeichnung',
  label_limit_error: 'Das Bezeichnungslimit wurde erreicht. Upgraden Sie auf ein Premium-Abonnement oder Bulk-Abonnement, um das Limit freizuschalten',
  segment_sample: 'Beispielausgabe',
  zoom_no_data: 'Es gibt keine Aufnahmen in den letzten 2 Jahren...',
  zoom_desc: 'Zeigt nur Aufnahmen der letzten 2 Jahre.',
  zoom_loading: 'Aufnahmen werden geladen...',
  import_srt: 'SRT importieren',
  srt_header: 'Transkriptionsmethode',
  srt_select: 'SRT-Datei auswählen',
  srt_desc: 'Warten auf SRT-Datei...',
  import_txt: 'TXT importieren',
  txt_select: 'Textdatei auswählen',
  txt_desc: 'Warten auf TXT-Datei...',
  direct_link: '<span class="directlink-text-de">Direkter Link</span>',

  multi_upload: 'Abonnenten können mehrere Dateien gleichzeitig transkribieren.',
  category: 'Kategorie',
  max_char_header: 'Maximale Zeichen',
  max_char_desc: 'Die maximale Anzahl von Zeichen, die gleichzeitig in den Untertiteln angezeigt werden sollen.<br> Das Transkript wird entsprechend diesem Schwellenwert in Abschnitte aufgeteilt.<br>Empfohlener Wert: 95<br> Empfohlener Wert für Chinesisch/Japanisch/Koreanisch: 20',
  language_tooltip: 'Bitte wählen Sie die gesprochene Sprache für dieses Video aus. <br> Um in andere Sprachen zu übersetzen, <br>gehen Sie nach der Transkription in die Bearbeitungsplattform und übersetzen Sie von oben rechts.<br><br> Wenn Ihre Auswahl für <span style="color:#2FC59F">Transkriptionsmethode</span> unten <span style="color:#2FC59F">SRT-Datei importieren</span> ist, <br> stellen Sie sicher, dass Sie die Sprache identisch zur bereitgestellten SRT-Datei auswählen.',
  import_txt_tooltip: 'Wir fügen der bereitgestellten Textdatei Zeitstempel hinzu. <br><strong>{0}</strong>: {1}',

  home_notice_title: 'Sparen Sie mehr Minuten',
  home_notice_desc: 'Unser neues Minutenabzugssystem wird jetzt zusätzliche Sekunden auslassen! <div class="desc">Beispiel: Das Hochladen eines 15 Minuten und 59 Sekunden langen Mediums wird 15 Minuten abziehen. (Das Hochladen eines Mediums von weniger als 1 Minute ist kostenlos 🙂)</div>',
  no_name: 'Unbenannt',
  upload_language_reminder_title: 'Stellen Sie sicher, dass die ausgewählte Sprache mit der gesprochenen Sprache des Videos übereinstimmt',
  upload_language_reminder_desc: 'Beispiel: Wenn das Video auf Englisch ist, wählen Sie bitte "Englisch" als Sprache aus. <br> Wenn Sie Video-Untertitel oder Transkripte übersetzen möchten, konvertieren Sie diese zuerst in Text und gehen Sie dann zur Bearbeitungsseite. <br> Sobald Sie sich auf der Bearbeitungsseite befinden, verwenden Sie die Option "Übersetzen" in der oberen rechten Ecke, um das Video zu übersetzen.'
}