module.exports = { 
    monthly_free_minutes: 'Minutos acumulados mensualmente gratis (Válidos solo con suscripción)',
    monthly_free_minutes_tooltip: 'Si los minutos mensuales no se consumen, se acumularán para el siguiente mes y serán válidos hasta que se cancele la suscripción. <br>Estos minutos mensuales se usan antes de los minutos adicionales comprados por separado.',
    additional_seat: 'Espacios adicionales',
    additional_seat_tooltip: 'Permite compartir la cuenta invitando usuarios y otorgándoles acceso a minutos, vídeos y funciones con permisos personalizables.',
    monthly_free_ai_credits: 'Créditos de IA <a href="https://www.taption.com/ai-analysis" target="__blank" style="color:#2FC59F;padding:0px 5px;font-size:12px;">más información</a>',
    monthly_free_ai_credits_tooltip: 'Los créditos de IA se recargan hasta {0} mensualmente para la Suscripción Premium.<br>Los créditos de IA se recargan hasta {1} mensualmente para la Suscripción Corporativa.<br>Se pueden comprar créditos adicionales de IA en cualquier momento.',
    monthly_free_ai_credits_desc: 'Hasta {0} créditos mensuales',
    minutes_price: 'Precio de minutos adicionales',
    minutes_price_tooltip: 'Los minutos adicionales comprados por separado no expiran después de que se cancele la suscripción.',
    max_file_length: 'Duración máxima del vídeo',
    max_file_size: 'Tamaño máximo de archivo a subir',
    max_label_number: 'Número máximo de etiquetas',
    automated_transcription: 'Transcripción automática',
    editing_platform_unlimited: 'Sin límite de tiempo en el uso de la plataforma de edición',
    language_supported: 'Más de 40 idiomas compatibles',
    highlight_text: 'Editor de texto resaltado',
    translate: 'Traducir a más de 50 idiomas',
    translate_basic_tooltip: 'La traducción descontará minutos adicionales',
    translate_premium_tooltip: 'La traducción descontará minutos adicionales',
    translate_corp_tooltip: 'La traducción descontará minutos adicionales',
    personal_dictionary: 'Diccionario Personalizado de IA',
    allow_commercial: 'Permitir uso comercial',
    mp4_multi_language_export_tooltip: 'Soporta subtítulos en 2 idiomas de entre las más de 50 traducciones disponibles. Puedes añadir más traducciones desde el menú desplegable de traducción en la parte superior derecha de la página de edición de la transcripción.',
    mp4_multi_language_export: 'Soporta subtítulos multilingües incrustados',
    mp4_multi_language_export_basic_tooltip: 'La exportación de vídeo descontará minutos adicionales para usuarios sin suscripción.',
    mp4_audio_export: 'Convierte audio en vídeo con subtítulos y fondo personalizado',
    mp4_audio_export_tooltip: 'Puedes convertir y exportar tu archivo de audio en un vídeo con fondo personalizado y subtítulos incrustados.',
    mp4_audio_export_basic_tooltip: 'Los usuarios del plan estándar solo pueden exportar vídeos con un fondo negro.',
    api_integration: 'Integración de API personalizada',
    api_integration_tooltip: 'Por favor contáctanos en help@taption.com para más información.',
    api_integration_basic_tooltip: 'Por favor contáctanos en help@taption.com para más información.',
    api_integration_premium_tooltip: 'Por favor contáctanos en help@taption.com para más información.',
    api_integration_corp_tooltip: 'Por favor contáctanos en help@taption.com para más información.',
    custom_payments: 'Opciones de facturación personalizadas',
    custom_payments_tooltip: 'Opciones de pago flexibles, incluyendo transferencia bancaria/cheque. Por favor contáctanos en help@taption.com para más información.',
    advanced_training: 'Capacitación avanzada para usuarios',
    advanced_training_tooltip: '¿Necesitas ayuda para incorporar a todos? Podemos ayudarte con eso.',
    high_definition: 'Vídeo en calidad original',
    high_definition_tooltip: 'Edita y comparte tu vídeo en su calidad original',
    mp4_export: 'Exportación ilimitada de subtítulos incrustados',
    mp4_export_basic_tooltip: 'La exportación de vídeo descontará minutos adicionales para usuarios sin suscripción.',
    editable_transcript_sharing: 'Compartir transcripciones editables',
    dark_mode: 'Modo oscuro',
    video_editing: 'Edita el vídeo editando el texto',
    video_cutting: 'Corte de vídeo',
    multi_upload: 'Transcribe varios archivos a la vez',
    invoice_company: 'Factura con título personalizado',
    invoice_taxid: 'Factura con número de identificación fiscal',
    share_minutes: 'Acceso multiusuario',
    share_minutes_tooltip: 'Añade usuarios a la cuenta para compartir minutos y transcripciones.',
    permission_users: 'Gestión de permisos de usuario <a href="https://www.taption.com/collaborate" target="__blank" style="color:#2FC59F;padding:0px 5px;font-size:12px;">más información</a>',
    permission_users_tooltip: 'Restringe a las cuentas añadidas para traducir, subir, eliminar, exportar y editar transcripciones.',
    max_char: 'Máximo de caracteres preestablecido para subtítulos',
    max_char_tooltip: 'El número máximo de caracteres para que se muestren los subtítulos a la vez.<br> La transcripción se dividirá en secciones de acuerdo con este límite.',
    direct_link: 'Aceptar enlaces como fuentes al transcribir',
    direct_link_tooltip: 'Soporta lo siguiente:<br>• Enlace de vídeo de Facebook<br>• Enlace de vídeo de Instagram<br>• Enlace de vídeo de X (anteriormente Twitter)<br>• Enlace de vídeo de TikTok<br>• Enlace de archivo multimedia URL<br>• Enlace de compartición de Dropbox<br>• Enlace de compartición de One Drive<br>• Enlace de compartición de Google Drive<br><br>',
    auto_extent_subtitle: 'Extender automáticamente la duración de cada subtítulo',
    auto_extent_subtitle_tooltip: 'Extiende la duración de todos los subtítulos por una cierta cantidad de tiempo. <br/>Esto puede evitar que los subtítulos "parpadeen" entre pausas cortas, suavizando la transición.',
    clipped_video: 'Transcribir dentro del rango de tiempo',
    clipped_video_tooltip: 'Extrae y transcribe dentro del rango de tiempo seleccionado del archivo multimedia.<br>Soporta medios de tipo Youtube, mp3 y mp4.',
    custom_format: 'Formato de descarga personalizado',
    custom_format_tooltip: 'Al exportar tu transcripción como txt o pdf, nuestro constructor de plantillas flexible te permitirá descargar en tu formato deseado.',
    editing_font_size: 'Personaliza el tamaño de fuente en la plataforma de edición',
    low_cost: 'Precios asequibles.',
    title_basic: 'Paga por uso',
    basic_cost_usd: '$8',
    basic_cost_ntd: '(240 NTD)',
    hour: 'hora',
    basic_feature5: 'Crédito <span style="color: #00d1b2">60 minutos</span>',
    basic_feature1: 'Transcripción automática',
    basic_feature2: 'Más de 40 idiomas compatibles',
    basic_feature3: 'Editor de texto resaltado',
    basic_feature4: 'Traducir a más de 50 idiomas',
    basic_feature7: 'Diccionario personalizado de IA',
    basic_feature6: 'Permitir uso comercial',
    basic_feature8: 'Soporta subtítulos multilingües incrustados',
    title_premium: 'Suscripción Premium',
    premium_cost_usd: '$12',
    premium_cost_ntd: '(360 NTD)',
    premium_basic_cost_usd: '$6',
    month: 'mes',
    premium_before: '<span class="landing-arrow-left">&#10229;</span>Todos los beneficios de「Paga por uso」',
    premium_feature1: '<span style="color: #00d1b2">120 minutos</span> acreditados a tu cuenta cada mes.<br><span style="color:#797676;font-size:14px;font-weight: 100">(Minutos acumulables, válidos hasta la cancelación de la suscripción)</span>',
    premium_feature1_tooltip: 'Si los minutos mensuales no se consumen, se acumularán para el siguiente mes y serán válidos hasta que se cancele la suscripción. <br>Estos minutos mensuales se usan antes de los minutos adicionales comprados por separado. <br>Los minutos adicionales comprados por separado no expiran después de que se cancele la suscripción.',
    premium_feature2: '25% de descuento en compras por hora',
    premium_feature3: 'Exportaciones ilimitadas de subtítulos incrustados gratis',
    premium_feature4: 'Compartir transcripciones editables',
    premium_feature5: 'Exporta tus grabaciones de audio a vídeos con subtítulos',
    premium_feature6: 'Corte de vídeo (La transcripción/subtítulos exportados se ajustarán automáticamente)',
    title_corp: 'Suscripción Corporativa',
    bulk_cost_usd: '$69',
    bulk_cost_ntd: '(2070 NTD)',
    bulk_basic_cost_usd: '$4',
    bulk_before: '<span class="landing-arrow-left">&#10229;</span>Todos los beneficios de「Paga por uso」y「Suscripción Premium」',
    bulk_feature2: '63% de descuento en compras por hora',
    premium_feature1_bulk: '<span style="color: #00d1b2">1000 minutos</span> acreditados a tu cuenta cada mes.<br><span style="color:#797676;font-size:14px;font-weight: 100">(Minutos acumulables, válidos hasta la cancelación de la suscripción)</span>',
    bulk_feature1: 'Proporciona descargas de recibos con el nombre de tu institución y número de identificación fiscal',
    bulk_feature3: 'Comparte minutos con usuarios',
    bulk_feature4: '6 permisos configurables diferentes',
    go_to_price: 'Más detalles',
    choose_plan: 'Elige un plan',
    annually: 'Anual',
    monthly: 'Mensual',
    annually_save: 'Ahorra {0}% con facturación anual',
    annually_deduct: '${0} facturado anualmente',
    unit: 'USD',
}