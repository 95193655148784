module.exports = { 
    monthly_free_minutes: 'Menit gratis bulanan yang terakumulasi (Hanya berlaku saat berlangganan)',
    monthly_free_minutes_tooltip: 'Jika menit bulanan tidak habis digunakan, mereka akan diakumulasi ke bulan berikutnya dan berlaku hingga langganan dibatalkan. <br>Menit bulanan ini digunakan sebelum menit tambahan yang dibeli secara terpisah.',
    additional_seat: 'Kursi tambahan',
    additional_seat_tooltip: 'Aktifkan berbagi akun dengan mengundang pengguna dan memberikan mereka akses ke menit, video, dan fitur dengan izin yang dapat disesuaikan.',
    monthly_free_ai_credits: 'Kredit AI <a href="https://www.taption.com/ai-analysis" target="__blank" style="color:#2FC59F;padding:0px 5px;font-size:12px;">pelajari lebih lanjut</a>',
    monthly_free_ai_credits_tooltip: 'Kredit AI mengisi ulang hingga {0} setiap bulan untuk Langganan Premium.<br>Kredit AI mengisi ulang hingga {1} setiap bulan untuk Langganan Bulk.<br>Kredit AI tambahan dapat dibeli kapan saja.',
    monthly_free_ai_credits_desc: 'Hingga {0} kredit per bulan',
    minutes_price: 'Harga untuk menit tambahan',
    minutes_price_tooltip: 'Menit tambahan yang dibeli secara terpisah tidak kedaluwarsa setelah langganan dibatalkan.',
    max_file_length: 'Panjang video maksimum',
    max_file_size: 'Ukuran file unggahan maksimum',
    max_label_number: 'Jumlah label maksimum',
    automated_transcription: 'Transkripsi otomatis',
    editing_platform_unlimited: 'Tidak ada batas waktu penggunaan platform pengeditan',
    language_supported: '40+ bahasa didukung',
    highlight_text: 'Editor teks yang disorot',
    translate: 'Terjemahkan ke lebih dari 50 bahasa',
    translate_basic_tooltip: 'Terjemahan akan mengurangi tambahan menit',
    translate_premium_tooltip: 'Terjemahan akan mengurangi tambahan menit',
    translate_corp_tooltip: 'Terjemahan akan mengurangi tambahan menit',
    personal_dictionary: 'Kamus AI Pribadi',
    allow_commercial: 'Izinkan penggunaan komersial',
    mp4_multi_language_export_tooltip: 'Mendukung subtitle dalam 2 bahasa dari lebih dari 50 terjemahan yang tersedia. Anda dapat menambahkan lebih banyak terjemahan dari dropdown terjemahan di kanan atas setelah memasuki halaman pengeditan transkrip.',
    mp4_multi_language_export: 'Mendukung subtitle multibahasa yang dibakar dalam video',
    mp4_multi_language_export_basic_tooltip: 'Mengekspor video akan mengurangi tambahan menit untuk pengguna non-langganan.',
    mp4_audio_export: 'Ubah audio menjadi video dengan subtitle dan latar belakang yang disesuaikan',
    mp4_audio_export_tooltip: 'Anda dapat mengonversi dan mengekspor file audio Anda menjadi video dengan latar belakang yang disesuaikan dengan subtitle yang dibakar dalam video.',
    mp4_audio_export_basic_tooltip: 'Pengguna paket Standar hanya dapat mengekspor video dengan latar belakang berwarna hitam.',
    api_integration: 'Integrasi API kustom',
    api_integration_tooltip: 'Silakan hubungi kami di help@taption.com untuk informasi lebih lanjut.',
    api_integration_basic_tooltip: 'Silakan hubungi kami di help@taption.com untuk informasi lebih lanjut.',
    api_integration_premium_tooltip: 'Silakan hubungi kami di help@taption.com untuk informasi lebih lanjut.',
    api_integration_corp_tooltip: 'Silakan hubungi kami di help@taption.com untuk informasi lebih lanjut.',
    custom_payments: 'Opsi penagihan kustom',
    custom_payments_tooltip: 'Opsi pembayaran yang fleksibel termasuk pembayaran dengan transfer bank/cek. Silakan hubungi kami di help@taption.com untuk informasi lebih lanjut.',
    advanced_training: 'Pelatihan lanjutan untuk pengguna',
    advanced_training_tooltip: 'Butuh bantuan untuk onboarding pengguna? Kami bisa membantu.',
    high_definition: 'Kualitas video asli',
    high_definition_tooltip: 'Edit dan bagikan video Anda dalam kualitas aslinya',
    mp4_export: 'Ekspor subtitle yang dibakar dalam video tanpa batas',
    mp4_export_basic_tooltip: 'Mengekspor video akan mengurangi tambahan menit untuk pengguna non-langganan.',
    editable_transcript_sharing: 'Berbagi transkrip yang dapat diedit',
    dark_mode: 'Mode Gelap',
    video_editing: 'Edit video dengan mengedit teks',
    video_cutting: 'Pemotongan video',
    multi_upload: 'Mentraskripsi beberapa file sekaligus',
    invoice_company: 'Faktur dengan judul kustom',
    invoice_taxid: 'Faktur dengan nomor ID pajak',
    share_minutes: 'Akses multi-pengguna',
    share_minutes_tooltip: 'Tambahkan pengguna ke akun untuk berbagi menit dan transkrip.',
    permission_users: 'Manajemen izin pengguna <a href="https://www.taption.com/collaborate" target="__blank" style="color:#2FC59F;padding:0px 5px;font-size:12px;">pelajari lebih lanjut</a>',
    permission_users_tooltip: 'Batasi akun yang ditambahkan untuk menerjemahkan, mengunggah, menghapus, mengekspor, dan mengedit transkrip.',
    max_char: 'Jumlah karakter maksimum untuk subtitle',
    max_char_tooltip: 'Jumlah karakter maksimum untuk subtitle yang ditampilkan sekaligus.<br> Transkrip akan dibagi menjadi bagian sesuai dengan batas ini.',
    direct_link: 'Terima tautan sebagai sumber saat mentranskripsi',
    direct_link_tooltip: 'Mendukung tautan berikut:<br>• Tautan video Facebook<br>• Tautan video Instagram<br>• X (sebelumnya Twitter) tautan video<br>• Tautan video TikTok<br>• Tautan URL file media<br>• Tautan berbagi Dropbox<br>• Tautan berbagi One Drive<br>• Tautan berbagi Google Drive<br><br>',
    auto_extent_subtitle: 'Perpanjang otomatis durasi setiap subtitle',
    auto_extent_subtitle_tooltip: 'Perpanjang durasi semua subtitle dengan panjang waktu tertentu. <br/>Dengan melakukan ini, Anda dapat menghindari subtitle yang "berkedip" di antara jeda singkat, yang membuat transisi menjadi lebih halus.',
    clipped_video: 'Mentraskripsi dalam rentang waktu',
    clipped_video_tooltip: 'Ekstrak dan transkripsikan dalam rentang waktu yang dipilih dari file media.<br>Mendukung jenis media Youtube, mp3, dan mp4.',
    custom_format: 'Format unduhan kustom',
    custom_format_tooltip: 'Saat mengekspor transkrip Anda sebagai txt atau pdf, pembuat template yang fleksibel kami memungkinkan Anda mengunduh dalam format yang diinginkan.',
    editing_font_size: 'Sesuaikan ukuran font di platform pengeditan',
    low_cost: 'Harga terjangkau.',
    title_basic: 'Bayar sesuai pemakaian',
    basic_cost_usd: '$8',
    basic_cost_ntd: '(240 NTD)',
    hour: 'jam',
    basic_feature5: 'Kredit <span style="color: #00d1b2">60 menit</span>',
    basic_feature1: 'Transkripsi otomatis',
    basic_feature2: '40+ bahasa didukung',
    basic_feature3: 'Editor teks yang disorot',
    basic_feature4: 'Terjemahkan ke lebih dari 50 bahasa',
    basic_feature7: 'Kamus AI Pribadi',
    basic_feature6: 'Izinkan penggunaan komersial',
    basic_feature8: 'Mendukung subtitle multibahasa yang dibakar dalam video',
    title_premium: 'Langganan Premium',
    premium_cost_usd: '$12',
    premium_cost_ntd: '(360 NTD)',
    premium_basic_cost_usd: '$6',
    month: 'bulan',
    premium_before: '<span class="landing-arrow-left">&#10229;</span>Semua fitur dalam「Bayar sesuai pemakaian」',
    premium_feature1: '<span style="color: #00d1b2">120 menit</span> kredit ke akun Anda setiap bulan.<br><span style="color:#797676;font-size:14px;font-weight: 100">(Menit rollover, berlaku hingga langganan dibatalkan)</span>',
    premium_feature1_tooltip: 'Jika menit bulanan tidak habis digunakan, mereka akan diakumulasi ke bulan berikutnya dan berlaku hingga langganan dibatalkan. <br>Menit bulanan ini digunakan sebelum menit tambahan yang dibeli secara terpisah. <br>Menit tambahan yang dibeli secara terpisah tidak kedaluwarsa setelah langganan dibatalkan.',
    premium_feature2: 'Diskon 25% untuk pembelian per jam',
    premium_feature3: 'Ekspor subtitle yang dibakar dalam video tanpa batas secara gratis',
    premium_feature4: 'Berbagi transkrip yang dapat diedit',
    premium_feature5: 'Ekspor rekaman audio Anda ke video dengan subtitle',
    premium_feature6: 'Pemotongan video (Transkrip/subtitle yang diekspor akan otomatis disesuaikan)',
    title_corp: 'Langganan Bulk',
    bulk_cost_usd: '$69',
    bulk_cost_ntd: '(2070 NTD)',
    bulk_basic_cost_usd: '$4',
    bulk_before: '<span class="landing-arrow-left">&#10229;</span>Semua fitur dalam「Bayar sesuai pemakaian」dan「Langganan Premium」',
    bulk_feature2: 'Diskon 63% untuk pembelian per jam',
    premium_feature1_bulk: '<span style="color: #00d1b2">1000 menit</span> kredit ke akun Anda setiap bulan.<br><span style="color:#797676;font-size:14px;font-weight: 100">(Menit rollover, berlaku hingga langganan dibatalkan)</span>',
    bulk_feature1: 'Menyediakan unduhan tanda terima dengan nama dan ID pajak institusi Anda',
    bulk_feature3: 'Berbagi menit dengan pengguna',
    bulk_feature4: '6 izin yang dapat dikonfigurasi',
    go_to_price: 'Detail lebih lanjut',
    choose_plan: 'Pilih paket',
    annually: 'Tahunan',
    monthly: 'Bulanan',
    annually_save: 'Hemat {0}% dengan penagihan tahunan',
    annually_deduct: '${0} ditagih tahunan',
    unit: 'Dolar AS',
}