module.exports = {
  account_settings: 'Kontoeinstellungen',
  name: 'Name',
  company: 'Firma',
  company_description: 'Der Firmenname wird auf Rechnungen und Zahlungen angezeigt.',
  institution_name: 'Steuerinformationen',
  email: 'E-Mail',
  card: 'Karte',
  create_password: 'Neues Passwort erstellen',
  password: 'Passwort',
  status: 'Status',
  new_password: 'Neues Passwort',
  confirm_password: 'Passwort bestätigen',
  update: 'Aktualisieren',
  update_name: 'Name aktualisieren',
  update_corp_name: 'Firmennamen aktualisieren',
  update_taxid_name: 'Steuer-ID aktualisieren',
  update_email: 'E-Mail aktualisieren',
  update_password: 'Passwort aktualisieren',
  upgrade: 'Upgrade',
  unsubscribe: 'Abmelden',
  update_name_description: '',
  update_email_description: 'Bitte geben Sie Ihre neue E-Mail-Adresse ein',
  update_email_password_confirm: 'Passwort bestätigen',
  update_password_description: '',
  thank_you_interest: 'Vielen Dank für Ihr Interesse',
  reach_out: 'Bitte kontaktieren Sie uns unter: {0}',
  update_language: 'Spracheinstellung',
  reactivate: 'Reaktivieren',
  credit_card_update: 'Ihre Kreditkarte wurde aktualisiert',
  password_update: 'Ihr Passwort wurde aktualisiert',
  click_here: 'Hier klicken',
  if_not_direct: 'Wenn Sie nicht in 3 Sekunden weitergeleitet werden...',
  email_verified: 'Ihre E-Mail wurde verifiziert.',
  email_restored: 'Ihre E-Mail wurde aktualisiert',
  email_unable_to_authenticate: 'Wir unterstützen keine anonyme E-Mail-Registrierung',
  email_unable_to_authenticate_desc: 'Bitte registrieren Sie sich mit einer gültigen E-Mail, um kostenlose Minuten zu erhalten.',
  purchase_records: 'Rechnungen',
  purchase_date: 'Datum',
  purchase_amount: 'Betrag',
  purchase_download: 'Herunterladen',
  purchase_view: 'Alle ansehen',
  purchase_recent: 'Die letzten 20⟶',
  purchase_unit: 'USD',
  coupon_title: 'Aktionscode',
  coupon_redeem: 'Einlösen',
  role: 'Rolle',
  view: 'Ansehen',
  upload: 'Hochladen',
  edit: 'Bearbeiten',
  translate: 'Übersetzen',
  export: 'Exportieren',
  analysis: 'KI-Analyse',
  admin: 'Admin',
  producer: 'Produzent',
  member: 'Mitglied',
  individual: 'Einzelnutzer',
  editor: 'Editor',
  viewer: 'Zuschauer',
  disabled: 'Deaktiviert',
  custom: 'Benutzerdefiniert',
  check_description: 'Volle Kontrolle',
  info_description: 'Zugriff nur auf Transkriptionen, die von diesem Benutzer hochgeladen wurden',
  times_description: 'Kein Zugriff',
  member_name: 'Name',
  member_email: 'E-Mail',
  member_last_login: 'Letzter Login',
  member_minutes: 'Verwendete Minuten',
  user_role: 'Rolle',
  add_new_role: 'Neue Mitglieder hinzufügen',
  member_invite: 'Einladen',
  member_slot: 'Anzahl der Benutzer',
  share_slot: 'Anzahl der freigegebenen Benutzer',
  member_slot_price_desc: 'Zusätzliche Sitze kosten {1} pro {0}.',
  member_slot_increase: 'Benutzerplätze erhöhen',
  member_slot_increase_header: 'Sitze hinzufügen',
  member_slot_price_tooltip: 'Der Preis wird anteilig bis zum nächsten Abrechnungszyklus berechnet',
  member_slot_agree: 'Ich verstehe und stimme den folgenden Bedingungen zu:',
  member_slot_agree_desc1: '• {0} ist der anteilige Betrag für diese Abonnementdauer bis zum nächsten Abrechnungsdatum',
  member_slot_agree_desc2: '• Ab dem nächsten Abrechnungszyklus erhöht sich die Abonnementgebühr um <span>{0} pro {1}</span> vom aktuellen Tarif',
  member_slot_agree_desc3: '• Kostenaufstellung: {0}',
  member_slot_purchase: 'Zahlung bestätigen',
  member_invite_desc: 'Sobald ein Benutzer hinzugefügt wurde, kann er nicht ersetzt werden. Weisen Sie ihm <br> die Berechtigung zu, den Zugriff zu widerrufen',
  tax_id: 'Steuer-ID',
  optional: '(Optional)',
  required: '(Erforderlich)',
  added_users: 'Mitglieder',
  shareable_users: 'Freigegebene Mitglieder',
  available_user_permissions: 'Verfügbare Benutzerberechtigungen',
  corp_manage: 'Verwaltung',
  edit_credit_card: 'Kreditkarte bearbeiten',
  third_party: 'Installierte Apps',
  disconnect: 'Deinstallieren',
  personal_information: 'Persönliche Informationen',
  billing_information: 'Abrechnungsdetails',
  product_information: 'Abonnement',
  other_information: 'Sonstiges',
  remaining_sub_minutes: 'Abonnementminuten',
  remaining_ai_credits: 'KI-Credits',
  remaining_forever_minutes: 'Minuten',
  remaining_total_minutes: 'Gesamtminuten',
  add_more_minutes: 'Minuten hinzufügen',
  account_removal: 'Bestätigung der Kontolöschung',
  account_removal_desc: '<span style="color:#00D1B2;font-weight:bold;">{0}</span> <br/> verliert den Zugriff auf alle freigegebenen Transkriptionen.<br/> Möchten Sie dieses Konto wirklich löschen?',
  branding: 'Logo',
  branding_desc: 'Dieses Logo kann das Standard-Taption-Logo ersetzen, wenn Sie Transkriptionen als PDF exportieren.',
  add_payment_method: 'Bitte gehen Sie zu <strong>"{0}"</strong> → <strong>"{1}"</strong> → <strong>"{1}"</strong>, um eine Zahlungsmethode hinzuzufügen',
  add_member_tips1: 'Laden Sie mehrere Empfänger ein, indem Sie ihre E-Mails durch Kommas trennen.',
  add_member_tips2: 'Jede eingegebene E-Mail muss ein registrierter Taption-Nutzer sein.'
}
