module.exports = {
    convert_to_text_title_meta:"Video in Text umwandeln - Online KI-Transkriptionssoftware",
    convert_to_text_desc_meta:"Wandeln Sie MP4-Videos, YouTube-Videos und MP3-Audio online automatisch in Text um. Unterstützt über 40 Sprachen, bietet mehrsprachige Untertitel und automatische Formatierung und Sprecherbeschriftung. Melden Sie sich heute für eine kostenlose Testversion an!",
    convert_to_text_title:"Video in Text umwandeln",
    convert_to_text_desc:"Wandeln Sie Ihr MP4-Video oder MP3-Audio automatisch in Text um. Unterstützt mehrsprachige Untertitel, automatische Formatierung und Sprecherbeschriftung.",
    start_your_trial:"Starten Sie Ihre kostenlose Testversion!",
    hero_img:"./01_English.webp",

    three_step_title:"Transkriptionsschritte",
    step_one:"Datei hochladen",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"Wählen Sie aus Gerät, YouTube, Google Drive oder Zoom zum Konvertieren in Text.",
    
    step_two:"Methode wählen",
    step_two_img:"./select_split_en.webp",
    step_two_desc:"Wählen Sie Ihre Transkriptionsmethode aus den folgenden: 1) Automatische Transkription 2) Textdatei importieren 3) Manuell, und wählen Sie „Artikel“ für die Segmentierung der einzelnen Abschnitte.",

    step_three:"Exportieren",
    step_three_img:"./select_export_en.webp",
    step_three_desc:"Nach der Bearbeitung auf der Plattform exportieren Sie (srt, txt, pdf, mp4), indem Sie auf die Schaltfläche „Exportieren“ oben rechts klicken.",

    feature_tutorial_title:'Mühelose Video-zu-Text-Konvertierung',
    feature_tutorial_desc:'Taption bietet vier verschiedene Methoden, um Untertitel zu Ihrem Video hinzuzufügen: <span class="bullet-point">•</span>Automatische Transkription <span class="bullet-point">•</span>SRT-Import <span class="bullet-point">•</span>TXT-Import <span class="bullet-point">•</span>Manuelle Eingabe<br><br>Unsere Bearbeitungsplattform dient als intuitives Tool für Audio-zu-Text-, Sprach-zu-Text- und Video-zu-Text-Konvertierungen, um Ihre Transkriptionsaufgaben zu optimieren. Wenn Sie ein globales Publikum erreichen möchten, unterstützen wir auch mehrsprachige Untertitel. Sehen Sie sich unser Tutorial an, in dem wir diese Transkriptionsschritte einfach erklären, um Ihr Verständnis für eine effiziente Verwaltung Ihrer Video-zu-Text-Anforderungen zu verbessern.',
    feature_tutorial_poster_url:'./add_subtitles_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/KvyaH-QU9is?autoplay=1',

    feature_extra_title:"Die vielen Vorteile der Video-zu-Text-Konvertierung",
    feature_extra_desc:"Die Video-zu-Text-Konvertierung, einschließlich Audio-zu-Text- und Sprach-zu-Text-Prozessen, geht über die Grundfunktion der Untertitelerstellung hinaus. Diese Dienste bieten eine umfassende Suite von Vorteilen, die Ihre Inhaltsstrategie durch Transkription erheblich verbessern können. Beachten Sie die folgenden Hauptvorteile der Verwendung von Video-zu-Text-, Audio-zu-Text- und Sprach-zu-Text-Konvertierungen in Ihrem Betrieb: <ul><li><strong>Durchsuchbare Medien:</strong> Ihre Mediendateien werden vollständig durchsuchbar, was die Auffindbarkeit Ihres Inhalts verbessert. </li><li><strong>Effiziente Überprüfung und Inhaltsänderung:</strong> Sie beschleunigen Ihre Überprüfungs- und Bearbeitungsprozesse, ohne die Qualität zu beeinträchtigen. </li><li><strong>Einfache Übersetzung:</strong> Sie können mühelos mehrere Sprachen abdecken und so Ihre globale Reichweite erweitern. </li><li><strong>SEO-Optimierung:</strong> Sie machen Ihre Medien durchsuchbar und steigern so die SEO-Leistung Ihrer Website. </li><li><strong>ADA-Konformität:</strong> Ihr Inhalt wird für alle zugänglich sein und entspricht den ADA-Konformitätsstandards. </li></ul>",

    feature_why_image: "./translate_step1_en.webp",
    feature_what_title:'5 Schritte zur Audio-zu-Text-Konvertierung',
    feature_what_desc: "<ul>\n  <li>Registrieren Sie ein neues Konto oder melden Sie sich mit Gmail an: Gehen Sie zur <a href=\"https://www.taption.com/login\">Taption-Anmeldeseite</a>.</li>\n  <li>Klicken Sie auf die Schaltfläche „Hochladen“ und wählen Sie die Medienquelle aus, z. B. das Hochladen von Ihrem Computer, YouTube, Google Drive, Zoom oder das Einfügen eines Medienlinks.</li>\n  <li>Wählen Sie die „Sprache“ und „Segmentierungsmethode“ für die Transkription aus.</li>\n  <li>Nach dem Hochladen wird die Datei automatisch in Text umgewandelt, und Sie können auf der Bearbeitungsplattform weitere Bearbeitungen vornehmen.</li>\n  <li>Auf der Bearbeitungsplattform können Sie übersetzen, die KI-Analyse verwenden und schließlich das Dokument exportieren oder die Untertitel in die Videodatei einbetten.</li>\n</ul>"
}
