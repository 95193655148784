module.exports = {
    login:'登入',
    or:'或',
    member_login:'會員登入',
    signin_with:'以此身分登入:',
    signin_fb:'以 Facebook 登入',
    signin_google:'用Google登錄',
    email_address: '電子郵件',
    password: '密碼',
    confirm_password: '重新輸入密碼',
    been_logged_out_title: '您被登出了',
    //been_logged_out_desc: '請重新登入',
    been_logged_out_desc: '您的帳號在別的地點登入',
    forgot_password:'忘記密碼',
    create_free_account:'創建新帳號',
    create_an_account:'創建帳號',
    create_account:'創建帳號',
    or_go_back_to:'或者返回',
    name:'名子',
    check_to_reset:'請去信箱點擊連結來重製密碼',
    check_to_verify_header:'驗證信箱',
    check_to_verify:'您的帳號已經建立，請到信箱點擊我們寄給你的連結來啟用. 如果沒收到請到 <span style="font-weight:bold">垃圾郵件</span> 確認',
    send_to_verify_header:'驗證信已寄出',
    send_to_verify:'請到信箱點擊我們寄給你的連結來啟用. 如果沒收到請到 <span style="font-weight:bold">垃圾郵件</span> 確認',
    term_of_service_description1: '當您註冊完成或開始使用本服務時，即表示您已閱讀、了解並同意接受本',
    term_of_service:'服务条款',
    term_of_service_description2: '之所有內容和',
    privacy_policy:'隱私權政策',
    resend_verification:'重新寄驗證EMAIL',
  //  invite_desc:'感謝{0}的邀請<br> 你將在你訂閱時免費獲得額外{1}分鐘。你註冊將送你免費一次轉換15分鐘以內的影片.',
    invite_desc:'感謝{0}的邀請<br> 註冊立即送你15分鐘',
    login_desc:'註冊立即送15分鐘',
    referral_note:'註冊時無需信用卡',
    redirecting:'載入頁面中...'
  }