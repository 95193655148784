module.exports = { 
    affiliate: 'Marketing d\'affiliation',
    page_title: 'Rejoignez le programme d\'affiliation Taption',
    page_description: 'Présentez Taption aux nouveaux clients. Partagez votre lien d\'affiliation exclusif et gagnez des commissions à chaque recommandation réussie. Ensemble, faisons croître notre communauté !',
    tell_us_about_yourself: 'PARLEZ-NOUS DE VOUS',
    first_name: 'Prénom',
    last_name: 'Nom de famille',
    business_name: 'Nom de l\'entreprise (si applicable)',
    location: 'Où habitez-vous ? (Ville, État, Pays)',
    contact: 'INFORMATIONS DE CONTACT',
    email: 'Adresse e-mail',
    phone: 'Numéro de téléphone',
    linkedin_url: 'URL LinkedIn',
    social_url: 'URL des réseaux sociaux',
    tell_us_about_site: 'PARLEZ-NOUS DE VOTRE SITE',
    website_url: 'URL du site web',
    traffic: 'Trafic mensuel estimé',
    what_site_about: 'De quoi parle votre site ?',
    anything_else: 'Autre chose que nous devrions savoir ?',
    must_agree: 'Vous devez accepter pour continuer',
    read_agree: 'J\'ai lu et j\'accepte les',
    affiliate_tos: 'Conditions générales de l\'alliance',
    submit_success: 'Merci de votre intérêt ! Votre candidature a été soumise, et notre équipe vous contactera dès que possible.',
    name_required_err: 'Le nom est requis',
    name_character_limit_err: 'Le nom doit contenir moins de 50 caractères',
    email_required_err: 'L\'e-mail est requis',
    email_invalid_err: 'L\'email doit être valide',
    phone_needed_err: 'Veuillez entrer un numéro de téléphone valide',
    website_required_err: 'L\'URL du site Web est requise',
    invalid_website_err: 'Veuillez entrer une URL valide',
    location_required_err: 'La localisation est requise',
    estimate_traffic_err: 'Veuillez sélectionner une estimation du trafic',
    describe_site_err: 'Veuillez décrire votre site.',
    submit: 'Soumettre une demande',
    generic_err: 'Veuillez examiner le formulaire et corriger toute erreur en surbrillance avant de le soumettre.',
    step1: 'Étape 1 : Inscrivez-vous',
    step1_desc: 'L\'inscription prend moins de 3 minutes. Remplissez le formulaire ci-dessous et nous vous contacterons sous 24 heures.',
    step2: 'Étape 2 : Partagez votre lien personnalisé',
    step2_desc: 'Une fois approuvé en tant qu\'affilié Taption, vous recevrez un lien unique à partager, garantissant le suivi de toutes les références et commissions.',
    step3: 'Étape 3 : Gagner de l\'argent',
    step3_desc: 'Gagnez des commissions lorsque quelqu\'un s\'abonne ou achète des minutes via votre lien—simple et gratifiant !',
}