module.exports = {
    convert_to_text_title_meta:"Convertir vídeo a texto - Software de transcripción con IA en línea",
    convert_to_text_desc_meta:"Convierte automáticamente vídeos MP4, vídeos de YouTube y audio MP3 a texto en línea. Soporta más de 40 idiomas, ofrece subtítulos multilingües y cuenta con formato automático y etiquetado de oradores. ¡Regístrate para una prueba gratuita hoy mismo!",
    convert_to_text_title:"Convertir vídeo a texto",
    convert_to_text_desc:"Convierte automáticamente tu vídeo MP4 o audio MP3 a texto. Soporta subtítulos multilingües, formato automático y etiquetado de oradores.",
    start_your_trial:"¡Comienza tu prueba gratuita!",
    hero_img:"./01_English.webp",

    three_step_title:"Pasos de transcripción",
    step_one:"Sube el archivo",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"Selecciona desde el dispositivo, YouTube, Google Drive o Zoom para convertir a texto.",
    
    step_two:"Elige el método",
    step_two_img:"./select_split_en.webp",
    step_two_desc:"Elige tu método de transcripción de entre las siguientes opciones: 1) Transcripción automática 2) Importar archivo de texto 3) Manual, luego selecciona Segmentar cada sección para \"artículo\".",

    step_three:"Exporta",
    step_three_img:"./select_export_en.webp",
    step_three_desc:"Después de editar en la plataforma, exporta (srt, txt, pdf, mp4) haciendo clic en el botón de exportación en la parte superior derecha.",

    feature_tutorial_title:'Conversión de vídeo a texto sin esfuerzo',
    feature_tutorial_desc:'Taption ofrece cuatro métodos distintos para añadir subtítulos a tu vídeo: <span class="bullet-point">•</span>Transcripción automática <span class="bullet-point">•</span>Importación de SRT <span class="bullet-point">•</span>Importación de TXT <span class="bullet-point">•</span>Entrada manual<br><br>Nuestra plataforma de edición sirve como una herramienta intuitiva para la conversión de audio a texto, voz a texto y vídeo a texto, ayudando a agilizar tus tareas de transcripción. Para aquellos que buscan llegar a audiencias globales, también ofrecemos soporte para subtítulos multilingües. Asegúrate de ver nuestro tutorial, donde explicamos estos pasos de transcripción de manera simple, mejorando tu comprensión de cómo gestionar eficientemente la conversión de vídeo a texto.',
    feature_tutorial_poster_url:'./add_subtitles_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/KvyaH-QU9is?autoplay=1',

    feature_extra_title:"Los muchos beneficios de la conversión de vídeo a texto",
    feature_extra_desc:"La conversión de vídeo a texto, que incluye los procesos de audio a texto y voz a texto, va más allá de la función básica de generar subtítulos. Estos servicios ofrecen una amplia gama de beneficios que pueden mejorar significativamente tu estrategia de contenido a través de la transcripción. Considera las siguientes ventajas clave de utilizar la conversión de vídeo a texto, audio a texto y voz a texto en tus operaciones: <ul><li><strong>Medios buscables:</strong> Tus archivos multimedia serán completamente buscables, lo que potenciará la visibilidad de tu contenido. </li><li><strong>Revisión eficiente y modificación de contenido:</strong> Aceleras tus procesos de revisión y edición sin tener que sacrificar la calidad. </li><li><strong>Traducción fácil:</strong> Puedes abordar varios idiomas sin esfuerzo y expandir tu alcance global de esta manera. </li><li><strong>Optimización SEO:</strong> Haces que tus medios sean buscables, lo que también significa que mejoras el rendimiento SEO de tu sitio. </li><li><strong>Conformidad ADA:</strong> Tu contenido será accesible para todos y cumplirá con los estándares de conformidad ADA. </li></ul>",

    feature_why_image: "./translate_step1_en.webp",
    feature_what_title:'5 Pasos para convertir audio a texto',
    feature_what_desc: "<ul>\n  <li>Registra una nueva cuenta o inicia sesión con Gmail: Ve a la <a href=\"https://www.taption.com/login\">página de inicio de sesión de Taption</a>.</li>\n  <li>Haz clic en el botón \"Subir\" y selecciona la fuente multimedia, como subir desde tu ordenador, YouTube, Google Drive, Zoom o pegar un enlace multimedia.</li>\n  <li>Selecciona el \"Idioma\" y el \"Método de Segmentación\" necesario para la transcripción.</li>\n  <li>Después de subir, el archivo se convertirá automáticamente a texto, y puedes proceder a la plataforma de edición para realizar más ediciones.</li>\n  <li>En la plataforma de edición, puedes traducir, usar análisis con IA y finalmente exportar el documento o incrustar los subtítulos en el archivo de vídeo.</li>\n</ul>"
}
