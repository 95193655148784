module.exports = {
  account_settings: '계정 설정',
  name: '이름',
  company: '회사명',
  company_description:'회사명은 청구서 및 결제 시 표시됩니다.',
  institution_name:'기관명/계정 이름',
  email: '이메일',
  card: '신용카드',
  create_password:'새 비밀번호 만들기',
  password: '비밀번호',
  status: '상태',
  new_password: '새 비밀번호',
  confirm_password: '비밀번호를 확인하세요',
  update: '변경',
  update_name: '성함 변경',
  update_corp_name: '회사명 변경',
  update_taxid_name: '납세자 식별변호 변경',
  update_email: '이메일 변경',
  update_password: '비밀번호 변경',

  upgrade: '업그레이드',
  unsubscribe: '구독 취소',

  update_name_description: '',
  update_email_description: '새 이메일 주소를 입력하세요',
  update_email_password_confirm: '비밀번호를 확인하세요',
  update_password_description: '',
  
  thank_you_interest:'관심을 가져주셔서 감사합니다',
  reach_out:'문의사항은 {0}로 연락주세요',
  update_language:'언어설정',
  reactivate:'계정 복구',

  credit_card_update:'신용카드가 업데이트되었습니다',
  password_update:'비밀번호가 변경되었습니다.',
  click_here:'여기를 클릭하세요',
  if_not_direct:'3초 안에 리디렉션되지 않는 경우...',
  email_verified:'메일이 인증되었습니다.',
  email_restored:'메일이 변경되었습니다',

  email_unable_to_authenticate: '익명 이메일 등록은 허용되지 않습니다',
  email_unable_to_authenticate_desc: '무료 이용시간을 받으시려면 유효한 이메일을 등록해주세요',
  purchase_records: '청구서',
  purchase_date: '날짜',
  purchase_amount: '금액',
  purchase_download: '다운로드',
  purchase_view: '모두 보기',
  purchase_recent:'최근 20건⟶',
  purchase_unit:'USD',

  coupon_title:'할인 코드',
  coupon_redeem: '적용',

  role:'권한',
  view:'보기',
  upload:'업로드',
  edit:'편집',
  translate:'번역',
  export:'추출',
  analysis:'AI분석',

  admin: '관리자',
  producer: '제작자',
  member: '멤버',
  individual: '개인',
  editor: '편집',
  viewer: '열람',
  disabled: '권한 없음',
  custom:'사용자 정의',

  check_description:'전체 권한',
  info_description:'이 유저가 업로드한 받아쓰기에만 접근권한 있음',
  times_description:'접근권한 없음',

  member_name: "이름",
  member_email: "이메일",
  member_last_login: "최근 로그인 날짜",
  member_minutes: "사용시간",
  user_role:"권한",
  add_new_role:'새 멤버를 추가',
  member_invite: '초대',
  member_slot:'유저 수',
  share_slot:'공유가능한 유저 수',
  member_slot_price_desc: '슬롯 추가는 한{0}에 ${1} 요금이 발생합니다.',
  member_slot_increase: '유저 슬롯 추가하기',
  member_slot_increase_header: '슬롯 추가',
  member_slot_price_tooltip: '이 수수료는 오늘부터 다음 구독일까지 일할 계산된 총액입니다.',
  member_slot_agree:'이해 및 동의합니다:',
  member_slot_agree_desc1:'* {0}은(는) 오늘부터 다음 결제일까지의 비례 계산된 금액입니다',
  member_slot_agree_desc2:'* 다음 구독 주기부터 구독 요금이 기존 금액에 <span>{0}/{1}</span>만큼 추가됩니다',
  member_slot_agree_desc3:'* 금액 상세: {0}',
  member_slot_purchase:'결제 확인',
  member_invite_desc:'추가된 유저는 다른 유저로 변경하거나 {0} <br> 권한을 줄 수 없습니다.',
  tax_id:'납세자 등록번호',
  optional:'(선택항목)',
  required:'(필수항목)',
  added_users:'멤버',
  shareable_users:'멤버',
  available_user_permissions:'사용가능한 권한 목록',
  corp_manage:'관리',
  edit_credit_card:'신용카드 정보 수정',

  third_party:'설치된 앱',
  disconnect:'앱 삭제',

  personal_information:'개인 정보',
  billing_information:'청구 정보',
  product_information:'구독 정보',
  other_information:'기타 정보',

  remaining_sub_minutes: '구독 이용가능시간(분)',
  remaining_ai_credits: '남은 AI 포인트',
  remaining_forever_minutes: '이용가능시간(분)',
  remaining_total_minutes: '총 이용가능시간(분)',
  add_more_minutes:'이용시간 추가',

  account_removal:'계정 삭제 확인',
  account_removal_desc:'<span style="color:#00D1B2;font-weight:bold;">{0}</span> <br/>는 공유된 받아쓰기에 대한 모든 액세스를 잃게 됩니다.<br/> 계정을 삭제하시겠습니까?',
  branding:'상표 아이콘',
  branding_desc: 'PDF를 내보낼 때 이 사용자 지정 브랜드 아이콘을 사용하여 기본 Taption 아이콘을 대체할 수 있습니다.',
  add_payment_method: '결제 방식을 추가하려면 <strong>「{0}」</strong> → <strong>「{1}」</strong> → <strong>「{1}」</strong>으로 이동하세요.',
  add_member_tips1: '여러 이메일을 초대하려면 이메일을 쉼표로 구분하세요',
  add_member_tips2: '입력된 모든 이메일은 Taption에 등록된 사용자여야 합니다'
}
