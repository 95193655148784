module.exports = { 
    affiliate: 'アフィリエイトマーケティング',
    page_title: 'Taptionアフィリエイトプログラムに参加する',
    page_description: 'Taptionを新しいお客様に紹介してください。あなたの専用アフィリエイトリンクを共有し、成功した紹介ごとにコミッションを獲得しましょう。一緒に成長しましょう！',
    tell_us_about_yourself: '私たちにあなた自身について教えてください。',
    first_name: '名',
    last_name: '姓',
    business_name: '事業名（該当する場合）',
    location: 'どちらにお住まいですか？（市、州、国）',
    contact: '連絡先情報',
    email: 'メールアドレス',
    phone: '電話番号',
    linkedin_url: 'LinkedIn URL',
    social_url: 'ソーシャルメディアURL',
    tell_us_about_site: 'あなたのサイトについて教えてください',
    website_url: 'WebサイトURL',
    traffic: '推定月間トラフィック',
    what_site_about: 'あなたのサイトは何についてですか？',
    anything_else: '他に知っておくべきことはありますか？',
    must_agree: '続行するには同意が必要です。',
    read_agree: '私は読んで、に同意します',
    affiliate_tos: '連盟に関する利用規約',
    submit: '申請を提出',
    submit_success: 'ご関心をお寄せいただき、ありがとうございます！応募を受け付けました。担当者より速やかにご連絡いたします。',
    name_required_err: '名前が必要です',
    name_character_limit_err: '名前は50文字未満でなければなりません',
    email_required_err: 'メールアドレスは必須です',
    email_invalid_err: 'メールは有効でなければなりません',
    phone_needed_err: '有効な電話番号を入力してください。',
    website_required_err: 'ウェブサイトのURLが必要です',
    invalid_website_err: '有効なURLを入力してください',
    location_required_err: '場所が必要です',
    estimate_traffic_err: 'トラフィック推定を選択してください',
    describe_site_err: 'あなたのサイトを説明してください。',
    generic_err: '送信する前にフォームを確認し、ハイライトされたエラーを修正してください。',
    step1: 'ステップ 1: 登録',
    step1_desc: 'サインアップは3分以内で完了します。以下のフォームにご記入いただければ、24時間以内にご連絡いたします。',
    step2: 'ステップ2: カスタムリンクを共有',
    step2_desc: 'Taptionのアフィリエイトに承認されると、専用リンクが提供され、すべての紹介とコミッションが追跡されます。',
    step3: 'ステップ3: お金を稼ぐ',
    step3_desc: 'あなたのリンクを通じて誰かがサブスクライブしたり、分を購入したりすると、報酬が得られます—簡単でやりがいがあります！',
}