module.exports = {
    convert_to_text_title_meta:"Ringkasan Video AI Online: Tambahkan Topik, Rangkum Pertemuan, Buat Bab YouTube dengan Satu Klik",
    convert_to_text_desc_meta:"Alat analisis AI kami memungkinkan Anda merangkum konten secara efisien, menentukan pembicara utama, membuat skrip narasi, dan mengintegrasikan bab dan stempel waktu YouTube. Jelajahi berbagai templat kami, termasuk peringkas video, generator stempel waktu YouTube, dan generator stempel waktu topik video. Mulailah dengan uji coba gratis hari ini!",
    convert_to_text_title:"Analisis AI",
    convert_to_text_desc:"Ringkasan video, Bab YouTube, Ringkasan Pertemuan",
    start_your_trial:"Mulai uji coba gratis Anda!",
    hero_img:"./11_English.webp",

    three_step_title:"3 Langkah untuk Mengonversi ke Teks",
    step_one:"Ajukan pertanyaan atau beri perintah",
    step_one_img:"./aianalysis_step1_en_US.webp",
    step_one_desc:"Anda dapat memilih apakah alat video AI harus merangkum konten Anda, mengidentifikasi pembicara utama, menyarankan skrip narasi, dan menambahkan Bab dan stempel waktu YouTube.",
    
    step_two:"Pilih dari perintah yang telah ditentukan",
    step_two_img:"./aianalysis_step2_en_US.webp",
    step_two_desc:"Analisis AI menawarkan templat yang telah ditentukan seperti generator ringkasan video, generator stempel waktu YouTube, dan generator stempel waktu topik video.",

    step_three:"Rangkum dalam bahasa pilihan Anda",
    step_three_img:"./aianalysis_step3_en_US.webp",
    step_three_desc:"Terlepas dari bahasa asli video, Anda dapat merangkum atau memberikan perintah dalam bahasa pilihan Anda.",

    feature_tutorial_title:'Analisis dan ringkasan video dengan Taption',
    feature_tutorial_desc:"Fitur \"Analisis AI\" adalah alat yang kuat yang dirancang untuk analisis konten video secara mendalam. Pengguna dapat mengunggah video untuk dirangkum secara komprehensif atau menggunakan templat yang telah ditentukan seperti ringkasan video, generator topik AI, stempel waktu YouTube, dan bab YouTube untuk analisis satu klik, menghilangkan kebutuhan akan perintah manual. Ini sangat meningkatkan pemahaman dan interaksi dengan poin utama video.",
    feature_tutorial_poster_url:'./aianalysis_poster_en_US.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/PWOWwbHQCUM?autoplay=1',

    feature_why_image:'./ai_template_demo_en.webp',
    feature_extra_title:"Penggunaan dan Contoh Analisis AI",
    feature_extra_desc:"Untuk memanfaatkan potensi penuh fitur Analisis AI, kuncinya adalah merumuskan perintah yang tepat. Berikut cara Anda dapat mengartikulasikan permintaan Anda untuk mendapatkan wawasan yang paling bermakna：<ul><li><strong>Perintah untuk Ringkasan</strong>：\"Ringkas video dalam 300 kata.\" Perintah ini ideal untuk ikhtisar cepat.</li><li><strong>Perintah untuk Tindakan</strong>：\"Identifikasi poin tindakan dari pertemuan.\" Perintah ini dapat membantu menentukan tugas dan tanggung jawab.</li><li><strong>Perintah untuk Umpan Balik Bimbingan</strong>：\"Anda adalah pelatih yang membantu. Berikan analisis transkrip dan tawarkan area untuk peningkatan dengan kutipan yang tepat. Tanpa pendahuluan.\"</li><li><strong>Skrip Video</strong>：Bantu saya membuat skrip untuk video penjelasan sekitar 5-10 menit untuk film ini, fokus pada plot dan ide yang ingin disampaikan oleh penulis.</li></ul>Ketika membuat perintah, mulailah dengan ringkasan umum, lalu tambahkan pernyataan yang lebih rinci.",
    
    feature_what_title:"Bagaimana cara menambahkan stempel waktu dan ringkasan pada YouTube Anda?",
    feature_what_desc: "<ul>\n  <li>Masuk ke <a href=\"https://www.taption.com/login\">Taption</a></li>\n  <li>Klik \"Unggah\" untuk mengunggah video MP4 Anda atau tempelkan tautan YouTube untuk ditranskripsikan</li>\n  <li>Setelah selesai, masuk ke platform pengeditan dan edit atau terjemahkan sesuai kebutuhan</li>\n  <li>Klik tombol \"Analisis AI\" di bawah</li>\n  <li>Klik \"Analisis\" untuk Bab YouTube dan Buat Ringkasan Video</li>\n</ul>"
}
