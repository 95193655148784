module.exports = { 
    affiliate: 'Afiliado',
    page_title: 'Únete al Programa de Afiliados de Taption',
    page_description: 'Introduce a nuevos clientes a Taption. Comparte tu enlace de afiliado único y gana comisión por cada referencia exitosa. ¡Crezcamos juntos!',
    tell_us_about_yourself: 'CUÉNTANOS SOBRE TI MISMO',
    first_name: 'Nombre',
    last_name: 'Apellido',
    business_name: 'Nombre de la empresa (si corresponde)',
    location: '¿Dónde vives? (Ciudad, Estado, País)',
    contact: 'INFORMACIÓN DE CONTACTO',
    email: 'Dirección de correo electrónico',
    phone: 'Número de teléfono',
    linkedin_url: 'URL de LinkedIn',
    social_url: 'URL de redes sociales',
    tell_us_about_site: 'CUÉNTENOS SOBRE SU SITIO',
    website_url: 'URL del sitio web',
    traffic: 'Tráfico mensual estimado',
    what_site_about: '¿De qué trata su sitio?',
    anything_else: '¿Hay algo más que deberíamos saber?',
    must_agree: 'Debe aceptar para continuar',
    read_agree: 'He leído y acepto los',
    affiliate_tos: 'Términos y condiciones de afiliación',
    submit: 'Enviar solicitud de afiliado',
    submit_success: '¡Gracias por tu interés! Tu solicitud ha sido enviada, y nuestro equipo se pondrá en contacto contigo lo antes posible.',
    name_required_err: 'Se requiere el nombre',
    name_character_limit_err: 'El nombre debe tener menos de 50 caracteres',
    email_required_err: 'Correo electrónico es obligatorio',
    email_invalid_err: 'El correo electrónico debe ser válido',
    phone_needed_err: 'Por favor, ingrese un número de teléfono válido',
    website_required_err: 'Se requiere URL del sitio web',
    invalid_website_err: 'Por favor, introduce una URL válida.',
    location_required_err: 'Se requiere ubicación',
    estimate_traffic_err: 'Por favor, seleccione la estimación de tráfico.',
    describe_site_err: 'Por favor describe tu sitio',
    generic_err: 'Por favor, revise el formulario y corrija cualquier error resaltado antes de enviarlo.',
    step1: 'Paso 1: Regístrate',
    step1_desc: 'Registrarse toma menos de 3 minutos. Complete el formulario a continuación y nos pondremos en contacto en un plazo de 24 horas.',
    step2: 'Paso 2: Comparte tu enlace personalizado',
    step2_desc: 'Una vez aprobado como afiliado de Taption, obtendrás un enlace único para compartir, garantizando que todas las referencias y comisiones sean rastreadas.',
    step3: 'Paso 3: Gana dinero',
    step3_desc: 'Gana comisiones cuando alguien se suscriba o compre minutos a través de tu enlace—¡simple y gratificante!',
}