module.exports = {
  login: 'Iniciar Sessão',
  or: 'ou',
  member_login: 'Login de Membro',
  signin_with: 'Iniciar sessão com:',
  signin_fb: 'Iniciar sessão com Facebook',
  signin_google: 'Iniciar sessão com Google',
  email_address: 'Endereço de email',
  password: 'Palavra-passe',
  confirm_password: 'Digite a senha novamente',
  been_logged_out_title: 'Foi desconectado',
  been_logged_out_desc: 'A sua conta foi acessada de outro local',
  forgot_password: 'Esqueceu-se da palavra-passe',
  create_free_account: 'Criar a sua conta gratuita',
  create_an_account: 'Criar uma conta',
  create_account: 'Criar conta',
  or_go_back_to: 'Ou volte para',
  name: 'Nome',
  check_to_reset: 'Por favor, verifique a sua caixa de entrada para redefinir a sua palavra-passe.',
  check_to_verify_header: 'Verificar o seu email',
  check_to_verify: 'A sua conta foi criada! Se não receber a mensagem de confirmação, verifique a sua pasta de <span style="font-weight:bold">Spam</span>.',
  send_to_verify_header: 'Email de verificação enviado',
  send_to_verify: 'Por favor, verifique o seu email para o link de verificação e ative a sua conta. Se não receber a mensagem de confirmação, verifique a sua pasta de <span style="font-weight:bold">Spam</span>.',
  term_of_service_description1: 'Ao iniciar sessão no Taption, concorda com os nossos ',
  term_of_service: 'termos de serviço',
  term_of_service_description2: ' e leu e compreendeu a ',
  privacy_policy: 'política de privacidade',
  resend_verification: 'Reenviar email de verificação',
  invite_desc: 'Obrigado a {0}<br>Inscreva-se e ganhe 15 minutos grátis',
  login_desc: 'Inscreva-se e ganhe 15 minutos grátis',
  referral_note: 'Não é necessário cartão de crédito.',
  redirecting: 'A redirecionar...'
}
